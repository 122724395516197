// DoctorAvailability.js

import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import moment from "moment";
import SpecialSlots from "./SpecialSlots";
import Button from "../atoms/Button";
import Heading from "../atoms/Heading";
import { useDispatch } from "react-redux";
import { submitTherapistProfile } from "../../redux/onboarding/onboardingThunks";
import Modal from "react-modal";
import isEqual from "lodash/isEqual";
import WarningIcon from "../../assets/warning_icon.svg";

const timeOptions = [
  { value: "00:00", label: "12:00 AM" },
  { value: "00:30", label: "12:30 AM" },
  { value: "01:00", label: "01:00 AM" },
  { value: "01:30", label: "01:30 AM" },
  { value: "02:00", label: "02:00 AM" },
  { value: "02:30", label: "02:30 AM" },
  { value: "03:00", label: "03:00 AM" },
  { value: "03:30", label: "03:30 AM" },
  { value: "04:00", label: "04:00 AM" },
  { value: "04:30", label: "04:30 AM" },
  { value: "05:00", label: "05:00 AM" },
  { value: "05:30", label: "05:30 AM" },
  { value: "06:00", label: "06:00 AM" },
  { value: "06:30", label: "06:30 AM" },
  { value: "07:00", label: "07:00 AM" },
  { value: "07:30", label: "07:30 AM" },
  { value: "08:00", label: "08:00 AM" },
  { value: "08:30", label: "08:30 AM" },
  { value: "09:00", label: "09:00 AM" },
  { value: "09:30", label: "09:30 AM" },
  { value: "10:00", label: "10:00 AM" },
  { value: "10:30", label: "10:30 AM" },
  { value: "11:00", label: "11:00 AM" },
  { value: "11:30", label: "11:30 AM" },
  { value: "12:00", label: "12:00 PM" },
  { value: "12:30", label: "12:30 PM" },
  { value: "13:00", label: "01:00 PM" },
  { value: "13:30", label: "01:30 PM" },
  { value: "14:00", label: "02:00 PM" },
  { value: "14:30", label: "02:30 PM" },
  { value: "15:00", label: "03:00 PM" },
  { value: "15:30", label: "03:30 PM" },
  { value: "16:00", label: "04:00 PM" },
  { value: "16:30", label: "04:30 PM" },
  { value: "17:00", label: "05:00 PM" },
  { value: "17:30", label: "05:30 PM" },
  { value: "18:00", label: "06:00 PM" },
  { value: "18:30", label: "06:30 PM" },
  { value: "19:00", label: "07:00 PM" },
  { value: "19:30", label: "07:30 PM" },
  { value: "20:00", label: "08:00 PM" },
  { value: "20:30", label: "08:30 PM" },
  { value: "21:00", label: "09:00 PM" },
  { value: "21:30", label: "09:30 PM" },
  { value: "22:00", label: "10:00 PM" },
  { value: "22:30", label: "10:30 PM" },
  { value: "23:00", label: "11:00 PM" },
  { value: "23:30", label: "11:30 PM" },
  { value: "23:59", label: "11:59 PM" },
];

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const TherapistAvailability = ({ userData }) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const initialSchedules = userData?.appointment_schedules?.schedules || {};
  // const initialSpecialDates =
  //   userData?.appointment_schedules?.special_dates || [];
  const [availability, setAvailability] = useState(
    userData.appointment_schedules.schedules
  );
  const initialSelectedStartTimes = {};
  daysOfWeek.map((day) => {
    // Initialize selectedStartTimes with slot.start_time values for the current day
    availability[day].slots.forEach((slot, index) => {
      initialSelectedStartTimes[`${day}_${index}`] = slot.start_time.value;
    });
  });

  const [selectedStartTimes, setSelectedStartTimes] = useState(
    initialSelectedStartTimes
  );

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [cancelDayIndex, setCancelDayIndex] = useState("");

  const [copyDropdownOpen, setCopyDropdownOpen] = useState(null);
  const [daysToCopy, setDaysToCopy] = useState(null);
  const [copyToAllDays, setCopyToAllDays] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });
  const [isActive, setIsActive] = useState(true);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCopyDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [slotErrors, setSlotErrors] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  const [isAddSpecialSlotsModalOpen, setIsAddSpecialSlotsModalOpen] =
    useState(false);
  const [specialSlots, setSpecialSlots] = useState(
    userData.appointment_schedules.special_dates || []
  );
  const openAddSpecialSlotsModal = () => {
    setIsAddSpecialSlotsModalOpen(true);
  };

  const closeAddSpecialSlotsModal = () => {
    setIsAddSpecialSlotsModalOpen(false);
  };
  const toggleCopyDropdown = (day) => {
    setCopyDropdownOpen(copyDropdownOpen === day ? null : day);
    setDaysToCopy(daysOfWeek.filter((item) => item !== day));
  };

  const copyToAll = () => {
    const selectedDay = copyDropdownOpen;
    let updatedAvailability = { ...availability };

    daysOfWeek.forEach((day) => {
      // Copy the selected time slots to all days
      if (availability[selectedDay].enabled)
        updatedAvailability[day] = availability[selectedDay];
    });

    setAvailability(updatedAvailability);
    //setCopyDropdownOpen(null);
    setCopyToAllDays({
      Sunday: true,
      Monday: true,
      Tuesday: true,
      Wednesday: true,
      Thursday: true,
      Friday: true,
      Saturday: true,
    });
  };

  const toggleCopyToAll = (day) => {
    setCopyToAllDays((prevCopyToAllDays) => {
      const newCopyToAllDays = {
        ...prevCopyToAllDays,
        [day]: !prevCopyToAllDays[day],
      };
      let updatedAvailability = { ...availability };

      // If the checkbox is checked, copy the slot to the particular day
      if (newCopyToAllDays[day]) {
        // updatedAvailability[day] = availability[copyDropdownOpen];
        updatedAvailability[day] = {
          enabled: availability[day].enabled,
          slots: [...availability[copyDropdownOpen].slots],
        };
      } else {
        let newSlot = {
          enabled: availability[day].enabled,
          slots: [{ start_time: "", end_time: "" }],
        };
        updatedAvailability[day] = newSlot;
      }
      setAvailability(updatedAvailability);
      return newCopyToAllDays;
    });
  };

  const toggleDay = (day) => {
    setAvailability((prevAvailability) => ({
      ...prevAvailability,
      [day]: {
        ...prevAvailability[day],
        enabled: !prevAvailability[day].enabled,
      },
    }));
  };

  const addSlot = (day) => {
    setAvailability((prevAvailability) => ({
      ...prevAvailability,
      [day]: {
        ...prevAvailability[day],
        slots: [
          ...prevAvailability[day].slots,
          { start_time: "", end_time: "" },
        ],
      },
    }));
  };

  const removeSlot = (day, index) => {
    setAvailability((prevAvailability) => ({
      ...prevAvailability,
      [day]: {
        ...prevAvailability[day],
        slots: prevAvailability[day].slots.filter((_, i) => i !== index),
      },
    }));
  };

  const warningModalStyles = {
    content: {
      maxWidth: "400px",
      border: "none",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      margin: "auto",
      borderRadius: "12px",
      height: "max-content",
      background: "#fff",
      overflowY: "auto",
      padding: "24px",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.09)",
    },
  };

  // Function to check for overlapping slots on the specific day
  const checkOverlappingSlots = (slots) => {
    for (let i = 0; i < slots.length; i++) {
      for (let j = i + 1; j < slots.length; j++) {
        const slot1 = slots[i];
        const slot2 = slots[j];

        const startTime1 = slot1.start_time?.value || "00:00";
        const endTime1 = slot1.end_time?.value || "00:00";
        const startTime2 = slot2.start_time?.value || "00:00";
        const endTime2 = slot2.end_time?.value || "00:00";

        // Check if the slots overlap
        if (
          (startTime1 < endTime2 && endTime1 > startTime2) ||
          (startTime2 < endTime1 && endTime2 > startTime1)
        ) {
          return `Slot ${i + 1} and Slot ${j + 1} are overlapping.`;
        }
      }
    }
    return null;
  };

  const handleTimeChange = (day, index, key, selectedOption) => {
    setAvailability((prevAvailability) => ({
      ...prevAvailability,
      [day]: {
        ...prevAvailability[day],
        slots: prevAvailability[day].slots.map((slot, i) =>
          i === index ? { ...slot, [key]: selectedOption } : slot
        ),
      },
    }));
    // Clear the error for the corresponding slot
    setSlotErrors((prevSlotErrors) => ({
      ...prevSlotErrors,
      [day]: {
        ...prevSlotErrors[day],
        [index]: "",
      },
      [`${day}_${index}`]: "",
    }));

    // Get the updated slots for the specific day
    const updatedSlots = [
      ...availability[day].slots.map((slot, i) =>
        i === index ? { ...slot, [key]: selectedOption } : slot
      ),
    ];
    // Check for overlapping slots on the specific day
    const overlapError = checkOverlappingSlots(updatedSlots);

    if (overlapError) {
      setSlotErrors((prevSlotErrors) => ({
        ...prevSlotErrors,
        [day]: {
          ...prevSlotErrors[day],
          overlap: overlapError,
        },
      }));
    } else {
      setSlotErrors((prevSlotErrors) => ({
        ...prevSlotErrors,
        [day]: {
          ...prevSlotErrors[day],
          overlap: "",
        },
      }));
    }
    if (key === "start_time") {
      const selectedStartTime = selectedOption.value;
      const currentEndTime = availability[day].slots[index].end_time.value;
      // Check if the selected start_time is less than the current end_time
      if (currentEndTime && selectedStartTime >= currentEndTime) {
        setSlotErrors((prevSlotErrors) => ({
          ...prevSlotErrors,
          [day]: {
            ...prevSlotErrors[day],
            [index]: "Start cannot be greater than end time.",
          },
          [`${day}_${index}`]: "Start time cannot be greater than end time.",
        }));
      }

      // Update the selected start_time in the state
      setSelectedStartTimes((prevState) => ({
        ...prevState,
        [`${day}_${index}`]: selectedStartTime,
      }));
    }

    // Check if end_time is less than start_time and update error state
    if (key === "end_time") {
      const startTime = availability[day].slots[index].start_time.value;
      const endTime = selectedOption.value;

      if (startTime && endTime && startTime >= endTime && endTime !== "00:00") {
        setSlotErrors((prevSlotErrors) => ({
          ...prevSlotErrors,
          [day]: {
            ...prevSlotErrors[day],
            [index]: "End time must be greater than start time.",
          },
          [`${day}_${index}`]: "End time must be greater than start time.",
        }));
      }
    }
    setCopyToAllDays({
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
    });
  };

  // Filter end_time options based on selected start_time
  const getEndTimeOptions = (day, index) => {
    const startTime = selectedStartTimes[`${day}_${index}`];
    if (!startTime) return timeOptions; // If start_time is not selected, return all options

    // Filter end_time options based on the selected start_time
    return timeOptions.filter((option) => option.value > startTime);
  };

  const isAnyChange = () => {
    // Check if 'enabled' state or slots have changed for each day
    for (const day of daysOfWeek) {
      const initialDaySchedule = initialSchedules[day] || {};
      const currentDaySchedule = availability[day] || {};

      // Check if 'enabled' state has changed
      if (currentDaySchedule.enabled !== initialDaySchedule.enabled) {
        return true;
      }

      // Check if any slots have been added or removed
      if (!isEqual(initialDaySchedule.slots, currentDaySchedule.slots)) {
        return true;
      }
    }

    // Check if 'specialSlots' array has changed
    // if (!isEqual(initialSpecialDates, specialSlots)) {
    //   return true;
    // }

    return false;
  };
  const handleSaveSpecialSlot = (slot) => {
    const formattedDate =
      moment(slot.date).format("YYYY-MM-DDTHH:mm:ss.000") + "Z";

    setSpecialSlots((prevSpecialSlots) => {
      const updatedSlots = prevSpecialSlots.map((existingSlot) => {
        if (existingSlot.date.split("T")[0] === formattedDate.split("T")[0]) {
          // Special slot already exists for this date, merge and remove duplicates
          const mergedSlots = [...slot.slots];
          const uniqueSlots = mergedSlots.filter(
            (slot, index, self) =>
              index ===
              self.findIndex(
                (s) =>
                  s.start_time === slot.start_time &&
                  s.end_time === slot.end_time
              )
          );

          return {
            date: formattedDate,
            enabled: slot.enabled,
            slots: uniqueSlots,
          };
        }
        return existingSlot;
      });

      // If the date wasn't found, add the new slot
      if (!updatedSlots.some((s) => s.date === formattedDate)) {
        const uniqueNewSlots = slot.slots.filter(
          (slot, index, self) =>
            index ===
            self.findIndex(
              (s) =>
                s.start_time === slot.start_time && s.end_time === slot.end_time
            )
        );
        updatedSlots.push({
          date: formattedDate,
          enabled: slot.enabled,
          slots: uniqueNewSlots,
        });
      }

      handleSubmit(updatedSlots, slot);
      return updatedSlots;
    });

    setIsAddSpecialSlotsModalOpen(false);
  };

  const handleRemoveSlot = (index) => {
    const updatedSlots = [...specialSlots];
    updatedSlots.splice(index, 1);
    setSpecialSlots(updatedSlots);
    handleSubmit(updatedSlots);
  };
  const validateAvailability = () => {
    const newSlotErrors = {};

    for (const day of daysOfWeek) {
      if (availability[day].enabled) {
        const slots = availability[day].slots;
        for (let i = 0; i < availability[day].slots.length; i++) {
          const slot = slots[i];
          const slotErrors = [];

          // Check for overlapping slots within the same day
          const overlapError = checkOverlappingSlots(slots);
          if (overlapError) {
            slotErrors.push(overlapError);
            if (!newSlotErrors[day]) {
              newSlotErrors[day] = {};
            }
            newSlotErrors[day].overlap = slotErrors;
            break;
          }

          if (
            !slot.start_time ||
            !slot.start_time.value ||
            !slot.end_time ||
            !slot.end_time.value
          ) {
            slotErrors.push(`Time slots cannot be empty`);
          }

          // Check if end_time is less than start_time
          if (
            slot.start_time.value &&
            slot.end_time.value &&
            slot.start_time.value >= slot.end_time.value &&
            slot.end_time.value !== "00:00"
          ) {
            slotErrors.push(`End time must be greater than start time.`);
          }

          // Associate errors with the specific slot
          newSlotErrors[`${day}`] = slotErrors;
          newSlotErrors[`${day}_${i}`] = slotErrors;
        }
      }
    }

    // Update state based on the new errors
    setSlotErrors((prevSlotErrors) => ({
      ...prevSlotErrors,
      ...newSlotErrors,
    }));
    // Check if there are any errors
    return Object.values(newSlotErrors).flat().length === 0;
  };

  const handleSubmit = async (slots = specialSlots, slot) => {
    setIsActive(true);
    try {
      if (slot?.slots?.length > 0 && !validateAvailability()) {
        await dispatch(
          submitTherapistProfile({
            special_dates: JSON.stringify([...slots]),
          })
        );
      } else {
        // You can handle the submission of the slots here
        if (!validateAvailability()) {
          // You can show a global error message here or handle it as needed
          return;
        }
        await dispatch(
          submitTherapistProfile({
            schedules: JSON.stringify({ ...availability }),
            special_dates: JSON.stringify([...slots]),
          })
        );
      }
    } catch (error) {
      console.error("Error during submission:", error);
      // Optionally handle the error, e.g., show an error message
    } finally {
      // Set active state to false when the response is received
      setIsActive(false);
    }
  };

  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      height: "100%",
      borderRadius: "10px",
      borderColor: state.isFocused ? "#005996" : "#E4E7EC",
      ":hover": { borderColor: state.isFocused ? "#005996" : "#E4E7EC" },
      cursor: "pointer",
      boxShadow: state.isFocused ? "0px 0px 0px 4px #BFDEF4" : "",
      minHeight: "36px",
      fontSize: "14px",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#F2F4F7",
      borderRadius: "0.5rem",
      border: "1px solid #E4E7EC",
      fontSize: "14px",
      fontWeight: "500",
      padding: "0 2px",
    }),
    multiValueRemove: (css) => ({
      ...css,
      color: "#98A2B3",
      ":hover": { background: "transparent" },
    }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        color: isSelected ? "#fff" : "#232428",
        backgroundColor: isSelected ? "#005996" : "initial",
        cursor: isDisabled ? "not-allowed" : "default",
        ":hover": { backgroundColor: isSelected ? "#005996" : "#F1F6F9" },
        fontWeight: isDisabled ? "700" : "400",
      };
    },
  };

  return (
    <div className="mt-6">
      <button
        onClick={openAddSpecialSlotsModal}
        className="px-1 lg:px-2 py-1 rounded-lg border border-solid border-blue-500 bg-blue-50 text-xs font-medium flex shadow-none absolute right-2 lg:right-6 top-4 lg:top-6 text-blue-900"
        disabled={isActive ? false : true}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          className="mr-0.5 lg:mr-1"
        >
          <path
            d="M8.6665 3.33341C8.6665 2.96522 8.36803 2.66675 7.99984 2.66675C7.63165 2.66675 7.33317 2.96522 7.33317 3.33341V7.33341H3.33317C2.96498 7.33341 2.6665 7.63189 2.6665 8.00008C2.6665 8.36827 2.96498 8.66675 3.33317 8.66675H7.33317V12.6667C7.33317 13.0349 7.63165 13.3334 7.99984 13.3334C8.36803 13.3334 8.6665 13.0349 8.6665 12.6667V8.66675H12.6665C13.0347 8.66675 13.3332 8.36827 13.3332 8.00008C13.3332 7.63189 13.0347 7.33341 12.6665 7.33341H8.6665V3.33341Z"
            fill="#007BD2"
          />
        </svg>
        Add special days
      </button>
      {daysOfWeek.map((day) => (
        <div
          key={day}
          className={`mb-8 lg:mb-4 flex flex-wrap items-start relative ${availability[day].enabled ? "" : "text-gray-500"
            }`}
        >
          <div className="text-sm mr-4 lg:mr-6 font-medium mt-2 w-24">
            {day}
          </div>
          <div className="flex items-center lg:mr-16 mt-2">
            <label className="relative w-11 h-6 mr-2">
              <input
                type="checkbox"
                checked={availability[day].enabled}
                onChange={() => toggleDay(day)}
                className="opacity-0 w-0 h-0"
              />
              <span
                className={`slider absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full transition duration-400 ${availability[day].enabled ? "bg-blue-500" : "bg-gray-100"
                  }`}
              >
                <span
                  className={`toggle-circle absolute h-5 w-5 bg-white rounded-full transition duration-400 left-[2px] bottom-[2px] ${availability[day].enabled ? "transform translate-x-5" : ""
                    }`}
                ></span>
              </span>
            </label>
            <span
              className={`mobile:hidden text-sm lg:text-base ${availability[day].enabled ? "" : "text-gray-500"
                }`}
            >
              {availability[day].enabled ? "Available" : "Unavailable"}
            </span>
          </div>

          <div className="flex mt-3 lg:mt-0 mobile:w-full">
            {availability[day].enabled && (
              <div className="flex items-start w-full">
                <div className="lg:mr-3 space-y-2 w-full">
                  {availability[day].slots.map((slot, index) => (
                    <div key={index} className="flex items-center relative">
                      <div className="flex flex-wrap w-[calc(100%-1.5rem)] lg:w-68">
                        <div className="w-[calc(50%-4px)] lg:w-[calc(50%-6px)] mr-2 lg:mr-3">
                          <Select
                            options={timeOptions}
                            value={slot.start_time}
                            onChange={(selectedOption) =>
                              handleTimeChange(
                                day,
                                index,
                                "start_time",
                                selectedOption
                              )
                            }
                            styles={colourStyles}
                          />
                        </div>
                        <div className="w-[calc(50%-4px)] lg:w-[calc(50%-6px)]">
                          <Select
                            options={getEndTimeOptions(day, index)} // Filtered end_time options
                            value={slot.end_time}
                            onChange={(selectedOption) =>
                              handleTimeChange(
                                day,
                                index,
                                "end_time",
                                selectedOption
                              )
                            }
                            styles={colourStyles}
                            isDisabled={slot.start_time ? false : true}
                          />
                        </div>
                        {slotErrors[day] && slotErrors[`${day}_${index}`] && (
                          <span className="text-danger-900 block text-xs mt-0.5">
                            {slotErrors[`${day}_${index}`]}
                          </span>
                        )}
                      </div>
                      {index !== 0 && (
                        <button
                          className="shadow-none absolute right-0 lg:-right-8 top-2"
                          onClick={() => removeSlot(day, index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M15 5L5 15M5 5L15 15"
                              stroke="#667085"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      )}
                      {index === availability[day].slots.length - 1 && (
                        <button
                          onClick={() => addSlot(day)}
                          className={`${index === 0 ? "lg:-right-8" : "lg:-right-16"
                            } shadow-none absolute right-0 top-2 mobile:hidden`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10.0001 4.16675V15.8334M4.16675 10.0001H15.8334"
                              stroke="#667085"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  ))}
                  {slotErrors[day] && slotErrors[day]?.overlap && (
                    <span className="text-danger-900 block text-xs mt-0.5">
                      {slotErrors[day]?.overlap}
                    </span>
                  )}
                </div>
                <button
                  onClick={() => addSlot(day)}
                  className={`shadow-none mr-0 lg:mr-3 lg:mt-2 absolute right-6 top-2.5 lg:static lg:hidden`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10.0001 4.16675V15.8334M4.16675 10.0001H15.8334"
                      stroke="#667085"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="lg:mt-2 lg:ml-8">
                  <button
                    onClick={() => toggleCopyDropdown(day)}
                    className="shadow-none absolute right-0 top-2.5 lg:static"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M6.25 2.5H12.1667C14.0335 2.5 14.9669 2.5 15.68 2.86331C16.3072 3.18289 16.8171 3.69282 17.1367 4.32003C17.5 5.03307 17.5 5.96649 17.5 7.83333V13.75M5.16667 17.5H11.9167C12.8501 17.5 13.3168 17.5 13.6733 17.3183C13.9869 17.1586 14.2419 16.9036 14.4017 16.59C14.5833 16.2335 14.5833 15.7668 14.5833 14.8333V8.08333C14.5833 7.14991 14.5833 6.6832 14.4017 6.32668C14.2419 6.01308 13.9869 5.75811 13.6733 5.59832C13.3168 5.41667 12.8501 5.41667 11.9167 5.41667H5.16667C4.23325 5.41667 3.76654 5.41667 3.41002 5.59832C3.09641 5.75811 2.84144 6.01308 2.68166 6.32668C2.5 6.6832 2.5 7.14991 2.5 8.08333V14.8333C2.5 15.7668 2.5 16.2335 2.68166 16.59C2.84144 16.9036 3.09641 17.1586 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5Z"
                        stroke="#667085"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  {copyDropdownOpen === day && (
                    <div
                      className="absolute right-0 lg:right-auto lg:mt-2 bg-white border rounded-2xl z-10 text-sm max-w-[278px] w-full"
                      ref={dropdownRef}
                    >
                      <div className="p-4">
                        <button
                          className="shadow-none flex"
                          onClick={copyToAll}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.16146 7H12.8385C13.3657 6.99998 13.8205 6.99997 14.195 7.03057C14.5904 7.06287 14.9836 7.13419 15.362 7.32698C15.9265 7.6146 16.3854 8.07354 16.673 8.63803C16.8658 9.01641 16.9371 9.40963 16.9694 9.80497C17 10.1795 17 10.6343 17 11.1614V18.8385C17 19.3657 17 19.8205 16.9694 20.195C16.9371 20.5904 16.8658 20.9836 16.673 21.362C16.3854 21.9265 15.9265 22.3854 15.362 22.673C14.9836 22.8658 14.5904 22.9371 14.195 22.9694C13.8205 23 13.3658 23 12.8386 23H5.16148C4.63437 23 4.17952 23 3.80497 22.9694C3.40963 22.9371 3.01641 22.8658 2.63803 22.673C2.07354 22.3854 1.6146 21.9265 1.32698 21.362C1.13419 20.9836 1.06287 20.5904 1.03057 20.195C0.999965 19.8205 0.999982 19.3657 1 18.8385V11.1615C0.999982 10.6343 0.999965 10.1796 1.03057 9.80497C1.06287 9.40963 1.13419 9.01641 1.32698 8.63803C1.6146 8.07354 2.07354 7.6146 2.63803 7.32698C3.01641 7.13419 3.40963 7.06287 3.80497 7.03057C4.17953 6.99997 4.63432 6.99998 5.16146 7Z"
                              fill="#667085"
                            />
                            <path
                              d="M18.8385 1H11.1615C10.6343 0.999984 10.1795 0.999969 9.80498 1.03057C9.40963 1.06287 9.01641 1.13419 8.63803 1.32698C8.07355 1.6146 7.61461 2.07354 7.32699 2.63803C7.13419 3.01641 7.06287 3.40963 7.03057 3.80497C7.00314 4.14076 7.00031 4.54098 7.00003 5.00003L12.8809 5.00001C13.3695 4.9999 13.8993 4.99977 14.3579 5.03724C14.8769 5.07964 15.5626 5.1846 16.27 5.54499C17.2108 6.02436 17.9757 6.78926 18.455 7.73007C18.8154 8.43739 18.9204 9.12311 18.9628 9.64213C19.0003 10.1007 19.0001 10.6305 19 11.1192L19 17C19.459 16.9997 19.8593 16.9969 20.195 16.9694C20.5904 16.9371 20.9836 16.8658 21.362 16.673C21.9265 16.3854 22.3854 15.9265 22.673 15.362C22.8658 14.9836 22.9371 14.5904 22.9694 14.195C23 13.8205 23 13.3658 23 12.8386V5.16148C23 4.63437 23 4.17952 22.9694 3.80497C22.9371 3.40963 22.8658 3.01641 22.673 2.63803C22.3854 2.07354 21.9265 1.6146 21.362 1.32698C20.9836 1.13419 20.5904 1.06287 20.195 1.03057C19.8205 0.999969 19.3657 0.999984 18.8385 1Z"
                              fill="#667085"
                            />
                          </svg>
                          <span className="ml-2 font-medium">Copy to all</span>
                        </button>
                      </div>
                      <div>
                        {daysToCopy.map((copyDay) => (
                          <label
                            key={copyDay}
                            className="flex items-center p-4 border-t border-gray-100 cursor-pointer"
                          >
                            <input
                              className="appearance-none checked:bg-blue-500 border-blue-500 w-5 h-5 flex items-center justify-center checked:before:w-[.35rem] checked:before:h-[.75rem] before:border-b-2 before:border-white before:border-r-2 before:rotate-45"
                              type="checkbox"
                              checked={copyToAllDays[copyDay]}
                              onChange={() => toggleCopyToAll(copyDay)}
                            />
                            <span className="ml-2">{copyDay}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      <SpecialSlots
        isOpen={isAddSpecialSlotsModalOpen}
        onRequestClose={closeAddSpecialSlotsModal}
        onSaveSpecialSlot={handleSaveSpecialSlot}
        timeOptions={timeOptions}
        selectStyles={colourStyles}
        specialSlots={specialSlots}
        onRemoveSlot={handleRemoveSlot}
      />
      {specialSlots.length > 0 && (
        <div className="mt-6 pt-6 border-t border-gray-100">
          <Heading
            variant="h2"
            heading="Special Days"
            className="font-medium"
          />
          <p className="text-sm text-gray-500">
            Override your availability for specific dates when your hours differ
            from your regular weekly hours.
          </p>
          <ul>
            {specialSlots.map((slot, index) => (
              <li key={index} className={`items-start flex mt-6`}>
                <span className="xs:text-xxs text-xs text-gray-500 uppercase w-[7.5rem] lg:w-40 font-semibold mt-0.5 lg:mt-1">
                  {moment.utc(slot.date).format("ddd, MMM D, YYYY")}
                </span>
                <div className="flex flex-col font-medium xs:text-xs text-sm lg:text-base">
                  {slot.slots.length > 0 && slot.enabled === true
                    ? slot.slots.map((time, timeIndex) => (
                      <span key={timeIndex}>
                        {`${formatTime(time.start_time)} - ${formatTime(
                          time.end_time
                        )}`}
                      </span>
                    ))
                    : "Unavailable"}
                </div>

                <button
                  onClick={() => {
                    setConfirmationModal(true);
                    setCancelDayIndex(index);
                  }}
                  className="shadow-none ml-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15 5L5 15M5 5L15 15"
                      stroke="#667085"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="text-right mt-10">
        {isAnyChange() && (
          <Button
            className="bg-blue-900 text-white px-4 py-1.5 rounded-lg font-medium"
            onClick={() => handleSubmit()}
          >
            Save changes
          </Button>
        )}
      </div>
      <Modal
        isOpen={confirmationModal}
        onRequestClose={() => setConfirmationModal(false)}
        style={warningModalStyles}
        className={`translate-y-full lg:translate-y-0 w-full transform transition-transform fixed bottom-0 outline-none`}
      >
        <img src={WarningIcon} />
        <div className="text-lg font-medium mb-2 mt-4">Warning</div>
        <div className="text-sm text-gray-500">
          Are you sure you want to remove this?
        </div>
        <div className="flex items-center justify-between space-x-4 mt-7">
          <div
            onClick={() => setConfirmationModal(false)}
            className="cursor-pointer rounded-full w-full justify-center flex bg-blue-50 text-blue-900 font-medium text-base py-2"
          >
            No
          </div>
          <div
            onClick={() => {
              setConfirmationModal(false);
              handleRemoveSlot(cancelDayIndex);
            }}
            className="cursor-pointer rounded-full bg-[#005996] w-full justify-center flex text-white font-medium text-base py-2"
          >
            Yes
          </div>
        </div>
      </Modal>
    </div>
  );
};
function formatTime(timeString) {
  const time = new Date(`2000-01-01T${timeString}`);
  return time.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
  });
}
export default TherapistAvailability;
