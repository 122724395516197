import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  genderOptions,
  countriesOptions,
  languageOptions,
  religionOptions,
  bloodGroupOptions,
  approachesOptions,
  professionsOptions,
  timeZonesOptions,
  therapistGenderOptions,
} from "../../services/utils/constants";
import {
  submitPatientProfile,
  deleteMedia,
} from "../../redux/patient/patientThunks";
import { formatBytes } from "../../services/globalFunctions";
import Heading from "../atoms/Heading";
import Input from "../atoms/Input";
import Button from "../atoms/Button";

import RadioButton from "../atoms/RadioButton";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/js/utils.js";
import "intl-tel-input/build/css/intlTelInput.css";
import upload from "../../assets/upload.svg";
import Loader from "../atoms/Loader";
import moment from "moment";
import Tooltip from "../atoms/Tooltip";

const firstNamePattern = /^[A-Za-z\s'-]+$/;
const lastNamePattern = /^[A-Za-z\s'-]+$/;
const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const PatientProfileForm = ({ target, setModalIsOpen }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);
  const app = user.role;
  const [dobYear, dobMonth, dobDay] = (user?.date_of_birth ?? "").split("-");
  const [firstName, setFirstName] = useState(user.first_name);
  const [emergencyFirstName, setEmergencyFirstName] = useState(
    user.emergency_first_name
  );
  const [lastName, setLastName] = useState(user.last_name);
  const [email, setEmail] = useState(user.username);
  const [height, setHeight] = useState(user.height);
  const [weight, setWeight] = useState(user.weight);
  const [city, setCity] = useState(user.city);
  const [emergencyEmail, setEmergencyEmail] = useState(user.emergency_email);
  const [errors, setErrors] = useState({});
  const [day, setDay] = useState(
    dobDay ? { value: dobDay, label: dobDay } : ""
  );
  const [month, setMonth] = useState(
    dobMonth ? { value: dobMonth, label: dobMonth } : ""
  );
  const [year, setYear] = useState(
    dobYear ? { value: dobYear, label: dobYear } : ""
  );
  const [selectedGender, setSelectedGender] = useState(
    user.gender
      ? {
          value: user.gender.toUpperCase(),
          label: user.gender,
        }
      : ""
  );
  const [selectedCountry, setSelectedCountry] = useState(
    user.country
      ? {
          value: user.country,
          label: user.country,
        }
      : ""
  );
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    user.timezone
      ? {
          value: user.timezone,
          label: user.timezone,
        }
      : ""
  );
  const [selectedProfession, setSelectedProfession] = useState(
    user.profession
      ? {
          value: user.profession,
          label: user.profession,
        }
      : ""
  );

  const [selectedSecondaryLanguage, setSelectedSecondaryLanguage] = useState(
    user.preferred_languages ? Object.values(user.preferred_languages) : []
  );
  const [selectedReligion, setSelectedReligion] = useState(
    user.religious_belief
      ? {
          value: user.religious_belief,
          label: user.religious_belief,
        }
      : ""
  );

  const [selectedTherapistGender, setSelectedTherapistGender] = useState(
    user.preferred_therapists && user.preferred_therapists != "undefined"
      ? {
          value: user.preferred_therapists,
          label: user.preferred_therapists,
        }
      : ""
  );

  const [selectedBookingOption, setSelectedBookingOption] = useState(
    user.same_day_book === true ? "yes" : "no"
  );

  const [selectedIntroductorySession, setSelectedIntroductorySession] =
    useState(user.inperson_therapy === true ? "yes" : "no");
  const [selectedAssessmentsOption, setSelectedAssessmentsOption] = useState(
    user.assessment_availability === true ? "yes" : "no"
  );

  const [selectedReminderOption, setSelectedReminderOption] = useState(
    user.reminder === true ? "yes" : "no"
  );
  const [bloodGroup, setBloodGroup] = useState(
    user.blood_group && user.blood_group != "undefined"
      ? {
          value: user.blood_group,
          label: user.blood_group,
        }
      : ""
  );
  const [selectedMedicalConds, setSelectedMedicalConds] = useState(
    user.medical_conditions ? Object.values(user.medical_conditions) : []
  );
  const [activeMedication, setActiveMedication] = useState(
    user.medications && user.medications != "null" ? user.medications : ""
  );
  const [selectedApproach, setSelectedApproach] = useState(
    user.therapy_approach ? Object.values(user.therapy_approach) : []
  );

  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [showDocuments, setShowDocuments] = useState(user.medical_documents);
  const phoneInput = useRef();
  const [inputValue, setInputValue] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        event.preventDefault();
        setSelectedMedicalConds((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        break;
      default:
        break;
    }
  };
  const handleInputBlur = () => {
    if (!inputValue) return;
    setSelectedMedicalConds((prev) => [...prev, createOption(inputValue)]);
    setInputValue("");
  };
  useEffect(() => {
    if (target === "Personal details") {
      const iti = intlTelInput(phoneInput?.current, {
        separateDialCode: true,
        nationalMode: false,
      });
      iti.setNumber(user.cell_number);
    } else if (target === "Emergency contact") {
      const iti = intlTelInput(phoneInput?.current, {
        separateDialCode: true,
        nationalMode: false,
      });
      iti.setNumber(user.emergency_cell_number ?? "");
    }
  }, [target, phoneInput]);

  const handleDocumentsChange = (e) => {
    const selectedFiles = e.target.files;
    let promises = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileName = file.name;
      const fileSize = file.size;
      const formattedSize = formatBytes(fileSize);

      const reader = new FileReader();
      const promise = new Promise((resolve, reject) => {
        reader.onload = (event) => {
          const uploadedFile = {
            id: i,
            fileName: fileName,
            fileSize: fileSize,
            formattedSize: formattedSize,
            media_mime: file.type,
            full_url: event.target.result,
          };
          resolve(uploadedFile);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });

      reader.readAsDataURL(file);
      promises.push(promise);
    }

    Promise.all(promises)
      .then((uploadedFiles) => {
        setShowDocuments((prevFiles) => [...prevFiles, ...uploadedFiles]);
      })
      .catch((error) => {
        console.error("An error occurred while processing files:", error);
      });

    setUploadedDocuments((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleDocumentDelete = (id, selectedIndex) => {
    setShowDocuments((prevFiles) =>
      prevFiles.filter((_, index) => index !== selectedIndex)
    );
    if (id != 0) dispatch(deleteMedia({ id: id }));
  };

  // Options for days, months, and years
  const days = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));
  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 83 }, (_, i) => ({
    value: currentYear - 18 - i,
    label: `${currentYear - 18 - i}`,
  }));

  const handleDayChange = (selectedOption) => {
    setDay(selectedOption);
  };
  const handleMonthChange = (selectedOption) => {
    setMonth(selectedOption);
  };

  const handleYearChange = (selectedOption) => {
    setYear(selectedOption);
  };

  const handleGenderChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.genderError;
    setErrors(Object.assign({}, Errors));
    setSelectedGender(selectedOption);
  };

  const handleCountryChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.countryError;
    setErrors(Object.assign({}, Errors));
    setSelectedCountry(selectedOption);
  };

  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption);
  };
  const handleProfessionsChange = (selectedOption) => {
    setSelectedProfession(selectedOption);
  };

  const handleSecondaryLanguageChange = (selectedOption) => {
    setSelectedSecondaryLanguage(selectedOption);
  };

  const handleReligionChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.religionError;
    setErrors(Object.assign({}, Errors));
    setSelectedReligion(selectedOption);
  };
  const handleTherapistGender = (selectedOption) => {
    setSelectedTherapistGender(selectedOption);
  };

  const handleBloodGroup = (selectedOption) => {
    setBloodGroup(selectedOption);
  };

  const handleBookingOptionChange = (event) => {
    setSelectedBookingOption(event.target.value);
  };

  const handleIntroSessionChange = (event) => {
    setSelectedIntroductorySession(event.target.value);
  };

  const handleAssessmentsAvailability = (event) => {
    setSelectedAssessmentsOption(event.target.value);
  };

  const handleReminderChange = (event) => {
    setSelectedReminderOption(event.target.value);
  };

  const handleApproachesChange = (selectedOption) => {
    setSelectedApproach(selectedOption);
  };

  const getSelectedDate = () => {
    if (day && month && year) {
      const selectedDate = moment(
        `${year.value}-${month.value}-${day.value}`,
        "YYYY-MM-DD"
      );
      return selectedDate.format("YYYY-MM-DD");
    }
    return null;
  };
  const handleSubmitAnswer = async (e) => {
    e.preventDefault();
    let validationErrors = {};
    let rawPhoneNumber;
    if (target === "Personal details") {
      let iti = window.intlTelInputGlobals.getInstance(phoneInput.current);
      let validNumber = iti.isValidNumber();
      rawPhoneNumber = iti.getNumber();
      if (!validNumber) {
        validationErrors.validNumberError = "Please enter a valid phone number";
      }

      const firstNameError = checkFirstNameValidation(firstName);
      const lastNameError = checkLastNameValidation(lastName);
      const emailError = checkEmailValidation(email);

      validationErrors = {
        ...validationErrors,
        ...firstNameError,
        ...lastNameError,
        ...emailError,
      };

      if (!day || !month || !year) {
        validationErrors.dobError = "Date of birth is required";
      }

      if (!selectedGender) {
        validationErrors.genderError = "Gender is required";
      }
      if (!selectedCountry) {
        validationErrors.countryError = "Country is required";
      }
    } else if (target === "Preferences") {
      if (!selectedReligion) {
        validationErrors.religionError = "Religion is required";
      }
    } else if (target === "Emergency contact") {
      let iti = window.intlTelInputGlobals.getInstance(phoneInput.current);
      let validNumber = iti.isValidNumber();
      rawPhoneNumber = iti.getNumber();
      if (!validNumber) {
        validationErrors.validNumberError = "Please enter a valid phone number";
      }
    }
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      const res = await dispatch(
        target === "Personal details"
          ? submitPatientProfile({
              first_name: e.target.first_name.value ?? user.first_name,
              last_name: e.target.last_name.value ?? user.last_name,
              email: e.target.email.value ?? user.username,
              cell_number: rawPhoneNumber ?? user.cell_number,
              date_of_birth: getSelectedDate(),
              gender: selectedGender.value,
              profession: selectedProfession.value,
              country: selectedCountry.value,
              city: e.target.city.value ?? user.city,
              timezone: selectedTimeZone?.value,
            })
          : target === "Emergency contact"
          ? submitPatientProfile({
              emergency_first_name:
                e.target.emergency_first_name.value ??
                user.emergency_first_name,
              emergency_email:
                e.target.emergency_email.value ?? user.emergency_email,
              emergency_cell_number:
                rawPhoneNumber ?? user.emergency_cell_number,
            })
          : target === "Preferences"
          ? submitPatientProfile({
              preferred_languages: JSON.stringify({
                ...selectedSecondaryLanguage,
              }),
              religious_belief: selectedReligion.value,
              preferred_therapists: selectedTherapistGender.value,
            })
          : target === "Health Record"
          ? submitPatientProfile({
              height: e.target.height.value ?? user.height,
              weight: e.target.weight.value ?? user.weight,
              blood_group: bloodGroup?.value,
              medications: activeMedication,
              medical_conditions: JSON.stringify({ ...selectedMedicalConds }),
            })
          : target === "Documents & health records"
          ? submitPatientProfile({
              medical_documents: uploadedDocuments,
            })
          : target === "Approaches/Techniques"
          ? submitPatientProfile({
              therapy_approach: JSON.stringify({ ...selectedApproach }),
              assessment_availability: selectedAssessmentsOption,
            })
          : target === "Schedule Preferences"
          ? submitPatientProfile({
              same_day_book: selectedBookingOption,
              intro_session: selectedIntroductorySession,
              reminders: selectedReminderOption,
            })
          : null
      );
      if (res) {
        setModalIsOpen(false);
        setIsLoading(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const checkFirstNameValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.firstNameError = "Firstname is required";
    else if (!firstNamePattern.test(value))
      Errors.firstNameError = "Firstname is not valid";
    else delete Errors.firstNameError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkLastNameValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.lastNameError = "Lastname is required";
    else if (!lastNamePattern.test(value))
      Errors.lastNameError = "Lastname is not valid";
    else delete Errors.lastNameError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkEmailValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.emailError = "Email is required";
    else if (!emailPattern.test(value))
      Errors.emailError = "Email is not valid";
    else delete Errors.emailError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      height: "100%",
      borderRadius: "10px",
      borderColor: state.isFocused ? "#01554D" : "#E4E7EC",
      ":hover": { borderColor: state.isFocused ? "#01554D" : "#E4E7EC" },
      cursor: "pointer",
      boxShadow: state.isFocused
        ? "0px 0px 0px 4px rgba(0, 150, 136, 0.20)"
        : "",
      minHeight: "44px",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#F2F4F7",
      borderRadius: "0.5rem",
      border: "1px solid #E4E7EC",
      fontSize: "14px",
      fontWeight: "500",
      padding: "0 2px",
    }),
    multiValueRemove: (css) => ({
      ...css,
      color: "#98A2B3",
      ":hover": { background: "transparent" },
    }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        color: isSelected ? "#fff" : "#232428",
        backgroundColor: isSelected ? "#005996" : "initial",
        cursor: isDisabled ? "not-allowed" : "default",
        ":hover": { backgroundColor: isSelected ? "#005996" : "#F1F6F9" },
        fontWeight: isDisabled ? "700" : "400",
      };
    },
  };
  return (
    <form method="POST" onSubmit={handleSubmitAnswer}>
      {target === "Personal details" ? (
        <div>
          <div className="p-4 lg:p-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Personal Details
            </Heading>
            <p className="text-sm text-gray-800/70 mt-1.5">
              Here you can provide your personal details.
            </p>
          </div>

          <ul className="space-y-8 lg:space-y-12 py-4 lg:py-6 px-4 lg:px-8">
            <li className="flex flex-col lg:flex-row justify-between gap-8 lg:gap-4">
              <div className="group relative flex-1">
                <Input
                  label="Email"
                  placeholder="Your email address"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    checkEmailValidation(e.target.value, errors);
                  }}
                  error={errors.emailError}
                  onBlur={() => {
                    checkEmailValidation(email, errors);
                  }}
                  disabled={true}
                />
                <Tooltip
                  className="mt-[0.5rem] left-[2rem] z-10"
                  direction="right"
                  right="-right-5 after:right-[18px] before:right-4"
                  position="bottom"
                  pin="top"
                >
                  You may reach out to our support team and we’ll update these
                  changes for you
                </Tooltip>
              </div>
              <Input
                label="First Name"
                className="flex-1"
                placeholder="Your first name"
                type="text"
                name="first_name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  checkFirstNameValidation(e.target.value, errors);
                }}
                error={errors.firstNameError}
                onBlur={() => {
                  checkFirstNameValidation(firstName, errors);
                }}
              />
              <Input
                label="Last Name"
                className="flex-1"
                placeholder="Your last name"
                type="text"
                name="last_name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  checkLastNameValidation(e.target.value, errors);
                }}
                error={errors.lastNameError}
                onBlur={() => {
                  checkLastNameValidation(lastName, errors);
                }}
              />
            </li>
            <li className="grid lg:grid-cols-12 gap-8 lg:gap-4">
              <Input
                label="Phone Number"
                className="lg:col-span-4"
                ref={phoneInput}
                placeholder="Your Phone Number"
                type="tel"
                name="cell_number"
                error={errors.validNumberError}
              />
              <div className="lg:col-span-8">
                <label className="text-sm font-medium text-gray-500">
                  Date of birth <span className="text-danger-900">*</span>
                </label>
                <div className="flex space-x-2 mt-2">
                  <Select
                    value={day}
                    onChange={handleDayChange}
                    options={days}
                    placeholder="Day"
                    className="h-11 flex-1"
                    styles={colourStyles}
                    menuPlacement="auto"
                  />
                  <Select
                    value={month}
                    onChange={handleMonthChange}
                    options={months}
                    placeholder="Month"
                    className="h-11 flex-1"
                    styles={colourStyles}
                    menuPlacement="auto"
                  />
                  <Select
                    value={year}
                    onChange={handleYearChange}
                    options={years}
                    placeholder="Year"
                    className="h-11 flex-1"
                    styles={colourStyles}
                    menuPlacement="auto"
                  />
                </div>
                {errors.dobError && (
                  <div className="text-sm text-danger-900 mt-1.5">
                    {errors.dobError}
                  </div>
                )}
              </div>
            </li>
            <li className="grid lg:grid-cols-12 gap-8 lg:gap-4">
              <div className="lg:col-span-4">
                <label className="text-sm font-medium text-gray-500">
                  Gender <span className="text-danger-900">*</span>
                </label>
                <Select
                  value={selectedGender}
                  onChange={handleGenderChange}
                  options={genderOptions}
                  placeholder="Select Gender"
                  className="h-11 mt-2"
                  styles={colourStyles}
                  menuPlacement="auto"
                />
                {errors.genderError && (
                  <div className="text-sm text-danger-900 mt-1.5">
                    {errors.genderError}
                  </div>
                )}
              </div>
              <div className="lg:col-span-8">
                <label className="text-sm font-medium">Profession</label>
                <Select
                  value={selectedProfession}
                  onChange={handleProfessionsChange}
                  options={professionsOptions}
                  placeholder="Choose"
                  className="h-11 mt-2"
                  styles={colourStyles}
                  menuPlacement="auto"
                />
              </div>
            </li>
            <li className="flex flex-col lg:flex-row justify-between gap-8 lg:gap-4">
              <div className="flex-1">
                <label className="text-sm font-medium">
                  Country <span className="text-danger-900">*</span>
                </label>
                <Select
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  options={countriesOptions.countries}
                  placeholder="Choose"
                  className="h-11 mt-2"
                  styles={colourStyles}
                />
                {errors.countryError && (
                  <div className="text-sm text-danger-900 mt-1.5">
                    {errors.countryError}
                  </div>
                )}
              </div>
              <div className="flex-1">
                <label className="text-sm font-medium">City</label>
                <Input
                  className="flex-1 mt-2"
                  placeholder="Your city"
                  type="text"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="flex-1">
                <label className="text-sm font-medium">TimeZone</label>
                <Select
                  value={selectedTimeZone}
                  onChange={handleTimeZoneChange}
                  options={timeZonesOptions}
                  placeholder="Choose"
                  className="h-11 mt-2"
                  styles={colourStyles}
                />
              </div>
            </li>
          </ul>
        </div>
      ) : target === "Emergency contact" ? (
        <div>
          <div className="p-4 lg:p-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Emergency Contact
            </Heading>
            <p className="text-sm text-gray-800/70 mt-1.5">
              {`This is where you can provide contact details of someone close to you. In case it becomes necessary in future.`}
            </p>
          </div>

          <ul className="space-y-8 lg:space-y-12 py-4 lg:py-6 px-4 lg:px-8">
            <li className="flex flex-col lg:flex-row justify-between gap-8 lg:gap-4">
              <Input
                label="Name"
                className="flex-1"
                placeholder="Full Name"
                type="text"
                name="emergency_first_name"
                value={emergencyFirstName}
                onChange={(e) => setEmergencyFirstName(e.target.value)}
              />
              <Input
                label="Email"
                className="flex-1"
                placeholder="Email"
                type="email"
                name="emergency_email"
                value={emergencyEmail}
                onChange={(e) => setEmergencyEmail(e.target.value)}
              />

              <Input
                label="Phone Number"
                className="flex-1"
                ref={phoneInput}
                placeholder="Phone Number"
                type="tel"
                name="cell_number"
                error={errors.validNumberError}
              />
            </li>
          </ul>
        </div>
      ) : target === "Preferences" ? (
        <div className="pt-8 z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Preferences
            </Heading>
            <p className="text-sm text-gray-800/70 mt-1.5">
              {`This is where you can choose your preference for the therapist you're looking for.`}
            </p>
          </div>
          <div className="pt-6 lg:py-6 px-4 lg:px-8">
            <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0">
              <div className="flex-1">
                <label className="text-sm font-medium">
                  Therapist Religion
                </label>
                <Select
                  value={selectedReligion}
                  onChange={handleReligionChange}
                  options={religionOptions}
                  placeholder="Choose"
                  className="h-11 mt-2"
                  styles={colourStyles}
                />
                {errors.religionError && (
                  <div className="text-sm text-danger-900 mt-1.5">
                    {errors.religionError}
                  </div>
                )}
              </div>
              <div className="flex-1">
                <label className="text-sm font-medium">
                  Therapist Languages
                </label>
                <Select
                  isMulti
                  value={selectedSecondaryLanguage}
                  onChange={handleSecondaryLanguageChange}
                  options={languageOptions}
                  placeholder="Choose"
                  className="mt-2"
                  styles={colourStyles}
                />
              </div>
              <div className="flex-1">
                <label className="text-sm font-medium">Therapist Gender</label>
                <Select
                  value={selectedTherapistGender}
                  onChange={handleTherapistGender}
                  options={therapistGenderOptions}
                  placeholder="Choose"
                  className="mt-2"
                  styles={colourStyles}
                />
              </div>
            </div>
          </div>
        </div>
      ) : target === "Health Record" ? (
        <div className="pt-8 rounded-lg z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Health Record
            </Heading>
            <p className="text-sm text-gray-800/70 mt-1.5">
              This is where you can provide your health details.
            </p>
          </div>

          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Height
              </label>
              <Input
                className="flex-1 mt-2"
                placeholder="Answer"
                type="text"
                name="height"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              />
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Weight
              </label>
              <Input
                className="flex-1 mt-2"
                placeholder="Answer"
                type="text"
                name="weight"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Blood Group
              </label>
              <Select
                value={bloodGroup}
                onChange={handleBloodGroup}
                options={bloodGroupOptions}
                placeholder="Answer"
                className="mt-2"
                styles={colourStyles}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Medical Conditions
              </label>
              <CreatableSelect
                className="mt-2"
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(newValue) => setSelectedMedicalConds(newValue)}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                onBlur={handleInputBlur}
                placeholder="Type something and press enter..."
                value={selectedMedicalConds}
                styles={colourStyles}
              />
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Active Medication
              </label>
              <Input
                className="mt-2"
                type="textarea"
                name="active_medication"
                placeholder="Type here..."
                value={activeMedication}
                onChange={(e) => setActiveMedication(e.target.value)}
                inputClassName="px-4 py-2.5 h-28 resize-none"
              />
            </div>
          </div>
        </div>
      ) : target === "Documents & health records" ? (
        <div className="py-8 rounded-lg z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Documents & Health Records
            </Heading>
            <p className="text-sm text-gray-800/70 mt-1.5">
              This is where you can sumbmit documents related to your Medical
              Conditions and Active Medications
            </p>
          </div>

          <div className="pt-6 lg:py-6 px-4 lg:px-8 flex flex-col lg:flex-row gap-4">
            <div className="lg:w-1/2">
              <label className="text-sm font-medium">Upload Documents</label>
              <label
                htmlFor="dropzone-degree"
                className="mt-2.5 flex flex-1 flex-col items-center justify-center py-4 px-6 w-full h-40 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white/20 hover:bg-gray-100/50"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <img src={upload} className="mb-3" />
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="text-teal-500">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500">
                    PDF, DOC, JPG or PNG (max. 800x400px)
                  </p>
                </div>

                <input
                  id="dropzone-degree"
                  type="file"
                  onChange={handleDocumentsChange}
                  accept="application/pdf, image/*"
                  multiple
                />
              </label>
            </div>
            {showDocuments.length > 0 && (
              <div className="lg:w-1/2">
                <div className="text-sm font-medium">Uploads</div>
                <ul className="flex flex-col gap-4 mt-2.5">
                  {showDocuments.map((file, index) => {
                    return (
                      <li
                        className="p-4 bg-gray-100 rounded-2xl text-sm text-gray-500 flex items-start justify-between w-full"
                        key={index}
                      >
                        <div className="flex space-x-4 items-center">
                          {file?.full_url &&
                          file?.media_mime?.includes("image") ? (
                            <img
                              src={file?.full_url}
                              className={
                                "w-10 h-10 rounded-lg object-contain bg-white"
                              }
                            />
                          ) : (
                            <div className="p-2 bg-white rounded-full">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M11.3333 1.67932C11.3333 1.6574 11.3333 1.64645 11.3324 1.62657C11.3127 1.21509 10.9509 0.853408 10.5394 0.833941C10.5195 0.833002 10.513 0.833004 10.5 0.833008H7.79888C7.1281 0.832999 6.57446 0.832992 6.12348 0.869838C5.65507 0.908109 5.22448 0.990246 4.82003 1.19632C4.19283 1.5159 3.68289 2.02584 3.36331 2.65304C3.15724 3.05749 3.0751 3.48807 3.03683 3.95649C2.99998 4.40748 2.99999 4.96111 3 5.6319V14.3674C2.99999 15.0382 2.99998 15.5919 3.03683 16.0429C3.0751 16.5113 3.15724 16.9419 3.36331 17.3463C3.68289 17.9735 4.19283 18.4835 4.82003 18.803C5.22448 19.0091 5.65507 19.0912 6.12348 19.1295C6.57447 19.1664 7.1281 19.1664 7.7989 19.1663H13.2011C13.8719 19.1664 14.4255 19.1664 14.8765 19.1295C15.3449 19.0912 15.7755 19.0091 16.18 18.803C16.8072 18.4835 17.3171 17.9735 17.6367 17.3463C17.8428 16.9419 17.9249 16.5113 17.9632 16.0429C18 15.5919 18 15.0382 18 14.3674V8.33296C18 8.32001 18 8.31354 17.9991 8.29369C17.9796 7.88216 17.6179 7.52027 17.2064 7.50063C17.1865 7.49968 17.1756 7.49968 17.1538 7.49968L13.4734 7.49969C13.2631 7.49971 13.0583 7.49974 12.8848 7.48556C12.6943 7.47 12.4695 7.43328 12.2434 7.31803C11.9298 7.15824 11.6748 6.90327 11.515 6.58967C11.3997 6.36348 11.363 6.1387 11.3475 5.94821C11.3333 5.77474 11.3333 5.56992 11.3333 5.3596L11.3333 1.67932ZM10.5 9.99968C10.9602 9.99968 11.3333 10.3728 11.3333 10.833V12.4997H13C13.4602 12.4997 13.8333 12.8728 13.8333 13.333C13.8333 13.7932 13.4602 14.1663 13 14.1663H11.3333V15.833C11.3333 16.2932 10.9602 16.6663 10.5 16.6663C10.0398 16.6663 9.66667 16.2932 9.66667 15.833V14.1663H8C7.53976 14.1663 7.16667 13.7932 7.16667 13.333C7.16667 12.8728 7.53976 12.4997 8 12.4997H9.66667V10.833C9.66667 10.3728 10.0398 9.99968 10.5 9.99968Z"
                                  fill="#009688"
                                />
                                <path
                                  d="M16.2566 5.833C16.5013 5.83301 16.6237 5.83301 16.7239 5.77157C16.8654 5.6848 16.95 5.48038 16.9112 5.31895C16.8838 5.20466 16.804 5.12495 16.6444 4.96554L13.8675 2.18861C13.7081 2.02904 13.6284 1.94925 13.5141 1.92178C13.3526 1.88298 13.1482 1.96759 13.0614 2.10912C13 2.20935 13 2.33169 13 2.57637L13 5.16631C13 5.39966 13 5.51633 13.0454 5.60546C13.0854 5.68386 13.1491 5.7476 13.2275 5.78755C13.3166 5.83296 13.4333 5.83296 13.6667 5.83297L16.2566 5.833Z"
                                  fill="#009688"
                                />
                              </svg>
                            </div>
                          )}
                          <div>
                            {file.fileName ?? file.media_name}{" "}
                            <div>
                              {file.formattedSize ??
                                formatBytes(file.media_size)}
                            </div>
                          </div>
                        </div>

                        <button
                          type="button"
                          className="shadow-none"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent form submission
                            handleDocumentDelete(file.id, index);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5"
                              stroke="#98A2B3"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      ) : target === "Schedule Preferences" ? (
        <div className="py-8 rounded-lg z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Schedule Preferences
            </Heading>
            <p className="text-sm text-gray-800/70 mt-1.5">
              Dignissimos repellendus commodi dolor temporibus enim incidunt
              quia eum.
            </p>
          </div>

          <div className="pt-6 px-4 lg:px-8">
            <div>
              <label className="text-sm font-medium text-gray-700">
                Same Day Bookings <span className="text-danger-900">*</span>
              </label>
              <p className="text-sm text-gray-500 font-normal mt-2">
                Would you like to be contacted for same-day counselling
                sessions?
              </p>
              <div className="flex space-x-6 mt-6">
                <RadioButton
                  value="yes"
                  checked={selectedBookingOption === "yes"}
                  onChange={handleBookingOptionChange}
                  label="Yes"
                />

                <RadioButton
                  value="no"
                  checked={selectedBookingOption === "no"}
                  onChange={handleBookingOptionChange}
                  label="No"
                />
              </div>
            </div>
            <div className="mt-12">
              <label className="text-sm font-medium text-gray-700">
                Available for introductory sessions
                <span className="text-danger-900">*</span>
              </label>
              <p className="text-sm text-gray-500 mt-2">
                Would you like to be available for introductory sessions?
              </p>
              <div className="flex space-x-6 mt-6">
                <RadioButton
                  value="yes"
                  checked={selectedIntroductorySession === "yes"}
                  onChange={handleIntroSessionChange}
                  label="Yes"
                />

                <RadioButton
                  value="no"
                  checked={selectedIntroductorySession === "no"}
                  onChange={handleIntroSessionChange}
                  label="No"
                />
              </div>
            </div>
            <div className="mt-12">
              <label className="text-sm font-medium text-gray-700">
                Reminders
                <span className="text-danger-900">*</span>
              </label>
              <p className="text-sm text-gray-500 mt-2">
                Would you like to be updated?
              </p>
              <div className="flex space-x-6 mt-6">
                <RadioButton
                  value="yes"
                  checked={selectedReminderOption === "yes"}
                  onChange={handleReminderChange}
                  label="Yes"
                />

                <RadioButton
                  value="no"
                  checked={selectedReminderOption === "no"}
                  onChange={handleReminderChange}
                  label="No"
                />
              </div>
            </div>
          </div>
        </div>
      ) : target === "Approaches/Techniques" ? (
        <div className="pt-8 rounded-lg bg-white z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Approaches/Techniques
            </Heading>
            <p className="text-sm text-gray-800/70 mt-1.5">
              Dignissimos repellendus commodi dolor temporibus enim incidunt
              quia eum.
            </p>
          </div>
          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Approaches/Techniques
                <span className="text-danger-900">*</span>
              </label>
              <Select
                isMulti
                value={selectedApproach}
                onChange={handleApproachesChange}
                options={approachesOptions}
                placeholder="Choose"
                className="mt-2"
                styles={colourStyles}
              />
            </div>
          </div>
          <div className="p-4 lg:p-8">
            <label className="text-sm font-medium text-gray-700">
              Are you able to provide assessments?*
            </label>
            <p className="text-sm text-gray-500 mt-2">
              Would you like to be available for introductory sessions?
            </p>
            <div className="flex space-x-6 mt-6">
              <RadioButton
                value="yes"
                checked={selectedAssessmentsOption === "yes"}
                onChange={handleAssessmentsAvailability}
                label="Yes"
              />

              <RadioButton
                value="no"
                checked={selectedAssessmentsOption === "no"}
                onChange={handleAssessmentsAvailability}
                label="No"
              />
            </div>
          </div>
        </div>
      ) : null}

      <div className="p-4 lg:p-6 flex justify-end">
        <button
          type="button"
          onClick={() => setModalIsOpen(false)}
          className="text-gray-500 shadow-none mr-4 font-medium"
        >
          Cancel
        </button>

        <Button
          type="submit"
          text="Save Changes"
          className="bg-teal-900 hover:shadow-button hover:bg-teal-500 transition-al text-white"
          app={app}
        />
      </div>
      {isLoading && <Loader />}
    </form>
  );
};
export default PatientProfileForm;
