import React, { useState, useEffect } from "react";
import Loader from "../../components/atoms/Loader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { retrievePaymentCharge } from "../../redux/onboarding/onboardingThunks";
import SuccessScreen from "../../components/molecules/SuccessScreen";
import backgroundImage from "../../assets/onboarding/bg-shadow.png";

const Payment = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tapId = params.get("tap_id");
  const [paymentStatus, setPaymentStatus] = useState(false);

  useEffect(() => {
    sendData();
  }, []);

  const sendData = async () => {
    try {
      const res = await dispatch(
        retrievePaymentCharge({
          charge_id: tapId,
        })
      );

      if (res.payload) {
        setPaymentStatus(res.payload);
      } else {
        console.error("Failed to process payment");
      }
    } catch (error) {
      console.error("Error in processing payment:", error);
    }
  };
  return (
    <div
      className={`bg-cover mobile:bg-center pt-[4.5rem]`}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      {!paymentStatus || paymentStatus?.data.status === "PENDING" ? (
        <Loader />
      ) : paymentStatus?.data.status === "SUCCESS" ? (
        <SuccessScreen
          data={paymentStatus?.data}
          message={paymentStatus?.message}
        />
      ) : null}
    </div>
  );
};
export default Payment;
