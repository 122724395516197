import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  genderOptions,
  countriesOptions,
  nationalityOptions,
  languageOptions,
  religionOptions,
  timeZonesOptions,
  hoursOfTherapyOptions,
  clientsExpOptions,
  designationOptions,
  clientsTypeOptions,
  certifiedBodiesOptions,
  approachesOptions,
  clinicalCredsOptions,
} from "../../services/utils/constants";
import {
  getTherapistProfile,
  submitTherapistProfile,
  deleteMedia,
} from "../../redux/onboarding/onboardingThunks";
import {
  formatBytes,
  getParseDataKeyOrVal,
} from "../../services/globalFunctions";
import Heading from "../atoms/Heading";
import Input from "../atoms/Input";
import Button from "../atoms/Button";

import RadioButton from "../atoms/RadioButton";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/js/utils.js";
import "intl-tel-input/build/css/intlTelInput.css";
import upload from "../../assets/upload.svg";
import Loader from "../atoms/Loader";
import moment from "moment";
import Tooltip from "../atoms/Tooltip";

const firstNamePattern = /^[A-Za-z\s'-]+$/;
const lastNamePattern = /^[A-Za-z\s'-]+$/;
const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const ProfileForm = ({ target, setModalIsOpen }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.app.user);
  const app = user.role;
  const [dobYear, dobMonth, dobDay] = (user?.date_of_birth ?? "").split("-");
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [firstNameArabic, setFirstNameArabic] = useState(
    user.first_name_arabic
  );
  const [lastNameArabic, setLastNameArabic] = useState(user.last_name_arabic);
  const [email, setEmail] = useState(user.username);
  const [bio, setBio] = useState(
    user.bio && user.bio != "null" ? user.bio : ""
  );
  const [bioArabic, setBioArabic] = useState(user.bio_arabic);
  const [errors, setErrors] = useState({});
  const [day, setDay] = useState(
    dobDay ? { value: dobDay, label: dobDay } : ""
  );
  const [month, setMonth] = useState(
    dobMonth ? { value: dobMonth, label: dobMonth } : ""
  );
  const [year, setYear] = useState(
    dobYear ? { value: dobYear, label: dobYear } : ""
  );
  const [selectedGender, setSelectedGender] = useState(
    user.gender
      ? {
          value: user.gender.toUpperCase(),
          label: user.gender,
        }
      : ""
  );
  const [selectedCountry, setSelectedCountry] = useState(
    user.country
      ? {
          value: user.country,
          label: user.country,
        }
      : ""
  );
  const [selectedNationality, setSelectedNationality] = useState(
    user.nationality && user.nationality != "undefined"
      ? {
          value: user.nationality,
          label: user.nationality.replace(/_/g, ", "),
        }
      : ""
  );
  const [selectedEthnicity, setSelectedEthnicity] = useState(
    user.ethnicity && user.ethnicity != "undefined"
      ? {
          value: user.ethnicity,
          label: user.ethnicity.replace(/_/g, ", "),
        }
      : ""
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    user.primary_language
      ? {
          value: user.primary_language,
          label: user.primary_language,
        }
      : ""
  );
  const [selectedSecondaryLanguage, setSelectedSecondaryLanguage] = useState(
    user.secondary_language ? getParseDataKeyOrVal(user.secondary_language) : []
  );
  const [selectedReligion, setSelectedReligion] = useState(
    user.religious_belief && user.religious_belief != "null"
      ? {
          value: user.religious_belief,
          label: user.religious_belief,
        }
      : ""
  );
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    user.timezone
      ? {
          value: user.timezone,
          label: user.timezone,
        }
      : ""
  );
  const [selectedBookingOption, setSelectedBookingOption] = useState(
    user.same_day_book === true ? "yes" : "no"
  );
  const [faithBasedTherapy, setFaithBasedTherapy] = useState(
    user.faith_base_therapy === true ? "yes" : "no"
  );
  const [selectedIntroductorySession, setSelectedIntroductorySession] =
    useState(user.intro_session === true ? "yes" : "no");
  const [selectedAssessmentsOption, setSelectedAssessmentsOption] = useState(
    user.assessment_availability === true ? "yes" : "no"
  );
  const [selectedTherapyOption, setSelectedTherapyOption] = useState(
    user.intro_session === true ? "yes" : "no"
  );
  // const [selectedReminderOption, setSelectedReminderOption] = useState(
  //   user.reminder === true ? "yes" : "no"
  // );
  const [startingYear, setStartingYear] = useState(
    user.years_of_experience
      ? {
          value: user.years_of_experience,
          label: user.years_of_experience,
        }
      : ""
  );
  const [hoursOfTherapy, setHoursOfTherapy] = useState(
    user.clinical_hours
      ? {
          value: user.clinical_hours,
          label: user.clinical_hours,
        }
      : ""
  );
  const [clientExperience, setClientExperience] = useState(
    user.client_experience
      ? {
          value: user.client_experience,
          label: user.client_experience,
        }
      : ""
  );
  const [designation, setDesignation] = useState(
    user.designation
      ? {
          value: user.designation,
          label: user.designation,
        }
      : ""
  );

  const [selectedClinicalCreds, setSelectedClinicalCreds] = useState(
    user.clinical_creds ? getParseDataKeyOrVal(user.clinical_creds) : []
  );
  const [clientTypes, setClientTypes] = useState(
    user.client_work ? getParseDataKeyOrVal(user.client_work) : []
  );
  const [educationalBackground, setEducationalBackground] = useState(
    user.educational_background_certification || ""
  );
  const [licenses, setLicenses] = useState(
    user.license_certifications.map((cert) => ({
      licenseNumber: cert.license_number,
      certifiedFrom: { label: cert.certified_from, value: cert.certified_from },
    }))
  );
  // const [licenseNumber, setLicenseNumber] = useState(user.license_number);
  // const [certifiedBody, setCertifiedBody] = useState(
  //   user.certified_from ? getParseDataKeyOrVal(user.certified_from) : []
  // );
  const [selectedApproach, setSelectedApproach] = useState(
    user.therapy_approach ? getParseDataKeyOrVal(user.therapy_approach) : []
  );

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showFiles, setShowFiles] = useState(user.licenses);
  const [uploadedEduFiles, setUploadedEduFiles] = useState([]);
  const [showEduFiles, setShowEduFiles] = useState(user.certifications);
  const [isLoading, setIsLoading] = useState(false);

  const phoneInput = useRef();
  useEffect(() => {
    dispatch(getTherapistProfile());
  }, []);

  useEffect(() => {
    if (target === "Personal details") {
      const iti = intlTelInput(phoneInput?.current, {
        separateDialCode: true,
        nationalMode: false,
      });
      if (user?.cell_number) {
        iti.setNumber(user.cell_number);
      }
    }
  }, [target, phoneInput]);

  const handleDocChange = (e) => {
    const selectedFiles = e.target.files;
    let promises = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileName = file.name;
      const fileSize = file.size;
      const formattedSize = formatBytes(fileSize);

      const reader = new FileReader();
      const promise = new Promise((resolve, reject) => {
        reader.onload = (event) => {
          const uploadedFile = {
            id: i,
            fileName: fileName,
            fileSize: fileSize,
            formattedSize: formattedSize,
            media_mime: file.type,
            full_url: event.target.result,
          };
          resolve(uploadedFile);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });

      reader.readAsDataURL(file);
      promises.push(promise);
    }

    Promise.all(promises)
      .then((uploadedFiles) => {
        setShowFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
      })
      .catch((error) => {
        console.error("An error occurred while processing files:", error);
      });

    setUploadedFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleEducationalDocChange = (e) => {
    const selectedFiles = e.target.files;
    let promises = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileName = file.name;
      const fileSize = file.size;
      const formattedSize = formatBytes(fileSize);

      const reader = new FileReader();
      const promise = new Promise((resolve, reject) => {
        reader.onload = (event) => {
          const uploadedFile = {
            id: i,
            fileName: fileName,
            fileSize: fileSize,
            formattedSize: formattedSize,
            media_mime: file.type,
            full_url: event.target.result,
          };
          resolve(uploadedFile);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });

      reader.readAsDataURL(file);
      promises.push(promise);
    }

    Promise.all(promises)
      .then((uploadedFiles) => {
        setShowEduFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
      })
      .catch((error) => {
        console.error("An error occurred while processing files:", error);
      });

    setUploadedEduFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleEduDelete = (id, selectedIndex) => {
    setShowEduFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== selectedIndex)
    );
    if (id != 0) dispatch(deleteMedia({ id: id }));
  };
  const handleDelete = (id, selectedIndex) => {
    setShowFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== selectedIndex)
    );
    if (id != 0) dispatch(deleteMedia({ id: id }));
  };

  // Options for days, months, and years
  const days = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));
  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => ({
    value: currentYear - i,
    label: `${currentYear - i}`,
  }));

  const getAdultYears = Array.from({ length: 83 }, (_, i) => ({
    value: currentYear - 18 - i,
    label: `${currentYear - 18 - i}`,
  }));

  const handleDayChange = (selectedOption) => {
    setDay(selectedOption);
  };

  const handleMonthChange = (selectedOption) => {
    setMonth(selectedOption);
  };

  const handleYearChange = (selectedOption) => {
    setYear(selectedOption);
  };

  const handleGenderChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.genderError;
    setErrors(Object.assign({}, Errors));
    setSelectedGender(selectedOption);
  };

  const handleCountryChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.countryError;
    setErrors(Object.assign({}, Errors));
    setSelectedCountry(selectedOption);
  };

  const handleNationalityChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.nationalityError;
    setErrors(Object.assign({}, Errors));
    setSelectedNationality(selectedOption);
  };

  const handleEthnicityChange = (selectedOption) => {
    setSelectedEthnicity(selectedOption);
  };

  const handleLanguageChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.languageError;
    setErrors(Object.assign({}, Errors));
    setSelectedLanguage(selectedOption);
  };

  const handleSecondaryLanguageChange = (selectedOption) => {
    setSelectedSecondaryLanguage(selectedOption);
  };

  const handleReligionChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.religionError;
    setErrors(Object.assign({}, Errors));
    setSelectedReligion(selectedOption);
  };
  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption);
  };

  const handleStartingYearChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.startingYearError;
    setErrors(Object.assign({}, Errors));
    setStartingYear(selectedOption);
  };

  const handleHoursChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.hoursError;
    setErrors(Object.assign({}, Errors));
    setHoursOfTherapy(selectedOption);
  };

  const handleClinicalCredsChange = (selectedOption) => {
    setSelectedClinicalCreds(selectedOption);
  };

  const handleExperienceChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.experienceError;
    setErrors(Object.assign({}, Errors));
    setClientExperience(selectedOption);
  };

  const handleDesignationChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.designationError;
    setErrors(Object.assign({}, Errors));
    setDesignation(selectedOption);
  };

  const handleTypesChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.clientTypeError;
    setErrors(Object.assign({}, Errors));
    setClientTypes(selectedOption);
  };

  const handleBookingOptionChange = (event) => {
    setSelectedBookingOption(event.target.value);
  };

  const handleFBTherapyChange = (event) => {
    setFaithBasedTherapy(event.target.value);
  };

  const handleIntroSessionChange = (event) => {
    setSelectedIntroductorySession(event.target.value);
  };

  const handleAssessmentsAvailability = (event) => {
    setSelectedAssessmentsOption(event.target.value);
  };

  // const handleReminderChange = (event) => {
  //   setSelectedReminderOption(event.target.value);
  // };

  const handleTherapySessionChange = (event) => {
    setSelectedTherapyOption(event.target.value);
  };

  // const handleCertifiedBody = (selectedOption) => {
  //   let Errors = errors;
  //   delete Errors.certifiedBodyError;
  //   setErrors(Object.assign({}, Errors));
  //   setCertifiedBody(selectedOption);
  // };

  const handleLicenseChange = (index, field, value) => {
    const updatedLicenses = [...licenses];
    updatedLicenses[index][field] = value;
    setLicenses(updatedLicenses);
  };
  const addLicense = () => {
    if (licenses.length < 5) {
      setLicenses([...licenses, { licenseNumber: "", certifiedFrom: "" }]);
    }
  };
  const removeLicense = (index) => {
    const updatedLicenses = licenses.filter((_, i) => i !== index);
    setLicenses(updatedLicenses);
  };

  const handleApproachesChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.approachError;
    setErrors(Object.assign({}, Errors));
    setSelectedApproach(selectedOption);
  };

  const getSelectedDate = () => {
    if (day && month && year) {
      const selectedDate = moment(
        `${year.value}-${month.value}-${day.value}`,
        "YYYY-MM-DD"
      );
      return selectedDate.format("YYYY-MM-DD");
    }
    return null;
  };
  const handleSubmitAnswer = async (e) => {
    e.preventDefault();
    let validationErrors = {};
    let rawPhoneNumber;
    if (target === "Personal details") {
      let iti = window.intlTelInputGlobals.getInstance(phoneInput.current);
      let validNumber = iti.isValidNumber();
      rawPhoneNumber = iti.getNumber();
      if (!validNumber) {
        validationErrors.validNumberError = "Please enter a valid phone number";
      }

      const firstNameError = checkFirstNameValidation(firstName);
      const lastNameError = checkLastNameValidation(lastName);
      const emailError = checkEmailValidation(email);

      validationErrors = {
        ...validationErrors,
        ...firstNameError,
        ...lastNameError,
        ...emailError,
      };

      if (!day || !month || !year) {
        validationErrors.dobError = "Date of birth is required";
      }

      if (!selectedGender) {
        validationErrors.genderError = "Gender is required";
      }
    } else if (target === "Demographical Preferences") {
      if (!selectedCountry) {
        validationErrors.countryError = "Country is required";
      }
      if (!selectedNationality) {
        validationErrors.nationalityError = "Nationality is required";
      }
      if (!selectedLanguage) {
        validationErrors.languageError = "Language is required";
      }
      if (!selectedReligion) {
        validationErrors.religionError = "Religion is required";
      }
    } else if (target == "Practice Details") {
      if (!startingYear) {
        validationErrors.startingYearError =
          "Please select a value from the drop-down";
      }
      if (!hoursOfTherapy) {
        validationErrors.hoursError =
          "Please select a value from the drop-down";
      }
      if (!clientExperience) {
        validationErrors.experienceError =
          "Please select a value from the drop-down";
      }
      if (!designation) {
        validationErrors.designationError =
          "Please select a value from the drop-down";
      }
      if (clientTypes?.length == 0) {
        validationErrors.clientTypeError =
          "You must select at least one client type";
      }
    } else if (target == "Educational Background") {
      const educationBgError = checkEducationBgError(educationalBackground);
      validationErrors = { ...validationErrors, ...educationBgError };

      if (uploadedEduFiles?.length == 0 && showEduFiles?.length === 0) {
        validationErrors.degreeError = "Please upload the necessary documents";
      }
    } else if (target == "License Details") {
      // const licenseNumberError = checkLicenseNumberError(licenseNumber);
      // validationErrors = { ...validationErrors, ...licenseNumberError };

      // if (certifiedBody?.length == 0) {
      //   validationErrors.certifiedBodyError =
      //     "Please select a value from the drop-down";
      // }
      // Validate licenses
      licenses.forEach((license, index) => {
        // Validate license number
        if (
          !license.licenseNumber ||
          !checkLicenseNumberError(license.licenseNumber)
        ) {
          validationErrors[
            `licenseNumberError_${index}`
          ] = `Please provide your License number`;
        }

        // Validate certified body
        if (!license.certifiedFrom) {
          validationErrors[
            `certifiedBodyError_${index}`
          ] = `Please select a Certification Body`;
        }
      });
    } else if (target == "Approaches/Techniques") {
      if (selectedApproach?.length == 0) {
        validationErrors.approachError = "Approaches/Techniques is required";
      }
    }

    if (Object.keys(validationErrors).length === 0) {
      const licenseNumbers = licenses.map((item) => item.licenseNumber);
      // For certifiedFroms, extract the value from the multi-select options
      const certifiedFroms = licenses.map((item) => item.certifiedFrom.value);
      setIsLoading(true);
      const res = await dispatch(
        target === "Personal details"
          ? submitTherapistProfile({
              first_name: e.target.first_name.value ?? user.first_name,
              last_name: e.target.last_name.value ?? user.last_name,
              first_name_arabic:
                e.target.first_name_arabic.value ?? user.first_name_arabic,
              last_name_arabic:
                e.target.last_name_arabic.value ?? user.last_name_arabic,
              email: e.target.email.value ?? user.username,
              cell_number: rawPhoneNumber ?? user.cell_number,
              bio: bio,
              bio_arabic: bioArabic,
              date_of_birth: getSelectedDate(),
              gender: selectedGender.value,
            })
          : target === "Demographical Preferences"
          ? submitTherapistProfile({
              country: selectedCountry.value,
              nationality: selectedNationality?.value,
              ethnicity: selectedEthnicity?.value,
              primary_language: selectedLanguage.value,
              secondary_language: JSON.stringify({
                ...selectedSecondaryLanguage,
              }),
              religious_belief: selectedReligion.value,
              timezone: selectedTimeZone?.value,
              faith_base_therapy: faithBasedTherapy,
            })
          : target === "Practice Details"
          ? submitTherapistProfile({
              years_of_experience: startingYear?.value,
              clinical_hours: hoursOfTherapy?.value,
              client_experience: clientExperience?.value,
              designation: designation?.value,
              client_work: JSON.stringify({
                ...clientTypes,
              }),
              clinical_creds: JSON.stringify({ ...selectedClinicalCreds }),
              therapy_approach: JSON.stringify({ ...selectedApproach }),
              inperson_therapy: selectedTherapyOption,
            })
          : target === "Educational Background"
          ? submitTherapistProfile({
              educational_background_certification: educationalBackground,
              certifications: uploadedEduFiles,
            })
          : target === "License Details"
          ? submitTherapistProfile({
              // license_number: licenseNumber,
              // certified_from: JSON.stringify({ ...certifiedBody }),
              license_numbers: licenseNumbers,
              certified_froms: certifiedFroms,
              licenses: uploadedFiles,
            })
          : target === "Approaches/Techniques"
          ? submitTherapistProfile({
              therapy_approach: JSON.stringify({ ...selectedApproach }),
              assessment_availability: selectedAssessmentsOption,
            })
          : target === "Schedule Preferences"
          ? submitTherapistProfile({
              same_day_book: selectedBookingOption,
              intro_session: selectedIntroductorySession,
              // reminders: selectedReminderOption,
            })
          : null
      );
      if (res) {
        setModalIsOpen(false);
        setIsLoading(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const checkFirstNameValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.firstNameError = "Firstname is required";
    else if (!firstNamePattern.test(value))
      Errors.firstNameError = "Firstname is not valid";
    else delete Errors.firstNameError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkLastNameValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.lastNameError = "Lastname is required";
    else if (!lastNamePattern.test(value))
      Errors.lastNameError = "Lastname is not valid";
    else delete Errors.lastNameError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkEmailValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.emailError = "Email is required";
    else if (!emailPattern.test(value))
      Errors.emailError = "Email is not valid";
    else delete Errors.emailError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkEducationBgError = (value, validator = {}) => {
    let Errors = validator;
    if (!value)
      Errors.educationalBackgroundError = "Educational background is required";
    else delete Errors.educationalBackgroundError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkLicenseNumberError = (value, validator = {}) => {
    let Errors = validator;
    if (!value)
      Errors.licenseNumberError = "Please provide a valid License Number";
    else delete Errors.licenseNumberError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      height: "100%",
      borderRadius: "10px",
      borderColor: state.isFocused ? "#005996" : "#E4E7EC",
      ":hover": { borderColor: state.isFocused ? "#005996" : "#E4E7EC" },
      cursor: "pointer",
      boxShadow: state.isFocused ? "0px 0px 0px 4px #BFDEF4" : "",
      minHeight: "44px",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#F2F4F7",
      borderRadius: "0.5rem",
      border: "1px solid #E4E7EC",
      fontSize: "14px",
      fontWeight: "500",
      padding: "0 2px",
    }),
    multiValueRemove: (css) => ({
      ...css,
      color: "#98A2B3",
      ":hover": { background: "transparent" },
    }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        color: isSelected ? "#fff" : "#232428",
        backgroundColor: isSelected ? "#005996" : "initial",
        cursor: isDisabled ? "not-allowed" : "default",
        ":hover": { backgroundColor: isSelected ? "#005996" : "#F1F6F9" },
        fontWeight: isDisabled ? "700" : "400",
      };
    },
  };
  return (
    <form method="POST" onSubmit={handleSubmitAnswer}>
      {target === "Personal details" ? (
        <div>
          <div className="p-4 lg:p-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Personal Details
            </Heading>
            <p className="text-sm text-gray-800/70 mt-1.5">
              Here you can provide your personal details.
            </p>
          </div>

          <ul className="space-y-8 lg:space-y-12 py-4 lg:py-6 px-4 lg:px-8">
            <li className="flex flex-col lg:flex-row justify-between gap-8 lg:gap-4">
              <div className="group relative flex-1">
                <Input
                  label="Email"
                  placeholder="Your email address"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    checkEmailValidation(e.target.value, errors);
                  }}
                  error={errors.emailError}
                  app={app}
                  onBlur={() => {
                    checkEmailValidation(email, errors);
                  }}
                  disabled={true}
                />
                <Tooltip
                  className="mt-[0.5rem] left-[2rem] z-10"
                  direction="right"
                  right="-right-5 after:right-[18px] before:right-4"
                  position="bottom"
                  pin="top"
                >
                  You may reach out to our support team and we’ll update these
                  changes for you
                </Tooltip>
              </div>
              <Input
                label="First Name (English)"
                className="flex-1"
                placeholder="Your first name"
                type="text"
                name="first_name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  checkFirstNameValidation(e.target.value, errors);
                }}
                error={errors.firstNameError}
                app={app}
                onBlur={() => {
                  checkFirstNameValidation(firstName, errors);
                }}
              />
              <Input
                label="Last Name (English)"
                className="flex-1"
                placeholder="Your last name"
                type="text"
                name="last_name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  checkLastNameValidation(e.target.value, errors);
                }}
                error={errors.lastNameError}
                app={app}
                onBlur={() => {
                  checkLastNameValidation(lastName, errors);
                }}
              />
            </li>
            <li className="flex flex-col lg:flex-row justify-between gap-8 lg:gap-4">
              <Input
                label="First Name (Arabic)"
                className="flex-1"
                placeholder="only if you speak Arabic"
                type="text"
                name="first_name_arabic"
                value={firstNameArabic}
                onChange={(e) => setFirstNameArabic(e.target.value)}
                app={app}
              />
              <Input
                label="Last Name (Arabic)"
                className="flex-1"
                placeholder="only if you speak Arabic"
                type="text"
                name="last_name_arabic"
                value={lastNameArabic}
                onChange={(e) => setLastNameArabic(e.target.value)}
                app={app}
              />
              <Input
                label="Phone Number"
                className="flex-1"
                ref={phoneInput}
                placeholder="Your Phone Number"
                type="tel"
                name="cell_number"
                error={errors.validNumberError}
                app={app}
              />
            </li>
            <li className="grid lg:grid-cols-12 gap-8 lg:gap-4">
              <div className="lg:col-span-8">
                <label className="text-sm font-medium text-gray-500">
                  Date of Birth <span className="text-danger-900">*</span>
                </label>
                <div className="flex space-x-2 mt-2">
                  <Select
                    value={day}
                    onChange={handleDayChange}
                    options={days}
                    placeholder="Day"
                    className="h-11 flex-1"
                    styles={colourStyles}
                    menuPlacement="auto"
                  />
                  <Select
                    value={month}
                    onChange={handleMonthChange}
                    options={months}
                    placeholder="Month"
                    className="h-11 flex-1"
                    styles={colourStyles}
                    menuPlacement="auto"
                  />
                  <Select
                    value={year}
                    onChange={handleYearChange}
                    options={getAdultYears}
                    placeholder="Year"
                    className="h-11 flex-1"
                    styles={colourStyles}
                    menuPlacement="auto"
                  />
                </div>
                {errors.dobError && (
                  <div className="text-sm text-danger-900 mt-1.5">
                    {errors.dobError}
                  </div>
                )}
              </div>
              <div className="lg:col-span-4">
                <label className="text-sm font-medium text-gray-500">
                  Gender <span className="text-danger-900">*</span>
                </label>
                <Select
                  value={selectedGender}
                  onChange={handleGenderChange}
                  options={genderOptions}
                  placeholder="Select Gender"
                  className="h-11 mt-2"
                  styles={colourStyles}
                  menuPlacement="auto"
                />
                {errors.genderError && (
                  <div className="text-sm text-danger-900 mt-1.5">
                    {errors.genderError}
                  </div>
                )}
              </div>
            </li>
          </ul>

          <Input
            className="py-4 lg:py-6 px-4 lg:px-8"
            type="textarea"
            name="bio"
            label="Brief Introduction"
            placeholder="Write here"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            app={app}
            inputClassName="px-4 py-2.5 h-28 resize-none"
            hint="HINT: Introduce yourself, tell us about your approach in therapy and what should our customers expect from sessions with you."
          />
          <Input
            className="py-4 lg:py-6 px-4 lg:px-8"
            type="textarea"
            name="bio_arabic"
            label="Brief Introduction (Arabic)"
            placeholder="only if you speak Arabic"
            value={bioArabic}
            onChange={(e) => setBioArabic(e.target.value)}
            app={app}
            inputClassName="px-4 py-2.5 h-28 resize-none"
            hint="HINT: Introduce yourself, tell us about your approach in therapy and what should our customers expect from sessions with you."
          />
        </div>
      ) : target === "Demographical Preferences" ? (
        <div className="pt-8 z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Additional Info
            </Heading>
          </div>
          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium">
                Residing Country <span className="text-danger-900">*</span>
              </label>
              <Select
                value={selectedCountry}
                onChange={handleCountryChange}
                options={countriesOptions.countries}
                placeholder="Choose"
                className="h-11 mt-2"
                styles={colourStyles}
              />
              {errors.countryError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.countryError}
                </div>
              )}
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium">Ethnicity</label>
              <Select
                value={selectedEthnicity}
                onChange={handleEthnicityChange}
                options={nationalityOptions}
                placeholder="Choose"
                className="h-11 mt-2 capitalize"
                styles={colourStyles}
              />
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium">Nationality</label>
              <span className="text-danger-900">*</span>
              <Select
                value={selectedNationality}
                onChange={handleNationalityChange}
                options={nationalityOptions}
                placeholder="Choose"
                className="h-11 mt-2 capitalize"
                styles={colourStyles}
              />
              {errors.nationalityError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.nationalityError}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row pt-8 lg:pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium">Timezone</label>

              <p className="text-gray-500">
                Select the timezone that corresponds to your location.
              </p>
              <Select
                value={selectedTimeZone}
                onChange={handleTimeZoneChange}
                options={timeZonesOptions}
                placeholder="Choose"
                className="h-11 mt-2"
                styles={colourStyles}
              />
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium">Religion</label>{" "}
              <span className="text-danger-900">*</span>
              <p className="text-gray-500">
                Select the religion you identify yourself with.
              </p>
              <Select
                value={selectedReligion}
                onChange={handleReligionChange}
                options={religionOptions}
                placeholder="Choose"
                className="h-11 mt-2"
                styles={colourStyles}
                menuPlacement="auto"
              />
              {errors.religionError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.religionError}
                </div>
              )}
            </div>
          </div>
          <div className="pt-6 lg:py-6 px-4 lg:px-8">
            <label className="text-sm font-medium">Languages</label>
            <span className="text-danger-900">*</span>
            <p className="text-gray-500">
              Only select the languages you can conduct therapy in.
            </p>
            <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0">
              <div className="flex-1">
                <Select
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  options={languageOptions}
                  placeholder="Choose"
                  className="h-11 mt-2 capitalize"
                  styles={colourStyles}
                />
                {errors.languageError && (
                  <div className="text-sm text-danger-900 mt-1.5">
                    {errors.languageError}
                  </div>
                )}
              </div>
              <div className="flex-1">
                <Select
                  isMulti
                  value={selectedSecondaryLanguage}
                  onChange={handleSecondaryLanguageChange}
                  options={languageOptions}
                  placeholder="Choose"
                  className="mt-2"
                  styles={colourStyles}
                />
              </div>
            </div>
          </div>

          <div className="pt-6 px-4 lg:px-8">
            <label className="text-sm font-medium text-gray-700">
              Do you incorporate faith-based therapy support/religion-integrated
              therapy? <span className="text-danger-900">*</span>
            </label>

            <div className="flex space-x-6 mt-6">
              <RadioButton
                value="yes"
                checked={faithBasedTherapy === "yes"}
                onChange={handleFBTherapyChange}
                label="Yes"
                app={app}
              />

              <RadioButton
                value="no"
                checked={faithBasedTherapy === "no"}
                onChange={handleFBTherapyChange}
                label="No"
                app={app}
              />
            </div>
          </div>
        </div>
      ) : target === "Practice Details" ? (
        <div className="pt-8 rounded-lg z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Practice Details
            </Heading>
          </div>
          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                What year did you start clinical work?
                <span className="text-danger-900"> *</span>
              </label>
              <p className="text-sm text-gray-500 font-normal mt-1.5">
                Ensure the year provided is post supervision.
              </p>
              <Select
                value={startingYear}
                onChange={handleStartingYearChange}
                options={years}
                placeholder="Select"
                className="h-11 mt-2"
                styles={colourStyles}
              />
              {errors.startingYearError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.startingYearError}
                </div>
              )}
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Client Types <span className="text-danger-900">*</span>
              </label>
              <p className="text-sm text-gray-500 font-normal mt-1.5">
                What type of clients do you work with?
              </p>
              <Select
                isMulti
                value={clientTypes}
                onChange={handleTypesChange}
                options={clientsTypeOptions}
                placeholder="Choose"
                className="min-h-11 mt-2"
                styles={colourStyles}
              />
              {errors.clientTypeError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.clientTypeError}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Clinical Hours of Therapy
                <span className="text-danger-900"> *</span>
              </label>
              <p className="text-sm text-gray-500 font-normal mt-1.5">
                How many clinical hours of therapy have you done? (please
                exclude any supervision hours).
              </p>
              <Select
                value={hoursOfTherapy}
                onChange={handleHoursChange}
                options={hoursOfTherapyOptions}
                placeholder="Your answer"
                className="h-11 mt-2"
                styles={colourStyles}
              />
              {errors.hoursError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.hoursError}
                </div>
              )}
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Clinical Experience <span className="text-danger-900">*</span>
              </label>
              <p className="text-sm text-gray-500 font-normal mt-1.5 mb-2">
                How many clients have you handled in your experience? (please
                exclude cases handled during supervision/training).
              </p>
              <Select
                value={clientExperience}
                onChange={handleExperienceChange}
                options={clientsExpOptions}
                placeholder="Your Answer"
                className="h-11 mt-2"
                styles={colourStyles}
              />
              {errors.experienceError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.experienceError}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Therapy Approach
              </label>
              <p className="text-sm text-gray-500 font-normal mt-1.5 mb-2">
                How many Therapy Approaches are you licensed with?
              </p>
              <Select
                isMulti
                value={selectedClinicalCreds}
                onChange={handleClinicalCredsChange}
                options={clinicalCredsOptions}
                placeholder="Your answer"
                className="min-h-11 mt-2"
                styles={colourStyles}
              />
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Clinical Designation <span className="text-danger-900">*</span>
              </label>
              <p className="text-sm text-gray-500 font-normal mt-1.5 mb-2">
                What is your Clinical Designation or specialization?
              </p>
              <Select
                value={designation}
                onChange={handleDesignationChange}
                options={designationOptions}
                placeholder="Your Answer"
                className="h-11 mt-2"
                styles={colourStyles}
              />
              {errors.designationError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.designationError}
                </div>
              )}
            </div>
          </div>

          <div className="pt-6 px-4 lg:px-8 flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-700">
                Would you be available for in-person therapy session?{" "}
                <span className="text-danger-900">*</span>
              </label>
              <div className="flex space-x-6 mt-6">
                <RadioButton
                  value="yes"
                  checked={selectedTherapyOption === "yes"}
                  onChange={handleTherapySessionChange}
                  label="Yes"
                  app={app}
                />

                <RadioButton
                  value="no"
                  checked={selectedTherapyOption === "no"}
                  onChange={handleTherapySessionChange}
                  label="No"
                  app={app}
                />
              </div>
            </div>
          </div>
        </div>
      ) : target === "Educational Background" ? (
        <div className="py-8 rounded-lg z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Educational Background
            </Heading>
            <p className="text-sm text-gray-800/70 mt-1.5">
              {`Enter your educational background, along with any certifications, qualifications, licenses & independent mental health courses you've done`}
            </p>
          </div>

          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium">
                {` Please share your educational background, along with any
                certifications, qualifications, licenses & independent mental
                health courses, you've done? (Please specify)`}
                <span className="text-danger-900">*</span>
              </label>
              <Input
                className="mt-2"
                placeholder="Write here"
                type="textarea"
                name="educational_background"
                onChange={(e) => {
                  setEducationalBackground(e.target.value);
                  checkEducationBgError(e.target.value, errors);
                }}
                value={educationalBackground}
                error={errors.educationalBackgroundError}
                app={app}
                inputClassName="px-4 py-2.5 h-28 resize-none"
                onBlur={() =>
                  checkEducationBgError(educationalBackground, errors)
                }
              />
            </div>
          </div>
          <div className="pt-6 lg:py-6 px-4 lg:px-8 flex flex-col lg:flex-row gap-4">
            <div className="lg:w-1/2">
              <label className="text-sm font-medium">
                Upload degree(s), certificate(s)
                <span className="text-danger-900">*</span>
              </label>
              <label
                htmlFor="dropzone-degree"
                className="mt-2.5 flex flex-1 flex-col items-center justify-center py-4 px-6 w-full h-40 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white/20 hover:bg-gray-100/50"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <img src={upload} className="mb-3" />
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="text-blue-900">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500">
                    PDF, DOC, JPG or PNG (max. 800x400px)
                  </p>
                </div>

                <input
                  id="dropzone-degree"
                  type="file"
                  onChange={handleEducationalDocChange}
                  accept="application/pdf, image/*"
                  multiple
                />
              </label>
              {errors.degreeError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.degreeError}
                </div>
              )}
            </div>
            {showEduFiles.length > 0 && (
              <div className="lg:w-1/2">
                <div className="text-sm font-medium">Uploads</div>
                <ul className="flex flex-col gap-4 mt-2.5">
                  {showEduFiles.map((file, index) => (
                    <li
                      className="p-4 bg-gray-100 rounded-2xl text-sm text-gray-500 flex items-start justify-between w-full"
                      key={index}
                    >
                      <div className="flex space-x-4 items-center">
                        {file?.full_url &&
                        file?.media_mime?.includes("image") ? (
                          <img
                            src={file?.full_url}
                            className={
                              "w-10 h-10 rounded-lg object-contain bg-white"
                            }
                          />
                        ) : (
                          <div className="p-2 bg-white rounded-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                d="M11.3333 1.67932C11.3333 1.6574 11.3333 1.64645 11.3324 1.62657C11.3127 1.21509 10.9509 0.853408 10.5394 0.833941C10.5195 0.833002 10.513 0.833004 10.5 0.833008H7.79888C7.1281 0.832999 6.57446 0.832992 6.12348 0.869838C5.65507 0.908109 5.22448 0.990246 4.82003 1.19632C4.19283 1.5159 3.68289 2.02584 3.36331 2.65304C3.15724 3.05749 3.0751 3.48807 3.03683 3.95649C2.99998 4.40748 2.99999 4.96111 3 5.6319V14.3674C2.99999 15.0382 2.99998 15.5919 3.03683 16.0429C3.0751 16.5113 3.15724 16.9419 3.36331 17.3463C3.68289 17.9735 4.19283 18.4835 4.82003 18.803C5.22448 19.0091 5.65507 19.0912 6.12348 19.1295C6.57447 19.1664 7.1281 19.1664 7.7989 19.1663H13.2011C13.8719 19.1664 14.4255 19.1664 14.8765 19.1295C15.3449 19.0912 15.7755 19.0091 16.18 18.803C16.8072 18.4835 17.3171 17.9735 17.6367 17.3463C17.8428 16.9419 17.9249 16.5113 17.9632 16.0429C18 15.5919 18 15.0382 18 14.3674V8.33296C18 8.32001 18 8.31354 17.9991 8.29369C17.9796 7.88216 17.6179 7.52027 17.2064 7.50063C17.1865 7.49968 17.1756 7.49968 17.1538 7.49968L13.4734 7.49969C13.2631 7.49971 13.0583 7.49974 12.8848 7.48556C12.6943 7.47 12.4695 7.43328 12.2434 7.31803C11.9298 7.15824 11.6748 6.90327 11.515 6.58967C11.3997 6.36348 11.363 6.1387 11.3475 5.94821C11.3333 5.77474 11.3333 5.56992 11.3333 5.3596L11.3333 1.67932ZM10.5 9.99968C10.9602 9.99968 11.3333 10.3728 11.3333 10.833V12.4997H13C13.4602 12.4997 13.8333 12.8728 13.8333 13.333C13.8333 13.7932 13.4602 14.1663 13 14.1663H11.3333V15.833C11.3333 16.2932 10.9602 16.6663 10.5 16.6663C10.0398 16.6663 9.66667 16.2932 9.66667 15.833V14.1663H8C7.53976 14.1663 7.16667 13.7932 7.16667 13.333C7.16667 12.8728 7.53976 12.4997 8 12.4997H9.66667V10.833C9.66667 10.3728 10.0398 9.99968 10.5 9.99968Z"
                                fill="#009688"
                              />
                              <path
                                d="M16.2566 5.833C16.5013 5.83301 16.6237 5.83301 16.7239 5.77157C16.8654 5.6848 16.95 5.48038 16.9112 5.31895C16.8838 5.20466 16.804 5.12495 16.6444 4.96554L13.8675 2.18861C13.7081 2.02904 13.6284 1.94925 13.5141 1.92178C13.3526 1.88298 13.1482 1.96759 13.0614 2.10912C13 2.20935 13 2.33169 13 2.57637L13 5.16631C13 5.39966 13 5.51633 13.0454 5.60546C13.0854 5.68386 13.1491 5.7476 13.2275 5.78755C13.3166 5.83296 13.4333 5.83296 13.6667 5.83297L16.2566 5.833Z"
                                fill="#009688"
                              />
                            </svg>
                          </div>
                        )}
                        <div>
                          {file.fileName ?? file.media_name}{" "}
                          <div>
                            {file.formattedSize ?? formatBytes(file.media_size)}
                          </div>
                        </div>
                      </div>

                      <button
                        type="button"
                        className="shadow-none"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent form submission
                          handleEduDelete(file.id, index);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5"
                            stroke="#98A2B3"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      ) : target === "License Details" ? (
        <div className="py-8 rounded-lg z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              License Details
            </Heading>
            <p className="text-sm text-gray-800/70 mt-1.5">
              Please provide your licensing details
            </p>
          </div>

          {licenses.map((license, index) => (
            <div
              key={index}
              className="flex flex-col lg:flex-row py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0"
            >
              <div className="flex-1">
                <label className="text-sm font-medium text-gray-500">
                  License Number<span className="text-danger-900">*</span>
                </label>
                <Input
                  className="mt-2"
                  placeholder="Write here"
                  type="text"
                  name={`license_number_${index}`}
                  value={license.licenseNumber}
                  onChange={(e) =>
                    handleLicenseChange(index, "licenseNumber", e.target.value)
                  }
                  error={errors[`licenseNumberError_${index}`]}
                  app={app}
                />
              </div>
              <div className="flex-1 flex items-center space-x-4">
                <div className="flex-1">
                  <label className="text-sm font-medium text-gray-500">
                    Which body are you certified from?
                    <span className="text-danger-900"> *</span>
                  </label>
                  <Select
                    value={license.certifiedFrom}
                    onChange={(selectedOption) =>
                      handleLicenseChange(
                        index,
                        "certifiedFrom",
                        selectedOption
                      )
                    }
                    options={certifiedBodiesOptions}
                    placeholder="Select"
                    className="mt-2"
                    styles={colourStyles}
                  />
                  {errors[`certifiedBodyError_${index}`] && (
                    <div className="text-sm text-danger-900 mt-1.5">
                      {errors[`certifiedBodyError_${index}`]}
                    </div>
                  )}
                </div>{" "}
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => removeLicense(index)}
                    className="shadow-none mt-7"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M15 5L5 15M5 5L15 15"
                        stroke="#667085"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          ))}
          {licenses.length < 5 && (
            <div className="px-4 lg:px-8 flex justify-end">
              <button
                type="button"
                onClick={addLicense}
                disabled={licenses.length >= 5}
                className="border-none shadow-none flex bg-gray-100 items-center space-x-1 py-1 px-2 border rounded text-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="w-4 h-4"
                >
                  <path
                    d="M10.0001 4.16675V15.8334M4.16675 10.0001H15.8334"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Add more</span>
              </button>
            </div>
          )}
          {/* <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                License Number
                <span className="text-danger-900">*</span>
              </label>

              <Input
                className="mt-2"
                placeholder="Write here"
                type="text"
                name="license_number"
                value={licenseNumber}
                onChange={(e) => {
                  setLicenseNumber(e.target.value);
                  checkLicenseNumberError(e.target.value, errors);
                }}
                error={errors.licenseNumberError}
                app={app}
                onBlur={() => checkLicenseNumberError(licenseNumber, errors)}
              />
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Certification Provider
                <span className="text-danger-900">*</span>
              </label>
              <Select
                isMulti
                value={certifiedBody}
                onChange={handleCertifiedBody}
                options={certifiedBodiesOptions}
                placeholder="Choose"
                className="mt-2"
                styles={colourStyles}
              />
              {errors.certifiedBodyError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.certifiedBodyError}
                </div>
              )}
            </div>
          </div> */}
          <div className="flex flex-col lg:flex-row gap-4 pt-6 lg:py-6 px-4 lg:px-8">
            <div className="lg:w-1/2">
              <label className="text-sm font-medium text-gray-700">
                Supporting Documents
              </label>
              <label
                htmlFor="dropzone-licenses"
                className="mt-2.5 flex flex-1 flex-col items-center justify-center py-4 px-6 w-full h-40 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white/20 hover:bg-gray-100/50"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <img src={upload} className="mb-3" />
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="text-blue-900">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500">
                    PDF, DOC, JPG or PNG (max. 800x400px)
                  </p>
                </div>

                <input
                  id="dropzone-licenses"
                  type="file"
                  onChange={handleDocChange}
                  accept="application/pdf, image/*"
                  multiple
                />
              </label>
            </div>
            {showFiles.length > 0 && (
              <div className="lg:w-1/2">
                <div className="text-sm font-medium">Uploads</div>
                <ul className="flex flex-wrap gap-4 mt-4">
                  {showFiles.map((file, index) => (
                    <li
                      className="p-4 bg-gray-100 rounded-2xl text-sm text-gray-500 flex items-start justify-between w-full"
                      key={index}
                    >
                      <div className="flex space-x-4 items-center">
                        {file?.full_url &&
                        file?.media_mime?.includes("image") ? (
                          <img
                            src={file?.full_url}
                            className={
                              "w-10 h-10 rounded-lg object-contain bg-white"
                            }
                          />
                        ) : (
                          <div className="p-2 bg-white rounded-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                d="M11.3333 1.67932C11.3333 1.6574 11.3333 1.64645 11.3324 1.62657C11.3127 1.21509 10.9509 0.853408 10.5394 0.833941C10.5195 0.833002 10.513 0.833004 10.5 0.833008H7.79888C7.1281 0.832999 6.57446 0.832992 6.12348 0.869838C5.65507 0.908109 5.22448 0.990246 4.82003 1.19632C4.19283 1.5159 3.68289 2.02584 3.36331 2.65304C3.15724 3.05749 3.0751 3.48807 3.03683 3.95649C2.99998 4.40748 2.99999 4.96111 3 5.6319V14.3674C2.99999 15.0382 2.99998 15.5919 3.03683 16.0429C3.0751 16.5113 3.15724 16.9419 3.36331 17.3463C3.68289 17.9735 4.19283 18.4835 4.82003 18.803C5.22448 19.0091 5.65507 19.0912 6.12348 19.1295C6.57447 19.1664 7.1281 19.1664 7.7989 19.1663H13.2011C13.8719 19.1664 14.4255 19.1664 14.8765 19.1295C15.3449 19.0912 15.7755 19.0091 16.18 18.803C16.8072 18.4835 17.3171 17.9735 17.6367 17.3463C17.8428 16.9419 17.9249 16.5113 17.9632 16.0429C18 15.5919 18 15.0382 18 14.3674V8.33296C18 8.32001 18 8.31354 17.9991 8.29369C17.9796 7.88216 17.6179 7.52027 17.2064 7.50063C17.1865 7.49968 17.1756 7.49968 17.1538 7.49968L13.4734 7.49969C13.2631 7.49971 13.0583 7.49974 12.8848 7.48556C12.6943 7.47 12.4695 7.43328 12.2434 7.31803C11.9298 7.15824 11.6748 6.90327 11.515 6.58967C11.3997 6.36348 11.363 6.1387 11.3475 5.94821C11.3333 5.77474 11.3333 5.56992 11.3333 5.3596L11.3333 1.67932ZM10.5 9.99968C10.9602 9.99968 11.3333 10.3728 11.3333 10.833V12.4997H13C13.4602 12.4997 13.8333 12.8728 13.8333 13.333C13.8333 13.7932 13.4602 14.1663 13 14.1663H11.3333V15.833C11.3333 16.2932 10.9602 16.6663 10.5 16.6663C10.0398 16.6663 9.66667 16.2932 9.66667 15.833V14.1663H8C7.53976 14.1663 7.16667 13.7932 7.16667 13.333C7.16667 12.8728 7.53976 12.4997 8 12.4997H9.66667V10.833C9.66667 10.3728 10.0398 9.99968 10.5 9.99968Z"
                                fill="#009688"
                              />
                              <path
                                d="M16.2566 5.833C16.5013 5.83301 16.6237 5.83301 16.7239 5.77157C16.8654 5.6848 16.95 5.48038 16.9112 5.31895C16.8838 5.20466 16.804 5.12495 16.6444 4.96554L13.8675 2.18861C13.7081 2.02904 13.6284 1.94925 13.5141 1.92178C13.3526 1.88298 13.1482 1.96759 13.0614 2.10912C13 2.20935 13 2.33169 13 2.57637L13 5.16631C13 5.39966 13 5.51633 13.0454 5.60546C13.0854 5.68386 13.1491 5.7476 13.2275 5.78755C13.3166 5.83296 13.4333 5.83296 13.6667 5.83297L16.2566 5.833Z"
                                fill="#009688"
                              />
                            </svg>
                          </div>
                        )}
                        <div>
                          {file.fileName ?? file.media_name}{" "}
                          <div>
                            {file.formattedSize ?? formatBytes(file.media_size)}
                          </div>
                        </div>
                      </div>

                      <button
                        className="shadow-none"
                        onClick={() => handleDelete(file.id, index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5"
                            stroke="#98A2B3"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      ) : target === "Schedule Preferences" ? (
        <div className="py-8 rounded-lg z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Schedule Preferences
            </Heading>
            <p className="text-sm text-gray-800/70 mt-1.5">
              Additional settings for your availability.
            </p>
          </div>

          <div className="pt-6 px-4 lg:px-8">
            <div>
              <label className="text-sm font-medium text-gray-700">
                Same Day Bookings <span className="text-danger-900">*</span>
              </label>
              <p className="text-sm text-gray-500 font-normal mt-2">
                Would you like to be contacted for same-day counselling
                sessions?
              </p>
              <div className="flex space-x-6 mt-6">
                <RadioButton
                  value="yes"
                  checked={selectedBookingOption === "yes"}
                  onChange={handleBookingOptionChange}
                  label="Yes"
                  app={app}
                />

                <RadioButton
                  value="no"
                  checked={selectedBookingOption === "no"}
                  onChange={handleBookingOptionChange}
                  label="No"
                  app={app}
                />
              </div>
            </div>
            <div className="mt-12">
              <label className="text-sm font-medium text-gray-700">
                Introductory Sessions
                <span className="text-danger-900">*</span>
              </label>
              <p className="text-sm text-gray-500 mt-2">
                {`Would you like to be available for introductory sessions? This is where you'd provide a free introduction to client in order to help them understand what to expect in an actual therapy session.`}
              </p>
              <div className="flex space-x-6 mt-6">
                <RadioButton
                  value="yes"
                  checked={selectedIntroductorySession === "yes"}
                  onChange={handleIntroSessionChange}
                  label="Yes"
                  app={app}
                />

                <RadioButton
                  value="no"
                  checked={selectedIntroductorySession === "no"}
                  onChange={handleIntroSessionChange}
                  label="No"
                  app={app}
                />
              </div>
            </div>
            {/* <div className="mt-12">
              <label className="text-sm font-medium text-gray-700">
                Reminders
                <span className="text-danger-900">*</span>
              </label>
              <p className="text-sm text-gray-500 mt-2">
                Would you like to be updated?
              </p>
              <div className="flex space-x-6 mt-6">
                <RadioButton
                  value="yes"
                  checked={selectedReminderOption === "yes"}
                  onChange={handleReminderChange}
                  label="Yes"
                />

                <RadioButton
                  value="no"
                  checked={selectedReminderOption === "no"}
                  onChange={handleReminderChange}
                  label="No"
                />
              </div>
            </div> */}
          </div>
        </div>
      ) : target === "Approaches/Techniques" ? (
        <div className="pt-8 rounded-lg bg-white z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Approaches / Techniques
            </Heading>
            <p className="text-sm text-gray-800/70 mt-1.5">
              {`Choose the therapy approaches below that you're certified to practice.`}
            </p>
          </div>
          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Approaches/Techniques
                <span className="text-danger-900">*</span>
              </label>
              <Select
                isMulti
                value={selectedApproach}
                onChange={handleApproachesChange}
                options={approachesOptions}
                placeholder="Choose"
                className="mt-2"
                styles={colourStyles}
              />
              {errors.approachError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.approachError}
                </div>
              )}
            </div>
          </div>
          <div className="p-4 lg:p-8">
            <label className="text-sm font-medium text-gray-700">
              Are you able to provide assessments?*
            </label>
            <p className="text-sm text-gray-500 mt-2">
              Would you like to be available for introductory sessions?
            </p>
            <div className="flex space-x-6 mt-6">
              <RadioButton
                value="yes"
                checked={selectedAssessmentsOption === "yes"}
                onChange={handleAssessmentsAvailability}
                label="Yes"
                app={app}
              />

              <RadioButton
                value="no"
                checked={selectedAssessmentsOption === "no"}
                onChange={handleAssessmentsAvailability}
                label="No"
                app={app}
              />
            </div>
          </div>
        </div>
      ) : null}

      <div className="p-4 lg:p-6 flex justify-end">
        <button
          type="button"
          onClick={() => setModalIsOpen(false)}
          className="text-gray-500 shadow-none mr-4 font-medium"
        >
          Cancel
        </button>

        <Button
          type="submit"
          text="Save Changes"
          className="bg-blue-900 hover:shadow-button hover:bg-blue-500 transition-all text-white"
          app={app}
        />
      </div>
      {isLoading && <Loader />}
    </form>
  );
};
export default ProfileForm;
