import moment from "moment";
import overjoyedIcon from "../../src/assets/overjoyed.svg";
import happyIcon from "../../src/assets/happy.svg";
import neutralIcon from "../../src/assets/neutral.svg";
import sadIcon from "../../src/assets/sad.svg";
import depressedIcon from "../../src/assets/depressed.svg";
import Notification1 from "../../src/assets/notification1.svg";
import Notification2 from "../../src/assets/notification2.svg";
import Notification3 from "../../src/assets/notification3.svg";
import Notification4 from "../../src/assets/notification4.svg";
import Notification5 from "../../src/assets/notification5.svg";
import Notification6 from "../../src/assets/notification6.svg";
import Notification7 from "../../src/assets/notification7.svg";
import Notification8 from "../../src/assets/notification8.svg";
import Notification9 from "../../src/assets/notification9.svg";
import Notification10 from "../../src/assets/notification10.svg";
import Notification11 from "../../src/assets/notification11.svg";
import Notification12 from "../../src/assets/notification12.svg";
import Notification14 from "../../src/assets/notification14.svg";
import { useEffect } from "react";

export const getErrorDetailsforNotif = (response) => {
  if (response && response.detail) {
    return response.detail;
  } else if (response) {
    const breakdown = Object.entries(response);
    let stringtoreturn = "";

    breakdown.forEach((entry) => {
      stringtoreturn += entry[0] + ": " + entry[1];
      stringtoreturn += "\n\n";
    });

    return stringtoreturn;
  } else return null;
};

export const getGreetingTime = (m) => {
  var g = null; //return g

  if (!m || !m.isValid()) {
    return;
  } //if we can't find a valid or filled moment, we return.

  var split_afternoon = 12; //24hr time to split the afternoon
  var split_evening = 17; //24hr time to split the evening
  var currentHour = parseFloat(m.format("HH"));

  if (currentHour >= split_afternoon && currentHour <= split_evening) {
    g = "Afternoon";
  } else if (currentHour >= split_evening) {
    g = "Evening";
  } else {
    g = "Morning";
  }

  return g;
};

// Function to format bytes into a readable format
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const removeZeroTime = (timeString) => {
  const [hour, minute, period] = timeString.split(/[:\s]+/);
  const formattedHour = parseInt(hour, 10);

  if (minute === "00") {
    return `${formattedHour}${period.toLowerCase()}`;
  } else {
    return `${formattedHour}:${minute}${period.toLowerCase()}`;
  }
};
export const getOrdinal = (number) => {
  if (number % 100 >= 11 && number % 100 <= 13) {
    return number + "th";
  }

  switch (number % 10) {
    case 1:
      return number + "st";
    case 2:
      return number + "nd";
    case 3:
      return number + "rd";
    default:
      return number + "th";
  }
};

export const getCurrentDate = () => {
  return moment(new Date()).format("YYYY-MM-DD");
};

export const getMoodIcon = (label) => {
  if (label == "Overjoyed") return overjoyedIcon;
  else if (label == "Happy") return happyIcon;
  else if (label == "Neutral") return neutralIcon;
  else if (label == "Sad") return sadIcon;
  else if (label == "Depressed") return depressedIcon;
};

export const getTimeDifference = (time) => {
  const appointmentTime = new Date(time);
  const timeDifference = appointmentTime.getTime() - new Date().getTime();
  // eslint-disable-next-line no-unused-vars
  const timeDifferenceInMinutes = timeDifference / (1000 * 60);
  return 1; //for testing
};
export const getTimeDifferenceTemp = (time) => {
  const appointmentTime = new Date(time);
  const timeDifference = appointmentTime.getTime() - new Date().getTime();
  const timeDifferenceInMinutes = timeDifference / (1000 * 60);
  return timeDifferenceInMinutes; //for testing
};

export const convertToInitialCase = (str) => {
  if (str) return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
  return "";
};

export const calculateTimeDifference = (timestamp) => {
  const now = new Date();
  const time = new Date(timestamp);
  const differenceInSeconds = Math.floor((now - time) / 1000);

  let diff = "";
  if (differenceInSeconds < 60) {
    diff = `${differenceInSeconds}s ago`;
  } else if (differenceInSeconds < 3600) {
    const minutes = Math.floor(differenceInSeconds / 60);
    diff = `${minutes}m ago`;
  } else if (differenceInSeconds < 86400) {
    const hours = Math.floor(differenceInSeconds / 3600);
    diff = `${hours}h ago`;
  } else if (differenceInSeconds < 259200) {
    const days = Math.floor(differenceInSeconds / 86400);
    diff = `${days}d ago`;
  } else {
    const days = Math.floor(differenceInSeconds / 86400);
    diff = `${days}d ago`;
  }
  return diff;
};

export const getNotificationIcon = (count) => {
  if (count == 1) return Notification1;
  else if (count == 2) return Notification2;
  else if (count == 3) return Notification3;
  else if (count == 4) return Notification4;
  else if (count == 5) return Notification5;
  else if (count == 6) return Notification6;
  else if (count == 7) return Notification7;
  else if (count == 8) return Notification8;
  else if (count == 9) return Notification9;
  else if (count == 10) return Notification10;
  else if (count == 11) return Notification11;
  else if (count == 12) return Notification12;
  else if (count == 14) return Notification14;
};

export const timeToSeconds = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const getParseDataKeyOrVal = (data, type) => {
  let originalData;

  if (typeof data === "string") {
    originalData = JSON.parse(data);
    originalData =
      type === "keys" ? Object.keys(originalData) : Object.values(originalData);
  } else {
    originalData = type === "keys" ? Object.keys(data) : Object.values(data);
  }

  return originalData;
};

export const getParseData = (data) => {
  let originalData;

  if (typeof data === "string") {
    originalData = JSON.parse(data);
  } else {
    originalData = data;
  }

  return originalData;
};

export const lastMessageTime = (timestamp) => {
  const messageTime = moment(timestamp);
  const currentTime = moment();
  const timeDiff = currentTime.diff(messageTime, "seconds");

  let formattedTime;

  if (timeDiff < 60) {
    formattedTime = `${timeDiff}s ago`;
  } else if (timeDiff < 3600) {
    formattedTime = `${Math.floor(timeDiff / 60)}m ago`;
  } else if (timeDiff < 86400) {
    formattedTime = `${Math.floor(timeDiff / 3600)}h ago`;
  } else if (timeDiff < 2592000) {
    formattedTime = `${Math.floor(timeDiff / 86400)}d ago`;
  } else {
    formattedTime = messageTime.format("D MMM");
  }

  return formattedTime;
};

export const MetaTags = ({ title, description, url }) => {
  useEffect(() => {
    document.title = title;

    let metaDescription = document.querySelector('meta[name="description"]');
    let metaOgTitle = document.querySelector('meta[property="og:title"]');
    let metaOgDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    let metaOgUrl = document.querySelector('meta[property="og:url"]');
    let metaTwitterTitle = document.querySelector('meta[name="twitter:title"]');
    let metaTwitterDescription = document.querySelector(
      'meta[name="twitter:description"]'
    );
    metaDescription.content = description;
    metaOgTitle.content = title;
    metaOgDescription.content = description;
    metaTwitterTitle.content = title;
    metaTwitterDescription.content = description;
    metaOgUrl.content = `${process.env.REACT_APP_BASE_URL}${url}`;
  }, [title, description]);

  return null;
};
