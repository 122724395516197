import React from "react";
import Heading from "../atoms/Heading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
// import Author from "../../assets/author-placeholder.png";
import "swiper/css";

const ReviewSlider = ({ data, wrapperClassName, className, heading }) => {
  const reviewStars = (rating) =>
    Array.from({ length: 5 }).map((_, index) => (
      <svg
        key={index}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_1335_4239)">
          <path
            d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.63070 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
            fill={index < rating ? "#FF9310" : "#D0D5DD"}
          />
        </g>
        <defs>
          <clipPath id="clip0_1335_4239">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ));
  return (
    <section className={wrapperClassName}>
      <Heading
        variant="h2"
        heading={heading}
        className="text-lg lg:text-2xl font-medium"
      />
      <div className={`${className} review-swiper flex flex-col items-center`}>
        <Swiper
          modules={[Navigation, Pagination]}
          autoHeight={true}
          navigation={{
            prevEl: `.owl-prev`,
            nextEl: `.owl-next`,
          }}
          slidesPerView={1}
          spaceBetween={0}
          pagination={{
            el: ".swiper-pagination",
            clickable: true,
          }}
          className="w-full"
        >
          {data.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="flex space-x-1">{reviewStars(item.rating)}</div>
                <p className="mt-2">
                  <span className="text-gray-500">{`Customer’s review after ${item.session} sessions with `}</span>
                  {item.name}
                </p>
                <p className="mt-6 text-xl lg:text-2xl text-gray-500">
                  {item.review}
                </p>
                <div className="mt-4 lg:mt-8 flex items-center space-x-4">
                  <img src={item.image} className="w-12 h-12 rounded-full" />
                  <div className="flex-1">
                    <Heading variant="h4" className="text-sm">
                      {item.name}
                    </Heading>
                    <p className="text-gray-500 text-xs">{item.designation}</p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="inline-flex bg-white px-2 lg:px-3 py-1 lg:py-2 rounded-3xl space-x-4 mt-8 lg:mt-10">
          <div className="owl-prev stroke-black cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
            >
              <path
                d="M25.6666 16.0003L6.99995 16.0003M6.99995 16.0003L16.3333 6.66699M6.99995 16.0003L16.3333 25.3337"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="swiper-pagination flex items-center"></div>
          <div className="owl-next  stroke-black cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
            >
              <path
                d="M7.33337 16.0003L26 16.0003M26 16.0003L16.6667 6.66699M26 16.0003L16.6667 25.3337"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ReviewSlider;
