import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import accepted from "../../assets/accepted-cell.png";
import Notaccepted from "../../assets/not-accepted-cell.png";
import completed from "../../assets/completed-cell.png";
import cancelled from "../../assets/cancelled-cell.png";
import architecture from "../../assets/architecture.png";
import Heading from "../atoms/Heading";
import { useMediaQuery } from "react-responsive";

// Define the steps
const generateTourSteps = (isMobile) => {
  let steps = [
    {
      target: ".step-1",
      content: (
        <div>
          <Heading variant="h2" className="font-medium text-xl lg:text-2xl">
            Welcome to sessions
          </Heading>
          <p className="text-sm text-gray-600 mt-2">
            Hello there! Welcome to sessions, this is where you can keep track
            of your sessions, from upcoming sessions to the sessions already
            attended, you can see it all here!
          </p>
          <ul className="mt-6 lg:mt-10">
            <li className="lg:flex items-center lg:space-x-8 p-6 border border-gray-100 rounded-t-2xl">
              <img src={accepted} />
              <div>
                <Heading
                  variant="h3"
                  className="text-sm lg:text-base font-medium mt-4 lg:mt-0"
                >
                  Upcoming Sessions
                </Heading>
                <p className="text-sm text-gray-600 mt-2">
                  These are the upcoming sessions that you need to take up.
                </p>
              </div>
            </li>
            <li className="lg:flex items-center lg:space-x-8 p-6 border border-gray-100 border-t-0">
              <img src={Notaccepted} />
              <div>
                <Heading
                  variant="h3"
                  className="text-sm lg:text-base font-medium mt-4 lg:mt-0"
                >
                  Upcoming Sessions - Not yet accepted
                </Heading>
                <p className="text-sm text-gray-600 mt-2">
                  These are the sessions that you have yet to confirm.
                </p>
              </div>
            </li>
            <li className="lg:flex items-center lg:space-x-8 p-6 border border-gray-100 border-t-0">
              <img src={completed} />
              <div>
                <Heading
                  variant="h3"
                  className="text-sm lg:text-base font-medium mt-4 lg:mt-0"
                >
                  Completed Sessions
                </Heading>
                <p className="text-sm text-gray-600 mt-2">
                  These are the sessions that are successfully done with the
                  client.
                </p>
              </div>
            </li>
            <li className="lg:flex items-center lg:space-x-8 p-6 border border-gray-100 rounded-b-2xl border-t-0">
              <img src={cancelled} />
              <div>
                <Heading
                  variant="h3"
                  className="text-sm lg:text-base font-medium mt-4 lg:mt-0"
                >
                  Cancelled Sessions
                </Heading>
                <p className="text-sm text-gray-600 mt-2">
                  These are the sessions that are cancelled by either you or the
                  client.
                </p>
              </div>
            </li>
          </ul>
        </div>
      ),
      placement: "center",
    },
    {
      target: ".step-2",
      content: (
        <div>
          <Heading variant="h2" className="font-medium text-xl lg:text-2xl">
            Session Card Architecture
          </Heading>
          <p className="text-sm text-gray-600 mt-2">
            This is where you can see your Session’s details in a form of a
            small card.
          </p>
          <ul className="mt-6 lg:mt-10 border border-gray-100 rounded-2xl p-6">
            <li className="flex items-center justify-center">
              <img src={architecture} />
            </li>
            <li className="mt-8">
              <div className="flex items-center space-x-2">
                <div className="w-5 h-5 bg-blue-300 rounded-full text-xs flex items-center justify-center">
                  1
                </div>
                <Heading
                  variant="h3"
                  className="text-sm lg:text-base font-medium"
                >
                  {`Client’s Name`}
                </Heading>
              </div>
              <p className="text-sm text-gray-600 mt-2">
                {`This will be your Client’s name`}
              </p>
            </li>
            <li className="mt-8">
              <div className="flex items-center space-x-2">
                <div className="w-5 h-5 bg-blue-300 rounded-full text-xs flex items-center justify-center">
                  2
                </div>
                <Heading
                  variant="h3"
                  className="text-sm lg:text-base font-medium"
                >
                  Time - Duration
                </Heading>
              </div>
              <p className="text-sm text-gray-600 mt-2">
                This will be the start time and end time of the session
              </p>
            </li>
          </ul>
        </div>
      ),
      placement: "center",
    },
    {
      target: ".session-filters",
      content: (
        <div>
          <div className="font-medium text-xl lg:text-2xl">Session Filters</div>
          <p className="mt-2 text-sm text-gray-500 max-w-80">
            This is where you can filter which sessions should be visible on the
            calendar.
          </p>
          <ul className="mt-6">
            <li className="pb-4">
              <div className="text-xs font-medium text-gray-500">Upcoming</div>
              <p className="text-sm font-medium mt-1 max-w-80">
                This will display all the sessions that are yet to be completed
              </p>
            </li>
            <li className="border-t border-gray-100 py-4">
              <div className="text-xs font-medium text-gray-500">
                All Sessions
              </div>
              <p className="text-sm font-medium mt-1 max-w-80">
                {`This will display all the sessions you’ve taken with Fitcy Health.`}
              </p>
            </li>
            <li className="border-t border-gray-100 pt-4">
              <div className="text-xs font-medium text-gray-500">Completed</div>
              <p className="text-sm font-medium mt-1">
                This will display all the completed sessions.
              </p>
            </li>
          </ul>
        </div>
      ),
    },
  ];
  if (isMobile) {
    // If mobile, remove steps not available
    steps = steps.filter(
      (step) =>
        step.target !== ".view-filters" &&
        step.target !== ".manage-availability"
    );
  } else {
    // If not mobile, add remaining steps
    steps.push(
      {
        target: ".view-filters",
        content: (
          <div>
            <div className="font-medium text-0xl lg:text-2xl">
              Calendar View
            </div>
            <p className="mt-2 text-sm text-gray-500 max-w-80">
              This is where you can decide how you want to view your sessions.
            </p>
            <ul className="mt-6">
              <li className="pb-4">
                <div className="text-xs font-medium text-gray-500">
                  Weekly View -{" "}
                  <span className="text-blue-600">Recommended</span>
                </div>
                <p className="text-sm font-medium mt-1">
                  This will display sessions in for a week
                </p>
              </li>
              <li className="border-t border-gray-100 py-4">
                <div className="text-xs font-medium text-gray-500">3 Days</div>
                <p className="text-sm font-medium mt-1">
                  This will display sessions for 3 days.
                </p>
              </li>
              <li className="border-t border-gray-100 pt-4">
                <div className="text-xs font-medium text-gray-500">Daily</div>
                <p className="text-sm font-medium mt-1">
                  This will display sessions for a single day.
                </p>
              </li>
            </ul>
          </div>
        ),
      },
      {
        target: ".manage-availability",
        content: (
          <div>
            <div className="font-medium text-lg">Manage your availability</div>
            <p className="mt-2 text-sm text-gray-500 max-w-80">
              This is where you can manage your availability under which
              existing and new clients can book with you
            </p>
          </div>
        ),
      }
    );
  }
  return steps;
};

// Define the Tour component
const TherapistProductTour = ({ setTourGuide }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  // Define our state
  const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    disableBeacon: true,
    hideCloseButton: true,
    disableCloseOnEsc: true,
    disableOverlayClose: true,
    locale: { skip: "Close", last: isMobile ? "Complete" : "Done" },
    hideBackButton: true,
    showSkipButton: true,
  };

  // Set up the reducer function
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };
      default:
        return state;
    }
  };

  const [tourState, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
    steps: generateTourSteps(isMobile),
  });

  useEffect(() => {
    if (!localStorage.getItem("tour")) {
      dispatch({ type: "START" });
    }
  }, []);

  const callback = (data) => {
    const { action, index, type, status } = data;
    // if (status === STATUS.FINISHED) {
    //   setGoToManageAvailability(true);
    // }
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });
      setTourGuide(false);
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  const defaultStyles = {
    buttonNext: {
      backgroundColor: "#005996",
      borderRadius: 8,
      color: "#fff",
      fontSize: 12,
      outline: "none",
      padding: "0.5rem 0.75rem",
    },
    buttonSkip: {
      color: "",
      fontSize: 12,
      boxShadow: "none",
      fontWeight: "500",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: "30",
      position: "fixed",
    },
    tooltipContainer: {
      textAlign: "left",
    },
    tooltipContent: {
      padding: "0",
    },
    tooltipFooter: {
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: isMobile ? 0 : 64,
      position: isMobile ? "fixed" : "static",
      bottom: "0",
      padding: isMobile ? "1.5rem 1rem" : 0,
      backgroundColor: "#ffffff",
      left: "0",
      right: "0",
      boxShadow: isMobile ? "0px 0px 36px 0px rgba(0, 0, 0, 0.08)" : "none",
      borderRadius: "0 0 1rem 1rem",
    },
    buttonBack: {
      marginRight: 10,
    },
    tooltip: {
      padding: isMobile ? 16 : 24,
      paddingBottom: isMobile ? 84 : 24,
      borderRadius: 16,
      maxWidth: isMobile ? "100%" : 720,
      width: isMobile ? "calc(100vw - 2rem)" : "100%",
      height: isMobile ? "100%" : "auto",
      maxHeight: "90vh",
      overflow: "auto",
      position: "relative",
    },
    spotlight: {
      // background:
      //   "linear-gradient(180deg, rgba(0, 150, 136, 0.10) 0%, rgba(0, 150, 136, 0.00) 100%)",

      border: "2px dashed #007BD2",
      width: 128,
    },
    spotlightLegacy: {
      boxShadow: `0 0 0 9999px #cccccc, #bbbbbb`,
    },
  };

  return (
    <JoyRide
      {...tourState}
      callback={callback}
      showSkipButton={true}
      styles={defaultStyles}
    />
  );
};

export default TherapistProductTour;
