import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGet,
  apiGetAuthenticated,
  apiPatchAuthenticated,
  apiPost,
  apiPostAuthenticated,
  apiPutAuthenticated,
  apiDeleteAuthenticated,
} from "../../services/api/apiService";
import { getMe } from "../app/appThunks";

export const getQuestionnaire = createAsyncThunk(
  "onboarding/getQuestionnaire",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated("/api/assessments/");
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const submitAnswer = createAsyncThunk(
  "onboarding/submitAnswer",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      "/api/assessments/submit-answer/",
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getRecommendations = createAsyncThunk(
  "onboarding/getRecommendations",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated("/api/therapist/");
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getAllRecommendations = createAsyncThunk(
  "onboarding/getAllRecommendations",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(`/api/therapist/?${payload}`);
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getAssessmentsSummary = createAsyncThunk(
  "onboarding/getAssessmentsSummary",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated("/api/assessments/summary/");
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const createPayment = createAsyncThunk(
  "onboarding/createPayment",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      "/api/patient_onboarding/create-payment/",
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const applyCoupon = createAsyncThunk(
  "onboarding/applyCoupon",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      "/api/patient_onboarding/apply-coupon/",
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const patchOnboardingStatus = createAsyncThunk(
  "onboarding/patchOnboardingStatus",
  async (payload, { rejectWithValue }) => {
    const response = await apiPatchAuthenticated(
      `/patient/${payload.id}/`,
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const patchOnboardingStatusTherapist = createAsyncThunk(
  "onboarding/patchOnboardingStatusTherapist",
  async (payload, { rejectWithValue }) => {
    const response = await apiPatchAuthenticated(
      `/therapist/${payload.id}/`,
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistFromSlug = createAsyncThunk(
  "onboarding/getTherapistFromSlug",
  async (payload, { rejectWithValue }) => {
    const response = await apiGet(
      `/api/get_therapist_directory/${payload.slug}/`
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistPackageFromID = createAsyncThunk(
  "onboarding/getTherapistPackageFromID",
  async (payload, { rejectWithValue }) => {
    const response = await apiPost(
      `/api/patient_onboarding/list-package-of-therapist/`,
      { user_id: payload.therapistID }
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistProfile = createAsyncThunk(
  "onboarding/getTherapistProfile",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated("/api/therapist/profile/");
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const submitTherapistProfile = createAsyncThunk(
  "onboarding/submitTherapistProfile",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await apiPutAuthenticated(
      `/api/therapist/profile/`,
      payload
    );
    if (response.status === 200) {
      dispatch(getMe());
      return await response.json();
    } else return rejectWithValue(await response.json());
  }
);

export const deleteMedia = createAsyncThunk(
  "onboarding/deleteMedia",
  async (payload, { rejectWithValue }) => {
    const response = await apiDeleteAuthenticated(
      `/api/media/${payload.id}/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getPackageDetails = createAsyncThunk(
  "onboarding/getPackageDetails",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/appointment/${payload.package_id}/schedules/${
        payload.start_date
          ? `?start_date=${payload.start_date}&end_date=${payload.end_date}`
          : ""
      }${
        payload.start_date && payload.patient_id
          ? `&patient_id=${payload.patient_id}`
          : payload.patient_id
          ? `?patient_id=${payload.patient_id}`
          : ""
      }`,
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const submitSession = createAsyncThunk(
  "onboarding/submitSession",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/appointment/${payload.package_id}/schedules/`,
      payload?.selected_slot
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);
export const getCheckoutDetails = createAsyncThunk(
  "onboarding/getCheckoutDetails",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/checkout/detail/`,
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const createPaymentIntent = createAsyncThunk(
  "onboarding/createPaymentIntent",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/payments/stripe/create-intent/`,
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getPaymentStatus = createAsyncThunk(
  "onboarding/getPaymentStatus",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      "/api/payments/stripe/status/",
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getUserNotification = createAsyncThunk(
  "onboarding/getUserNotification",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(`/api/notification/`);
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const readNotification = createAsyncThunk(
  "onboarding/readNotification",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await apiPutAuthenticated(
      "/api/notification/mark-read/",
      payload
    );

    if (response.status === 200) {
      dispatch(getUserNotification());
      return await response.json();
    } else return rejectWithValue(await response.json());
  }
);

export const createPaymentCharge = createAsyncThunk(
  "onboarding/createPaymentCharge",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/payments/tap/create-charge/`,
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const retrievePaymentCharge = createAsyncThunk(
  "onboarding/retrievePaymentCharge",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/payments/tap/retrieve-charge/`,
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);
