import React, { useEffect, useState } from "react";
// import Select from "react-select";
import Modal from "react-modal";
import "react-calendar/dist/Calendar.css";
import Heading from "../atoms/Heading";
import Button from "../atoms/Button";
import { useMediaQuery } from "react-responsive";
import Input from "../atoms/Input";
import { addClientNote } from "../../redux/therapist/therapistThunks";
import { useDispatch } from "react-redux";
import ButtonLoader from "../atoms/ButtonLoader";

const AddNote = ({ isOpen, onRequestClose, clientId }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const dispatch = useDispatch();
  const [note, setNote] = useState("");
  const [addingNote, setAddingNote] = useState(false);
  const processAddNote = async () => {
    setAddingNote(true);
    await dispatch(
      addClientNote({
        patient: clientId,
        content: note,
      })
    );
    setAddingNote(false);
    onRequestClose();
  };

  useEffect(() => {
    setNote("");
    setAddingNote(false);
  }, [isOpen, dispatch]);

  const customStyles = {
    content: {
      maxWidth: "450px",
      height: "auto",
      maxHeight: "50%",
      width: isMobile ? "calc(100% - 2rem)" : "100%",
      left: 0,
      right: 0,
      bottom: "auto",
      margin: "auto",
      border: "none",
      borderRadius: "16px",
      padding: isMobile ? "" : "",
    },
    overlay: {
      zIndex: "30",
      background: "rgba(0, 0, 0, 0.5)",
    },
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Special Slots"
      style={customStyles}
    >
    <Heading heading="Clinical Note" variant="h2" className="p-4 font-medium" />
      
      <Input
        className="p-4 lg:py-6"
        type="textarea"
        name="bio"
        placeholder="Write here..."
        value={note}
        onChange={(e) => setNote(e.target.value)}
        inputClassName="px-4 py-2.5 h-28 resize-none"
        hint="⚠️ This note is confidential and will only be visible to you."
        disabled={addingNote}
      />
      <div className="px-4 pb-4 flex justify-end">
        <button
          onClick={onRequestClose}
          className="text-gray-500 shadow-none mr-4 font-medium"
          disabled={addingNote}
        >
          Cancel
        </button>
        <Button
          className="bg-blue-900 text-white px-4 py-1.5 rounded-lg font-medium min-w-[160px]"
          onClick={processAddNote}
          disabled={addingNote || note === ""}
        >
          {addingNote ? <ButtonLoader /> : "Save changes"}
        </Button>
      </div>
    </Modal>
  );
};

export default AddNote;
