import React from "react";

const ButtonLoader = () => {
    return (
        <div className="flex items-center justify-center">
            <div className="border-t-2 border-white border-solid rounded-full h-6 w-6 animate-spin"></div>
        </div>
    );
};
export default ButtonLoader;
