import React from "react";
import Heading from "../atoms/Heading";
import Link from "../atoms/Link";
import successIcon from "../../assets/success.png";
import Button from "../atoms/Button";

const SuccessScreen = ({ data, message }) => {
  return (
    <>
      <div className="py-8 rounded-lg bg-white shadow-registrationForm z-10 relative max-w-lg mx-auto top-12">
        <img src={successIcon} className="mx-auto" />
        <div className="max-w-xs text-center mx-auto">
          <Heading variant="h2" className="text-2xl font-medium">
            {message || "Appointment Scheduled Successfully!"}
          </Heading>
          <p className="mt-2 text-gray-500">
            {`We've sent an email to both you and your therapist about the same.`}
          </p>
        </div>
        <div className="flex border-t border-b border-gray-200 text-xs lg:text-sm mt-4">
          <ul className="px-6 space-y-11 border-r border-gray-200 pt-6 pb-8 font-medium">
            {data?.package_text && <li>Item</li>}
            {data?.datetime_text && <li>Date & time</li>}
          </ul>
          <ul className="pl-8 pr-6 text-gray-500 space-y-5 lg:space-y-8 flex-1 pt-6 pb-8">
            {data?.package_text && <li>{data?.package_text}</li>}
            {data?.datetime_text && <li>{data?.datetime_text}</li>}
          </ul>
        </div>
        <div className="text-gray-400 font-medium text-sm text-center mt-6">
          <span className="hidden">
            Need to make a change? Reschedule or Cancel
          </span>
          <Link href="/sessions" className="text-teal-500 underline block">
            Go back to Sessions
          </Link>
        </div>
      </div>
      <div className="p-4 bg-teal-900 rounded-2xl shadow-md mt-6 flex items-center space-x-6 hidden">
        <div className="flex space-x-4 flex-1 items-center">
          <div className="flex items-center bg-teal-50 border-2 border-white rounded-full h-8 w-8 justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.1787 2.96289H5.11751C4.52124 2.96288 4.02912 2.96287 3.62823 2.99563C3.21186 3.02965 2.82912 3.10266 2.46961 3.28584C1.9121 3.5699 1.45882 4.02318 1.17475 4.5807C0.991573 4.94021 0.918562 5.32295 0.884544 5.73932C0.85179 6.1402 0.851798 6.63232 0.851807 7.22858V10.549C0.851798 11.1453 0.85179 11.6374 0.884544 12.0383C0.918562 12.4547 0.991573 12.8374 1.17475 13.1969C1.45882 13.7545 1.9121 14.2077 2.46961 14.4918C2.82912 14.675 3.21186 14.748 3.62823 14.782C4.02912 14.8148 4.52124 14.8148 5.11751 14.8147H9.17868C9.77495 14.8148 10.2671 14.8148 10.668 14.782C11.0843 14.748 11.4671 14.675 11.8266 14.4918C12.3841 14.2077 12.8374 13.7545 13.1215 13.1969C13.3046 12.8374 13.3776 12.4547 13.4117 12.0383C13.442 11.6665 13.4442 11.2162 13.4444 10.6771L14.8915 12.1241C15.0331 12.2658 15.1722 12.405 15.2942 12.5071C15.4031 12.5983 15.6323 12.781 15.9498 12.806C16.3044 12.8339 16.6509 12.6904 16.8819 12.4199C17.0887 12.1777 17.1216 11.8864 17.1341 11.745C17.1482 11.5866 17.1481 11.3897 17.1481 11.1894V6.58812C17.1481 6.38785 17.1482 6.19098 17.1341 6.03249C17.1216 5.89109 17.0887 5.59979 16.8819 5.35761C16.6509 5.08715 16.3044 4.94362 15.9498 4.97153C15.6323 4.99652 15.4031 5.17927 15.2942 5.27039C15.1723 5.37252 15.0331 5.51173 14.8915 5.65336L13.4444 7.10047C13.4442 6.5614 13.442 6.11112 13.4117 5.73932C13.3776 5.32295 13.3046 4.94021 13.1215 4.5807C12.8374 4.02318 12.3841 3.5699 11.8266 3.28584C11.4671 3.10266 11.0843 3.02965 10.668 2.99563C10.2671 2.96287 9.77497 2.96288 9.1787 2.96289Z"
                fill="#009688"
              />
            </svg>
          </div>
          <p className="text-sm text-teal-100 flex-1">
            Laboriosam totam qui eum sunt et qui cumque. Aut numquam dolore
            facilis incidunt aperiam repellendus.
          </p>
        </div>
        <Button
          className="text-teal-900 bg-teal-100 flex items-center space-x-2 shadow-none w-full lg:w-auto lg:ml-auto text-sm mt-4 lg:mt-0"
        // onClick={openCreditsModal}
        >
          Buy Session Credits
        </Button>
      </div>
    </>
  );
};

export default SuccessScreen;
