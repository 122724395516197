import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Header from "../../components/molecules/Header";
import backgroundImage from "../../assets/onboarding/bg-shadow.png";
import cardPlaceholder from "../../assets/patient_placeholder-card.png";

import Heading from "../../components/atoms/Heading";
import Button from "../../components/atoms/Button";

import close from "../../assets/x-close.svg";
import "react-accessible-accordion/dist/fancy-example.css";
import { useMediaQuery } from "react-responsive";
import {
  getPatientSettings,
  updatePatientSettings,
} from "../../redux/patient/patientThunks";
import Loader from "../../components/atoms/Loader";
import ButtonLoader from "../../components/atoms/ButtonLoader";
import { MetaTags } from "../../services/globalFunctions";

const PatientSettings = () => {
  const patientSettings = useSelector((state) => state.patient.patientSettings);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const [selectedTabIndex, setSelectedTabIndex] = useState(isMobile ? -1 : 0);

  const [isLoading, setIsLoading] = useState(false);

  const handleTabClose = () => {
    // Set selectedIndex to -1 to deselect the current tab
    setSelectedTabIndex(-1);
    document.body.classList.remove("overflow-hidden");
  };

  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
    if (index !== -1 && isMobile) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const settingTitles = {
    bn_session_upcoming: "Upcoming Appointment",
    bn_session_cancel_reschedule: "Session Cancelled/Rescheduled",
    bn_new_message: "New Messages",
    bn_package_expiry: "Package Expiry",
    browser_announcements: "Announcements",
    browser_notes: "Notes",
    en_invoicing: "Invoicing",
    en_session_upcoming: "Upcoming Appointment",
    en_session_cancel_reschedule: "Session Cancelled/Rescheduled",
    en_new_message: "New Messages",
    en_package_expiry: "Package Expiry",
    email_announcements: "Announcements",
    email_notes: "Notes",
  };

  const settingDescriptions = {
    bn_session_upcoming:
      "Get notified of your upcoming appointment 10 mins before it starts.",
    bn_session_cancel_reschedule:
      "Get notified when a session gets cancelled or rescheduled.",
    bn_new_message: "Get notified when your therapist sends you a message.",
    bn_package_expiry:
      "Get notified when your package is expiring within 24 hours.",
    browser_announcements: "Get notified when we announce new feature updates!",
    browser_notes:
      "Get notified when the therapist add a new note to a session.",
    en_invoicing: "Receive an invoice when you make a purchase.",
    en_session_upcoming:
      "Get notified of your upcoming appointment 10 mins before it starts",
    en_session_cancel_reschedule:
      "Get notified when a session gets cancelled or rescheduled.",
    en_new_message: "Get notified when your therapist sends you a message.",
    en_package_expiry:
      "Get notified when your package is expiring within 24 hours.",
    email_announcements: "Get notified when we announce new feature updates!",
    email_notes: "et notified when the therapist add a new note to a session.",
  };

  const [settings, setSettings] = useState(null);

  useEffect(() => {
    dispatch(getPatientSettings());
  }, []);

  useEffect(() => {
    if (patientSettings) setSettings(patientSettings?.settings);
  }, [patientSettings]);

  const handleToggleChange = (setting) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [setting]: !prevSettings[setting],
    }));
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    const res = await dispatch(updatePatientSettings(settings));
    if (res) setIsLoading(false);
  };

  const handleCancel = () => {
    // Reset settings to initial state when Cancel is clicked
    setSettings(patientSettings?.settings);
  };

  return (
    <>
      <MetaTags
        title="Account Settings | Fitcy Health"
        description="Manage your Fitcy Health account"
        url="/settings"
      />
      <Header
        className="border-b border-gray-200 fixed z-10 bg-white"
        backButton={true}
        section="profile"
      />
      <div className="pt-[4.5rem] min-h-screen">
        <div
          className={`pt-10 lg:pt-14 pb-24 bg-cover mobile:bg-center min-h-[calc(100vh-4.5rem)]`}
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="container mx-auto max-w-screen-xl px-4 pb-4">
            <Heading
              variant="h1"
              heading="Settings"
              className="font-medium text-2xl lg:text-2.5xl pb-5 border-b border-gray-200"
            />
            <Tabs
              className="flex mt-6 lg:mt-10 settings-tabs patient"
              selectedIndex={selectedTabIndex}
              onSelect={handleTabSelect}
            >
              <TabList className="w-full lg:w-80 lg:mr-6">
                <Tab className="p-4 whitespace-nowrap bg-white lg:bg-transparent shadow-settingsTabs text-gray-500 stroke-gray-400 cursor-pointer outline-none text-sm lg:shadow-none flex-1 font-medium flex items-center border border-gray-100 lg:border-none rounded-xl justify-between">
                  <div className="flex space-x-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M9.35419 21C10.0593 21.6224 10.9856 22 12 22C13.0145 22 13.9407 21.6224 14.6458 21M18 8C18 6.4087 17.3679 4.88258 16.2427 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.8826 2.63214 7.75738 3.75736C6.63216 4.88258 6.00002 6.4087 6.00002 8C6.00002 11.0902 5.22049 13.206 4.34968 14.6054C3.61515 15.7859 3.24788 16.3761 3.26134 16.5408C3.27626 16.7231 3.31488 16.7926 3.46179 16.9016C3.59448 17 4.19261 17 5.38887 17H18.6112C19.8074 17 20.4056 17 20.5382 16.9016C20.6852 16.7926 20.7238 16.7231 20.7387 16.5408C20.7522 16.3761 20.3849 15.7859 19.6504 14.6054C18.7795 13.206 18 11.0902 18 8Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Notification & Email Preferences</span>
                  </div>
                  {isMobile && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="15"
                      viewBox="0 0 8 15"
                      fill="none"
                      className="ml-auto"
                    >
                      <path
                        d="M1 13.4511L7 7.45114L1 1.45114"
                        stroke="#667085"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </Tab>
                <Tab className="p-4 whitespace-nowrap bg-white lg:bg-transparent shadow-settingsTabs text-gray-500 stroke-gray-400 cursor-pointer outline-none text-sm lg:shadow-none flex-1 font-medium flex items-center justify-between border border-gray-100 lg:border-none rounded-xl mt-6 lg:mt-0">
                  <div className="flex space-x-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="16"
                      viewBox="0 0 22 16"
                      fill="none"
                    >
                      <path
                        d="M21 6H1M10 10H5M1 4.2L1 11.8C1 12.9201 1 13.4802 1.21799 13.908C1.40973 14.2843 1.71569 14.5903 2.09202 14.782C2.51984 15 3.07989 15 4.2 15L17.8 15C18.9201 15 19.4802 15 19.908 14.782C20.2843 14.5903 20.5903 14.2843 20.782 13.908C21 13.4802 21 12.9201 21 11.8V4.2C21 3.0799 21 2.51984 20.782 2.09202C20.5903 1.7157 20.2843 1.40974 19.908 1.21799C19.4802 1 18.9201 1 17.8 1L4.2 1C3.0799 1 2.51984 1 2.09202 1.21799C1.7157 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.07989 1 4.2Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Billing</span>
                  </div>
                  {isMobile && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="15"
                      viewBox="0 0 8 15"
                      fill="none"
                      className="ml-auto"
                    >
                      <path
                        d="M1 13.4511L7 7.45114L1 1.45114"
                        stroke="#667085"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </Tab>
              </TabList>

              <TabPanel
                className={`${isMobile && selectedTabIndex === -1 ? "hidden" : ""
                  } translate-x-full transition-all lg:translate-x-0 bg-white h-[calc(100%-4.5rem)] lg:h-auto lg:rounded-2xl`}
              >
                <div className="lg:shadow-therapistCard lg:rounded-2xl h-full">
                  {isMobile && (
                    <button
                      onClick={handleTabClose}
                      className="shadow-none absolute right-4 top-4"
                    >
                      <img src={close} />{" "}
                    </button>
                  )}
                  <div className="p-4 lg:p-10 lg:rounded-t-2xl bg-white pb-24 lg:pb-10 h-full lg:h-auto mobile:overflow-auto">
                    <div>
                      <Heading
                        variant="h2"
                        className="text-lg lg:text-2xl font-medium"
                      >
                        Notification Preferences
                      </Heading>
                      <p className="text-sm text-gray-500 mt-2">
                        Get emails to find out what’s going on when you’re not
                        online. You can turn them off anytime.
                      </p>
                    </div>
                    <div className="lg:flex gap-10 border-t border-gray-200 pt-8 mt-8">
                      <div className="lg:w-64">
                        <div className="text-gray-700 font-medium">
                          Browser Notifications
                        </div>
                        <p className="text-sm text-gray-500">
                          These will be the notifications you’ll receive from
                          your browser.
                        </p>
                      </div>
                      <ul className="space-y-6 mt-6 lg:mt-0">
                        {settings &&
                          Object.keys(settings)?.map((setting, index) => {
                            if (index < 4) {
                              return (
                                <li key={setting} className="flex">
                                  <label className="relative w-9 h-5 mr-2">
                                    <input
                                      type="checkbox"
                                      id={setting}
                                      checked={settings[setting]}
                                      onChange={() =>
                                        handleToggleChange(setting)
                                      }
                                      className="opacity-0 w-0 h-0"
                                    />
                                    <span
                                      className={`slider absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full transition duration-400 ${settings[setting]
                                        ? "bg-teal-500"
                                        : "bg-gray-100"
                                        }`}
                                    >
                                      <span
                                        className={`toggle-circle absolute h-4 w-4 bg-white rounded-full transition duration-400 left-[2px] bottom-[2px] ${settings[setting]
                                          ? "transform translate-x-4"
                                          : ""
                                          }`}
                                      ></span>
                                    </span>
                                  </label>
                                  <div className="flex-1">
                                    <div className="text-sm text-gray-700 font-medium">
                                      {settingTitles[setting]}
                                    </div>
                                    <p className="text-sm text-gray-500">
                                      {settingDescriptions[setting]}
                                    </p>
                                  </div>
                                </li>
                              );
                            }
                            return null; // Add this line to handle the other items in the array
                          })}
                      </ul>
                    </div>
                    <div className="lg:flex gap-10 border-t border-gray-200 pt-8 mt-8">
                      <div className="lg:w-64">
                        <div className="text-gray-700 font-medium">
                          Email Notifications
                        </div>
                        <p className="text-sm text-gray-500">
                          These will be the notifications you’ll receive in your
                          email inbox.
                        </p>
                      </div>
                      <ul className="space-y-6 mt-6 lg:mt-0">
                        {settings &&
                          Object.keys(settings)?.map((setting, index) => {
                            if (index >= 4 && index <= 8) {
                              return (
                                <li key={setting} className="flex">
                                  <label className="relative w-9 h-5 mr-2">
                                    <input
                                      type="checkbox"
                                      id={setting}
                                      checked={settings[setting]}
                                      onChange={() =>
                                        handleToggleChange(setting)
                                      }
                                      className="opacity-0 w-0 h-0"
                                    />
                                    <span
                                      className={`slider absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full transition duration-400 ${settings[setting]
                                        ? "bg-teal-500"
                                        : "bg-gray-100"
                                        }`}
                                    >
                                      <span
                                        className={`toggle-circle absolute h-4 w-4 bg-white rounded-full transition duration-400 left-[2px] bottom-[2px] ${settings[setting]
                                          ? "transform translate-x-4"
                                          : ""
                                          }`}
                                      ></span>
                                    </span>
                                  </label>
                                  <div className="flex-1">
                                    <div className="text-sm text-gray-700 font-medium">
                                      {settingTitles[setting]}
                                    </div>
                                    <p className="text-sm text-gray-500">
                                      {settingDescriptions[setting]}
                                    </p>
                                  </div>
                                </li>
                              );
                            }
                            return null; // Add this line to handle the other items in the array
                          })}
                      </ul>
                    </div>
                  </div>

                  <div className="px-4 lg:px-10 py-4 bg-gray-100 border-t border-gray-200 lg:rounded-b-2xl flex items-center justify-end mobile:fixed bottom-0 mobile:w-full">
                    {patientSettings?.settings != settings && (
                      <button
                        className="text-gray-500 mr-4 font-medium shadow-none"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    )}
                    <Button
                      className={`${patientSettings?.settings != settings
                        ? "bg-teal-900 text-white"
                        : "bg-gray-200 text-gray-500"
                        }  ${isLoading && "w-40"}`}
                      onClick={handleSaveChanges}
                      disabled={
                        patientSettings?.settings != settings ? false : true
                      }
                    >
                      {isLoading ? <ButtonLoader /> : "Save Changes"}
                    </Button>
                  </div>
                </div>
              </TabPanel>
              <TabPanel
                className={`${isMobile && selectedTabIndex === -1 ? "hidden" : ""
                  } translate-x-full transition-all lg:translate-x-0 bg-white h-[calc(100vh-4.5rem)] lg:h-auto mobile:overflow-auto lg:rounded-2xl`}
              >
                <div className="p-4 lg:p-10 lg:rounded-2xl bg-white lg:shadow-therapistCard">
                  {isMobile && (
                    <button
                      onClick={handleTabClose}
                      className="shadow-none absolute right-4 top-4"
                    >
                      <img src={close} />{" "}
                    </button>
                  )}
                  <div>
                    <Heading
                      variant="h2"
                      className="text-lg lg:text-2xl font-medium"
                    >
                      Billing
                    </Heading>
                    <p className="text-sm text-gray-500 mt-2 mb-8 lg:mb-0">
                      Review your billing history
                    </p>
                  </div>
                  {patientSettings?.billings.length > 0 ? (
                    <>
                      {isMobile ? (
                        <>
                          {patientSettings?.billings?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="bg-gray-100 rounded-2xl p-4 space-y-4 mb-3"
                              >
                                <div className="flex items-center space-x-2">
                                  <div className="text-base text-gray-800">
                                    {item?.credit}{" "}
                                    {item?.credit > 1 ? "Sessions" : "Session"}
                                  </div>
                                  <span
                                    className={`${item?.validity_info?.expiry_status ==
                                      "Active"
                                      ? "text-teal-500 bg-teal-100"
                                      : " bg-warning-200 text-warning-900"
                                      }  px-1.5 py-0.5 rounded-3xl items-center text-xs font-medium`}
                                  >
                                    <span>
                                      {item?.validity_info?.expiry_status}
                                    </span>
                                  </span>
                                </div>
                                <div className="flex items-center justify-between">
                                  <div className="text-sm text-gray-500">
                                    Purchase date
                                  </div>
                                  <div className="text-sm text-gray-700">
                                    {item?.purchased_date}
                                  </div>
                                </div>
                                <div className="flex items-center justify-between">
                                  <div className="text-sm text-gray-500">
                                    Expiry
                                  </div>
                                  <div className="text-sm text-gray-700">
                                    {item?.validity_info?.expiry_date}
                                  </div>
                                </div>
                                <div className="flex items-center justify-between">
                                  <div className="text-sm text-gray-500">
                                    Amount
                                  </div>
                                  <div className="text-sm text-gray-700">
                                    ${item?.amount}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className="lg:flex gap-10 border-t border-gray-200 pt-8 mt-8">
                          <div className="lg:w-64">
                            <div className="text-gray-700 font-medium">
                              Billing History
                            </div>
                            <p className="text-sm text-gray-500">
                              Review your billing history.
                            </p>
                          </div>
                          <div className="border border-gray-200 rounded-lg mt-6 lg:mt-0">
                            <div className="grid gap-2 lg:gap-4 px-4 grid-cols-12 bg-gray-100 text-xs text-gray-500">
                              <div className="py-3 col-span-3">Purchase</div>
                              <div className="py-3 col-span-2">Status</div>
                              <div className="py-3 col-span-2">Plan</div>
                              <div className="py-3 col-span-3">Expiry</div>
                              <div className="py-3 col-span-2">Amount</div>
                            </div>
                            {patientSettings?.billings?.map((item) => {
                              return (
                                <div
                                  key={item?.id}
                                  className="grid gap-4 px-4  grid-cols-12 text-sm border-t border-gray-200"
                                >
                                  <div className="py-4 col-span-3">
                                    {item?.purchased_date}
                                  </div>
                                  <div className="py-4 col-span-2">
                                    <span
                                      className={`${item?.validity_info?.expiry_status ==
                                        "Active"
                                        ? "text-teal-500 bg-teal-50"
                                        : " bg-warning-100 text-warning-900"
                                        }  px-1.5 py-0.5 rounded-3xl items-center text-xs font-medium`}
                                    >
                                      <span>
                                        {item?.validity_info?.expiry_status}
                                      </span>
                                    </span>
                                  </div>
                                  <div className="py-4 col-span-2 text-gray-500">
                                    {item?.credit}{" "}
                                    {item?.credit > 1 ? "Sessions" : "Session"}
                                  </div>
                                  <div className="py-4 col-span-3 text-gray-500">
                                    {item?.validity_info?.expiry_date}
                                  </div>
                                  <div className="py-4 col-span-2 text-gray-500">
                                    ${item?.amount}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="lg:flex justify-center gap-10 border-t border-gray-200 pt-8 mt-8">
                      <div className="lg:w-72 text-center">
                        <img
                          src={cardPlaceholder}
                          className="block mx-auto max-w-16"
                          alt=""
                        />
                        <h3 className="text-[16px] font-medium text-gray-700 py-5">
                          Your Billing History will Apear Here{" "}
                        </h3>
                      </div>
                    </div>
                  )}
                </div>
              </TabPanel>
            </Tabs>
          </div>
          {isLoading && <Loader />}
        </div>
      </div>
    </>
  );
};

export default PatientSettings;
