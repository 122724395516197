// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --brand: #ffffff;
  --primary: #e0f2f1;
  --brand: rgba(0, 150, 136, 1);
  --borderradius-site: 10px;
  --borderradius-small: 4px;
  --boxshadow-soft: #80808080 0px 0px 4px;
  --app-red: #f44336;
}
`, "",{"version":3,"sources":["webpack://./src/styles/constants.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,6BAA6B;EAC7B,yBAAyB;EACzB,yBAAyB;EACzB,uCAAuC;EACvC,kBAAkB;AACpB","sourcesContent":[":root {\n  --brand: #ffffff;\n  --primary: #e0f2f1;\n  --brand: rgba(0, 150, 136, 1);\n  --borderradius-site: 10px;\n  --borderradius-small: 4px;\n  --boxshadow-soft: #80808080 0px 0px 4px;\n  --app-red: #f44336;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
