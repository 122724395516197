import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useGoogleLogin } from "@react-oauth/google";

import Header from "../../components/molecules/Header";
import backgroundImage from "../../assets/onboarding/bg-shadow.png";
import googleCalendarIcon from "../../assets/google-calendar-icon.png";
import delIcon from "../../assets/delete-icon.svg";

import Heading from "../../components/atoms/Heading";
import ConfirmationModal from "../../components/molecules/ConfirmationModal";

import { useMediaQuery } from "react-responsive";

import { MetaTags } from "../../services/globalFunctions";
import {
  SendGoogleCalendarToken,
  connectedAccountsList,
  deleteAccount,
  // updateGoogleAccount,
} from "../../redux/therapist/therapistThunks";

const TherapistSettings = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(isMobile ? -1 : 0);
  const [accountsList, setAccountsList] = useState([]);
  const [integrationError, setIntegrationError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountId, setAccountId] = useState(null);

  // const [visibilityState, setVisibilityState] = useState({});

  useEffect(() => {
    const fetchList = async () => {
      try {
        const response = await dispatch(connectedAccountsList());
        if (response.payload && response.payload.data) {
          const accountsData = response.payload.data;
          setAccountsList(accountsData);
          // const initialVisibilityState = accountsData.reduce((acc, account) => {
          //   acc[account.id] = account.calendar_event; // Assuming each account has an initial eventVisibility state
          //   return acc;
          // }, {});
          // setVisibilityState(initialVisibilityState);
        }
      } catch (error) {
        console.error("Error fetching connected accounts:", error);
      }
    };
    fetchList();
  }, [dispatch]);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log("tokenResponse", tokenResponse);
      try {
        const status = await dispatch(
          SendGoogleCalendarToken({
            token: tokenResponse.code,
            // calendar_email: userEmail,
            calendar_provider: "google",
          })
        );
        if (status.payload.success) {
          window.location.reload();
        } else {
          setIntegrationError("You need to give us permissions to read your calendar in order to sync your calendar successfully. Please retry after allowing.");
        }
      } catch (error) {
        console.error("Error in processing payment:", error);
      }
    },
    onError: (error) => console.log(error),
    scope:
      "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    flow: "auth-code",
    access_type: "offline",
  });

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleDelete = useCallback(
    async (accountId) => {
      try {
        await dispatch(deleteAccount({ account_id: accountId }));
        setAccountsList((prevAccounts) =>
          prevAccounts.filter((account) => account.id !== accountId)
        );
      } catch (error) {
        console.error("Error deleting account:", error);
      }
    },
    [dispatch]
  );

  const handleConfirm = useCallback(
    (id) => {
      handleDelete(id);
      handleCloseModal();
    },
    [handleDelete, handleCloseModal]
  );

  // const handleVisibilityChange = (accountId) => {
  //   setVisibilityState((prevState) => {
  //     const newVisibility = !prevState[accountId];
  //     dispatch(
  //       updateGoogleAccount({
  //         id: accountId,
  //         calendarVisibility: { calendar_event: newVisibility },
  //       })
  //     );
  //     return { ...prevState, [accountId]: newVisibility };
  //   });
  // };

  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
    if (index !== -1 && isMobile) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const accountListItems = useMemo(
    () =>
      accountsList.map((account) => (
        <div key={account.id} className="p-4 border rounded-2xl w-full">
          <div className="lg:flex justify-between">
            <div className="flex space-x-4">
              <img src={googleCalendarIcon} alt="Google Calendar Icon" />
              <div>
                <div className="font-medium">Google Calendar</div>
                <div className="text-sm font-normal text-gray-500 mt-1.5">
                  {account.calendar_email}
                </div>
              </div>
            </div>
            {account.token_active ? (
              <div className="flex items-center space-x-4 mt-4 lg:mt-0">
                <div className="rounded-3xl text-success-500 bg-[#DCFEE5] px-4 py-2 font-medium">
                  Connected
                </div>
                <div
                  onClick={() => {
                    handleOpenModal();
                    setAccountId(account.id);
                  }}
                  className="cursor-pointer"
                >
                  <img src={delIcon} alt="delete" />
                </div>
              </div>
            ) : (
              <div className="flex items-center space-x-4 mt-4 lg:mt-0">
                <button
                  onClick={() => login()}
                  className="shadow-none text-blue-900 font-medium"
                >
                  Reconnect
                </button>
                <div className="rounded-3xl text-danger-900 bg-danger-100 px-4 py-2 font-medium">
                  Disconnected
                </div>
                <div
                  onClick={() => {
                    handleOpenModal();
                    setAccountId(account.id);
                  }}
                  className="cursor-pointer"
                >
                  <img src={delIcon} alt="delete" />
                </div>
              </div>
            )}
          </div>
          {/* <div className="mt-10">
            <div className="text-blue-900 font-medium">
              Additional Settings
            </div>
            <div className="flex mt-4">
              <label className="relative w-9 h-5 mr-2">
                <input
                  type="checkbox"
                  id={account.id}
                  checked={visibilityState[account.id]}
                  onChange={() =>
                    handleVisibilityChange(account.id)
                  }
                  className="opacity-0 w-0 h-0"
                />
                <span
                  className={`slider absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full transition duration-400 ${
                    visibilityState[account.id]
                      ? "bg-blue-500"
                      : "bg-gray-100"
                  }`}
                >
                  <span
                    className={`toggle-circle absolute h-4 w-4 bg-white rounded-full transition duration-400 left-[2px] bottom-[2px] ${
                      visibilityState[account.id]
                        ? "transform translate-x-4"
                        : ""
                    }`}
                  ></span>
                </span>
              </label>
              <div className="flex-1">
                <div className="text-sm text-gray-700 font-medium">
                  Event Visibility
                </div>
                <p className="text-sm text-gray-500">
                  Choose to share the event details with your
                  calendar scheudler at Fitcy
                </p>
              </div>
            </div>
          </div> */}
        </div>
      )),
    [accountsList, handleDelete, handleOpenModal, login]
  );

  return (
    <>
      <MetaTags
        title="Account Settings | Fitcy Health"
        description="Manage your Fitcy Health account"
        url="/therapist/settings"
      />
      <Header
        className="border-b border-gray-200 fixed z-10 bg-white"
        backButton={true}
        section="profile"
        app="THERAPIST"
      />
      <div className="pt-[4.5rem] min-h-screen">
        <div
          className={`pt-10 lg:pt-14 pb-24 bg-cover mobile:bg-center min-h-[calc(100vh-4.5rem)]`}
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="container mx-auto max-w-screen-xl px-4 pb-4">
            <Heading
              variant="h1"
              heading="Settings"
              className="font-medium text-2xl lg:text-2.5xl pb-5 border-b border-gray-200"
            />
            <Tabs
              className="flex mt-6 lg:mt-10 settings-tabs therapist"
              selectedIndex={selectedTabIndex}
              onSelect={handleTabSelect}
            >
              <TabList className="w-full lg:w-80 lg:mr-6">
                <Tab className="p-4 whitespace-nowrap bg-white lg:bg-transparent shadow-settingsTabs text-gray-500 stroke-gray-400 cursor-pointer outline-none text-sm lg:shadow-none flex-1 font-medium flex items-center border border-gray-100 lg:border-none rounded-xl justify-between">
                  <div className="flex space-x-2 items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M21 10H3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span>Sync Calendar</span>
                  </div>
                  {isMobile && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="15"
                      viewBox="0 0 8 15"
                      fill="none"
                      className="ml-auto"
                    >
                      <path
                        d="M1 13.4511L7 7.45114L1 1.45114"
                        stroke="#667085"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </Tab>
                {/* <Tab className="p-4 whitespace-nowrap bg-white lg:bg-transparent shadow-settingsTabs text-gray-500 stroke-gray-400 cursor-pointer outline-none text-sm lg:shadow-none flex-1 font-medium flex items-center justify-between border border-gray-100 lg:border-none rounded-xl mt-6 lg:mt-0">
                  <div className="flex space-x-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="16"
                      viewBox="0 0 22 16"
                      fill="none"
                    >
                      <path
                        d="M21 6H1M10 10H5M1 4.2L1 11.8C1 12.9201 1 13.4802 1.21799 13.908C1.40973 14.2843 1.71569 14.5903 2.09202 14.782C2.51984 15 3.07989 15 4.2 15L17.8 15C18.9201 15 19.4802 15 19.908 14.782C20.2843 14.5903 20.5903 14.2843 20.782 13.908C21 13.4802 21 12.9201 21 11.8V4.2C21 3.0799 21 2.51984 20.782 2.09202C20.5903 1.7157 20.2843 1.40974 19.908 1.21799C19.4802 1 18.9201 1 17.8 1L4.2 1C3.0799 1 2.51984 1 2.09202 1.21799C1.7157 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.07989 1 4.2Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Billing</span>
                  </div>
                  {isMobile && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="15"
                      viewBox="0 0 8 15"
                      fill="none"
                      className="ml-auto"
                    >
                      <path
                        d="M1 13.4511L7 7.45114L1 1.45114"
                        stroke="#667085"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </Tab> */}
              </TabList>

              <TabPanel
                className={`${isMobile && selectedTabIndex === -1 ? "hidden" : ""
                  } translate-x-full transition-all lg:translate-x-0 bg-white h-[calc(100%-4.5rem)] lg:h-auto lg:rounded-2xl`}
              >
                <div className="p-4 lg:p-10 lg:rounded-t-2xl bg-white pb-24 lg:pb-10 h-full lg:h-auto mobile:overflow-auto">
                  <div>
                    <Heading
                      variant="h2"
                      className="text-lg lg:text-2xl font-medium"
                    >
                      Sync Calendar
                    </Heading>
                    <p className="text-sm text-gray-500 mt-2">
                      Connect with your google calendar to view all the
                      scheduled sessions at one place
                    </p>
                  </div>
                  <div className="border-t border-gray-200 pt-8 mt-8 space-y-4">
                    {" "}
                    {accountsList.length > 0 ? (
                      <>
                        {accountListItems}
                        {accountsList.length < 2 && (
                          <button
                            onClick={() => login()}
                            className="mt-4 shadow-none text-blue-900 font-medium border border-solid border-gray-300 rounded-2xl w-full p-6 text-center block"
                          >
                            Sync More Accounts
                          </button>
                        )}
                      </>
                    ) : (
                      <div className="p-4 border rounded-2xl w-full">
                        <div className="flex justify-between">
                          <div className="flex space-x-4">
                            <img src={googleCalendarIcon} />
                            <div>
                              <div className="font-medium">Google Calendar</div>
                              <div className="text-sm font-normal text-gray-500 mt-1.5">
                                Google suite sync
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => login()}
                            className="shadow-none text-blue-900 font-medium"
                          >
                            Connect
                          </button>
                        </div>
                      </div>
                    )}
                    {integrationError && (
                      <div className="text-sm mt-4 text-danger-900">
                        {integrationError}
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>
              {/* <TabPanel
                className={`${
                  isMobile && selectedTabIndex === -1 ? "hidden" : ""
                } translate-x-full transition-all lg:translate-x-0 bg-white h-[calc(100vh-4.5rem)] lg:h-auto mobile:overflow-auto lg:rounded-2xl`}
              >
                2
              </TabPanel> */}
            </Tabs>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Delete Account"
        message1="Are you sure you want to delete this account?"
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        onConfirm={() => handleConfirm(accountId)}
      />
    </>
  );
};

export default TherapistSettings;
