import React from "react";
import Modal from "react-modal";
import WarningIcon from "../../assets/warning_icon.svg";
import { useSelector } from "react-redux";

const ConfirmationModal = ({
  isOpen,
  onRequestClose,
  onConfirm,
  title,
  message1,
  message2,
}) => {
  const fitycyUser = useSelector((state) => state.app.user);
  const isTherapist = fitycyUser?.role == "THERAPIST";

  const warningModalStyles = {
    content: {
      maxWidth: "400px",
      border: "none",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      margin: "auto",
      borderRadius: "12px",
      height: "max-content",
      background: "#fff",
      overflowY: "auto",
      padding: "24px",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.09)",
      zIndex: "10",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={warningModalStyles}
    >
      <img src={WarningIcon} />
      <div className="text-lg font-medium mt-4">{title}</div>
      {message1 && <div className="text-sm text-gray-500 my-2">{message1}</div>}
      {message2 && <div className="text-sm text-gray-500">{message2}</div>}
      <div className="flex items-center justify-between space-x-4 mt-7">
        <div
          onClick={onRequestClose}
          className={`cursor-pointer rounded-full ${
            isTherapist
              ? "bg-blue-100 text-blue-900"
              : "bg-[#D8EEEC] text-teal-900"
          }  w-full justify-center flex  font-medium text-base py-2`}
        >
          No
        </div>
        <div
          onClick={onConfirm}
          className={`cursor-pointer rounded-full ${
            isTherapist ? "bg-blue-900" : "bg-teal-900"
          } w-full justify-center flex text-white font-medium text-base py-2`}
        >
          Yes
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
