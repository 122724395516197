import React from "react";

const StarRating = ({ rating, maxRating }) => {
  const calculateStarWidth = (rating, maxRating) => {
    const percentage = (rating / maxRating) * 100;
    return percentage;
  };
  const starWidthPercentage = calculateStarWidth(rating, maxRating);
  const renderStars = (fill) => {
    return (
      <svg
        width="116"
        height="20"
        viewBox="0 0 116 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_7_48)">
          <mask
            id="mask0_7_48"
            maskUnits="userSpaceOnUse"
            x="3"
            y="1"
            width="19"
            height="20"
          >
            <path
              d="M17.9965 1.54166H6.82411C4.76733 1.54166 3.09998 3.20004 3.09998 5.24577V16.3581C3.09998 18.4038 4.76733 20.0622 6.82411 20.0622H17.9965C20.0533 20.0622 21.7207 18.4038 21.7207 16.3581V5.24577C21.7207 3.20004 20.0533 1.54166 17.9965 1.54166Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_7_48)">
            <path
              d="M17.9965 1.54166H6.82411C4.76733 1.54166 3.09998 3.20004 3.09998 5.24577V16.3581C3.09998 18.4038 4.76733 20.0622 6.82411 20.0622H17.9965C20.0533 20.0622 21.7207 18.4038 21.7207 16.3581V5.24577C21.7207 3.20004 20.0533 1.54166 17.9965 1.54166Z"
              fill={fill}
            />
            <g filter="url(#filter1_d_7_48)">
              <path
                d="M12.1447 4.20689C12.2283 3.95094 12.5923 3.95094 12.6759 4.20689L14.0198 8.32071C14.0572 8.43521 14.1645 8.51267 14.2855 8.51267H18.6344C18.905 8.51267 19.0175 8.85706 18.7986 9.01523L15.2802 11.5577C15.1823 11.6285 15.1414 11.7539 15.1788 11.8683L16.5227 15.9822C16.6063 16.2381 16.3118 16.451 16.0928 16.2928L12.5745 13.7503C12.4766 13.6795 12.344 13.6795 12.2461 13.7503L8.7278 16.2928C8.50887 16.451 8.21438 16.2381 8.29799 15.9822L9.64189 11.8683C9.67927 11.7539 9.63831 11.6285 9.54041 11.5577L6.02203 9.01523C5.80314 8.85706 5.91566 8.51267 6.18622 8.51267H10.5352C10.6562 8.51267 10.7634 8.43521 10.8008 8.32071L12.1447 4.20689Z"
                fill="white"
              />
            </g>
          </g>
          <path
            d="M17.9965 1.77316H6.82408C4.89585 1.77316 3.3327 3.3279 3.3327 5.24577V16.3581C3.3327 18.276 4.89585 19.8307 6.82408 19.8307H17.9965C19.9247 19.8307 21.4879 18.276 21.4879 16.3581V5.24577C21.4879 3.3279 19.9247 1.77316 17.9965 1.77316Z"
            stroke="#BAE3E0"
          />
        </g>
        <g filter="url(#filter2_d_7_48)">
          <mask
            id="mask1_7_48"
            maskUnits="userSpaceOnUse"
            x="25"
            y="1"
            width="20"
            height="20"
          >
            <path
              d="M40.3414 1.54166H29.169C27.1122 1.54166 25.4448 3.20004 25.4448 5.24577V16.3581C25.4448 18.4038 27.1122 20.0622 29.169 20.0622H40.3414C42.3982 20.0622 44.0655 18.4038 44.0655 16.3581V5.24577C44.0655 3.20004 42.3982 1.54166 40.3414 1.54166Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_7_48)">
            <path
              d="M40.3414 1.54166H29.169C27.1122 1.54166 25.4448 3.20004 25.4448 5.24577V16.3581C25.4448 18.4038 27.1122 20.0622 29.169 20.0622H40.3414C42.3982 20.0622 44.0655 18.4038 44.0655 16.3581V5.24577C44.0655 3.20004 42.3982 1.54166 40.3414 1.54166Z"
              fill={fill}
            />
            <g filter="url(#filter3_d_7_48)">
              <path
                d="M34.4895 4.20689C34.5732 3.95094 34.9372 3.95094 35.0208 4.20689L36.3647 8.32071C36.4021 8.43521 36.5093 8.51267 36.6303 8.51267H40.9793C41.2498 8.51267 41.3623 8.85706 41.1435 9.01523L37.6251 11.5577C37.5272 11.6285 37.4862 11.7539 37.5236 11.8683L38.8675 15.9822C38.9511 16.2381 38.6566 16.451 38.4377 16.2928L34.9194 13.7503C34.8215 13.6795 34.6889 13.6795 34.591 13.7503L31.0727 16.2928C30.8537 16.451 30.5592 16.2381 30.6428 15.9822L31.9867 11.8683C32.0241 11.7539 31.9832 11.6285 31.8853 11.5577L28.3669 9.01523C28.148 8.85706 28.2605 8.51267 28.5311 8.51267H32.88C33.001 8.51267 33.1083 8.43521 33.1456 8.32071L34.4895 4.20689Z"
                fill="white"
              />
            </g>
          </g>
          <path
            d="M40.3414 1.77316H29.169C27.2408 1.77316 25.6776 3.3279 25.6776 5.24577V16.3581C25.6776 18.276 27.2408 19.8307 29.169 19.8307H40.3414C42.2696 19.8307 43.8328 18.276 43.8328 16.3581V5.24577C43.8328 3.3279 42.2696 1.77316 40.3414 1.77316Z"
            stroke="#BAE3E0"
          />
        </g>
        <g filter="url(#filter4_d_7_48)">
          <mask
            id="mask2_7_48"
            maskUnits="userSpaceOnUse"
            x="47"
            y="1"
            width="20"
            height="20"
          >
            <path
              d="M62.6862 1.54166H51.5137C49.457 1.54166 47.7896 3.20004 47.7896 5.24577V16.3581C47.7896 18.4038 49.457 20.0622 51.5137 20.0622H62.6862C64.7429 20.0622 66.4103 18.4038 66.4103 16.3581V5.24577C66.4103 3.20004 64.7429 1.54166 62.6862 1.54166Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_7_48)">
            <path
              d="M62.6862 1.54166H51.5137C49.457 1.54166 47.7896 3.20004 47.7896 5.24577V16.3581C47.7896 18.4038 49.457 20.0622 51.5137 20.0622H62.6862C64.7429 20.0622 66.4103 18.4038 66.4103 16.3581V5.24577C66.4103 3.20004 64.7429 1.54166 62.6862 1.54166Z"
              fill={fill}
            />
            <g filter="url(#filter5_d_7_48)">
              <path
                d="M56.8342 4.20689C56.918 3.95094 57.282 3.95094 57.3658 4.20689L58.7097 8.32071C58.747 8.43521 58.854 8.51267 58.9751 8.51267H63.3239C63.5949 8.51267 63.7071 8.85706 63.4883 9.01523L59.9699 11.5577C59.8721 11.6285 59.8312 11.7539 59.8684 11.8683L61.2123 15.9822C61.2961 16.2381 61.0015 16.451 60.7827 16.2928L57.2643 13.7503C57.1661 13.6795 57.0339 13.6795 56.9356 13.7503L53.4173 16.2928C53.1985 16.451 52.9038 16.2381 52.9876 15.9822L54.3315 11.8683C54.3688 11.7539 54.3278 11.6285 54.2301 11.5577L50.7117 9.01523C50.4929 8.85706 50.6051 8.51267 50.876 8.51267H55.2249C55.3459 8.51267 55.453 8.43521 55.4902 8.32071L56.8342 4.20689Z"
                fill="white"
              />
            </g>
          </g>
          <path
            d="M62.6862 1.77316H51.5138C49.5855 1.77316 48.0224 3.3279 48.0224 5.24577V16.3581C48.0224 18.276 49.5855 19.8307 51.5138 19.8307H62.6862C64.6144 19.8307 66.1776 18.276 66.1776 16.3581V5.24577C66.1776 3.3279 64.6144 1.77316 62.6862 1.77316Z"
            stroke="#BAE3E0"
          />
        </g>
        <g filter="url(#filter6_d_7_48)">
          <mask
            id="mask3_7_48"
            maskUnits="userSpaceOnUse"
            x="70"
            y="1"
            width="19"
            height="20"
          >
            <path
              d="M85.031 1.54166H73.8586C71.8018 1.54166 70.1345 3.20004 70.1345 5.24577V16.3581C70.1345 18.4038 71.8018 20.0622 73.8586 20.0622H85.031C87.0878 20.0622 88.7551 18.4038 88.7551 16.3581V5.24577C88.7551 3.20004 87.0878 1.54166 85.031 1.54166Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_7_48)">
            <path
              d="M85.031 1.54166H73.8586C71.8018 1.54166 70.1345 3.20004 70.1345 5.24577V16.3581C70.1345 18.4038 71.8018 20.0622 73.8586 20.0622H85.031C87.0878 20.0622 88.7551 18.4038 88.7551 16.3581V5.24577C88.7551 3.20004 87.0878 1.54166 85.031 1.54166Z"
              fill={fill}
            />
            <g filter="url(#filter7_d_7_48)">
              <path
                d="M79.179 4.20689C79.2628 3.95094 79.6268 3.95094 79.7106 4.20689L81.0546 8.32071C81.0918 8.43521 81.1989 8.51267 81.3199 8.51267H85.6688C85.9397 8.51267 86.0519 8.85706 85.8331 9.01523L82.3147 11.5577C82.217 11.6285 82.176 11.7539 82.2133 11.8683L83.5572 15.9822C83.641 16.2381 83.3463 16.451 83.1275 16.2928L79.6091 13.7503C79.5109 13.6795 79.3787 13.6795 79.2805 13.7503L75.7621 16.2928C75.5433 16.451 75.2486 16.2381 75.3324 15.9822L76.6764 11.8683C76.7136 11.7539 76.6727 11.6285 76.5749 11.5577L73.0565 9.01523C72.8377 8.85706 72.9499 8.51267 73.2209 8.51267H77.5697C77.6907 8.51267 77.7978 8.43521 77.8351 8.32071L79.179 4.20689Z"
                fill="white"
              />
            </g>
          </g>
          <path
            d="M85.031 1.77316H73.8586C71.9304 1.77316 70.3672 3.3279 70.3672 5.24577V16.3581C70.3672 18.276 71.9304 19.8307 73.8586 19.8307H85.031C86.9593 19.8307 88.5224 18.276 88.5224 16.3581V5.24577C88.5224 3.3279 86.9593 1.77316 85.031 1.77316Z"
            stroke="#BAE3E0"
          />
        </g>
        <g filter="url(#filter8_d_7_48)">
          <mask
            id="mask4_7_48"
            maskUnits="userSpaceOnUse"
            x="92"
            y="1"
            width="20"
            height="20"
          >
            <path
              d="M107.376 1.54166H96.2034C94.1467 1.54166 92.4793 3.20004 92.4793 5.24577V16.3581C92.4793 18.4038 94.1467 20.0622 96.2034 20.0622H107.376C109.433 20.0622 111.1 18.4038 111.1 16.3581V5.24577C111.1 3.20004 109.433 1.54166 107.376 1.54166Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask4_7_48)">
            <path
              d="M107.376 1.54166H96.2034C94.1467 1.54166 92.4793 3.20004 92.4793 5.24577V16.3581C92.4793 18.4038 94.1467 20.0622 96.2034 20.0622H107.376C109.433 20.0622 111.1 18.4038 111.1 16.3581V5.24577C111.1 3.20004 109.433 1.54166 107.376 1.54166Z"
              fill={fill}
            />
            <g filter="url(#filter9_d_7_48)">
              <path
                d="M101.524 4.20689C101.608 3.95094 101.972 3.95094 102.055 4.20689L103.399 8.32071C103.437 8.43521 103.544 8.51267 103.665 8.51267H108.014C108.285 8.51267 108.397 8.85706 108.178 9.01523L104.66 11.5577C104.562 11.6285 104.521 11.7539 104.558 11.8683L105.902 15.9822C105.986 16.2381 105.691 16.451 105.472 16.2928L101.954 13.7503C101.856 13.6795 101.724 13.6795 101.625 13.7503L98.107 16.2928C97.8882 16.451 97.5935 16.2381 97.6773 15.9822L99.0212 11.8683C99.0585 11.7539 99.0175 11.6285 98.9198 11.5577L95.4014 9.01523C95.1826 8.85706 95.2948 8.51267 95.5657 8.51267H99.9146C100.036 8.51267 100.143 8.43521 100.18 8.32071L101.524 4.20689Z"
                fill="white"
              />
            </g>
          </g>
          <path
            d="M107.376 1.77316H96.2034C94.2752 1.77316 92.712 3.3279 92.712 5.24577V16.3581C92.712 18.276 94.2752 19.8307 96.2034 19.8307H107.376C109.304 19.8307 110.867 18.276 110.867 16.3581V5.24577C110.867 3.3279 109.304 1.77316 107.376 1.77316Z"
            stroke="#BAE3E0"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_7_48"
            x="-1.1673"
            y="-0.726837"
            width="27.1552"
            height="27.0576"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7_48"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7_48"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_7_48"
            x="-94.0936"
            y="-87.9851"
            width="213.008"
            height="212.332"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="50" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7_48"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7_48"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_7_48"
            x="21.1776"
            y="-0.726837"
            width="27.1552"
            height="27.0576"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7_48"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7_48"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_7_48"
            x="-71.7488"
            y="-87.9851"
            width="213.008"
            height="212.332"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="50" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7_48"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7_48"
              result="shape"
            />
          </filter>
          <filter
            id="filter4_d_7_48"
            x="43.5224"
            y="-0.726837"
            width="27.1552"
            height="27.0576"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7_48"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7_48"
              result="shape"
            />
          </filter>
          <filter
            id="filter5_d_7_48"
            x="-49.404"
            y="-87.9851"
            width="213.008"
            height="212.332"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="50" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7_48"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7_48"
              result="shape"
            />
          </filter>
          <filter
            id="filter6_d_7_48"
            x="65.8672"
            y="-0.726837"
            width="27.1552"
            height="27.0576"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7_48"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7_48"
              result="shape"
            />
          </filter>
          <filter
            id="filter7_d_7_48"
            x="-27.0591"
            y="-87.9851"
            width="213.008"
            height="212.332"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="50" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7_48"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7_48"
              result="shape"
            />
          </filter>
          <filter
            id="filter8_d_7_48"
            x="88.212"
            y="-0.726837"
            width="27.1552"
            height="27.0576"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7_48"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7_48"
              result="shape"
            />
          </filter>
          <filter
            id="filter9_d_7_48"
            x="-4.71429"
            y="-87.9851"
            width="213.008"
            height="212.332"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="50" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7_48"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7_48"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  };

  return (
    <div className="relative w-28">
      <div
        style={{ width: `${starWidthPercentage}%` }}
        className="overflow-hidden absolute"
      >
        {renderStars("#009688")}
      </div>
      <div className="w-full">{renderStars("#E4E7EC")}</div>
    </div>
  );
};

export default StarRating;
