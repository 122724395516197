import React from "react";
import clsx from "clsx";

const Heading = ({ heading, className, variant, children }) => {
  const TAG = variant;
  return (
    <TAG className={clsx(className)}>
      {children}
      {heading}
    </TAG>
  );
};
export default Heading;
