import { createSlice } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
// import { getErrorDetailsforNotif } from "../../services/globalFunctions";
import {
  addAppointmentSlot,
  addPatientNote,
  getAllAppointmentsTherapist,
  getTherapistDashboard,
  getTherapistDashboardClients,
  getTherapistDashboardPerformance,
  getTherapistDashboardSessions,
  getTherapistNotes,
  getTherapistPatients,
  getUpcomingAppointmentsTherapist,
  patchPatientNote,
  updateTherapistPassword,
  updateTherapistProfile,
  submitRescheduleSlots,
  addClientNote,
  getClientNotes,
} from "./therapistThunks";

const initialState = {
  notes: [],
  clientNotes: [],
  messageHistory: null,
  upcomingAppointments: null,
  timezoneOptions: null,
  therapistSchedule: { appointment_slot: null },
  therapistPatients: null,
  dashboardData: null,
  dashboardSessionsData: null,
  dashboardClientsData: null,
  dashboardPerformanceData: null,
};

export const patientSlice = createSlice({
  name: "therapist",
  initialState,
  reducers: {
    clearNotes: (state) => {
      state.notes = [];
    },
  },
  extraReducers: {
    [addAppointmentSlot.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [addAppointmentSlot.fulfilled]: () => {
      // toast("Schedule updated");
    },
    [getTherapistNotes.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getTherapistNotes.fulfilled]: (state, action) => {
      state.notes = action.payload;
    },
    [getUpcomingAppointmentsTherapist.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getAllAppointmentsTherapist.fulfilled]: (state, action) => {
      state.all_appointments = action.payload;
    },
    [getAllAppointmentsTherapist.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getUpcomingAppointmentsTherapist.fulfilled]: (state, action) => {
      state.upcomingAppointments = action.payload;
    },
    [updateTherapistPassword.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [updateTherapistPassword.fulfilled]: () => {
      // toast("Password updated");
    },

    [getTherapistPatients.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getTherapistPatients.fulfilled]: (state, action) => {
      state.therapistPatients = action.payload;
    },
    [addPatientNote.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [updateTherapistProfile.fulfilled]: () => {
      // toast.success("Profile updated");
    },
    [patchPatientNote.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [patchPatientNote.fulfilled]: () => {
      // toast.success("Note updated");
    },
    [addClientNote.fulfilled]: (state, action) => {
      state.clientNotes = [{ ...action.payload }, ...state.clientNotes];
    },
    [getClientNotes.fulfilled]: (state, action) => {
      state.clientNotes = action.payload;
    },
    [getTherapistDashboard.fulfilled]: (state, action) => {
      state.dashboardData = action.payload;
    },
    [getTherapistDashboardSessions.fulfilled]: (state, action) => {
      state.dashboardSessionsData = action.payload;
    },
    [getTherapistDashboardClients.fulfilled]: (state, action) => {
      state.dashboardClientsData = action.payload;
    },
    [getTherapistDashboardPerformance.fulfilled]: (state, action) => {
      state.dashboardPerformanceData = action.payload;
    },
    [submitRescheduleSlots.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [submitRescheduleSlots.fulfilled]: () => {
      // toast.success("Request sent");
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearNotes } = patientSlice.actions;

export default patientSlice.reducer;
