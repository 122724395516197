import React from "react";

const SemiCircleProgressBar = ({
  percentage,
  page,
  width,
  height,
  className,
  wrapperClassName,
  strokeWidth,
  graphRadius,
  gradient,
  scales,
}) => {
  const getRange =
    percentage >= 80
      ? {
          range: `${scales?.[4]?.score_start}-${scales?.[4]?.score_end}`,
          title: scales?.[4]?.score_description,
        }
      : percentage >= 60
      ? {
          range: `${scales?.[3]?.score_start}-${scales?.[3]?.score_end}`,
          title: scales?.[3]?.score_description,
        }
      : percentage >= 40
      ? {
          range: `${scales?.[2]?.score_start}-${scales?.[2]?.score_end}`,
          title: scales?.[2]?.score_description,
        }
      : percentage >= 20
      ? {
          range: `${scales?.[1]?.score_start}-${scales?.[1]?.score_end}`,
          title: scales?.[1]?.score_description,
        }
      : {
          range: `${scales?.[0]?.score_start}-${scales?.[0]?.score_end}`,
          title: scales?.[0]?.score_description,
        };
  return (
    <div className={`flex justify-center ${wrapperClassName}`}>
      <div
        className={`relative flex overflow-hidden justify-center items-end ${className}`}
      >
        <div className="absolute top-0 w-full h-full rounded-full">
          <div>
            {progressBar(100, false, width, height, strokeWidth, graphRadius)}
          </div>
          <div className="absolute top-0">
            {progressBar(
              percentage,
              gradient,
              width,
              height,
              strokeWidth,
              graphRadius
            )}
          </div>
        </div>
        {page === "result" ? (
          <div className="text-center">
            <div className="text-gray-500 font-medium text-[2.5rem]">
              {getRange.range === "undefined-undefined" ? "" : getRange.range}
            </div>
            <div className="text-gray-400 mt-1">{getRange.title}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

function progressBar(
  widthPerc,
  gradient = false,
  width,
  height,
  strokeWidth,
  graphRadius
) {
  const radius = graphRadius;
  const dashArray = (Math.PI * radius * widthPerc) / 100;
  const dashOffset = -1 * Math.PI * radius;
  const cx = width / 2;
  const cy = width / 2;
  // Calculate the position for the <foreignObject> based on stroke dasharray
  // const xPosition = 125 + radius * Math.cos(-Math.PI / 2 + dashArray / radius);
  // const yPosition = 125 - radius * Math.sin(-Math.PI / 2 + dashArray / radius); // Adjust the sign here
  return (
    <svg width={width} height={height}>
      <circle
        cx={cx}
        cy={cy}
        r={radius}
        fill="none"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDashoffset={dashOffset}
        strokeDasharray={`${dashArray} 10000`}
        stroke={gradient ? "#01554D" : "#BAE3E0"}
      ></circle>

      {gradient ? (
        <defs>
          <linearGradient
            id="score-gradient"
            x1="-196.687"
            y1="231.768"
            x2="73.5125"
            y2="400.611"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="0.813953" stopColor="#009688" />
          </linearGradient>
        </defs>
      ) : (
        <linearGradient
          id="base-color"
          x1="-81.9347"
          y1="313.177"
          x2="306.484"
          y2="230.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDFFFC" />
          <stop offset="0.456304" stopColor="#24D9C8" />
          <stop offset="0.848958" stopColor="#004B45" />
        </linearGradient>
      )}
      {/* <foreignObject
        x={xPosition - 17}
        y={yPosition - 17}
        width="34"
        height="34"
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#ffffff",
            borderRadius: "50%",
          }}
        ></div>
      </foreignObject> */}
    </svg>
  );
}
export default SemiCircleProgressBar;
