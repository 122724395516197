import React from "react";

const Card = ({ className, children }) => {
  return (
    <div className={`border border-gray-200 rounded-2xl ${className}`}>
      {children}
    </div>
  );
};
export default Card;
