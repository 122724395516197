import React from "react";
import Modal from "react-modal";
import CheckCircle from '../../assets/check-circle.svg'
import CrossIcon from '../../assets/x-close.svg'
import FeedbackSuccess from '../../assets/feedback_success.svg'
import { useNavigate } from "react-router-dom";

const FeedbackSuccessModal = ({
  isOpen,
  onRequestClose,
}) => {

    const navigate = useNavigate()
    const modalStyles = {
        content: {
        maxWidth: "464px",
        border: "none",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        margin: "auto",
        borderRadius: "16px",
        height: "max-content",
        background: "#fff",
        overflowY: "auto",
        padding: "24px",
        },
        overlay: {
        background: "rgba(0, 0, 0, 0.09)",
        zIndex: "10",
        },
    };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={modalStyles}
      >
          <img className="absolute top-0 left-0 right-0 w-full" src={FeedbackSuccess} />
          <div className="flex justify-end"> 
              <img onClick={onRequestClose} className="cursor-pointer z-50" src={CrossIcon} />
          </div>
          <div className="flex flex-col items-center justify-center space-y-6">
              <img src={CheckCircle} />
              <div className="text-2xl font-medium text-gray-900 text-center">Feedback Submitted Succesfully</div>
              <div onClick={() => {
                  onRequestClose()
                  navigate(`/login`)
              }} className="text-base font-medium text-white cursor-pointer py-2 px-4 bg-teal-900 rounded-full">Back to Sessions</div>
          </div>
    </Modal>
  );
};

export default FeedbackSuccessModal;
