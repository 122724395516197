import React, { forwardRef } from "react";
import clsx from "clsx";

const Input = (
  {
    label,
    icon,
    type,
    name,
    className,
    inputClassName,
    required,
    onClick,
    onChange,
    onBlur,
    checked,
    value,
    pattern,
    placeholder,
    error,
    app,
    hint,
    autoFocus,
    disabled,
  },
  ref
) => {
  return (
    <div className={className}>
      {label && <label className="text-sm font-medium">{label}</label>}
      <div className={`${clsx("relative", label && "mt-2")}`}>
        {icon && (
          <img className="absolute left-4 top-0 bottom-0 m-auto" src={icon} />
        )}
        {type === "textarea" ? (
          <textarea
            className={`${clsx(
              inputClassName
            )} w-full outline-none border rounded-[10px] h-28 ${
              error
                ? "border-danger-900 shadow-inputError focus-visible:border-danger-900 focus-visible:shadow-inputError"
                : `border-gray-200 ${
                    app === "THERAPIST"
                      ? "focus-visible:border-blue-900 focus-visible:shadow-inputNormalSecondary"
                      : "focus-visible:border-teal-900 focus-visible:shadow-inputNormal"
                  } `
            }`}
            ref={ref}
            type={type}
            name={name}
            required={required}
            onClick={onClick}
            onChange={onChange}
            onBlur={onBlur}
            pattern={pattern}
            placeholder={placeholder}
            value={value}
          />
        ) : (
          <input
            className={`${clsx(
              inputClassName
            )} w-full outline-none border rounded-[10px] h-11 ${
              type === "tel" ? "indent-3" : icon ? "indent-12" : "px-4"
            } ${
              error
                ? "border-danger-900 shadow-inputError focus-visible:border-danger-900 focus-visible:shadow-inputError"
                : `border-gray-200 ${
                    app === "THERAPIST"
                      ? "focus-visible:border-blue-900 focus-visible:shadow-inputNormalSecondary"
                      : "focus-visible:border-teal-900 focus-visible:shadow-inputNormal"
                  } `
            }`}
            ref={ref}
            type={type}
            name={name}
            required={required}
            onClick={onClick}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            checked={checked}
            pattern={pattern}
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
          />
        )}
      </div>
      {error && <div className="text-sm text-danger-900 mt-1.5">{error}</div>}
      {hint && <div className="text-sm text-gray-400 mt-1.5">{hint}</div>}
    </div>
  );
};
export default forwardRef(Input);
