import React from "react";

export default function Dashboard({ Page }) {

  return (
    <>
      <Page />
    </>
  );
}
