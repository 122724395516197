import React, { useEffect, useState } from "react";
import close from "../../assets/x-close.svg";
import greenLockIcon from "../../assets/lock-green.svg";
import emptyStressIcon from "../../assets/empty_stress_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addPatientDashboardMood,
  addPatientDashboardStress,
  getPatientDashboardMood,
  getPatientDashboardStress,
} from "../../redux/patient/patientThunks";
import Select from "react-select";
import moment from "moment";
import { getCurrentDate, getMoodIcon } from "../../services/globalFunctions";
import {
  MOOD_HISTORY,
  MOOD_LEVEL,
  STRESS_HISTORY,
  STRESS_LEVEL,
  StressLevelData,
  StressLevelSliderData,
  moodLevelData,
} from "../../services/utils/constants";
import emptyMoodIcon from "../../assets/empty_mood_icon.svg";
import ReactSlider from "react-slider";
import thumbIcon from "../../assets/thumb_icon.svg";
import historyIcon from "../../assets/history_icon.svg";
import chevronDownIcon from "../../assets/chevron-down.svg";
import chevronUpIcon from "../../assets/chevron-up.svg";
import greenThumbIcon from "../../assets/green_thumb_icon.svg";
import grayThumbIcon from "../../assets/gray_thumb_icon.svg";
import orangeThumbIcon from "../../assets/orange_thumb_icon.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Loader from "../atoms/Loader";

const userSkeletonData = [{}, {}, {}];
const StressMoodHistory = ({
  setIsCommonModal,
  isModalType,
  setIsModalType,
}) => {
  const dispatch = useDispatch();
  const dashboardStressData = useSelector(
    (state) => state.patient.dashboardStressData
  );
  const dashboardMoodData = useSelector(
    (state) => state.patient.dashboardMoodData
  );
  const isHistoryAvailable =
    isModalType == STRESS_HISTORY
      ? dashboardStressData?.data?.length > 0
      : dashboardMoodData?.data?.length > 0;

  const currentDate = new Date();
  var startDate7Days = new Date(currentDate);
  var startDate1Month = new Date(currentDate);
  var startDate3Months = new Date(currentDate);
  var startDate7Months = new Date(currentDate);

  const dropDownOptions = [
    {
      value: `${moment(startDate7Days.setDate(currentDate.getDate() - 6)).format(
        "YYYY-MM-DD"
      ) +
        "/" +
        getCurrentDate()
        }`,
      label: "Last 7 days",
    },
    {
      value: `${moment(startDate1Month.setMonth(currentDate.getMonth() - 1)).format(
        "YYYY-MM-DD"
      ) +
        "/" +
        getCurrentDate()
        }`,
      label: "Last month",
    },
    {
      value: `${moment(startDate3Months.setMonth(currentDate.getMonth() - 3)).format(
        "YYYY-MM-DD"
      ) +
        "/" +
        getCurrentDate()
        }`,
      label: "Last 3 months",
    },
    {
      value: `${moment(startDate7Months.setMonth(currentDate.getMonth() - 6)).format(
        "YYYY-MM-DD"
      ) +
        "/" +
        getCurrentDate()
        }`,
      label: "Last 6 months",
    },
  ];
  const [dateRange, setDateRange] = useState(dropDownOptions[1]);
  const [level, setLevel] = useState(isModalType == STRESS_LEVEL ? 0 : 3);
  const [isShowStressLevel, setIsShowStressLevel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stressBackButton, setStressBackButton] = useState(false);
  const [moodBackButton, setMoodBackButton] = useState(false);
  const [apiLodaing, setApiLoading] = useState(false);

  useEffect(() => {
    getPageData();
  }, [dateRange, isModalType]);
  const getPageData = async () => {
    if (isModalType == STRESS_HISTORY) {
      setLoading(true);
      const stressData = await dispatch(
        getPatientDashboardStress({
          start_date: dateRange?.value?.split("/")[0],
          end_date: dateRange?.value?.split("/")[1],
        })
      );
      if (stressData) setLoading(false);
    } else if (isModalType == MOOD_HISTORY) {
      setLoading(true);
      const moodData = await dispatch(
        getPatientDashboardMood({
          start_date: dateRange?.value?.split("/")[0],
          end_date: dateRange?.value?.split("/")[1],
        })
      );
      if (moodData) setLoading(false);
    }
  };

  const dropDownStyles = {
    control: (styles, state) => ({
      ...styles,
      height: "100%",
      borderRadius: "90px",
      borderColor: state.isFocused ? "#01554D" : "#E4E7EC",
      ":hover": { borderColor: state.isFocused ? "#01554D" : "#E4E7EC" },
      cursor: "pointer",
      boxShadow: state.isFocused
        ? "0px 0px 0px 4px rgba(0, 150, 136, 0.20)"
        : "",
      minHeight: "44px",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        color: isSelected ? "#fff" : "#232428",
        backgroundColor: isSelected ? "#005996" : "initial",
        cursor: isDisabled ? "not-allowed" : "default",
        ":hover": { backgroundColor: isSelected ? "#005996" : "#F1F6F9" },
        fontWeight: isDisabled ? "700" : "400",
      };
    },
  };

  const handleStressLevel = async () => {
    setApiLoading(true);
    const res = await dispatch(
      addPatientDashboardStress({
        level: level,
        health_date: getCurrentDate(),
      })
    );
    if (res) {
      setIsCommonModal(false);
      setApiLoading(false);
    }
  };

  const handleMoodLevel = async () => {
    setApiLoading(true);
    const res = await dispatch(
      addPatientDashboardMood({
        level: level,
        health_date: getCurrentDate(),
      })
    );
    if (res) {
      setIsCommonModal(false);
      setApiLoading(false);
    }
  };

  if (isModalType == STRESS_LEVEL) {
    return (
      <>
        <div className="px-4 pt-4 lg:px-10 lg:pt-10">
          <div className="flex justify-between">
            <div className="text-2xl font-medium">
              How stressed are you feeling today?
            </div>
            <img
              src={close}
              className="cursor-pointer"
              onClick={() => setIsCommonModal(false)}
            />
          </div>
          <div className="text-sm mt-2">
            We will help you monitor your stress levels by logging each day.
          </div>
          <div className="flex rounded-lg p-2 items-center bg-teal-200 space-x-2 w-11/12 my-4">
            <img src={greenLockIcon} />
            <div className="text-xs font-medium">{`Remember: this data is private to you only, we won't share it with anyone without your consent.`}</div>
          </div>
          <div className="text-sm text-gray-500 font-medium">
            {moment().format("ddd, DD MMM YYYY")}
          </div>
          <div className="text-sm font-medium text-center text-gray-500 my-7">
            Drag the slider left & right to log your stress level for the day.
          </div>
          <div className="flex items-center pr-4 px-2">
            {StressLevelSliderData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="space-y-0.5 w-1/5 items-center flex flex-col"
                >
                  <div className="font-medium text-sm">{item.title}</div>
                  <div className="text-xs">{item.level}</div>
                </div>
              );
            })}
          </div>
          <ReactSlider
            className="horizontal-slider"
            trackClassName="slider-track"
            thumbClassName="horizontal-slider-thumb"
            renderThumb={({ key, ...restProps }) => (
              <img key={key} {...restProps} src={thumbIcon} />
            )}
            min={0}
            max={5}
            step={1}
            value={level}
            onChange={(val) => setLevel(val)}
            marks={[1, 2, 3, 4]}
            markClassName={"bg-white h-9 w-0.5 ml-4"}
          />
          <div className="flex items-center justify-between">
            <div
              className="flex items-center space-x-1.5 cursor-pointer"
              onClick={() => {
                setIsModalType(STRESS_HISTORY);
                setStressBackButton(true);
              }}
            >
              <img src={historyIcon} className="h-5" />
              <div className="text-base font-medium">View History</div>
            </div>
            <div
              onClick={() => (level == 0 ? {} : handleStressLevel())}
              className={`cursor-pointer rounded-full py-2 px-4 text-base font-medium ${level == 0
                ? "text-gray-400 bg-gray-100"
                : "text-white bg-teal-900"
                }`}
            >
              Log Stress
            </div>
          </div>
        </div>
        <div className="bg-orange-50 py-4 px-4 lg:px-10 mt-4">
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => setIsShowStressLevel(!isShowStressLevel)}
          >
            <div className="text-sm font-medium">What are stress levels</div>
            <img src={isShowStressLevel ? chevronUpIcon : chevronDownIcon} />
          </div>
          {isShowStressLevel && (
            <div className="space-y-0.5 mt-4">
              {StressLevelData?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${index == 0 && "rounded-t-2xl"} ${index == 4 && "rounded-b-2xl"
                      } bg-white space-y-1 p-3`}
                  >
                    <div className="text-sm font-medium">{item?.title}</div>
                    <div className="text-sm font-medium text-gray-500">
                      {item?.desc}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {apiLodaing && <Loader />}
      </>
    );
  }

  if (isModalType == MOOD_LEVEL) {
    return (
      <>
        <div className="px-4 pt-4 lg:px-10 lg:pt-10">
          <div className="flex justify-between">
            <div className="text-2xl font-medium">
              How are you feeling today?
            </div>
            <img
              src={close}
              className="cursor-pointer"
              onClick={() => setIsCommonModal(false)}
            />
          </div>
          <div className="text-sm mt-2">
            We will help you navigate different mood patterns you experience.
          </div>
          <div className="flex rounded-lg p-2 items-center bg-teal-200 space-x-2 w-11/12 my-4">
            <img src={greenLockIcon} />
            <div className="text-xs font-medium">{`Remember: this data is private to you only, we won't share it with anyone without your consent.`}</div>
          </div>
          <div className="text-sm text-gray-500 font-medium">
            {moment().format("ddd, DD MMM YYYY")}
          </div>
          <div className="text-sm font-medium text-center text-gray-500 my-7">
            Drag the slider up & down to log your mood for the day.
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="w-full h-96 space-y-0.5">
            {moodLevelData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`h-[76.8px] flex items-center justify-between px-4 lg:px-10 ${level < 3 && index > 2 && `bg-[#FAD8BB]`
                    } ${level == 3 && index == 2 && `bg-[#E4E7EC]`} ${level > 3 && index < 2 && `bg-[#E1E8D1]`
                    }`}
                >
                  <div className="w-5/12 space-y-1">
                    <div className="text-base font-medium">{item?.title}</div>
                    <div className="text-xs">{item?.desc}</div>
                  </div>
                  <img
                    onClick={() => setLevel(item?.level)}
                    src={item.icon}
                    className="h-12 w-12 cursor-pointer z-50"
                  />
                </div>
              );
            })}
          </div>
          <div className="justify-center flex absolute left-0 right-0 m-auto mt-2">
            <ReactSlider
              className={`vertical-slider cursor-pointer`}
              thumbClassName="vertical-slider-thumb"
              renderThumb={({ key, ...restProps }) => (
                <img
                  {...restProps}
                  key={key}
                  src={
                    level <= 2
                      ? orangeThumbIcon
                      : level == 3
                        ? grayThumbIcon
                        : greenThumbIcon
                  }
                />
              )}
              min={1}
              max={5}
              step={1}
              value={level}
              onChange={(val) => setLevel(val)}
              orientation="vertical"
              invert
              pearling
              renderTrack={({ key, ...restProps }) => (
                <div
                  {...restProps}
                  key={key}
                  className={`w-6 rounded-full left-3 ${level <= 2 && `bg-[#ED7E1C]`
                    } ${level == 3 && `bg-[#667085]`} ${level > 3 && `bg-[#9BB167]`
                    }`}
                />
              )}
            />
          </div>
        </div>

        <div className="px-4 pb-4 lg:pb-10 lg:px-10 mt-5">
          <div className="flex items-center justify-between">
            <div
              className="flex items-center space-x-1.5 cursor-pointer"
              onClick={() => {
                setIsModalType(MOOD_HISTORY);
                setMoodBackButton(true);
              }}
            >
              <img src={historyIcon} className="h-5" />
              <div className="text-base font-medium">View History</div>
            </div>
            <div
              onClick={handleMoodLevel}
              className="cursor-pointer bg-teal-900 rounded-full py-2 px-4 text-base font-medium text-white"
            >
              Log Mood
            </div>
          </div>
        </div>
        {apiLodaing && <Loader />}
      </>
    );
  }

  return (
    <>
      <div className="px-4 pt-4 lg:px-10 lg:pt-10">
        <div className="flex justify-between">
          <div className="flex items-center space-x-2">
            {(stressBackButton || moodBackButton) && (
              <button
                onClick={() => {
                  if (stressBackButton) setIsModalType(STRESS_LEVEL);
                  else setIsModalType(MOOD_LEVEL);
                }}
                className="w-10 h-10 flex justify-center items-center rounded-full border border-solid border-gray-200 shadow-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M13.3333 8.66667H4M4 8.66667L8.66667 13.3333M4 8.66667L8.66667 4"
                    stroke="#667085"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
            <div className="text-2xl font-medium">
              {isHistoryAvailable
                ? isModalType == STRESS_HISTORY
                  ? "Stress Level History"
                  : "History - Mood Log"
                : isModalType == STRESS_HISTORY
                  ? "Stress History"
                  : "Mood History"}
            </div>
          </div>
          <img
            src={close}
            className="cursor-pointer"
            onClick={() => setIsCommonModal(false)}
          />
        </div>
        <div className="text-sm mt-2 mb-3">
          {isModalType == STRESS_HISTORY
            ? "You can see the history of stress levels you logged over the chosen period of time"
            : "You can see the history of mood you logged over the chosen period of time"}
        </div>
        {isHistoryAvailable && (
          <div className="flex rounded-lg p-2 items-center bg-teal-200 space-x-2 w-11/12 mb-3">
            <img src={greenLockIcon} />
            <div className="text-xs font-medium">{`Remember: this data is private to you only, we won't share it with anyone without your consent.`}</div>
          </div>
        )}
      </div>
      <div className="border-b border-solid border-gray-200"></div>
      <div className="px-4 pb-4 lg:pb-10 lg:px-10">
        <div
          className={`flex items-center justify-between ${isHistoryAvailable ? "mt-2" : "mt-4"
            }`}
        >
          <div className="text-base font-medium">History</div>
          {isHistoryAvailable && (
            <Select
              value={dateRange}
              onChange={(val) => setDateRange(val)}
              options={dropDownOptions}
              styles={dropDownStyles}
              menuPlacement="auto"
            />
          )}
        </div>
        {loading ? (
          <SkeletonTheme baseColor="#E4E7EC" highlightColor="#F2F4F7">
            {userSkeletonData?.map((_, index) => {
              return (
                <div
                  key={index}
                  className={`py-3 flex items-center space-x-3 rounded-lg`}
                >
                  <Skeleton height={40} width={40} />
                  <div className="flex-1 space-y-1">
                    <Skeleton height={20} width={130} />
                    <Skeleton height={15} width={60} />
                  </div>
                </div>
              );
            })}
          </SkeletonTheme>
        ) : (
          <>
            {isHistoryAvailable ? (
              <>
                {isModalType == STRESS_HISTORY &&
                  dashboardStressData?.data?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="space-x-3 flex items-center mt-4"
                      >
                        <div
                          className={`border-solid border border-gray-200 rounded-lg w-12 cursor-pointer`}
                        >
                          <div
                            className={`rounded-tl-lg rounded-tr-lg py-0.5 text-center bg-gray-200 text-xs font-medium text-gray-400`}
                          >
                            {item?.month || ""}
                          </div>
                          <div
                            className={`text-sm font-medium text-center py-1 text-gray-500`}
                          >
                            {item?.day || ""}
                          </div>
                        </div>
                        <div className="space-y-0.5">
                          <div className="text-base font-medium">
                            {item?.label || ""}
                          </div>
                          <div className="text-xs">
                            Level {item?.level || ""}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {isModalType == MOOD_HISTORY &&
                  dashboardMoodData?.data?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="flex items-center justify-between mt-4"
                      >
                        <div className="flex items-center space-x-3">
                          <div
                            className={`border-solid border border-gray-200 rounded-lg w-12 cursor-pointer`}
                          >
                            <div
                              className={`rounded-tl-lg rounded-tr-lg py-0.5 text-center bg-gray-200 text-xs font-medium text-gray-400`}
                            >
                              {item?.month || ""}
                            </div>
                            <div
                              className={`text-sm font-medium text-center py-1 text-gray-500`}
                            >
                              {item?.day || ""}
                            </div>
                          </div>
                          <div className="space-y-0.5">
                            <div className="text-base font-medium">
                              {item?.label || ""}
                            </div>
                            <div className="text-xs">
                              {moodLevelData?.find((i) => {
                                return i.title == item.label;
                              })?.desc || ""}
                            </div>
                          </div>
                        </div>
                        <img src={getMoodIcon(item?.label)} />
                      </div>
                    );
                  })}
              </>
            ) : (
              <div className="flex items-center flex-col py-20">
                <img
                  src={
                    isModalType == STRESS_HISTORY
                      ? emptyStressIcon
                      : emptyMoodIcon
                  }
                />
                <div className="text-sm font-medium mt-4 text-center">
                  Your {isModalType == STRESS_HISTORY ? "stress" : "mood"}{" "}
                  history will be displayed here once you start logging
                </div>
                <div className="text-xs text-gray-400 text-center w-4/5 mt-1.5">
                  {isModalType == STRESS_HISTORY
                    ? "Track and understand your stress levels over time. Monitor patterns, identify triggers, and learn to manage stress more effectively with our stress history logs."
                    : "Track fluctuations in your mood over time, identify patterns, and discover factors that influence how you feel. By logging your moods regularly, you can better understand yourself and take proactive steps towards improving your emotional well-being."}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default StressMoodHistory;
