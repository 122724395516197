import React from "react";
import clsx from "clsx";
import Heading from "../../components/atoms/Heading";

const Form = ({
  heading,
  method,
  onSubmit,
  children,
  className,
  peragraph,
}) => {
  return (
    <div>
      <Heading variant="h1" className="text-2xl font-semibold">
        {heading}
      </Heading>
      <p className="text-sm text-gray-800/70 mt-1">{peragraph}</p>
      <form
        method={method}
        className={`${clsx(className)} p-6 shadow-form rounded-xl `}
        onSubmit={onSubmit}
        noValidate
      >
        {children}
      </form>
    </div>
  );
};
export default Form;
