import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/molecules/Header";
import SideMenu from "../../components/molecules/SideMenu";
import emptyNotificationList from "../../assets/empty_therapist_notification.svg";
import UpGreenArrow from "../../assets/up-green-arrow.svg";
import DownRedArrow from "../../assets/down-red-arrow.svg";
import EmptySessionChart from "../../assets/empty-session-chart.svg";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import {
  getTherapistDashboard,
  getTherapistDashboardClients,
  getTherapistDashboardPerformance,
  getTherapistDashboardSessions,
} from "../../redux/therapist/therapistThunks";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import emptySessionsList from "../../assets/empty_therapist_session_list.svg";
import Select from "react-select";
import {
  MetaTags,
  calculateTimeDifference,
  getCurrentDate,
  getNotificationIcon,
  getTimeDifference,
} from "../../services/globalFunctions";
import Avatar from "../../assets/profile-picture-avatar.png";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import userAvatar from "../../assets/user-large.svg";

Chart.register(CategoryScale);

const dropDownStyles = {
  control: (styles) => ({
    ...styles,
    height: "100%",
    borderRadius: "90px",
    borderColor: "transparent",
    ":hover": { borderColor: "transparent" },
    cursor: "pointer",
    boxShadow: "",
    minHeight: "44px",
    backgroundColor: "transparent",
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "0",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#667085",
    fontSize: "14px",
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
  option: (styles, { isDisabled, isSelected }) => {
    return {
      ...styles,
      color: isSelected ? "#fff" : "#232428",
      backgroundColor: isSelected ? "#005996" : "initial",
      cursor: isDisabled ? "not-allowed" : "default",
      ":hover": { backgroundColor: isSelected ? "#005996" : "#F1F6F9" },
      fontWeight: isDisabled ? "700" : "400",
      fontSize: "14px",
    };
  },
};

export default function TherapistDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const user = useSelector((state) => state.app.user);
  const dashboardData = useSelector((state) => state.therapist.dashboardData);
  const notifications = useSelector((state) => state.onboarding.notifications);
  const dashboardSessionsData = useSelector(
    (state) => state.therapist.dashboardSessionsData
  );
  const dashboardClientsData = useSelector(
    (state) => state.therapist.dashboardClientsData
  );
  const dashboardPerformanceData = useSelector(
    (state) => state.therapist.dashboardPerformanceData
  );

  const currentDate = new Date();
  var sevenDaysFromToday = new Date();
  sevenDaysFromToday.setDate(currentDate.getDate() - 6);

  var oneMonthFromToday = new Date();
  oneMonthFromToday.setMonth(currentDate.getMonth() - 1);

  const dropDownOptions = [
    {
      value: `${
        moment(sevenDaysFromToday).format("YYYY-MM-DD") + "/" + getCurrentDate()
      }`,
      label: "Last 7 days",
    },
    {
      value: `${
        moment(oneMonthFromToday).format("YYYY-MM-DD") + "/" + getCurrentDate()
      }`,
      label: "Last month",
    },
  ];
  const [dateRange, setDateRange] = useState(dropDownOptions[0]);
  const [displayCount, setDisplayCount] = useState(
    isMobile ? 5 : notifications?.length
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getTherapistDashboard());
    dispatch(
      getTherapistDashboardSessions({
        start_date: moment(sevenDaysFromToday).format("YYYY-MM-DD"),
        end_date: getCurrentDate(),
      })
    );
    dispatch(
      getTherapistDashboardClients({
        start_date: moment(sevenDaysFromToday).format("YYYY-MM-DD"),
        end_date: getCurrentDate(),
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getTherapistDashboardPerformance({
        start_date: dateRange?.value?.split("/")[0],
        end_date: dateRange?.value?.split("/")[1],
      })
    );
  }, [dateRange]);

  const completedSessionArray = dashboardSessionsData?.data?.map((item) => {
    return item.completed;
  });
  const rescheduledSessionArray = dashboardSessionsData?.data?.map((item) => {
    return item.rescheduled;
  });
  const cancelledSessionArray = dashboardSessionsData?.data?.map((item) => {
    return item.cancelled;
  });
  const finalSessionArray = completedSessionArray
    ?.concat(rescheduledSessionArray)
    ?.concat(cancelledSessionArray);
  const allSessionZeros = finalSessionArray?.every((value) => value === 0);

  const newUserArray = dashboardClientsData?.data?.map((item) => {
    return item.new_user;
  });
  const oldUserArray = dashboardClientsData?.data?.map((item) => {
    return item.old_user;
  });
  const finalClientArray = newUserArray?.concat(oldUserArray);
  const allClientsZeros = finalClientArray?.every((value) => value === 0);

  const sessionChartOption = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        display: false,
        stacked: true,
      },
    },
    plugins: {
      tooltip: {
        // backgroundColor: '#F2F4F7',
        callbacks: {
          label: function () {
            return ""; // Remove the default label
          },
          afterLabel: function (context) {
            let label = "";
            context.chart.data.datasets.forEach((dataset) => {
              label += `${dataset.data[context.dataIndex] || "0"} ${
                dataset.label
              }\n`;
            });
            return label.slice(0, -1); // Remove the last newline character
          },
          // labelTextColor: function () {
          //   return '#101828'; // Change the text color of the tooltip
          // }
        },
      },
    },
  };

  const sessionLabels = dashboardSessionsData?.data?.map((item) => {
    return item.day;
  });

  const sessionChartData = {
    labels: sessionLabels,
    datasets: [
      {
        label: "COMPLETED",
        data: completedSessionArray?.map((value) =>
          value === 0 ? undefined : value
        ),
        backgroundColor: "#007BD2",
        borderRadius: 8,
        barThickness: 41,
      },
      {
        label: "RESCHEDULED",
        data: rescheduledSessionArray?.map((value) =>
          value === 0 ? undefined : value
        ),
        backgroundColor: "#FF9310",
        borderRadius: 8,
        barThickness: 41,
      },
      {
        label: "CANCEL",
        data: cancelledSessionArray?.map((value) =>
          value === 0 ? undefined : value
        ),
        backgroundColor: "#FF2E00",
        borderRadius: 8,
        barThickness: 41,
      },
    ],
  };

  const clientLabels = dashboardClientsData?.data?.map((item) => {
    return item.day;
  });

  const clientChartData = {
    labels: clientLabels,
    datasets: [
      {
        label: "NEW",
        data: newUserArray?.map((value) => (value === 0 ? undefined : value)),
        backgroundColor: "#007BD2",
        stack: "Stack 0",
        borderRadius: 4,
        barThickness: 23,
      },
      {
        label: "RETURNING",
        data: oldUserArray?.map((value) => (value === 0 ? undefined : value)),
        backgroundColor: "#B6E3FF",
        stack: "Stack 1",
        borderRadius: 4,
        barThickness: 23,
      },
    ],
  };

  const handleJoinSession = (item) => {
    if (getTimeDifference(item?.appointment_time) > 15) {
      // toast.error("You can not join early!")
    } else
      window.open(
        `/dashboard/session/?patient_id=${item?.patient}&appointment=${item?.id}&therapist_id=${item?.therapist}`
      );
  };

  const SessionBlock = () => {
    return (
      <div className="drop-shadow bg-white rounded-lg py-2 px-3 w-1/2 lg:w-1/5 space-y-2.5">
        <div className="text-xs font-bold text-gray-400">SESSIONS</div>
        <div className="flex space-x-0.5 items-end">
          <div className="text-2xl font-semibold ">
            {dashboardPerformanceData?.data?.total_sessions || 0}
          </div>
          <img
            src={
              dashboardPerformanceData?.data?.growth > 0
                ? UpGreenArrow
                : DownRedArrow
            }
            className={
              dashboardPerformanceData?.data?.growth > 0 ? "mb-0.5" : "mb-2"
            }
          />
          <div
            className={`text-xs font-semibold ${
              dashboardPerformanceData?.data?.growth > 0
                ? "text-[#16A249]"
                : "text-red-500"
            } mb-1`}
          >
            {`${Math.abs(dashboardPerformanceData?.data?.growth)}%` || 0}
          </div>
        </div>
      </div>
    );
  };

  const ClientBlock = () => {
    return (
      <div className="drop-shadow bg-white rounded-lg py-2 px-3 w-1/2 lg:w-1/5 space-y-2.5">
        <div className="text-xs font-bold text-gray-400">CLIENTS</div>
        <div className="text-2xl font-semibold">
          {dashboardPerformanceData?.data?.clients || 0}
        </div>
      </div>
    );
  };

  const FeedBackBlock = () => {
    return (
      <div className="drop-shadow bg-white rounded-lg py-2 px-3 w-1/2 lg:w-1/5 space-y-2.5">
        <div className="text-xs font-bold text-gray-400">FEEDBACK</div>
        <div className="text-2xl font-semibold">{`${
          dashboardPerformanceData?.data?.feedback || 0
        }%`}</div>
      </div>
    );
  };

  // const EarningBlock = () => {
  //   return (
  //     <div className="drop-shadow bg-white rounded-lg py-2 px-3 w-1/2 lg:w-1/5 space-y-2.5 ">
  //       <div className="text-xs font-bold text-gray-400">EARNINGS</div>
  //       <div className="text-2xl font-semibold">{`$${
  //         0
  //         //  0 || dashboardPerformanceData?.data?.earnings
  //       }`}</div>
  //     </div>
  //   );
  // };

  const Availability = () => {
    return (
      <div
        className={`drop-shadow bg-white rounded-lg py-2 px-3 w-1/2 lg:w-1/5 space-y-2.5`}
      >
        <div className="text-xs font-bold text-gray-400">AVAILABILITY</div>
        <div className="flex space-x-1 items-end">
          <div className="text-2xl font-semibold">{`${
            dashboardPerformanceData?.data?.availability || 0
          }%`}</div>
          <div className="text-xs font-semibold text-green-500 mb-1">
            Booked
          </div>
        </div>
      </div>
    );
  };

  const SessionChart = () => {
    return (
      <div className="bg-white rounded-xl lg:w-3/5 drop-shadow p-5">
        <div className="text-base font-medium">SESSIONS</div>
        <div className="text-sm text-gray-500 mb-2">
          {moment(sevenDaysFromToday).format("DD MMM")} -{" "}
          {moment(currentDate).format("DD MMM YYYY")}
        </div>
        {!allSessionZeros ? (
          <Bar options={sessionChartOption} data={sessionChartData} />
        ) : (
          <div className="flex items-center flex-col pt-12 pb-12 lg:pb-0 lg:pt-12">
            <img src={EmptySessionChart} />
            <div className="text-sm font-medium mt-4 text-center">
              Your sessions graph will appear here
            </div>
          </div>
        )}
      </div>
    );
  };

  const SessionList = () => {
    const uniqueDates = [];
    return (
      <div className="bg-white rounded-2xl lg:w-2/5 drop-shadow">
        <div className="flex justify-between items-center p-5 border-b border-solid border-gray-200">
          <div className="text-xs text-gray-500 font-medium">
            UPCOMING SESSIONS
          </div>
          <div
            onClick={() => navigate("/therapist/sessions")}
            className="text-xs text-blue-600 font-medium cursor-pointer"
          >
            View Calendar
          </div>
        </div>
        <div className="overflow-auto p-6 pb-14 flex flex-col gap-4">
          {dashboardData?.data?.upcoming_sessions?.length > 0 ? (
            <>
              {dashboardData?.data?.upcoming_sessions
                ?.slice(0, 5)
                ?.map((item, index) => {
                  const sessionDate = moment(
                    item.session_date,
                    "dddd, DD MMM"
                  ).format("ddd, DD MMM");
                  const todaySession =
                    sessionDate === moment(new Date()).format("ddd, DD MMM");

                  // Check if the date has already been displayed
                  const dateAlreadyDisplayed =
                    uniqueDates.includes(sessionDate);

                  // If not displayed, add the date to the uniqueDates array and render it
                  if (!dateAlreadyDisplayed) {
                    uniqueDates.push(sessionDate);
                  }

                  return (
                    <div
                      key={index}
                      className="flex items-start justify-between"
                    >
                      <div className="text-xs w-20 mt-0.5">
                        {dateAlreadyDisplayed
                          ? ""
                          : todaySession
                          ? "Today"
                          : sessionDate || ""}
                      </div>
                      <div
                        className={`flex flex-1 items-center justify-between ${
                          index == 0 ? "bg-blue-100" : "bg-gray-100"
                        } rounded-lg p-3`}
                      >
                        <div className="flex items-center space-x-2">
                          <div
                            className={`${
                              index == 0 ? "bg-blue-600" : "bg-gray-400"
                            } h-9 w-1 rounded-full`}
                          ></div>
                          <div className="space-y-1">
                            <div className="text-xs">
                              {(item?.patient_info?.first_name || "") +
                                " " +
                                (item?.patient_info?.last_name || "")}
                            </div>
                            <div className="text-xs text-gray-500">
                              {item?.start_time || ""} to {item?.end_time || ""}
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => handleJoinSession(item)}
                          className={`text-xs cursor-pointer ${
                            index == 0 ? "text-blue-600" : "text-gray-400"
                          }`}
                        >
                          {getTimeDifference(item?.appointment_time) > 15
                            ? ""
                            : "Join now"}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </>
          ) : (
            <div className="flex items-center flex-col pt-12">
              <img src={emptySessionsList} />
              <div className="text-sm font-medium mt-4">
                Your upcoming sessions will appear here
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const ClientChart = () => {
    return (
      <div className="bg-white rounded-xl lg:w-3/5 drop-shadow p-5">
        <div className="text-base font-medium">CLIENTS</div>
        <div className="text-sm text-gray-500 mb-2">
          {moment(sevenDaysFromToday).format("DD MMM")} -{" "}
          {moment(currentDate).format("DD MMM YYYY")}
        </div>
        {!allClientsZeros ? (
          <Bar options={sessionChartOption} data={clientChartData} />
        ) : (
          <div className="flex items-center flex-col pt-12 pb-12 lg:pb-0 lg:pt-12">
            <img src={EmptySessionChart} />
            <div className="text-sm font-medium mt-4 text-center">
              Your clients graph will appear here
            </div>
          </div>
        )}
      </div>
    );
  };

  const ActivityFeed = () => {
    return (
      <div className="bg-white rounded-2xl lg:w-2/5 drop-shadow">
        <div className="p-5 border-b border-solid border-gray-200">
          <div className="text-base font-medium uppercase">Activity Feed</div>
        </div>
        <div className="overflow-auto lg:h-[345px] py-6 px-5 ">
          {notifications?.length > 0 ? (
            <>
              {notifications?.slice(0, displayCount).map((item, index) => {
                return (
                  <div key={index} className="flex space-x-3 items-start mb-2">
                    {item?.notification_iconcode == 13 ? (
                      <img
                        className="h-10 w-10 rounded-xl"
                        src={item?.notification_dump?.icon_url || userAvatar}
                      />
                    ) : (
                      <img
                        src={getNotificationIcon(item?.notification_iconcode)}
                      />
                    )}
                    <div>
                      <div className="text-sm">
                        {item?.notification_text || ""}
                      </div>
                      <div className="text-[10px] text-gray-500">
                        {calculateTimeDifference(item?.created_at)}
                      </div>
                      <div className="flex items-center space-x-2 mt-1">
                        {item?.notification_action?.map((i) => {
                          return (
                            <div
                              className="text-sm font-medium text-teal-900 rounded-full bg-teal-100 py-1 px-2"
                              key={i}
                            >
                              {i}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
              {isMobile && displayCount < notifications?.length ? (
                <div
                  onClick={() =>
                    setDisplayCount((prevDisplayCount) => prevDisplayCount + 10)
                  }
                >
                  See More
                </div>
              ) : null}
            </>
          ) : (
            <div className="flex items-center flex-col pt-12 pb-12 lg:pb-0">
              <img src={emptyNotificationList} />
              <div className="text-sm font-medium mt-4">
                Your activity feed will appear here
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <MetaTags
        title="Home | Fitcy Health"
        description="Explore your Fitcy Health dashboard"
        url="/therapist/home"
      />
      <Header
        className="border-b border-gray-200  fixed z-10 bg-white"
        dashboard={true}
        app="THERAPIST"
      />
      <div className="lg:flex lg:pl-24 pt-[4.5rem]">
        <SideMenu app="THERAPIST" className="left-0 lg:top-[4.45rem]" />
        <div className="pb-24 w-full">
          <div className="bg-blue-100">
            <div className="container mx-auto px-4 lg:px-16 py-10">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <img
                    src={user?.profile_picture?.full_url || Avatar}
                    className="w-[3.75rem] h-[3.75rem] rounded-full bg-gray-100 object-contain"
                  />
                  <div className="space-y-1.5">
                    <div className="font medium text-2.5xl">{`${
                      user?.first_name || ""
                    }  ${user?.last_name || ""}`}</div>
                    {/* <div className="flex flex-wrap gap-2">
                    {user?.certified_from && Object.keys(user?.certified_from)?.map((key, index) => (
                      <div key={key} className="flex flex-wrap gap-2">
                        <div className="text-gray-500 rounded-lg text-sm">
                          {user.certified_from[key].label}
                        </div>
                        {index != Object.keys(user?.certified_from)?.length - 1 &&
                          <div className="text-gray-500 -mt-0.5">|</div>
                        }
                      </div>
                    ))}
                  </div> */}
                    {user?.designation && (
                      <div className="text-gray-500 text-sm">
                        {user?.designation}
                      </div>
                    )}
                    {isMobile && (
                      <div
                        className={`text-sm font-semibold capitalize ${
                          user?.tier === "GOLD"
                            ? "text-[#EFA826]"
                            : user?.tier === "SILVER"
                            ? "text-[#989898]"
                            : "text-blue-900"
                        }`}
                      >
                        {user?.tier
                          ? user?.tier.toLowerCase() + " Tier"
                          : "N/A"}
                      </div>
                    )}
                  </div>
                </div>
                {!isMobile && (
                  <div className="bg-white space-y-px rounded-lg w-48 px-2.5 py-1">
                    <div className="text-xs font-normal text-gray-500">
                      Your Tier at Fitcy
                    </div>
                    <div
                      className={`text-sm font-semibold capitalize ${
                        user?.tier === "GOLD"
                          ? "text-[#EFA826]"
                          : user?.tier === "SILVER"
                          ? "text-[#989898]"
                          : "text-blue-900"
                      }`}
                    >
                      {user?.tier ? user?.tier.toLowerCase() + " Tier" : "N/A"}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between mt-8">
                <div className="text-base font-medium">
                  {isMobile
                    ? "How you are performing"
                    : "Glimpse of your performance"}
                </div>
                <Select
                  value={dateRange}
                  onChange={(val) => setDateRange(val)}
                  options={dropDownOptions}
                  styles={dropDownStyles}
                  menuPlacement="auto"
                  isSearchable={false}
                />
              </div>
              <div className="lg:flex space-y-4 lg:space-y-0 lg:space-x-3.5 mt-3">
                {isMobile ? (
                  <>
                    <div className="flex items-center space-x-4">
                      {SessionBlock()}
                      {ClientBlock()}
                    </div>
                    <div className="flex items-center space-x-4">
                      {FeedBackBlock()}
                      {/* {EarningBlock()} */}
                      {Availability()}
                    </div>
                  </>
                ) : (
                  <>
                    {SessionBlock()}
                    {ClientBlock()}
                    {FeedBackBlock()}
                    {Availability()}
                    {/* {EarningBlock()} */}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="container mx-auto px-4 lg:px-16 py-10 space-y-6">
            <div className="lg:flex lg:space-x-6">
              {!isMobile && SessionChart()}
              {SessionList()}
            </div>

            <div className="lg:flex lg:space-x-6">
              {!isMobile && ClientChart()}
              {ActivityFeed()}
            </div>
            {isMobile && (
              <>
                {ClientChart()}
                {SessionChart()}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
