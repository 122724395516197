import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentStatus } from "../../redux/onboarding/onboardingThunks";
import crossMark from "../../assets/cross.png";
import Loader from "../atoms/Loader";
import SuccessScreen from "./SuccessScreen";
import Heading from "../atoms/Heading";
import Link from "../atoms/Link";
import { whatsappClientNumber } from "../../services/utils/constants";

const PaymentStatus = () => {
  const dispatch = useDispatch();
  const paymentPackage = useSelector((state) => state.onboarding.payment);
  const paymentStatus = useSelector((state) => state.onboarding.payment_status);
  const [reloader, setReloader] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!paymentStatus) {
          await dispatch(
            getPaymentStatus({
              setup_intent_id: paymentPackage.setup_intent.id,
            })
          );
        }

        if (paymentStatus && paymentStatus?.data.status === "PENDING") {
          await dispatch(
            getPaymentStatus({
              setup_intent_id: paymentPackage.setup_intent.id,
            })
          );
          setReloader(reloader + 1);
        }

        if (reloader === 0 || !paymentStatus) {
          setReloader(reloader + 1);
        }
      } catch (error) {
        console.error("Error fetching payment status:", error);
        // Handle error appropriately
      }
    };

    fetchData();
  }, [reloader, paymentPackage.setup_intent.id, paymentStatus]);

  if (!paymentStatus || paymentStatus?.data.status === "PENDING")
    return <Loader />;

  if (paymentStatus?.data.status === "SUCCESS")
    return (
      <SuccessScreen
        data={paymentStatus?.data}
        message={paymentStatus?.message}
      />
    );

  if (paymentStatus?.data.status === "FAILURE")
    return (
      <div className="py-8 rounded-lg bg-white shadow-registrationForm z-10 relative max-w-lg mx-auto top-12">
        <img className="mx-auto" src={crossMark} width={100} alt="failure" />
        <div className="max-w-xs text-center mx-auto mt-4">
          <Heading variant="h2" className="text-2xl font-medium">
            {paymentStatus?.data.reason || "Transaction Failed!"}
          </Heading>
          <p className="mt-2 text-gray-500">
            {`Your transaction has failed, please re-initiate your payment request or contact our support team.`}
          </p>
        </div>
        <div className="flex space-x-4 items-center mt-4 justify-center">
          <Link href="/sessions" className="text-teal-500 underline block">
            Go back to Sessions
          </Link>
          <div
            className="text-teal-500 underline cursor-pointer"
            onClick={() => window.open(`https://wa.me/${whatsappClientNumber}`)}
          >
            Contact support
          </div>
        </div>
      </div>
    );
};

export default PaymentStatus;
