import React, { forwardRef } from "react";

const Link = (
  {
    id,
    rel = "noreferrer",
    href,
    target,
    children,
    ariaLabel,
    className,
    onClick,
  },
  ref
) => {
  return (
    <a
      href={href ?? ""}
      id={id}
      target={target}
      className={className}
      aria-label={ariaLabel}
      rel={rel}
      ref={ref}
      onClick={onClick}
    >
      {children}
    </a>
  );
};
export default forwardRef(Link);
