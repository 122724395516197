// SpecialSlots.js

import React, { useState, useEffect } from "react";
import Select from "react-select";
import Modal from "react-modal";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Heading from "../atoms/Heading";
import Button from "../atoms/Button";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

const CustomPrevIcon = () => {
  // Replace this with your custom SVG for the previous arrow
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M7.96855 10.1579L12.4067 14.6263C12.7373 14.9597 12.7373 15.5001 12.4067 15.8334C12.0762 16.1667 11.5403 16.1667 11.2098 15.8334L6.17323 10.7615C5.84265 10.4281 5.84265 9.88765 6.17323 9.55428L11.2098 4.48241C11.5403 4.14909 12.0762 4.14909 12.4067 4.48241C12.7373 4.81572 12.7373 5.35613 12.4067 5.68944L7.96855 10.1579Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};

const CustomNextIcon = () => {
  // Replace this with your custom SVG for the previous arrow
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M12.821 10.1579L8.38282 14.6263C8.05229 14.9597 8.05229 15.5001 8.38282 15.8334C8.71335 16.1667 9.24924 16.1667 9.57977 15.8334L14.6163 10.7615C14.9469 10.4281 14.9469 9.88765 14.6163 9.55428L9.57977 4.48241C9.24924 4.14909 8.71335 4.14909 8.38282 4.48241C8.05229 4.81572 8.05229 5.35613 8.38282 5.68944L12.821 10.1579Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};

const SpecialSlots = ({
  isOpen,
  onRequestClose,
  onSaveSpecialSlot,
  timeOptions,
  selectStyles,
  specialSlots,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [enabled, setEnabled] = useState(true);
  const [slots, setSlots] = useState([{ start_time: "", end_time: "" }]);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  // const [slotsData, setSlotsData] = useState({});
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const [slotsErrors, setSlotsErrors] = useState([]);

  useEffect(() => {
    if (isOpen) {
      handleDayClick(new Date());
    }
  }, [isOpen]);

  const formatTimeLabel = (time) => {
    return moment(time, "HH:mm").format("hh:mm A");
  };

  const handleSaveSlot = () => {
    if (enabled && !validateAvailability()) {
      // You can show a global error message here or handle it as needed
      return;
    }
    const newSlotErrors = [];
    if (enabled) {
      const validSlots = slots.filter((slot) => {
        const isEmptySlot = !slot.start_time || !slot.end_time;
        const isInvalidTime =
          !isEmptySlot && slot.start_time.value >= slot.end_time.value;

        if (isEmptySlot) {
          newSlotErrors.push(`Time slot is empty.`);
        } else if (isInvalidTime) {
          newSlotErrors.push(`End time should be greater than start time.`);
        }

        return !isEmptySlot && !isInvalidTime;
      });
      if (validSlots.length > 0) {
        // const dateKey = selectedDate.toISOString();
        // setSlotsData((prevSlotsData) => ({
        //   ...prevSlotsData,
        //   [dateKey]: validSlots,
        // }));
        setSlotsErrors(newSlotErrors);
        const newSlot = {
          date: selectedDate,
          enabled: enabled,
          slots: validSlots.map((slot) => ({
            start_time: slot.start_time.value,
            end_time: slot.end_time.value,
          })),
        };

        onSaveSpecialSlot(newSlot);
        setSelectedDate(new Date());
        setEnabled(false);
        setSlots([]);
      } else {
        // Update state to show errors
        setSlotsErrors(newSlotErrors);
      }
    } else {
      const newSlot = {
        date: selectedDate,
        enabled: enabled,
        slots: slots,
      };
      onSaveSpecialSlot(newSlot);
    }
  };

  const handleDayClick = (date) => {
    setSelectedDate(date);
    const selectedDateStr = moment(date).format("YYYY-MM-DD");
    const slotForTheDate = specialSlots.find(
      (slot) => moment(slot.date).format("YYYY-MM-DD") === selectedDateStr
    );

    // const dateKey = date.toISOString();
    // const slotsForDate = slotsData[dateKey] || [];

    setSlots(
      slotForTheDate?.slots?.length > 0
        ? slotForTheDate.slots.map((slot) => ({
          start_time: {
            label: formatTimeLabel(slot.start_time),
            value: slot.start_time,
          },
          end_time: {
            label: formatTimeLabel(slot.end_time),
            value: slot.end_time,
          },
        }))
        : [{ start_time: "", end_time: "" }]
    );
    setEnabled(slotForTheDate ? slotForTheDate?.enabled : true);
  };
  const addSlot = () => {
    setSlots([...slots, { start_time: "", end_time: "" }]);
  };

  const removeSlot = (index) => {
    const updatedSlots = [...slots];
    updatedSlots.splice(index, 1);
    setSlots(updatedSlots);
  };

  const validateAvailability = () => {
    const newSlotError = {};

    for (let i = 0; i < slots.length; i++) {
      const slot = slots[i];
      const slotErrors = [];

      // Check for overlapping slots within the same day
      const overlapError = checkOverlappingSlots(slots);
      if (overlapError) {
        slotErrors.push(overlapError);
        if (!newSlotError) {
          newSlotError.overlap = "";
        }
        newSlotError.overlap = slotErrors;
        break;
      }

      if (
        !slot.start_time ||
        !slot.start_time.value ||
        !slot.end_time ||
        !slot.end_time.value
      ) {
        slotErrors.push(`Time slots cannot be empty`);
      }

      // Check if end_time is less than start_time
      if (
        slot.start_time.value &&
        slot.end_time.value &&
        slot.start_time.value >= slot.end_time.value &&
        slot.end_time.value !== "00:00"
      ) {
        slotErrors.push(`End time must be greater than start time.`);
      }

      if (slotErrors.length > 0) {
        newSlotError[i] = slotErrors;
      }
    }

    // Update state based on the new errors
    setSlotsErrors((prevSlotErrors) => ({
      ...prevSlotErrors,
      ...newSlotError,
    }));
    // Check if there are any errors
    return Object.values(newSlotError).flat().length === 0;
  };
  const checkOverlappingSlots = (slots) => {
    for (let i = 0; i < slots.length; i++) {
      for (let j = i + 1; j < slots.length; j++) {
        const slot1 = slots[i];
        const slot2 = slots[j];

        const startTime1 = slot1.start_time?.value || "00:00";
        const endTime1 = slot1.end_time?.value || "00:00";
        const startTime2 = slot2.start_time?.value || "00:00";
        const endTime2 = slot2.end_time?.value || "00:00";

        // Check if the slots overlap
        if (
          (startTime1 < endTime2 && endTime1 > startTime2) ||
          (startTime2 < endTime1 && endTime2 > startTime1)
        ) {
          return `Slot ${i + 1} and Slot ${j + 1} are overlapping.`;
        }
      }
    }
    return null;
  };

  const handleTimeChange = (index, key, selectedOption) => {
    setSlots((prevSlots) =>
      prevSlots.map((slot, i) =>
        i === index ? { ...slot, [key]: selectedOption } : slot
      )
    );
    setSlotsErrors((prevSlotErrors) => ({
      ...prevSlotErrors,

      [index]: "",
    }));
    // Check for overlapping slots on the specific day
    const updatedSlots = [
      slots.map((slot, i) =>
        i === index ? { ...slot, [key]: selectedOption } : slot
      ),
    ];
    const overlapError = checkOverlappingSlots(updatedSlots[0]);

    if (overlapError) {
      setSlotsErrors((prevSlotErrors) => ({
        ...prevSlotErrors,
        overlap: overlapError,
      }));
    } else {
      setSlotsErrors((prevSlotErrors) => ({
        ...prevSlotErrors,
        overlap: "",
      }));
    }
    if (key === "start_time") {
      setSelectedStartTime(selectedOption);
      const selectedStartTime = selectedOption.value;
      const currentEndTime = slots[index].end_time.value;
      // Check if the selected start_time is less than the current end_time
      if (currentEndTime && selectedStartTime >= currentEndTime) {
        setSlotsErrors((prevSlotErrors) => ({
          ...prevSlotErrors,
          [index]: "Start cannot be greater than end time.",
        }));
      }
    }

    // Check if end_time is less than start_time and update error state
    if (key === "end_time") {
      const startTime = slots[index].start_time.value;
      const endTime = selectedOption.value;
      if (startTime && endTime && startTime >= endTime && endTime !== "00:00") {
        setSlotsErrors((prevSlotErrors) => ({
          ...prevSlotErrors,
          [index]: "End time must be greater than start time.",
        }));
      }
    }
  };
  // Modify timeOptions for end_time based on selected start_time
  const filteredEndTimes = timeOptions.filter(
    (option) => !selectedStartTime || option.value > selectedStartTime.value
  );
  const disableYearChange = ({ date }) => {
    const currentYear = new Date().getFullYear();
    const currentDate = new Date();

    // Disable previous dates and years other than the current year
    return (
      date.getFullYear() < currentYear ||
      (date.getFullYear() === currentYear &&
        date.getMonth() < currentDate.getMonth()) ||
      (date.getFullYear() === currentYear &&
        date.getMonth() === currentDate.getMonth() &&
        date.getDate() < currentDate.getDate())
    );
  };

  const customStyles = {
    content: {
      maxWidth: "450px",
      height: "auto",
      maxHeight: "90%",
      width: isMobile ? "calc(100% - 2rem)" : "100%",
      left: 0,
      right: 0,
      bottom: isMobile ? "1rem" : "auto",
      margin: "auto",
      border: "none",
      borderRadius: "16px",
      padding: isMobile ? "" : "",
    },
    overlay: {
      zIndex: "30",
      background: "rgba(0, 0, 0, 0.5)",
    },
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Special Slots"
      style={customStyles}
    >
      <Heading
        heading="Select the date you want to assign specific hours"
        variant="h2"
        className="p-4 font-medium"
      />
      <div>
        <Calendar
          onChange={(date) => setSelectedDate(date)}
          value={selectedDate}
          className="!w-full px-4 pb-6 special-slots"
          prevLabel={<CustomPrevIcon />} // Custom component for the previous arrow
          nextLabel={<CustomNextIcon />}
          tileDisabled={disableYearChange} // Prevents year change
          onClickDay={(date) => handleDayClick(date)}
          minDetail={"month"}
        />
      </div>
      <div className="px-4 py-6 bg-gray-100">
        {enabled && (
          <>
            <Heading
              variant="h3"
              heading="What hours are you available?"
              className="font-medium"
            />
            <div className="mt-2 flex items-start">
              <div className="space-y-2">
                {slots.map((slot, index) => (
                  <div key={index} className="items-center">
                    <div className="flex">
                      <div className="w-32 mr-3">
                        <Select
                          options={timeOptions}
                          value={slot.start_time}
                          onChange={(selectedOption) =>
                            handleTimeChange(
                              index,
                              "start_time",
                              selectedOption
                            )
                          }
                          styles={selectStyles}
                          menuPlacement="auto"
                        />
                      </div>
                      <div className="w-32">
                        <Select
                          options={filteredEndTimes}
                          value={slot.end_time}
                          onChange={(selectedOption) =>
                            handleTimeChange(index, "end_time", selectedOption)
                          }
                          styles={selectStyles}
                          menuPlacement="auto"
                          isDisabled={slot.start_time ? false : true}
                        />
                      </div>
                      {index !== 0 && (
                        <button
                          className="shadow-none ml-3"
                          onClick={() => removeSlot(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M15 5L5 15M5 5L15 15"
                              stroke="#667085"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      )}
                      {index === slots.length - 1 && (
                        <button onClick={addSlot} className="shadow-none ml-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10.0001 4.16675V15.8334M4.16675 10.0001H15.8334"
                              stroke="#667085"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                    {slotsErrors[index] && (
                      <div className="text-danger-900 text-xs">
                        {slotsErrors[index]}
                      </div>
                    )}
                  </div>
                ))}
                {slotsErrors?.overlap && (
                  <div className="text-danger-900 text-xs">
                    {slotsErrors.overlap}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="flex items-center mr-16 mt-2">
          <span className={enabled ? "" : "text-gray-500"}>
            Mark this day unavailable
          </span>
          <label className="relative w-11 h-6 ml-2">
            <input
              type="checkbox"
              checked={enabled}
              onChange={() => setEnabled(!enabled)}
              className="opacity-0 w-0 h-0"
            />
            <span
              className={`slider absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full transition duration-400 ${!enabled ? "bg-blue-500" : "bg-[#EBEBEB]"
                }`}
            >
              <span
                className={`toggle-circle absolute h-5 w-5 bg-white rounded-full transition duration-400 left-[2px] bottom-[2px] ${!enabled ? "transform translate-x-5" : ""
                  }`}
              ></span>
            </span>
          </label>
        </div>
      </div>
      <div className="p-4 flex justify-end">
        <button
          onClick={onRequestClose}
          className="text-gray-500 shadow-none mr-4 font-medium"
        >
          Cancel
        </button>
        <Button
          className="bg-blue-900 text-white px-4 py-1.5 rounded-lg font-medium"
          onClick={handleSaveSlot}
        >
          Save changes
        </Button>
      </div>
    </Modal>
  );
};

export default SpecialSlots;
