import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/molecules/Header";
import SideMenu from "../../components/molecules/SideMenu";
import Tooltip from "../../components/atoms/Tooltip";
import UserIcon from "../../assets/user.svg";
import MessageIcon from "../../assets/message-chat-square.svg";
import backgroundImage from "../../assets/client_list_background.svg";
import ChevronDown from "../../assets/chevron-down.svg";
import SearchIcon from "../../assets/search-icon.svg";
import HelpIcon from "../../assets/help-icon.svg";
import VerticalDotIcon from "../../assets/dots-vertical.svg";
import cross from "../../assets/x-close.svg";
import arrowBack from "../../assets/arrow-left.svg";
import sparkle from "../../assets/sparkle.svg";
import cancelledIcon from "../../assets/checkbox-circle-line.svg";
import completedIcon from "../../assets/checkbox-circle-line-green.svg";
import noShowIcon from "../../assets/no-show.svg";
import FilterLines from "../../assets/filter-lines.svg";
import CloseIcon from "../../assets/x-close.svg";
import EmptyListIcon from "../../assets/empty_client_list.svg";
// import StickyNote from "../../assets/sticky_note.svg";
import {
  calculateTimeDifference,
  MetaTags,
  removeZeroTime,
} from "../../services/globalFunctions";
import Modal from "react-modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TherapistClientsFilter from "../../components/molecules/TherapistClientsFilter";
import {
  ACTION_POPUP,
  CONDITIONS_FILTER,
  COUNTRY_FILTER,
  SESSION_FILTER,
  SORTING_FILTER,
  STATUS_FILTER,
} from "../../services/utils/constants";
import { useMediaQuery } from "react-responsive";
import {
  getTherapistClientsList,
  getClientData,
  getClientNotes,
} from "../../redux/therapist/therapistThunks";
import Heading from "../../components/atoms/Heading";
import AddNote from "../../components/molecules/AddNote";

const buildQueryString = (params) => {
  const queryStringParams = [];

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (Array.isArray(value)) {
      // If the value is an array, join its elements with '&'
      const arrayParams = value.map(
        (v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`
      );
      queryStringParams.push(...arrayParams);
    } else {
      // If it's not an array, handle it as usual
      queryStringParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  });

  return queryStringParams.join("&");
};

const sortingLabels = {
  latest: "Most Recent",
  oldest: "Least Recent",
  ascending: "Alphabetically Ascending",
  descending: "Alphabetically Descending",
};

export default function TherapistClients() {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const dispatch = useDispatch();
  const [isCommonModal, setIsCommonModal] = useState(false);
  const [isModalType, setIsModalType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [filterEnable, setFilterEnable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addNoteModalFlag, setAddNoteModalFlag] = useState(false);
  // const [filters, setFilters] = useState({});
  const [modalPosition, setModalPosition] = useState({
    top: 0,
    left: 0,
  });
  const [data, setData] = useState(false);
  const [countries, setCountries] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [activeFilters, setActiveFilters] = useState({});
  const [selectedSorting, setSelectedSorting] = useState("Most Recent");
  const [panel, setPanel] = useState(false);
  const [selectedClient, setSelectedClient] = useState(false);
  const [history, setHistory] = useState(false);
  const [assessmentResponse, setAssessmentResponse] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);
  const [viewNotes, setViewNotes] = useState(false);

  const countryDivRef = useRef();
  const conditionDivRef = useRef();
  const statusDivRef = useRef();
  const sessionDivRef = useRef();
  const sortingDivRef = useRef();
  const notes = useSelector((state) => state.therapist.clientNotes);

  const clientsList = data?.data;
  const recordsPerPage = 10;
  const totalPages = Math.ceil(data?.count / recordsPerPage);
  const handleSearch = (query) => {
    setSearchText(query);
    setActivePage(1);
  };

  const updateActiveFilters = () => {
    const newActiveFilters = {};
    Object.entries(appliedFilters).forEach(([filterKey, filterValue]) => {
      if (filterKey === "sorting" && filterValue === "latest") {
        newActiveFilters[filterKey] = false;
      } else {
        newActiveFilters[filterKey] = appliedFilters[filterKey].length > 0;
      }
    });
    setActiveFilters(newActiveFilters);
  };

  useEffect(() => {
    const fetchList = async () => {
      try {
        setLoading(true);
        const queryStringFilters = buildQueryString(appliedFilters);
        const response = await dispatch(
          getTherapistClientsList(
            `page=${activePage}${searchText ? `&name=${searchText}` : ""}${
              queryStringFilters && `&${queryStringFilters}`
            }`
          )
        );
        if (response.payload) {
          setData(response.payload.data);
          setCountries(response.payload.countries);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching Blog data:", error);
      }
    };
    fetchList();
    updateActiveFilters();
  }, [appliedFilters, activePage, searchText]);

  const clearAllFilters = () => {
    // setFilters({});
    setSelectedSorting("Most Recent");
    setAppliedFilters({});
  };

  const totalLength = Object.values(appliedFilters).reduce((total, filter) => {
    if (Array.isArray(filter)) {
      return total + filter.length;
    }
    return total;
  }, 0);

  const commonModalStyles = {
    content: {
      maxWidth: isMobile ? "100%" : "375px",
      width: "100%",
      top: isMobile
        ? "auto"
        : isModalType == ACTION_POPUP
        ? "400px"
        : `${modalPosition.top}px`,
      left: isMobile ? "0" : `${modalPosition.left}px`,
      right: isMobile ? "0" : `auto`,
      border: "none",
      borderRadius: isMobile ? "16px 16px 0 0" : "16px",
      bottom: isMobile ? "0" : `"auto"`,
      height: isModalType == ACTION_POPUP ? "105px" : "auto",
      maxHeight: isMobile ? "90vh" : "420px",
      background: "#fff",
      overflowY: "auto",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.09)",
      zIndex: "20",
    },
  };
  const panelStyles = {
    content: {
      background: "#fff",
      overflowY: "auto",
    },
    overlay: {
      background: "transparent",
      zIndex: "30",
    },
  };

  const getRefByModal = (type) => {
    if (type == COUNTRY_FILTER) return countryDivRef;
    else if (type == CONDITIONS_FILTER) return conditionDivRef;
    else if (type == STATUS_FILTER) return statusDivRef;
    else if (type == SESSION_FILTER) return sessionDivRef;
    else if (type == SORTING_FILTER) return sortingDivRef;
  };

  const openDropdown = (modal) => {
    setIsModalType(modal);
    const boundingBox = getRefByModal(modal)?.current.getBoundingClientRect();
    // Calculate the top position by considering both bounding box and scroll position
    const topPosition = boundingBox.top + window.scrollY + 48 - window.scrollY;

    // Calculate the left position based on the modal type
    const leftPosition = boundingBox.left + window.scrollX;
    setModalPosition({
      top: topPosition,
      left: leftPosition,
    });
    setIsCommonModal(true);
  };

  const handleOpenPanel = async (item) => {
    console.log("🚀 ~ handleOpenPanel ~ item:", item);
    try {
      const [response, clientNotesResponse] = await Promise.all([
        dispatch(getClientData({ id: item.id })),
        dispatch(getClientNotes({ patientId: item.id })),
      ]);
      // const response = await dispatch(getClientData({ id: item.id }));
      // const clientNotesResponse = await dispatch(
      //   getClientNotes({ patientId: item.id })
      // );
      if (response.payload && clientNotesResponse.payload) {
        setPanel(true);
        setSelectedClient(item);
        setHistory(response.payload.data.session_history);
      }
      // After getClientData completes, you can access the updated state
    } catch (error) {
      // Handle any errors that might occur during getClientData
      console.error("Error fetching client data:", error);
    }
  };

  const handleClosePanel = () => {
    setPanel(false);
    !isMobile && setSelectedClient(false);
  };

  const handleCloseAddNote = () => {
    setAddNoteModalFlag(false);
  };

  const CountryFilter = () => {
    return (
      <div
        ref={countryDivRef}
        onClick={() => openDropdown(COUNTRY_FILTER)}
        className={`${
          activeFilters.country
            ? "border-gray-400 bg-gray-100 outline-offset-2 outline-blue-600 outline text-gray-900"
            : "border-gray-200 text-gray-500"
        } flex items-center border border-solid rounded-lg py-2 px-4 space-x-2 cursor-pointer mt-3 mr-3 lg:mt-0 lg:mr-0`}
      >
        <div className="text-base">Country</div>
        <img src={ChevronDown} />
      </div>
    );
  };

  // const ConditionFilter = () => {
  //   return (
  //     <div
  //       ref={conditionDivRef}
  //       onClick={() => openDropdown(CONDITIONS_FILTER)}
  //       className="flex items-center border border-solid border-gray-200 rounded-lg py-2 px-4 space-x-2 cursor-pointer mt-3 mr-3 lg:mt-0 mr-0"
  //     >
  //       <div className="text-base text-gray-500">Conditions</div>
  //       <img src={ChevronDown} />
  //     </div>
  //   );
  // };

  const StatusFilter = () => {
    return (
      <div
        ref={statusDivRef}
        onClick={() => openDropdown(STATUS_FILTER)}
        className={`${
          activeFilters.patient_status
            ? "border-gray-400 bg-gray-100 outline-offset-2 outline-blue-600 outline text-gray-900"
            : "border-gray-200 text-gray-500"
        } flex items-center border border-solid rounded-lg py-2 px-4 space-x-2 cursor-pointer mt-3 mr-3 lg:mt-0 lg:mr-0`}
      >
        <div className="text-base text-gray-500">Status</div>
        <img src={ChevronDown} />
      </div>
    );
  };

  const SessionFilter = () => {
    return (
      <div
        ref={sessionDivRef}
        onClick={() => openDropdown(SESSION_FILTER)}
        className={`${
          activeFilters.session_count
            ? "border-gray-400 bg-gray-100 outline-offset-2 outline-blue-600 outline text-gray-900"
            : "border-gray-200 text-gray-500"
        } flex items-center border border-solid rounded-lg py-2 px-4 space-x-2 cursor-pointer mt-3 mr-3 lg:mt-0 lg:mr-0`}
      >
        <div className="text-base text-gray-500">Sessions</div>
        <img src={ChevronDown} />
      </div>
    );
  };

  const SortByFilter = () => {
    return (
      <div
        ref={sortingDivRef}
        onClick={() => openDropdown(SORTING_FILTER)}
        className={`${
          activeFilters.sorting
            ? "border-gray-400 bg-gray-100 outline-offset-2 outline-blue-600 outline text-gray-900"
            : "border-gray-200 text-gray-500"
        } flex items-center border border-solid rounded-lg py-2 px-4 space-x-2 cursor-pointer mt-3 lg:mt-0`}
      >
        <div className="flex items-center space-x-1">
          <div className="text-base text-gray-400">Sort by:</div>
          <div className="">{selectedSorting}</div>
        </div>
        <img src={ChevronDown} />
      </div>
    );
  };

  return (
    <>
      <MetaTags
        title="Your Clients | Fitcy Health"
        description="Manage your Fitcy Health clients"
        url="/clients"
      />
      <Header
        className="border-b border-gray-200 fixed z-10 bg-white"
        dashboard={true}
        app="THERAPIST"
      />
      <div className="lg:flex pt-[4.5rem] lg:pl-24">
        <SideMenu app="THERAPIST" className="left-0 lg:top-[4.45rem]" />
        <div
          className={`bg-cover flex-1 px-4 lg:px-6 pt-6 pb-24`}
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="flex items-center space-x-1">
            <div className="font-medium text-2xl lg:text-2.5xl">Clients</div>
            <div className="text-xs text-white font-medium rounded-lg bg-blue-600 py-1 px-2">
              {data?.total_count}
            </div>
          </div>
          {data?.count == 0 &&
          !searchText &&
          !loading &&
          Object.keys(appliedFilters).length === 0 ? (
            <div className="flex flex-col justify-center items-center w-[90vw] h-[70vh]">
              <img src={EmptyListIcon} />
              <div className="text-xl font-medium text-gray-800 text-center">{`Your client's list will appear here once they are assigned to you.`}</div>
            </div>
          ) : (
            <>
              <div
                className={`bg-white lg:flex items-center drop-shadow rounded-lg p-3 my-6 lg:space-x-3`}
              >
                {isMobile ? (
                  <>
                    <div className="flex items-center justify-between border border-solid border-gray-200 bg-gray-100 rounded-lg py-1 px-2 cursor-pointer w-full">
                      <div className="flex items-center space-x-2">
                        <img src={SearchIcon} />
                        <input
                          placeholder="Search clients"
                          className="outline-none border-none bg-gray-100 w-full"
                          value={searchText}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                      </div>
                      <div
                        onClick={() => setFilterEnable(!filterEnable)}
                        className="bg-white space-x-1.5 border border-solid border-gray-200 py-2 px-3 rounded-lg flex items-center cursor-pointer"
                      >
                        <div className="text-sm font-medium">Filters</div>
                        <img src={FilterLines} />
                      </div>
                    </div>
                    {filterEnable && (
                      <div className="flex flex-wrap items-center">
                        {CountryFilter()}
                        {/* {ConditionFilter()} */}
                        {StatusFilter()}
                        {SessionFilter()}
                        {SortByFilter()}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="flex items-center border border-solid border-gray-200 rounded-lg py-2 px-4 space-x-2 cursor-pointer">
                      <img src={SearchIcon} />
                      <input
                        placeholder="Search clients"
                        className="outline-none border-none"
                        value={searchText}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </div>
                    {CountryFilter()}
                    {/* {ConditionFilter()} */}
                    {StatusFilter()}
                    {SessionFilter()}
                    {SortByFilter()}
                  </>
                )}
              </div>
              <div className="flex flex-wrap items-center mb-7">
                {Object.entries(appliedFilters).map(
                  ([filterKey, filterValue], index) => {
                    if (filterKey === "sorting" && filterValue === "latest") {
                      return null; // Exclude "latest" sorting filter
                    }
                    if (Array.isArray(filterValue) && filterValue.length > 0) {
                      return filterValue.map((value, innerIndex) => {
                        // Exclude "All" from being displayed
                        if (value !== "All") {
                          return (
                            <div
                              key={`${index}-${innerIndex}`}
                              className="flex items-center space-x-2 py-1.5 px-3 bg-white drop-shadow rounded-full mr-3"
                            >
                              <div className="text-xs font-medium">{value}</div>
                              <img
                                src={CloseIcon}
                                className="h-2.5 w-2.5 cursor-pointer"
                                onClick={() => {
                                  // Remove the specific value from the filter array
                                  const updatedFilters = { ...appliedFilters };
                                  updatedFilters[filterKey] = updatedFilters[
                                    filterKey
                                  ].filter((item) => item !== value);

                                  // If the filter array becomes empty, remove the entire filter
                                  if (updatedFilters[filterKey].length === 0) {
                                    delete updatedFilters[filterKey];
                                  }

                                  setAppliedFilters(updatedFilters);
                                }}
                              />
                            </div>
                          );
                        }
                        return null;
                      });
                    } else if (typeof filterValue === "string") {
                      // Handle string values
                      return (
                        <div
                          key={`${index}`}
                          className="flex items-center space-x-2 py-1.5 px-3 bg-white drop-shadow rounded-full mr-3"
                        >
                          <div className="text-xs font-medium">
                            {sortingLabels[filterValue]}
                          </div>
                          <img
                            src={CloseIcon}
                            className="h-2.5 w-2.5 cursor-pointer"
                            onClick={() => {
                              // Remove the entire filter with string value
                              const updatedFilters = { ...appliedFilters };
                              delete updatedFilters[filterKey];
                              setSelectedSorting("Most Recent");
                              setAppliedFilters(updatedFilters);
                            }}
                          />
                        </div>
                      );
                    }
                    return null;
                  }
                )}
                {totalLength > 0 && (
                  <div
                    className="text-xs font-medium text-gray-500 cursor-pointer"
                    onClick={clearAllFilters}
                  >
                    Clear all filters
                  </div>
                )}
              </div>
              {data?.count > 0 && (
                <div className="text-xs font-medium text-gray-500">
                  {`Showing ${
                    activePage > 1
                      ? `${1 + (activePage - 1) * recordsPerPage} -`
                      : ""
                  }${Math.min(
                    activePage * recordsPerPage,
                    data?.count
                  )} out of ${data?.total_count} Clients`}
                </div>
              )}

              <table className="w-full table-auto">
                <thead>
                  {data?.count > 0 && (
                    <tr>
                      <th
                        className={`text-xs font-medium text-gray-500 py-3 text-left  ${
                          isMobile ? "w-3/5 px-3" : "w-1/3 px-6"
                        }`}
                      >
                        Name
                      </th>
                      {!isMobile && (
                        <th className="text-xs font-medium text-gray-500 py-3 text-left w-1/12">
                          Country
                        </th>
                      )}
                      <th
                        className={` py-3 text-left ${
                          isMobile ? "w-1/5" : "w-1/12"
                        }`}
                      >
                        <div className="flex items-center space-x-1 ">
                          <div className="text-xs font-medium text-gray-500">
                            Status
                          </div>
                          <div
                            className={`group cursor-pointer ${
                              isMobile ? "" : "relative"
                            }`}
                          >
                            <img src={HelpIcon} />

                            <Tooltip
                              pin="top"
                              className={`mt-2  ${
                                isMobile
                                  ? "max-w-[calc(100%-2rem)] max-h-[calc(100%-24rem)] overflow-auto left-4 fixed before:hidden after:hidden"
                                  : ""
                              }`}
                              direction={isMobile ? "left" : ""}
                              position="bottom"
                            >
                              <Heading variant="h4" className="test-sm mb-2">
                                Ongoing means:
                              </Heading>
                              <ul className="list-disc font-normal px-5">
                                <li>
                                  The Client is actively taking sessions with
                                  you.
                                </li>
                                <li> Active sessions are happening. </li>
                                <li>
                                  Session credits are available to book more
                                  sessions.
                                </li>
                              </ul>
                              <Heading
                                variant="h4"
                                className="test-sm mb-2 mt-2"
                              >
                                Paused means:
                              </Heading>
                              <ul className="list-disc font-normal px-5">
                                <li>
                                  The client has had sessions done with you in
                                  the past.
                                </li>
                                <li> Session credits are also available. </li>
                                <li>
                                  But no session happened for more than 30 days.
                                </li>
                              </ul>
                              <Heading
                                variant="h4"
                                className="test-sm mb-2 mt-2"
                              >
                                Completed means:
                              </Heading>
                              <ul className="list-disc font-normal px-5">
                                <li>
                                  The client has had sessions done with you in
                                  the past
                                </li>
                                <li> No credits left to book more sessions</li>
                              </ul>
                            </Tooltip>
                          </div>
                        </div>
                      </th>
                      {!isMobile && (
                        <>
                          <th className="py-3 text-left w-1/12">
                            <div className="flex items-center space-x-1 ">
                              <div className="text-xs font-medium text-gray-500">
                                Sessions
                              </div>
                              <div className="relative group cursor-pointer">
                                <img src={HelpIcon} />
                                <Tooltip>
                                  These are the total number of sessions
                                  completed with the Client
                                </Tooltip>
                              </div>
                            </div>
                          </th>
                          <th className="py-3 text-left w-1/3">
                            <div className="flex items-center space-x-1">
                              <div className="text-xs font-medium text-gray-500">
                                Medical Conditions
                              </div>
                              <div className="relative group cursor-pointer">
                                <img src={HelpIcon} />
                                <Tooltip>
                                  These are the medical conditions your client
                                  is dealing with
                                </Tooltip>
                              </div>
                            </div>
                          </th>
                        </>
                      )}
                      <th
                        className={`py-3 ${isMobile ? "w-1/5" : "w-1/12"}`}
                      ></th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="py-[10%]">
                        <div className="border-t-2 border-blue-300 border-solid rounded-full h-16 w-16 animate-spin mx-auto"></div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {data?.count === 0 && (
                        <div className="flex flex-col justify-center items-center w-[90vw]">
                          <img src={EmptyListIcon} />
                          <div className="text-xl font-medium text-gray-800">
                            {searchText
                              ? `No Results Found for “${searchText}”`
                              : "No Results Found"}
                          </div>
                          {searchText && (
                            <>
                              <div className="text-base text-gray-700 mt-2 mb-4">
                                Try Search with a different keyword
                              </div>
                              <div
                                onClick={() => handleSearch("")}
                                className="text-base font-medium text-white py-2 px-4 bg-blue-900 rounded-lg cursor-pointer"
                              >
                                Search
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {clientsList?.map((item, index) => {
                        const maxConditionsToShow = 2;
                        const visibleConditions =
                          item.medical_conditions &&
                          Object.values(item.medical_conditions)?.slice(
                            0,
                            maxConditionsToShow
                          );
                        const hiddenConditions =
                          item.medical_conditions &&
                          Object.values(item.medical_conditions).slice(
                            maxConditionsToShow
                          );
                        return (
                          <tr
                            key={index}
                            className={`${
                              item.id === selectedClient?.id
                                ? "bg-blue-100"
                                : index % 2 == 0
                                ? "bg-gray-50"
                                : ""
                            }`}
                          >
                            <td
                              className={`py-4 ${isMobile ? "px-3" : "px-6"}`}
                            >
                              <div className="flex items-center space-x-2">
                                <div className="bg-blue-100 rounded-full h-10 w-10 flex items-center justify-center text-base font-medium text-blue-500">
                                  {item?.profile_picture?.full_url ? (
                                    <img
                                      src={item.profile_picture.full_url}
                                      className="rounded-full w-full h-full object-cover"
                                    />
                                  ) : (
                                    item.full_name
                                      .split(" ") // Split the full name into an array of words
                                      .slice(0, 2) // Take the first two words
                                      .map((word) => word[0]) // Extract the first letter of each word
                                      .join("") // Join the extracted letters
                                      .toUpperCase()
                                  )}{" "}
                                  {/* Convert the result to uppercase */}
                                </div>
                                <div className="space-y-px">
                                  <div className="text-sm font-medium">
                                    {item.full_name}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {item.gender}
                                  </div>
                                </div>
                              </div>
                            </td>
                            {!isMobile && (
                              <td className="text-sm text-gray-500 py-4">
                                {item.country ?? "-"}
                              </td>
                            )}
                            <td className="py-4">
                              <label
                                className={`${
                                  item.patient_status === "Paused" ||
                                  item.patient_status === "Abandoned"
                                    ? "bg-gray-200 text-gray-500"
                                    : item.patient_status === "Completed"
                                    ? "bg-[#E6F8EF] text-[#027A48]"
                                    : "bg-blue-100 text-blue-600"
                                } text-xs font-medium rounded-full py-1.5 px-3`}
                              >
                                {item.patient_status === "Abandoned"
                                  ? "Paused"
                                  : item.patient_status}
                              </label>
                            </td>
                            {isMobile ? (
                              <td className="py-4">
                                <div
                                  onClick={() => {
                                    setIsModalType(ACTION_POPUP);
                                    setIsCommonModal(true);
                                    setSelectedClient(item);
                                  }}
                                  className="flex items-center justify-center cursor-pointer"
                                >
                                  <img src={VerticalDotIcon} />
                                </div>
                              </td>
                            ) : (
                              <>
                                <td className="text-sm font-medium text-gray-700 py-4">
                                  {item.session_count}
                                </td>
                                <td className="py-4 space-x-2">
                                  {visibleConditions?.map(
                                    (condition, index) => {
                                      return (
                                        <label
                                          key={index}
                                          className="bg-white rounded-lg border border-solid border-gray-200 py-1.5 px-3 text-xs font-medium inline-block"
                                        >
                                          <div className="text-ellipsis whitespace-nowrap max-w-32 overflow-hidden">
                                            {condition.label}
                                          </div>
                                        </label>
                                      );
                                    }
                                  )}
                                  {hiddenConditions?.length > 0 && (
                                    <span className="text-gray-700 text-xs font-medium relative -top-1">
                                      {`+${hiddenConditions.length}`}
                                    </span>
                                  )}
                                </td>
                                <td className="py-4">
                                  <div className="flex items-center space-x-4">
                                    <img
                                      src={UserIcon}
                                      className="w-6 h-6 cursor-pointer"
                                      onClick={() => handleOpenPanel(item)}
                                    />
                                    <a
                                      href={`/therapist/messages/?id=${item.id}`}
                                    >
                                      <img
                                        src={MessageIcon}
                                        className="w-6 h-6 cursor-pointer"
                                      />
                                    </a>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
              {clientsList?.length > 0 && (
                <div className="flex items-center justify-between py-4 border-t border-t-solid border-t-gray-200">
                  <div
                    onClick={() =>
                      activePage == 1 ? {} : setActivePage(activePage - 1)
                    }
                    className={`${
                      activePage === 1
                        ? "text-gray-400 stroke-gray-400"
                        : "cursor-pointer stroke-gray-900"
                    } border border-solid border-gray-300 flex items-center lg:space-x-2 py-2 px-3.5 rounded-lg`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M15.8332 9.99984H4.1665M4.1665 9.99984L9.99984 15.8332M4.1665 9.99984L9.99984 4.1665"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="text-sm font-medium hidden lg:block">
                      Previous
                    </div>
                  </div>

                  <div className="flex items-center">
                    {Array.from({ length: totalPages }, (_, index) => {
                      const pageNumber = index + 1;
                      const selectedPage = activePage === pageNumber;
                      return (
                        <div
                          key={index}
                          onClick={() => setActivePage(pageNumber)}
                          className={`text-sm font-medium ${
                            selectedPage
                              ? "bg-blue-100 text-blue-500"
                              : "bg-white text-gray-500"
                          } rounded-lg w-10 h-10 justify-center items-center flex cursor-pointer`}
                        >
                          {pageNumber}
                        </div>
                      );
                    })}
                  </div>
                  <div
                    onClick={() =>
                      activePage == totalPages
                        ? {}
                        : setActivePage(activePage + 1)
                    }
                    className={`${
                      activePage == totalPages
                        ? "text-gray-400 stroke-gray-400"
                        : "cursor-pointer stroke-gray-900"
                    } border border-solid border-gray-300 flex items-center lg:space-x-2 py-2 px-3.5 rounded-lg`}
                  >
                    <div className="text-sm font-medium hidden lg:block">
                      Next
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M4.1665 9.99984H15.8332M15.8332 9.99984L9.99984 4.1665M15.8332 9.99984L9.99984 15.8332"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        isOpen={panel}
        onRequestClose={handleClosePanel}
        style={panelStyles}
        className={`${
          panel ? "translate-x-0" : "translate-x-full"
        } w-full max-w-96 transform transition-transform fixed right-0 top-[4.5rem] bottom-0 outline-none bg-white shadow-calendarPopup`}
      >
        <>
          <div className="p-6 bg-blue-50">
            <div className="flex justify-end space-x-4">
              <img
                src={cross}
                onClick={handleClosePanel}
                className="cursor-pointer"
              />
            </div>
            {selectedClient?.session_count ? (
              <div className="px-2 py-0.5 bg-blue-100 rounded-2xl text-blue-600 text-xxs font-semibold max-w-max flex items-center">
                Total Sessions: {selectedClient?.session_count}
              </div>
            ) : (
              <div className="px-2 py-0.5 bg-blue-100 rounded-2xl text-blue-600 text-xxs font-semibold max-w-max flex items-center">
                Total Sessions: 0
              </div>
            )}
            <div className="flex justify-between mt-2">
              <div className="text-xl font-medium ">
                {selectedClient?.full_name}
              </div>
              <button
                type="button"
                onClick={() => {
                  setAddNoteModalFlag(true);
                }}
                className="border-none shadow-none flex bg-blue-600 items-center space-x-1 py-1 px-2 border rounded text-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="w-4 h-4"
                >
                  <path
                    d="M10.0001 4.16675V15.8334M4.16675 10.0001H15.8334"
                    stroke="#FFFFFF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="text-white">Add Note</span>
              </button>
            </div>
          </div>
          {assessmentResponse ? (
            <div className="p-6 pb-0">
              <img
                src={arrowBack}
                onClick={() => setAssessmentResponse(!assessmentResponse)}
                className="cursor-pointer"
              />
              <div className="mt-3 font-medium">
                Client’s Assessment Response
              </div>
              {selectedClient?.assessment?.results.length > 0 ? (
                <ul className="space-y-3">
                  {selectedClient?.assessment?.results?.map((item, index) => {
                    return (
                      <li
                        className="px-4 py-3 rounded-2xl shadow-calendarPopupInner"
                        key={index}
                      >
                        <span className="text-sm text-gray-600">
                          {item.question_title}
                        </span>
                        <div className="mt-0.5">{item.answer_title}</div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div className="text-sm text-gray-500 py-8 text-center">
                  No Assessment Response available
                </div>
              )}
            </div>
          ) : viewHistory ? (
            <div className="p-6 pb-0">
              <img
                src={arrowBack}
                onClick={() => setViewHistory(!viewHistory)}
                className="cursor-pointer"
              />
              <div className="mt-3 font-medium">Session History</div>
              <Tabs className="w-full plane-tabs mt-3">
                <TabList className="flex space-x-2">
                  <Tab className="px-2 py-1 text-sm text-gray-400 rounded-3xl font-medium border border-gray-200 flex items-center cursor-pointer">
                    Past
                  </Tab>
                  <Tab className="px-2 py-1 text-sm text-gray-400 rounded-3xl font-medium border border-gray-200 flex items-center cursor-pointer">
                    Upcoming
                  </Tab>
                </TabList>

                <TabPanel className="mt-6">
                  {history?.past?.length > 0 && (
                    <div className="space-y-2">
                      {history?.past.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="px-3 py-2 border border-gray-200 rounded-lg"
                          >
                            <div className="text-sm font-medium text-gray-700 flex items-center">
                              Session {item.sr_num}
                              <img
                                className="ml-1"
                                src={
                                  item.status === "cancelled"
                                    ? cancelledIcon
                                    : item.status === "no-show"
                                    ? noShowIcon
                                    : completedIcon
                                }
                              />
                            </div>
                            <div className="flex justify-between text-sm mt-0.5">
                              <div className="text-gray-600">
                                {item.session_date.slice(0, 3)},{" "}
                                {item.session_day} {item.session_month} ⋅{" "}
                                {removeZeroTime(item.start_time)} –{" "}
                                {removeZeroTime(item.end_time)}
                              </div>
                              <span
                                className={`${
                                  item.status === "completed"
                                    ? "text-success-900"
                                    : item.status === "no-show"
                                    ? "text-gray-500"
                                    : "text-danger-900"
                                } capitalize `}
                              >
                                {item.status === "no-show"
                                  ? "No Show"
                                  : item.status}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TabPanel>
                <TabPanel className="mt-6">
                  {history?.upcoming?.length > 0 && (
                    <div className="space-y-2">
                      {history?.upcoming.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={`${
                              item.therapist_verification &&
                              item.status !== "cancelled"
                                ? "bg-blue-600"
                                : ""
                            } ${
                              item.status === "cancelled"
                                ? "border-gray-200"
                                : "border-blue-500"
                            } px-3 py-2 border rounded-lg`}
                          >
                            <div
                              className={`${
                                item.therapist_verification &&
                                item.status !== "cancelled"
                                  ? "text-blue-100"
                                  : "text-gray-700"
                              }  text-sm font-medium flex items-center`}
                            >
                              Session {item.sr_num}
                              <img
                                className="ml-1"
                                src={
                                  item.status === "cancelled"
                                    ? cancelledIcon
                                    : completedIcon
                                }
                              />
                            </div>
                            <div className="flex justify-between text-sm mt-0.5">
                              <div
                                className={
                                  item.therapist_verification &&
                                  item.status !== "cancelled"
                                    ? "text-white/80"
                                    : "text-gray-600"
                                }
                              >
                                {item.session_date.slice(0, 3)},{" "}
                                {item.session_day} {item.session_month} ⋅{" "}
                                {removeZeroTime(item.start_time)} –{" "}
                                {removeZeroTime(item.end_time)}
                              </div>
                              <span
                                className={`${
                                  item.status === "completed"
                                    ? "text-success-900"
                                    : item.status === "cancelled"
                                    ? "text-danger-900"
                                    : item.therapist_verification
                                    ? "text-white/80"
                                    : "text-gray-600"
                                } capitalize`}
                              >
                                {item.status}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TabPanel>
              </Tabs>
            </div>
          ) : viewNotes ? (
            <div className="p-6 pb-0">
              <img
                src={arrowBack}
                onClick={() => setViewNotes(!viewNotes)}
                className="cursor-pointer"
              />
              <div className="mt-3 font-medium flex">Notes</div>
              {/* client notes */}
              {notes && notes.length ? (
                notes.map((note, index) => (
                  <div key={index} className="space-y-2 mt-3">
                    <div className="px-3 py-2 border border-gray-200 rounded-lg">
                      <div className="text-sm font-medium text-gray-700 items-center whitespace-pre-line break-all">
                        {note.content}
                      </div>
                      <div className="text-[10px] text-gray-500">
                        {calculateTimeDifference(note.created_at)}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-sm text-gray-500 py-8 text-center">
                  No Notes available.
                </div>
              )}
            </div>
          ) : (
            <div className="p-6">
              <div className="px-4 py-3 rounded-2xl shadow-calendarPopupInner mt-3">
                {(selectedClient?.gender ||
                  selectedClient?.age ||
                  selectedClient?.country ||
                  selectedClient?.medical_conditions) && (
                  <div className="mb-3">
                    <span className="text-sm text-gray-600 flex items-center">
                      <img src={sparkle} className="mr-2" />
                      About the Client
                    </span>

                    <ul className="font-medium text-sm flex divide-x divide-x-gray-200 mt-2">
                      {selectedClient?.gender && (
                        <li className="px-2 py-1">
                          {selectedClient?.age} {selectedClient?.gender}
                        </li>
                      )}
                      {selectedClient?.country && (
                        <li className="px-2 py-1">{selectedClient?.country}</li>
                      )}
                      {selectedClient?.medical_conditions?.[0]?.label && (
                        <li className="px-2 py-1">
                          {selectedClient?.medical_conditions?.[0]?.label}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                <div
                  className="text-sm text-gray-600 flex justify-between px-2 py-1.5 cursor-pointer"
                  onClick={() => setAssessmentResponse(!assessmentResponse)}
                >
                  <span>Read Client’s Assessment Response </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M12.1132 10.0002L7.74404 5.60119C7.41865 5.27306 7.41865 4.74106 7.74404 4.41293C8.06943 4.0848 8.59699 4.0848 8.92237 4.41293L13.8806 9.40594C14.2061 9.73412 14.2061 10.2662 13.8806 10.5944L8.92237 15.5874C8.59699 15.9155 8.06943 15.9155 7.74404 15.5874C7.41865 15.2593 7.41865 14.7273 7.74404 14.3991L12.1132 10.0002Z"
                      fill="#667085"
                    />
                  </svg>
                </div>
              </div>
              <div
                className="flex justify-between px-4 py-3 rounded-2xl shadow-calendarPopupInner mt-3 cursor-pointer"
                onClick={() => setViewHistory(!viewHistory)}
              >
                <span className="text-sm text-gray-600">
                  View Session History
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M12.1132 10.0002L7.74404 5.60119C7.41865 5.27306 7.41865 4.74106 7.74404 4.41293C8.06943 4.0848 8.59699 4.0848 8.92237 4.41293L13.8806 9.40594C14.2061 9.73412 14.2061 10.2662 13.8806 10.5944L8.92237 15.5874C8.59699 15.9155 8.06943 15.9155 7.74404 15.5874C7.41865 15.2593 7.41865 14.7273 7.74404 14.3991L12.1132 10.0002Z"
                    fill="#667085"
                  />
                </svg>
              </div>
              <div
                className="flex justify-between px-4 py-3 rounded-2xl shadow-calendarPopupInner mt-3 cursor-pointer"
                onClick={() => setViewNotes(!viewNotes)}
              >
                <span className="text-sm text-gray-600">View Notes</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M12.1132 10.0002L7.74404 5.60119C7.41865 5.27306 7.41865 4.74106 7.74404 4.41293C8.06943 4.0848 8.59699 4.0848 8.92237 4.41293L13.8806 9.40594C14.2061 9.73412 14.2061 10.2662 13.8806 10.5944L8.92237 15.5874C8.59699 15.9155 8.06943 15.9155 7.74404 15.5874C7.41865 15.2593 7.41865 14.7273 7.74404 14.3991L12.1132 10.0002Z"
                    fill="#667085"
                  />
                </svg>
              </div>
            </div>
          )}
        </>
      </Modal>

      <Modal
        isOpen={isCommonModal}
        onRequestClose={() => {
          setIsCommonModal(false);
        }}
        style={commonModalStyles}
        className={`${
          isMobile && isCommonModal
            ? "translate-y-0"
            : "translate-y-full lg:translate-y-0"
        } w-full transform transition-transform fixed outline-none`}
      >
        <TherapistClientsFilter
          isModalType={isModalType}
          setFilters={setAppliedFilters}
          appliedFilters={appliedFilters}
          setIsCommonModal={setIsCommonModal}
          setSelectedSorting={setSelectedSorting}
          selectedSorting={selectedSorting}
          countryOptions={countries}
          selectedClient={selectedClient}
          handleOpenPanel={handleOpenPanel}
          setActivePage={setActivePage}
        />
      </Modal>

      <AddNote
        isOpen={addNoteModalFlag}
        onRequestClose={handleCloseAddNote}
        clientId={selectedClient?.id}
      ></AddNote>
    </>
  );
}
