import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setNewPassword,
  setNewTherapistPassword,
} from "../../redux/app/appThunks";
import Header from "../../components/molecules/Header";
import { useNavigate } from "react-router-dom";
import Form from "../../components/molecules/Form";
import Input from "../../components/atoms/Input";
import Button from "../../components/atoms/Button";
import passwordIcon from "../../assets/passcode-lock.svg";

const passwordPattern =
  process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "dev"
    ? /.*/
    : /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export default function SetPassword({ app }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get("token");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    if (app === "THERAPIST") {
      navigate(`/therapist/login`);
    } else {
      navigate(`/login`);
    }
  };
  const handleConfirmPassword = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!password) {
      validationErrors.passwordError = "Password is required";
    } else if (!passwordPattern.test(password)) {
      validationErrors.passwordError = "Password is not valid";
    }
    if (!confirmPassword) {
      validationErrors.confirmPasswordError = "Password is required";
    } else if (!passwordPattern.test(confirmPassword)) {
      validationErrors.confirmPasswordError = "Password is not valid";
    }

    if (e.target.password.value !== e.target.confirm_password.value) {
      validationErrors.confirmPasswordError = "Passwords do no match";
    }

    if (Object.keys(validationErrors).length === 0) {
      // No validation errors, proceed with dispatch

      dispatch(
        app === "THERAPIST"
          ? setNewTherapistPassword({
            password: e.target.password.value,
            token,
          })
          : setNewPassword({
            password: e.target.password.value,
            token,
          })
      );
    } else {
      // Set validation errors in state
      setErrors(validationErrors);
    }
  };

  return (
    <div className="h-100">
      <Header />
      <div className="flex flex-col lg:flex-row h-full px-4 pt-16 lg:pt-6 relative lg:px-10">
        <div className="flex justify-center w-full pb-24 lg:pb-0">
          <div className="lg:w-96 w-full">
            <Form
              heading="Set password"
              peragraph="Please set your password"
              method="POST"
              onSubmit={handleConfirmPassword}
              className="mt-6"
            >
              <Input
                icon={passwordIcon}
                label="New Password"
                placeholder="&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                inputClassName="placeholder:text-xs"
                error={errors.passwordError}
                app={app}
              />

              <Input
                icon={passwordIcon}
                label="Confirm Password"
                placeholder="&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;"
                type="password"
                name="confirm_password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                inputClassName="placeholder:text-xs"
                className="mt-6"
                error={errors.confirmPasswordError}
                app={app}
              />

              <Button
                type="submit"
                className={`${app === "THERAPIST"
                  ? "bg-blue-900 hover:bg-blue-500"
                  : "bg-teal-900 hover:bg-teal-500"
                  }  hover:shadow-button transition-all mt-10 text-white`}
                text="Confirm Password"
                full
              />
            </Form>
          </div>
        </div>
        <button
          onClick={handleLogin}
          className="absolute lg:shadow-none w-12 h-12 flex justify-center items-center top-0 lg:top-6 rounded-full drop-shadow"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.41379 12L14.6568 17.2788C15.0473 17.6725 15.0473 18.3109 14.6568 18.7047C14.2663 19.0984 13.6333 19.0984 13.2428 18.7047L7.29289 12.7131C6.90237 12.3192 6.90237 11.6807 7.29289 11.2869L13.2428 5.29532C13.6333 4.90156 14.2663 4.90156 14.6568 5.29532C15.0473 5.68907 15.0473 6.32748 14.6568 6.72124L9.41379 12Z"
              fill="#101828"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
