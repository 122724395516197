import React, { useEffect } from "react";
import Loader from "../../components/atoms/Loader";
import { useLocation, useNavigate } from "react-router-dom";

export default function WpRedirect() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  window.localStorage.setItem("wp-redirect-user", true);
  const userEmail = params.get("email");
  window.localStorage.setItem("user-email", userEmail);
  useEffect(() => {
    navigate("/login", {
      state: { userEmail },
    });
  }, [userEmail]);

  return (
    <div className="h-100">
      <Loader />
    </div>
  );
}
