// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav_main {
  /* background-color: var(--primary); */
  /* box-shadow: var(--boxshadow-soft); */
  /* z-index: 99; */
  /* position: sticky; */
  height: 70px;
  width: 100%;
}

.nav_content {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.header_main {
  box-shadow: var(--boxshadow-soft);
  background-color: #fff;
  height: 8vh;
  /* position: absolute; */
  width: 100%;
  z-index: 2;
}

.header_content {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
}

.logoutButton {
  color: var(--app-red);
  background: none;
  box-shadow: none;
  font-weight: bold;
}

.bottom-nav {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(230, 230, 230);
}

.checkHeight {
  max-height: calc(calc(var(--vh, 1vh) * 100) - 140px);
  height: calc(calc(var(--vh, 1vh) * 100) - 140px);
  width: 100%;
}

.checkHeighDesktop {
  max-height: calc(100vh - 70px);
  height: calc(100vh - 70px);
  width: 100%;
}

.fullHeightCheck {
  height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/styles/navbar.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,uCAAuC;EACvC,iBAAiB;EACjB,sBAAsB;EACtB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,iCAAiC;EACjC,sBAAsB;EACtB,WAAW;EACX,wBAAwB;EACxB,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,oDAAoD;EACpD,gDAAgD;EAChD,WAAW;AACb;;AAEA;EACE,8BAA8B;EAC9B,0BAA0B;EAC1B,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":[".nav_main {\n  /* background-color: var(--primary); */\n  /* box-shadow: var(--boxshadow-soft); */\n  /* z-index: 99; */\n  /* position: sticky; */\n  height: 70px;\n  width: 100%;\n}\n\n.nav_content {\n  display: flex;\n  padding: 10px;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.header_main {\n  box-shadow: var(--boxshadow-soft);\n  background-color: #fff;\n  height: 8vh;\n  /* position: absolute; */\n  width: 100%;\n  z-index: 2;\n}\n\n.header_content {\n  display: flex;\n  padding: 10px 20px;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.logoutButton {\n  color: var(--app-red);\n  background: none;\n  box-shadow: none;\n  font-weight: bold;\n}\n\n.bottom-nav {\n  width: 100%;\n  height: 70px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-top: 1px solid rgb(230, 230, 230);\n}\n\n.checkHeight {\n  max-height: calc(calc(var(--vh, 1vh) * 100) - 140px);\n  height: calc(calc(var(--vh, 1vh) * 100) - 140px);\n  width: 100%;\n}\n\n.checkHeighDesktop {\n  max-height: calc(100vh - 70px);\n  height: calc(100vh - 70px);\n  width: 100%;\n}\n\n.fullHeightCheck {\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
