import { createSlice } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
// import { getErrorDetailsforNotif } from "../../services/globalFunctions";
import {
  applyCoupon,
  createPaymentIntent,
  getPaymentStatus,
  getQuestionnaire,
  getRecommendations,
  getAssessmentsSummary,
  getTherapistFromSlug,
  getTherapistPackageFromID,
  patchOnboardingStatus,
  patchOnboardingStatusTherapist,
  submitAnswer,
  getTherapistProfile,
  submitTherapistProfile,
  getPackageDetails,
  getCheckoutDetails,
  getUserNotification,
} from "./onboardingThunks";

const initialState = {
  questionnaire_dict: null,
  questionnaire_done: [],
  profile_done: [],
  recommendations: null,
  summary: null,
  payment: null,
  selected_therapist: null,
  payment_status: null,
  onboarding_therapist: null,
  onboarding_therapist_package: null,
  therapist_profile: null,
  selected_package: null,
  checkout_details: null,
  notifications: null,
  unreadNotification: 0,
  unreadMsgNotification: 0,
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    clearDict: (state) => {
      state.questionnaire_dict = null;
    },
    clearProfile: (state) => {
      state.therapist_profile = null;
    },
    setTherapist: (state, action) => {
      state.selected_therapist = action.payload;
    },
  },
  extraReducers: {
    [getQuestionnaire.fulfilled]: (state, { payload }) => {
      state.questionnaire_dict = payload;
    },
    [getQuestionnaire.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [submitAnswer.fulfilled]: (state, { payload }) => {
      state.questionnaire_done.push(payload);
    },
    [submitAnswer.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getRecommendations.fulfilled]: (state, { payload }) => {
      state.recommendations = payload;
    },
    [getAssessmentsSummary.fulfilled]: (state, { payload }) => {
      state.summary = payload;
    },
    [submitAnswer.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [createPaymentIntent.fulfilled]: (state, { payload }) => {
      state.payment = payload;
      // localStorage.setItem("fitcy_onboarding_payment_id", payload.payment.id);
      // localStorage.setItem(
      //   "fitcy_onboarding_client_secret",
      //   payload.setup_intent.client_secret
      // );
    },
    [createPaymentIntent.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [applyCoupon.fulfilled]: (state, { payload }) => {
      state.payment.payment = payload;
    },
    [applyCoupon.rejected]: () => {
      // toast.error("Invalid Coupon");
    },
    [getPaymentStatus.fulfilled]: (state, { payload }) => {
      state.payment_status = payload;
    },
    [getPaymentStatus.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },

    [patchOnboardingStatus.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [patchOnboardingStatusTherapist.fulfilled]: () => {
      window.location.replace("/therapist/sessions");
    },
    [patchOnboardingStatusTherapist.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getTherapistFromSlug.fulfilled]: (state, action) => {
      state.onboarding_therapist = action.payload;
    },
    [getTherapistFromSlug.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getTherapistPackageFromID.fulfilled]: (state, action) => {
      state.onboarding_therapist_package = action.payload;
    },
    [getTherapistPackageFromID.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getTherapistProfile.fulfilled]: (state, { payload }) => {
      state.therapist_profile = payload;
    },
    [getTherapistProfile.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [submitTherapistProfile.fulfilled]: (state, { payload }) => {
      state.profile_done.push(payload);
      // toast.success("Profile updated.");
    },
    [submitTherapistProfile.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getPackageDetails.fulfilled]: (state, { payload }) => {
      state.selected_package = payload;
    },
    [getPackageDetails.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getCheckoutDetails.fulfilled]: (state, { payload }) => {
      state.checkout_details = payload;
    },
    [getCheckoutDetails.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getUserNotification.fulfilled]: (state, action) => {
      state.notifications = action.payload.data;
      state.unreadNotification = action.payload.unread_count;
      state.unreadMsgNotification = action.payload.unread_msg_count;
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearDict, setTherapist, clearProfile } =
  onboardingSlice.actions;

export default onboardingSlice.reducer;
