import { Avatar, Box, Text } from "grommet";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRTMToken } from "../../../redux/agora/agoraThunks";
import Loader from "../../../components/atoms/Loader";
import Conversations from "./Conversations";
import { RTMInstance as rtm } from "./rtm-client";
import {
  getMessageHistory,
  patchSingleMessage,
} from "../../../redux/patient/patientThunks";
import moment from "moment";
import * as $ from "jquery";
// import { useMediaQuery } from "react-responsive";
const appId = process.env.REACT_APP_AGORA_APP_ID; //ENTER APP ID HERE
import noAvatar from "../../../assets/noAvatar.jpeg";
import { useMediaQuery } from "react-responsive";

export default function Messages() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.agora.rtmToken);
  const user = useSelector((state) => state.app.user);
  const data = useSelector((state) => state.patient.messageHistory);
  const [logined, setLogined] = useState();
  // const [popCount, setPopCount] = useState(0);

  useEffect(() => {
    if (rtm.logined) setLogined(true);
  }, [rtm.logined]);
  useEffect(() => {
    if (!token)
      dispatch(
        getRTMToken({
          user_id: user.id,
        })
      );
  }, []);

  const peerID = user.id;
  const peerName =
    user.current_therapist.first_name + " " + user.current_therapist.last_name;

  const updateScroll = () => {
    const element = document.getElementById("log");

    if (element) element.scrollTop = element.scrollHeight;
  };
  const uid = user.id;

  // const [lastMessage, setLastMessage] = useState();

  // const addConversation = (message) => {
  //     setConversations({ ...conversations, [peerID]: message });
  // };

  useEffect(() => {
    rtm.on("ChannelMessage", async (data) => {
      const msgObj = {
        message: data.args[0],
        from: data.args[1],
        timestamp: data.args[2].serverReceivedTs,
      };

      $("#log").append(
        `<div class="messageWrapperRecieve"><div class="recievedMessage" ><div class="messageSender">${peerName}
                </div>${
                  msgObj.message.text
                }</div><div class="timeStampMessageRecieve">${moment().format(
          "MM/DD/YY hh:mm a"
        )}</div></div>`
      );
      updateScroll();
      // setLastMessage(msgObj);
    });

    rtm.on("ConnectionStateChanged", async (data, data2) => {
      if (data === "ABORTED" && data2 === "REMOTE_LOGIN") {
        rtm.logined = false;
      }
    });
  }, []);

  // useEffect(() => {
  //     if (lastMessage) {
  //         addConversation(lastMessage);
  //     }
  // }, [lastMessage]);

  useEffect(() => {
    if (!rtm.logined && token) {
      try {
        rtm.init(appId);
        window.rtm = rtm;
        rtm
          .login(uid.toString(), token)
          .then(() => {
            rtm.logined = true;
            setLogined(true);
            rtm.joinChannel(peerID.toString());
            loadMessageHistory(peerID);
          })
          .catch((err) => {
            console.log("here we go:", err);
          });
      } catch (err) {
        console.error("catch we go:", err);
      }
    }
  }, [token, rtm.logined]);

  const loadMessageHistory = (peerid) => {
    dispatch(
      getMessageHistory({
        patient_id: peerid,
        therapist_id: user.current_therapist.id,
      })
    );
  };

  if (data) {
    // setPopCount(1);
    if (data) {
      $("#log").empty();
      // const datainverted = _.cloneDeep(data);
      // datainverted.reverse();

      data.forEach((element) => {
        if (
          (element.message_type === "text" ||
            element.message_type === "TEXT") &&
          (element.sender === user.current_therapist.id ||
            element.sender === user.id)
        ) {
          if (element.sender === user.id)
            $("#log").append(
              `<div class="messageWrapperSend"><div class="timeStampMessageSend">${moment(
                element.created
              ).format("MM/DD/YY hh:mm a")}</div><div class="sentMessage" >${
                element.message
              }</div></div>`
            );
          else {
            $("#log").append(
              `<div class="messageWrapperRecieve"><div class="recievedMessage" ><div class="messageSender">${
                element.sender === user.current_therapist.id
                  ? peerName
                  : element.sender
              }</div>${
                element.message
              }</div><div class="timeStampMessageRecieve">${moment(
                element.created
              ).format("MM/DD/YY hh:mm a")}</div></div>`
            );
            if (element.read_status === false)
              dispatch(
                patchSingleMessage({ id: element.id, read_status: true })
              );
          }
        }
      });
      updateScroll();
    }
  }
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  if (!token) return <Loader />;

  return (
    <Box
      style={{
        // minHeight: "100%",
        width: "100vw",
        // marginBottom: isTabletOrMobile ? "65px" : null,
      }}
      alignSelf="start"
      align="start"
      direction="row"
      height="100%"
      justify="start"
      gap="small"
      // background="black"
    >
      {isTabletOrMobile ? null : (
        <Box
          style={{
            borderRight: "1px solid #80808020",
            flexShrink: "0",
          }}
          height="100%"
          gap="medium"
        >
          <Box pad="medium" style={{ minWidth: "250px" }}>
            <Text weight="bold" className="ptFont" size="large">
              Messages
            </Text>
          </Box>
          <Box
            gap="small"
            style={{ cursor: "pointer" }}
            background="white"
            pad="small"
            direction="row"
          >
            <Avatar
              style={{ flexShrink: "0" }}
              size="medium"
              src={
                user.current_therapist.dp ? user.current_therapist.dp : noAvatar
              }
            />
            <Box
              style={{ marginLeft: isTabletOrMobile ? "12px" : null }}
              gap={isTabletOrMobile ? "small" : "xsmall"}
            >
              <Text size={isTabletOrMobile ? "small" : "small"}>
                {" "}
                {user.current_therapist.first_name +
                  " " +
                  user.current_therapist.last_name}
              </Text>
              {/* insert last message here  */}
              <Text
                color="#009688"
                weight="bold"
                size={isTabletOrMobile ? "xsmall" : "xsmall"}
                style={{
                  whiteSpace: "nowrap",
                  maxWidth: "300px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {" "}
                ....
              </Text>
            </Box>
          </Box>
        </Box>
      )}
      {!logined ? null : (
        <Conversations
          uid={uid}
          peerID={peerID}
          peerName={peerName}
          therapist={user.current_therapist}
        />
      )}
    </Box>
  );
}
