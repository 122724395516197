import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../atoms/Heading";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardSection from "./CardSection";
import SecurityInfoIcon from "../../assets/security-info.png";
import stripe from "../../assets/stripe.png";
import BlueIcon from "../../assets/blue-tier-ico.svg";
import GoldIcon from "../../assets/Gold-tier-ico.svg";
import SilverIcon from "../../assets/silver-tier-ico.svg";
import VideRecorderLarge from "../../assets/video-recorder-large.svg";
import VideRecorder from "../../assets/video-session-icon.svg";
import clock from "../../assets/clock-dark-gray.svg";
import Button from "../atoms/Button";
import { useMediaQuery } from "react-responsive";
import {
  getPackageDetails,
  getCheckoutDetails,
} from "../../redux/onboarding/onboardingThunks";
import moment from "moment-timezone";
import { MetaTags } from "../../services/globalFunctions";

const Checkout = ({ setStep }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.app.user);

  const checkoutDetails = useSelector(
    (state) => state.onboarding.checkout_details
  );
  const scheduledAppointment = checkoutDetails?.data.package;
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  // const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  useEffect(() => {
    const fetchData = async () => {
      dispatch(getCheckoutDetails());
    };
    fetchData();
  }, []);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleChangeBooking = async () => {
    try {
      const data = await dispatch(getCheckoutDetails());
      // After getCheckoutDetails completes, you can access the updated state
      if (data?.error) {
        setStep(3);
      } else {
        dispatch(
          getPackageDetails({
            package_id: checkoutDetails?.data?.package?.package_id,
          })
        );
        setStep(4);
      }
    } catch (error) {
      // Handle any errors that might occur during getCheckoutDetails
      console.error("Error fetching checkout details:", error);
      // Optionally, you can setStep(3) or handle the error in a different way
    }
  };
  return (
    <div className="lg:flex min-h-[calc(100vh-4.5rem)]">
      <MetaTags
        title="Checkout | Fitcy Health"
        description="You're just one step away from unlocking your mental wellness journey!"
        url="/resources"
      />
      <section className="pb-24 pt-12 lg:py-14 px-4 flex-1 relative">
        <div className="max-w-lg w-full mx-auto">
          <Heading variant="h1" className="text-2xl font-medium">
            Order Details
          </Heading>
          <div className="mt-6 lg:mt-10">
            <Heading variant="h2" className="text-gray-400 font-medium">
              Session Information
            </Heading>
            <div className="bg-white rounded-2xl shadow-therapistCard mt-3">
              <div className="p-4 flex flex-col lg:flex-row justify-between lg:space-x-6 items-start">
                <div className="flex space-x-4">
                  <div
                    className="p-4 rounded-2xl font-semibold text-teal-900"
                    style={{
                      backgroundImage: `linear-gradient(118deg, #D7FDF9 8.09%, #FFE1BC 130.78%)`,
                    }}
                  >
                    <img src={VideRecorderLarge} className="mb-2" />
                    {scheduledAppointment?.product_count}{" "}
                    <div className="leading-3 uppercase">
                      {scheduledAppointment?.product_count > 1
                        ? `Credits`
                        : `Credit`}
                    </div>
                  </div>
                  <div className="py-2 flex-1">
                    {scheduledAppointment ? (
                      <div className="flex items-center space-x-1">
                        <h3 className="font-medium text-lg">
                          {scheduledAppointment?.product_count > 1
                            ? `Bundle Package`
                            : scheduledAppointment?.product_name}
                        </h3>
                        <img
                          src={
                            checkoutDetails?.data?.therapist?.tier === "BLUE"
                              ? BlueIcon
                              : checkoutDetails?.data?.therapist?.tier ===
                                "GOLD"
                                ? GoldIcon
                                : SilverIcon
                          }
                        />
                      </div>
                    ) : (
                      <div className="text-lg font-medium">
                        No Session selected
                      </div>
                    )}

                    <div className="text-xs text-gray-500 flex space-x-1.5 mt-1.5">
                      <span className="font-semibold">
                        {scheduledAppointment?.product_count}x
                      </span>{" "}
                      <img src={VideRecorder} />{" "}
                      <span className="font-normal">Session credit</span>
                    </div>
                    <div className="flex uppercase font-medium text-gray-500 text-xs space-x-2 mt-2">
                      <p className="flex items-center space-x-1">
                        <img src={clock} />{" "}
                        <span>{scheduledAppointment?.slot_minutes} mins</span>
                      </p>
                      <span className="text-gray-400">|</span>
                      <span>
                        <span className="text-gray-400">Validity:</span>{" "}
                        {scheduledAppointment?.validity_months}{" "}
                        {scheduledAppointment?.validity_months > 1
                          ? "Months"
                          : "Month"}
                      </span>
                    </div>
                    {/* <div className="text-gray-500 text-sm">
                    {scheduledAppointment?.slot_name}
                  </div>
                  {scheduledAppointment?.validity && (
                    <div className="text-normal text-sm">
                      <span className="text-gray-400">Validity:</span>{" "}
                      {new Date(
                        scheduledAppointment?.validity
                      )?.toLocaleDateString(undefined, {
                        timeZone: user.timezone,
                        month: "short", // abbreviated month name
                        day: "numeric", // numeric day of the month
                        year: "numeric", // numeric day of the month
                      })}
                    </div>
                  )} */}
                  </div>
                </div>

                <div className="flex items-center text-2xl space-x-2 font-medium mt-6 lg:mt-2">
                  {/* {scheduledAppointment?.crossed_price > 0 && (
                    <span className="text-gray-400 text-right line-through w-max">
                      ${scheduledAppointment?.crossed_price}
                    </span>
                  )} */}
                  <span>${scheduledAppointment?.grand_total ?? "0"}</span>
                </div>
              </div>
              <div className="p-4 flex flex-col lg:flex-row flex-wrap gap-2">
                {checkoutDetails?.data?.therapist?.first_name && (
                  <div className="pl-1 pr-3 py-0.5 bg-teal-50 rounded-3xl flex items-center text-sm font-medium max-w-max">
                    <img
                      src={
                        checkoutDetails?.data?.therapist?.profile_picture
                          ?.full_url
                      }
                      className="w-6 h-6 rounded-full mr-1.5"
                    />
                    {checkoutDetails?.data?.therapist?.first_name}{" "}
                    {checkoutDetails?.data?.therapist?.last_name}
                  </div>
                )}

                {scheduledAppointment?.start_time && (
                  <div className="px-3 py-1 bg-teal-50 rounded-3xl space-x-1.5 max-w-max">
                    <span className="text-sm font-medium">
                      {moment(scheduledAppointment?.start_time).format(
                        "ddd, DD MMM, h:mm a"
                      )}
                    </span>
                    <button
                      className="px-3 text-teal-900 border-solid  border-2 border-teal-900 bg-white rounded-3xl text-xs py-0.5 font-medium"
                      onClick={handleChangeBooking}
                    >
                      Change
                    </button>
                  </div>
                )}
              </div>
            </div>
            <p className="text-xs text-gray-400 mt-3 px-4">
              *Schedule next sessions in dashboard
            </p>
            <ul className="mt-10">
              <li className="py-3 space-y-6 border-t border-gray-200">
                {/* <div className="flex justify-between text-sm text-gray-500">
                  <span>Original Price</span>
                  <span>$ {scheduledAppointment?.original_price}</span>
                </div> */}
                <div className="flex justify-between text-sm text-gray-500">
                  <span>Package Price</span>
                  <span>$ {scheduledAppointment?.grand_total}</span>
                </div>
                {/* <div className="flex justify-between text-sm text-gray-500">
                  <span>Package Discount</span>
                  <span className="text-danger-900">
                    $ {scheduledAppointment?.package_discount}
                  </span>
                </div> */}
              </li>
              {/* <li className="py-3 space-y-6 border-t border-gray-200">
                <div className="flex justify-between text-sm text-gray-500">
                  <span>Subtotal</span>
                  <span>$ {scheduledAppointment?.subtotal}</span>
                </div>
              </li> */}
              <li className="py-3 space-y-6 border-t border-gray-200">
                <div className="flex justify-between text-medium">
                  <span>Total Due Today</span>
                  <span>$ {scheduledAppointment?.grand_total}</span>
                </div>
                {/* {scheduledAppointment?.package_discount > 0 && (
                  <div className="px-3 py-1.5 bg-teal-50 border border-teal-100 rounded-lg flex items-center text-xs font-medium max-w-max">
                    <span className="text-gray-500 mr-0.5">You saved:</span> $
                    {scheduledAppointment?.package_discount}
                  </div>
                )} */}
              </li>
            </ul>
          </div>
        </div>
        <ul className="flex py-4 lg:p-4 text-xxs lg:text-xs text-gray-400 space-x-2 lg:space-x-4 justify-center items-center lg:absolute bottom-0 inset-x-0 border-t border-t-gray-200">
          <li>
            <a href="#">
              <img src={stripe} />
            </a>
          </li>
          <li>
            <a href="https://fitcyhealth.com/privacy-policy/">Privacy policy</a>
          </li>
          <li>
            <a href="#">Cancellation policy</a>
          </li>
          <li>
            <a href="https://fitcyhealth.com/contact-us/">Contact us</a>
          </li>
        </ul>
      </section>
      <div
        className={`${isMobile && isOpen
          ? "translate-y-0"
          : "translate-y-full lg:translate-y-0"
          } w-full transform transition-transform fixed bottom-0 top-0 lg:static flex-1 lg:shadow-checkoutShadow lg:bg-white`}
      >
        {isMobile && isOpen && (
          <div
            className="bg-black/10 backdrop-blur-sm absolute inset-0"
            onClick={toggleModal}
          ></div>
        )}
        <section
          className={`${isMobile && isOpen ? "bg-white rounded-t-2xl fixed bottom-0" : ""
            } pt-6 pb-24 lg:py-14 px-4`}
        >
          <div className="max-w-lg w-full mx-auto">
            <button
              onClick={() => setIsOpen(false)}
              className="shadow-none absolute right-4 lg:hidden"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 6L6 18M6 6L18 18"
                  stroke="#667085"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <Heading variant="h1" className="text-2xl font-medium">
              Checkout
            </Heading>
            <Heading
              variant="h2"
              className="text-gray-500 text-sm font-medium mt-6 lg:mt-10"
            >
              Contact Information
            </Heading>
            <div className="p-3 border border-gray-200 rounded-xl mt-2">
              <div className="text-sm flex items-center w-full">
                <label className="text-gray-500 mr-4 flex items-center w-28 lg:w-16">
                  Email
                </label>
                <span>{user.username}</span>
              </div>
              <div className="text-sm flex items-center w-full mt-3 pt-3 border-t border-t-gray-200">
                <label className="text-gray-500 mr-4 flex items-center w-28 lg:w-16">
                  Phone
                </label>
                <span>{user.cell_number}</span>
              </div>
            </div>
            <div className="mt-6 lg:mt-10">
              <Elements stripe={stripePromise}>
                <CardSection
                  setStep={setStep}
                  toggleModal={toggleModal}
                  scheduledAppointment={scheduledAppointment}
                />
              </Elements>
            </div>
            <div className="flex space-x-5 p-4 rounded-2xl shadow-sm mt-4">
              <div className="w-16">
                <img src={SecurityInfoIcon} />
              </div>

              <div className="flex-1">
                {" "}
                <div className="font-medium">Worry-free Booking</div>
                <div className="mt-2 text-gray-500 text-xs">
                  {`It's free to cancel, reschedule or change your therapist up to 24
              hours before the session begins`}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {isMobile && !isOpen && (
        <div className="fixed bottom-0 lg:static w-full inset-x-0 px-4 py-6 lg:px-0 lg:py-0 bg-white shadow-payButton lg:shadow-none">
          <Button
            type="button"
            className={`w-full lg:mt-10 bg-teal-900 text-white hover:bg-teal-500`}
            onClick={toggleModal}
          >
            Pay <span className="text-white/80">& Book now</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Checkout;
