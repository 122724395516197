import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import {
  genderOptions,
  countriesOptions,
  nationalityOptions,
  languageOptions,
  religionOptions,
  hoursOfTherapyOptions,
  clientsExpOptions,
  clientsTypeOptions,
  certifiedBodiesOptions,
  approachesOptions,
  expertiseOptions,
  maxHoursOptions,
} from "../../services/utils/constants";
import {
  getTherapistProfile,
  submitTherapistProfile,
  deleteMedia,
} from "../../redux/onboarding/onboardingThunks";
import Heading from "../atoms/Heading";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import Steps from "../atoms/Steps";
import RadioButton from "../atoms/RadioButton";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/js/utils.js";
import "intl-tel-input/build/css/intlTelInput.css";
import User from "../../assets/user.svg";
import Phone from "../../assets/phone.svg";
import Pencil from "../../assets/edit.svg";
import Email from "../../assets/mail.svg";
import userLarge from "../../assets/user-large.svg";
import upload from "../../assets/upload.svg";
import moment from "moment";
import Tooltip from "../atoms/Tooltip";

const stepsTitles = [
  "Personal",
  "Demographics",
  "Professional",
  "Availability",
];
const firstNamePattern = /^[A-Za-z\s'-]+$/;
const lastNamePattern = /^[A-Za-z\s'-]+$/;
const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const RegistrationForm = ({
  app = "THERAPIST",
  stepCompleted,
  setStepCompleted,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);
  const [dobYear, dobMonth, dobDay] = (user?.date_of_birth ?? "").split("-");
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [email, setEmail] = useState(user.username);
  const [phoneNumber, setPhoneNumber] = useState(user.cell_number);
  const [bio, setBio] = useState(
    user.bio && user.bio != "null" ? user.bio : ""
  );
  const [errors, setErrors] = useState({});
  const [editingFirstName, setEditingFirstName] = useState(false);
  const [editingLastName, setEditingLastName] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingPhone, setEditingPhone] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(
    user?.profile_picture?.full_url || null
  );

  const [selectedDays, setSelectedDays] = useState([]);
  const [day, setDay] = useState(
    dobDay ? { value: dobDay, label: dobDay } : ""
  );
  const [month, setMonth] = useState(
    dobMonth ? { value: dobMonth, label: dobMonth } : ""
  );
  const [year, setYear] = useState(
    dobYear ? { value: dobYear, label: dobYear } : ""
  );
  const [selectedGender, setSelectedGender] = useState(
    user.gender
      ? {
          value: user.gender,
          label: user.gender,
        }
      : ""
  );
  const [selectedCountry, setSelectedCountry] = useState(
    user.country
      ? {
          value: user.country,
          label: user.country,
        }
      : ""
  );
  const [selectedNationality, setSelectedNationality] = useState(
    user.nationality
      ? {
          value: user.nationality,
          label: user.nationality,
        }
      : ""
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    user.primary_language
      ? {
          value: user.primary_language,
          label: user.primary_language,
        }
      : ""
  );
  const [selectedSecondaryLanguage, setSelectedSecondaryLanguage] = useState(
    user.secondary_language ? Object.values(user.secondary_language) : []
  );
  const [selectedReligion, setSelectedReligion] = useState(
    user.religious_belief && user.religious_belief != "null"
      ? {
          value: user.religious_belief,
          label: user.religious_belief,
        }
      : ""
  );
  const [selectedBookingOption, setSelectedBookingOption] = useState(
    user.same_day_book === true ? "yes" : "no"
  );
  const [selectedIntroductorySession, setSelectedIntroductorySession] =
    useState(user.inperson_therapy === true ? "yes" : "no");
  const [selectedTherapyOption, setSelectedTherapyOption] = useState(
    user.intro_session === true ? "yes" : "no"
  );
  const [hoursOfTherapy, setHoursOfTherapy] = useState(
    user.clinical_hours
      ? {
          value: user.clinical_hours,
          label: user.clinical_hours,
        }
      : ""
  );
  const [startingYear, setStartingYear] = useState(
    user.years_of_experience
      ? {
          value: user.years_of_experience,
          label: user.years_of_experience,
        }
      : ""
  );
  const [clientExperience, setClientExperience] = useState(
    user.client_experience
      ? {
          value: user.client_experience,
          label: user.client_experience,
        }
      : ""
  );
  const [clientTypes, setClientTypes] = useState(
    user.client_work ? Object.values(user.client_work) : []
  );
  const [educationalBackground, setEducationalBackground] = useState(
    user.educational_background_certification
  );
  const [licenses, setLicenses] = useState(
    user.license_certifications.length > 0
      ? user.license_certifications.map((cert) => ({
          licenseNumber: cert.license_number,
          certifiedFrom: {
            label: cert.certified_from,
            value: cert.certified_from,
          },
        }))
      : [{ licenseNumber: "", certifiedFrom: "" }]
  );

  // const [licenseNumber, setLicenseNumber] = useState(user.license_number);
  // const [certifiedBody, setCertifiedBody] = useState(
  //   user.certified_from ? Object.values(user.certified_from) : []
  // );
  const [selectedApproach, setSelectedApproach] = useState(
    user.therapy_approach ? Object.values(user.therapy_approach) : []
  );
  const [selectedProblem, setSelectedProblem] = useState(
    user.certified_on ? Object.values(user.certified_on) : []
  );
  const [selectedMaxHours, setSelectedMaxHours] = useState(null);
  const [showFiles, setShowFiles] = useState(user?.certifications);
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const phoneInput = useRef();
  const phoneNumberDivRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      const excludedClasses = [
        "iti__country",
        "iti__country-name",
        "iti__selected-dial-code",
      ];
      const clickedOnExcludedElement = excludedClasses.some((className) =>
        event.target.classList.contains(className)
      );
      if (
        phoneNumberDivRef.current &&
        !phoneNumberDivRef.current.contains(event.target) &&
        !clickedOnExcludedElement
      ) {
        setEditingPhone(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(getTherapistProfile());
  }, []);

  useEffect(() => {
    if (editingPhone) {
      const iti = intlTelInput(phoneInput?.current, {
        separateDialCode: true,
        nationalMode: false,
      });
      iti.setNumber(phoneNumber || user.cell_number);
      phoneInput.current.focus();
    }
  }, [phoneInput, editingPhone]);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    // Use FileReader to display the selected image
    if (file) {
      if (file?.type?.includes("image")) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const imageUrl = event.target.result;
          setImageUrl(imageUrl);
        };
        reader.readAsDataURL(file);
        setErrors({
          imageFormatError: undefined,
        });
      } else {
        setErrors({
          imageFormatError: "Please select a supported image format",
        });
      }
    }
  };

  const handleDocChange = (e) => {
    const selectedFiles = e.target.files;
    let promises = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileName = file.name;
      const fileSize = file.size;
      const formattedSize = formatBytes(fileSize);

      const reader = new FileReader();
      const promise = new Promise((resolve, reject) => {
        reader.onload = (event) => {
          const uploadedFile = {
            id: i,
            fileName: fileName,
            fileSize: fileSize,
            formattedSize: formattedSize,
            media_mime: file.type,
            full_url: event.target.result,
          };
          resolve(uploadedFile);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });

      reader.readAsDataURL(file);
      promises.push(promise);
    }

    Promise.all(promises)
      .then((uploadedFiles) => {
        setShowFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
      })
      .catch((error) => {
        console.error("An error occurred while processing files:", error);
      });

    setUploadedDocFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    let Errors = errors;
    delete Errors.degreeError;
    setErrors(Object.assign({}, Errors));
  };

  const handleDelete = (id, selectedIndex) => {
    setShowFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== selectedIndex)
    );
    if (id != 0) dispatch(deleteMedia({ id: id }));
  };

  // Function to format bytes into a readable format
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  // Days of the week
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // Function to handle checkbox change
  const handleCheckboxChange = (day) => {
    // Check if the day is already selected
    if (selectedDays.includes(day)) {
      // If selected, remove it from the array
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      // If not selected, add it to the array
      setSelectedDays([...selectedDays, day]);
    }
  };
  // Options for days, months, and years
  const days = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));
  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => ({
    value: currentYear - i,
    label: `${currentYear - i}`,
  }));
  const getAdultYears = Array.from({ length: 83 }, (_, i) => ({
    value: currentYear - 18 - i,
    label: `${currentYear - 18 - i}`,
  }));

  const handleDayChange = (selectedOption) => {
    setDay(selectedOption);
  };

  const handleMonthChange = (selectedOption) => {
    setMonth(selectedOption);
  };

  const handleYearChange = (selectedOption) => {
    setYear(selectedOption);
  };

  const handleGenderChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.genderError;
    setErrors(Object.assign({}, Errors));
    setSelectedGender(selectedOption);
  };
  const handleCountryChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.countryError;
    setErrors(Object.assign({}, Errors));
    setSelectedCountry(selectedOption);
  };

  const handleNationalityChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.nationalityError;
    setErrors(Object.assign({}, Errors));
    setSelectedNationality(selectedOption);
  };

  const handleLanguageChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.languageError;
    setErrors(Object.assign({}, Errors));
    setSelectedLanguage(selectedOption);
  };

  const handleSecondaryLanguageChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.secondaryDuplicate;
    setErrors(Object.assign({}, Errors));
    setSelectedSecondaryLanguage(selectedOption);
  };

  const handleReligionChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.religionError;
    setErrors(Object.assign({}, Errors));
    setSelectedReligion(selectedOption);
  };
  const handleStartingYearChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.startingYearError;
    setErrors(Object.assign({}, Errors));
    setStartingYear(selectedOption);
  };

  const handleHoursChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.hoursError;
    setErrors(Object.assign({}, Errors));
    setHoursOfTherapy(selectedOption);
  };
  const handleMaxHoursChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.maxHoursError;
    setErrors(Object.assign({}, Errors));
    setSelectedMaxHours(selectedOption);
  };

  const handleExperienceChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.experienceError;
    setErrors(Object.assign({}, Errors));
    setClientExperience(selectedOption);
  };

  const handleTypesChange = (selectedOption) => {
    let Errors = errors;
    delete Errors.clientTypeError;
    setErrors(Object.assign({}, Errors));
    setClientTypes(selectedOption);
  };

  const handleBookingOptionChange = (event) => {
    setSelectedBookingOption(event.target.value);
  };

  const handleIntroSessionChange = (event) => {
    setSelectedIntroductorySession(event.target.value);
  };

  const handleTherapySessionChange = (event) => {
    setSelectedTherapyOption(event.target.value);
  };
  const handleLicenseChange = (index, field, value) => {
    const updatedLicenses = [...licenses];
    updatedLicenses[index][field] = value;
    setLicenses(updatedLicenses);
  };
  const addLicense = () => {
    if (licenses.length < 5) {
      setLicenses([...licenses, { licenseNumber: "", certifiedFrom: "" }]);
    }
  };
  const removeLicense = (index) => {
    const updatedLicenses = licenses.filter((_, i) => i !== index);
    setLicenses(updatedLicenses);
  };

  const handleApproachesChange = (selectedOption) => {
    setSelectedApproach(selectedOption);
  };

  const handleProblemChange = (selectedOption) => {
    setSelectedProblem(selectedOption);
  };
  const getSelectedDate = () => {
    if (day && month && year) {
      const selectedDate = moment(
        `${year.value}-${month.value}-${day.value}`,
        "YYYY-MM-DD"
      );
      return selectedDate.format("YYYY-MM-DD");
    }
    return null;
  };
  const handleSubmitAnswer = (e) => {
    e.preventDefault();
    let validationErrors = {};
    if (stepCompleted === 0) {
      const firstNameError = checkFirstNameValidation(firstName);
      const lastNameError = checkLastNameValidation(lastName);
      const emailError = checkEmailValidation(email);
      const phoneError = editingPhone
        ? checkMobileValidation(phoneInput)
        : null;
      const profilePictureError = checkProfilePictureValidation();

      validationErrors = {
        ...validationErrors,
        ...firstNameError,
        ...lastNameError,
        ...emailError,
        ...phoneError,
        ...profilePictureError,
      };

      if (!day || !month || !year) {
        validationErrors.dobError = "Please provide your date of birth";
      }

      if (!selectedGender) {
        validationErrors.genderError = "Please select a gender";
      }
    } else if (stepCompleted === 1) {
      if (!selectedCountry) {
        validationErrors.countryError = "Please select a country";
      }
      if (!selectedNationality) {
        validationErrors.nationalityError = "Please select a nationality";
      }
      if (!selectedLanguage) {
        validationErrors.languageError = "Please select a language";
      }
      if (
        selectedLanguage &&
        selectedSecondaryLanguage.some(
          (lang) =>
            lang.label.toLowerCase() === selectedLanguage.label.toLowerCase()
        )
      ) {
        validationErrors.secondaryDuplicate =
          "You can’t have Primary Language as Other Language at the same time. Kindly remove it from Other Languages and try again";
      }
      if (!selectedReligion) {
        validationErrors.religionError = "Please select a response";
      }
    } else if (stepCompleted === 2) {
      if (!startingYear) {
        validationErrors.startingYearError = "Please select a option";
      }
      if (!hoursOfTherapy) {
        validationErrors.hoursError = "Please select a option";
      }
      if (!clientExperience) {
        validationErrors.experienceError = "Please select a option";
      }
      if (clientTypes?.length == 0) {
        validationErrors.clientTypeError =
          "Please select at least one Client type";
      }

      // Validate licenses
      licenses.forEach((license, index) => {
        // Validate license number
        if (
          !license.licenseNumber ||
          !checkLicenseNumberError(license.licenseNumber)
        ) {
          validationErrors[
            `licenseNumberError_${index}`
          ] = `Please provide your License number`;
        }

        // Validate certified body
        if (!license.certifiedFrom) {
          validationErrors[
            `certifiedBodyError_${index}`
          ] = `Please select a Certification Body`;
        }
      });

      const educationBgError = checkEducationBgError(educationalBackground);

      validationErrors = {
        ...validationErrors,
        ...educationBgError,
      };

      if (showFiles?.length == 0) {
        validationErrors.degreeError =
          "Please submit at least one valid document";
      }
    } else if (stepCompleted === 3) {
      if (!selectedMaxHours) {
        validationErrors.maxHoursError = "Please select a range of hours";
      }
      if (selectedDays.length < 1) {
        validationErrors.availableDaysError = "Please select available days.";
      }
    }
    if (Object.keys(validationErrors).length === 0) {
      const licenseNumbers = licenses.map((item) => item.licenseNumber);
      // For certifiedFroms, extract the value from the multi-select options
      const certifiedFroms = licenses.map((item) => item.certifiedFrom.value);

      dispatch(
        stepCompleted === 0
          ? submitTherapistProfile({
              first_name: firstName || user.first_name,
              last_name: lastName || user.last_name,
              email: email || user.username,
              cell_number: phoneNumber || user.cell_number,
              profile_picture: image,
              step_completed: stepCompleted + 1,
              bio: bio,
              date_of_birth: getSelectedDate(),
              gender: selectedGender.value.toUpperCase(),
            })
          : stepCompleted === 1
          ? submitTherapistProfile({
              country: selectedCountry.value,
              nationality: selectedNationality?.value,
              primary_language: selectedLanguage.value,
              secondary_language: JSON.stringify({
                ...selectedSecondaryLanguage,
              }),
              religious_belief: selectedReligion.value,
              step_completed: stepCompleted + 1,
            })
          : stepCompleted === 2
          ? submitTherapistProfile({
              years_of_experience: startingYear?.value,
              clinical_hours: hoursOfTherapy?.value,
              client_experience: clientExperience?.value,
              client_work: JSON.stringify({
                ...clientTypes,
              }),
              educational_background_certification: educationalBackground,
              license_numbers: licenseNumbers,
              certified_froms: certifiedFroms,
              certifications: uploadedDocFiles,
              therapy_approach: JSON.stringify({ ...selectedApproach }),
              certified_on: JSON.stringify({
                ...selectedProblem,
              }),
              intro_session: selectedIntroductorySession,
              inperson_therapy: selectedTherapyOption,
              step_completed: stepCompleted + 1,
            })
          : stepCompleted === 3
          ? submitTherapistProfile({
              available_hours: selectedMaxHours.value,
              available_days: JSON.stringify({ ...selectedDays }),
              same_day_book: selectedBookingOption,
              step_completed: stepCompleted + 1,
            })
          : null
      );
      if (stepCompleted < 4) setStepCompleted(stepCompleted + 1);
    } else {
      setErrors(validationErrors);
    }
  };

  const checkFirstNameValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.firstNameError = "Please input the name";
    else if (!firstNamePattern.test(value))
      Errors.firstNameError = "Please enter a valid name";
    else delete Errors.firstNameError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkLastNameValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.lastNameError = "Please input the name";
    else if (!lastNamePattern.test(value))
      Errors.lastNameError = "Please enter a valid name";
    else delete Errors.lastNameError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkEmailValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.emailError = "Please input the email";
    else if (!emailPattern.test(value))
      Errors.emailError = "Please enter a valid email";
    else delete Errors.emailError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkMobileValidation = (value, validator = {}) => {
    let iti = window.intlTelInputGlobals.getInstance(value.current);
    let validNumber = iti.isValidNumber();
    setPhoneNumber(iti.getNumber());
    let Errors = validator;
    if (!validNumber)
      Errors.validNumberError = "Please enter a valid phone number";
    else delete Errors.validNumberError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkProfilePictureValidation = (validator = {}) => {
    let Errors = validator;
    if (!imageUrl) Errors.imageError = "Profile picture is required";
    else delete Errors.imageError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkEducationBgError = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.ebError = "Please provide your educational background";
    else if (value?.length < 25)
      Errors.ebError =
        "Your educational background must be at least 25 characters long";
    else delete Errors.ebError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkLicenseNumberError = (value, validator = {}) => {
    let Errors = validator;
    if (!value)
      Errors.licenseNumberError = "Please provide your License number";
    else delete Errors.licenseNumberError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      height: "100%",
      borderRadius: "10px",
      borderColor: state.isFocused ? "#005996" : "#E4E7EC",
      ":hover": { borderColor: state.isFocused ? "#005996" : "#E4E7EC" },
      cursor: "pointer",
      boxShadow: state.isFocused ? "0px 0px 0px 4px #BFDEF4" : "",
      minHeight: "44px",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#F2F4F7",
      borderRadius: "0.5rem",
      border: "1px solid #E4E7EC",
      fontSize: "14px",
      fontWeight: "500",
      padding: "0 2px",
    }),
    multiValueRemove: (css) => ({
      ...css,
      color: "#98A2B3",
      ":hover": { background: "transparent" },
    }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        color: isSelected ? "#fff" : "#232428",
        backgroundColor: isSelected ? "#005996" : "initial",
        cursor: isDisabled ? "not-allowed" : "default",
        ":hover": { backgroundColor: isSelected ? "#005996" : "#F1F6F9" },
        fontWeight: isDisabled ? "700" : "400",
      };
    },
  };
  return stepCompleted < 4 ? (
    <form
      method="POST"
      className="pt-12 px-4 lg:px-0"
      onSubmit={handleSubmitAnswer}
    >
      <div className="flex justify-between lg:items-end flex-col lg:flex-row">
        <div className="lg:pl-8">
          <Heading
            variant="h1"
            className="text-2xl lg:text-3xl text-gray-800 lg:text-center"
          >
            ✨ Welcome, {user.first_name} {user.last_name}!
          </Heading>
          <p className="text-gray-800/70 mt-4 lg:text-center">
            It will take 5-10 mins to fill this registration form.
          </p>
        </div>
        <Steps
          steps={4}
          stepsTitles={stepsTitles}
          active={stepCompleted + 1}
          className="mt-6"
        />
      </div>

      {stepCompleted === 0 ? (
        <div className="mt-8 py-8 rounded-lg bg-white shadow-registrationForm z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Personal Information
            </Heading>
          </div>

          <ul className="space-y-4 py-4 lg:py-6 px-4 lg:px-8">
            <li className="flex justify-between">
              <div className="text-sm flex items-center w-full lg:w-3/4">
                <label className="text-gray-500 mr-4 flex items-center text-sm w-28 lg:w-32">
                  <img src={User} className="mr-2" />
                  First Name:
                </label>
                {editingFirstName ? (
                  <Input
                    className="flex-1"
                    placeholder="Your first name"
                    type="text"
                    name="first_name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      checkFirstNameValidation(e.target.value, errors);
                    }}
                    error={errors.firstNameError}
                    app={app}
                    onBlur={() => {
                      if (!errors.firstNameError) {
                        setEditingFirstName(false);
                        checkFirstNameValidation(firstName, errors);
                      }
                    }}
                    autoFocus={true}
                  />
                ) : (
                  <span>{firstName}</span>
                )}
              </div>
              {!editingFirstName && (
                <img
                  src={Pencil}
                  onClick={() => setEditingFirstName(true)}
                  className="cursor-pointer"
                />
              )}
            </li>
            <li className="flex justify-between">
              <div className="text-sm flex items-center w-full lg:w-3/4">
                <label className="text-gray-500 mr-4 flex items-center text-sm w-28 lg:w-32">
                  <img src={User} className="mr-2" />
                  Last Name:
                </label>
                {editingLastName ? (
                  <Input
                    className="flex-1"
                    placeholder="Your last name"
                    type="text"
                    name="last_name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      checkLastNameValidation(e.target.value, errors);
                    }}
                    error={errors.lastNameError}
                    app={app}
                    onBlur={() => {
                      if (!errors.lastNameError) {
                        setEditingLastName(false);
                        checkLastNameValidation(lastName, errors);
                      }
                    }}
                    autoFocus={true}
                  />
                ) : (
                  <span>{lastName}</span>
                )}
              </div>
              {!editingLastName && (
                <img
                  src={Pencil}
                  onClick={() => setEditingLastName(true)}
                  className="cursor-pointer"
                />
              )}
            </li>
            <li className="flex justify-between">
              <div className="text-sm flex items-center w-full lg:w-3/4">
                <label className="text-gray-500 mr-4 flex items-center text-sm w-28 lg:w-32">
                  <img src={Email} className="mr-2" />
                  Email:
                </label>
                {editingEmail ? (
                  <Input
                    className="flex-1"
                    placeholder="Your email address"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      checkEmailValidation(e.target.value, errors);
                    }}
                    error={errors.emailError}
                    app={app}
                    onBlur={() => {
                      if (!errors.emailError) {
                        setEditingEmail(false);
                        checkEmailValidation(email, errors);
                      }
                    }}
                    autoFocus={true}
                  />
                ) : (
                  <span>{email}</span>
                )}
              </div>
              {!editingEmail && (
                <div className="group relative">
                  <img
                    disabled={true}
                    src={Pencil}
                    // onClick={() => setEditingEmail(true)}
                    className=" opacity-40"
                  />
                  <Tooltip
                    className="mt-2"
                    direction="left"
                    right="-right-5 after:right-[18px] before:right-4"
                    position="bottom"
                    pin="top"
                  >
                    You may reach out to our support team and we’ll update these
                    changes for you
                  </Tooltip>
                </div>
              )}
            </li>
            <li className="flex justify-between">
              <div
                ref={phoneNumberDivRef}
                className="text-sm flex items-center w-full lg:w-3/4"
              >
                <label className="text-gray-500 mr-4 flex items-center text-sm w-28 lg:w-32">
                  <img src={Phone} className="mr-2" />
                  Phone:
                </label>
                {editingPhone ? (
                  <Input
                    className="flex-1"
                    ref={phoneInput}
                    placeholder="Your Phone Number"
                    type="tel"
                    name="cell_number"
                    error={errors.validNumberError}
                    app={app}
                    onChange={() => {
                      checkMobileValidation(phoneInput, errors);
                    }}
                    onBlur={() => {
                      if (!errors.validNumberError) {
                        checkMobileValidation(phoneInput, errors);
                      }
                    }}
                  />
                ) : (
                  <span>{phoneNumber}</span>
                )}
              </div>
              {!editingPhone && (
                <img
                  src={Pencil}
                  onClick={() => setEditingPhone(true)}
                  className="cursor-pointer"
                />
              )}
            </li>
          </ul>
          <div className="py-4 lg:py-6 px-4 lg:px-8">
            <label className="text-sm font-medium text-gray-700">
              Profile Picture <span className="text-danger-900">*</span>
            </label>
            <div className="flex flex-col lg:flex-row lg:items-center justify-center w-full space-y-3 lg:space-y-0 lg:space-x-10 mt-2.5">
              <div className="w-24 h-24 rounded-full bg-blue-200 flex items-center justify-center">
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="Selected Image"
                    className="w-full h-full object-cover rounded-full"
                  />
                ) : (
                  <img src={userLarge} />
                )}
              </div>
              <div className="flex-1">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center py-4 px-6 w-full h-32 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white/20 hover:bg-gray-100/50"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <img src={upload} className="mb-3" />
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="text-blue-900">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500">
                      Only .png and .jpg file formats are allowed. Picture must
                      be in square with max resolution of 1000x1000
                    </p>
                  </div>

                  <input
                    id="dropzone-file"
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </label>
                {errors.imageError && (
                  <div className="text-sm text-danger-900 mt-1.5">
                    {errors.imageError}
                  </div>
                )}
                {errors.imageFormatError && (
                  <div className="text-sm text-danger-900 mt-1.5">
                    {errors.imageFormatError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <Input
            className="py-4 lg:py-6 px-4 lg:px-8"
            type="textarea"
            name="bio"
            label="Bio"
            placeholder="Write something about yourself"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            app={app}
            inputClassName="px-4 py-2.5 h-28 resize-none"
            hint="Here are some pointers to write about:"
          />
          <div className="pt-6 px-4 lg:px-8 flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Date of Birth <span className="text-danger-900">*</span>
              </label>
              <div className="flex space-x-2 mt-2">
                <Select
                  value={day}
                  onChange={handleDayChange}
                  options={days}
                  placeholder="Day"
                  className="h-11 flex-1"
                  styles={colourStyles}
                  menuPlacement="auto"
                />
                <Select
                  value={month}
                  onChange={handleMonthChange}
                  options={months}
                  placeholder="Month"
                  className="h-11 flex-1"
                  styles={colourStyles}
                  menuPlacement="auto"
                />
                <Select
                  value={year}
                  onChange={handleYearChange}
                  options={getAdultYears}
                  placeholder="Year"
                  className="h-11 flex-1"
                  styles={colourStyles}
                  menuPlacement="auto"
                />
              </div>
              {errors.dobError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.dobError}
                </div>
              )}
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Gender <span className="text-danger-900">*</span>
              </label>
              <Select
                value={selectedGender}
                onChange={handleGenderChange}
                options={genderOptions}
                placeholder="Select Gender"
                className="h-11 mt-2"
                styles={colourStyles}
                menuPlacement="auto"
              />
              {errors.genderError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.genderError}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : stepCompleted === 1 ? (
        <div className="mt-8 py-8 rounded-lg bg-white shadow-registrationForm z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Demographics & Language
            </Heading>
          </div>
          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Country <span className="text-danger-900">*</span>
              </label>
              <Select
                value={selectedCountry}
                onChange={handleCountryChange}
                options={countriesOptions.countries}
                placeholder="Select Country"
                className="h-11 mt-2"
                styles={colourStyles}
              />
              {errors.countryError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.countryError}
                </div>
              )}
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Nationality <span className="text-danger-900">*</span>
              </label>
              <Select
                value={selectedNationality}
                onChange={handleNationalityChange}
                options={nationalityOptions}
                placeholder="Select Nationality"
                className="h-11 mt-2 capitalize"
                styles={colourStyles}
              />
              {errors.nationalityError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.nationalityError}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Primary Language <span className="text-danger-900">*</span>
              </label>
              <Select
                value={selectedLanguage}
                onChange={handleLanguageChange}
                options={languageOptions}
                placeholder="Select a language"
                className="h-11 mt-2 capitalize"
                styles={colourStyles}
              />
              {errors.languageError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.languageError}
                </div>
              )}
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Other Languages
              </label>
              <Select
                isMulti
                value={selectedSecondaryLanguage}
                onChange={handleSecondaryLanguageChange}
                options={languageOptions}
                placeholder="Select language(s)"
                className="mt-2"
                styles={colourStyles}
              />
              {errors.secondaryDuplicate && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.secondaryDuplicate}
                </div>
              )}
            </div>
          </div>
          <div className="flex pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                What religion do you identify yourself with?
                <span className="text-danger-900">*</span>
              </label>
              <Select
                value={selectedReligion}
                onChange={handleReligionChange}
                options={religionOptions}
                placeholder="Select a response"
                className="h-11 mt-2"
                styles={colourStyles}
                menuPlacement="auto"
              />
              {errors.religionError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.religionError}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : stepCompleted === 2 ? (
        <div className="mt-8 py-8 rounded-lg bg-white shadow-registrationForm z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Professional Details
            </Heading>
          </div>
          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                What year did you start clinical work?
                <span className="text-danger-900"> *</span>
              </label>
              <p className="text-sm text-gray-500 font-normal mt-1.5">
                Ensure the year provided is post supervision.
              </p>
              <Select
                value={startingYear}
                onChange={handleStartingYearChange}
                options={years}
                placeholder="Select"
                className="h-11 mt-2"
                styles={colourStyles}
              />
              {errors.startingYearError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.startingYearError}
                </div>
              )}
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Client Types <span className="text-danger-900">*</span>
              </label>
              <p className="text-sm text-gray-500 font-normal mt-1.5">
                What type of clients do you work with?
              </p>
              <Select
                isMulti
                value={clientTypes}
                onChange={handleTypesChange}
                options={clientsTypeOptions}
                placeholder="Select"
                className="min- mt-2"
                styles={colourStyles}
              />
              {errors.clientTypeError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.clientTypeError}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Clinical Hours of Therapy
                <span className="text-danger-900"> *</span>
              </label>
              <p className="text-sm text-gray-500 font-normal mt-1.5">
                How many clinical hours of therapy have you done? (please
                exclude any supervision hours).
              </p>
              <Select
                value={hoursOfTherapy}
                onChange={handleHoursChange}
                options={hoursOfTherapyOptions}
                placeholder="Select"
                className="h-11 mt-2"
                styles={colourStyles}
              />
              {errors.hoursError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.hoursError}
                </div>
              )}
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Clients Experience <span className="text-danger-900">*</span>
              </label>
              <p className="text-sm text-gray-500 font-normal mt-1.5 mb-2">
                How many clients have you handled in your experience? (please
                exclude cases handled during supervision/training).
              </p>
              <Select
                value={clientExperience}
                onChange={handleExperienceChange}
                options={clientsExpOptions}
                placeholder="Select"
                className="h-11 mt-2"
                styles={colourStyles}
              />
              {errors.experienceError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.experienceError}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                {`Please share your educational background, along with any certifications, qualifications, licenses & independent mental health courses, you've done? (Please specify)`}
                <span className="text-danger-900"> *</span>
              </label>
              <Input
                className="mt-2"
                placeholder="Write here"
                type="textarea"
                name="educational_background"
                onChange={(e) => {
                  setEducationalBackground(e.target.value);
                  checkEducationBgError(e.target.value, errors);
                }}
                value={educationalBackground}
                error={errors.ebError}
                app={app}
                inputClassName="px-4 py-2.5 h-28 resize-none"
                onBlur={() =>
                  checkEducationBgError(educationalBackground, errors)
                }
              />
            </div>
          </div>

          {licenses.map((license, index) => (
            <div
              key={index}
              className="flex flex-col lg:flex-row pt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0"
            >
              <div className="flex-1">
                <label className="text-sm font-medium text-gray-500">
                  License Number<span className="text-danger-900">*</span>
                </label>
                <Input
                  className="mt-2"
                  placeholder="Write here"
                  type="text"
                  name={`license_number_${index}`}
                  value={license.licenseNumber}
                  onChange={(e) =>
                    handleLicenseChange(index, "licenseNumber", e.target.value)
                  }
                  error={errors[`licenseNumberError_${index}`]}
                  app={app}
                />
              </div>
              <div className="flex-1 flex items-center space-x-4">
                <div className="flex-1">
                  <label className="text-sm font-medium text-gray-500">
                    Which body are you certified from?
                    <span className="text-danger-900"> *</span>
                  </label>
                  <Select
                    value={license.certifiedFrom}
                    onChange={(selectedOption) =>
                      handleLicenseChange(
                        index,
                        "certifiedFrom",
                        selectedOption
                      )
                    }
                    options={certifiedBodiesOptions}
                    placeholder="Select"
                    className="mt-2"
                    styles={colourStyles}
                  />
                  {errors[`certifiedBodyError_${index}`] && (
                    <div className="text-sm text-danger-900 mt-1.5">
                      {errors[`certifiedBodyError_${index}`]}
                    </div>
                  )}
                </div>{" "}
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => removeLicense(index)}
                    className="shadow-none mt-7"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M15 5L5 15M5 5L15 15"
                        stroke="#667085"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          ))}
          {licenses.length < 5 && (
            <div className="px-4 lg:px-8 flex justify-end">
              <button
                type="button"
                onClick={addLicense}
                disabled={licenses.length >= 5}
                className="border-none shadow-none flex bg-gray-100 items-center space-x-1 py-1 px-2 border rounded "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10.0001 4.16675V15.8334M4.16675 10.0001H15.8334"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Add more</span>
              </button>
            </div>
          )}

          <div className="pt-6 lg:py-6 px-4 lg:px-8">
            <label className="text-sm font-medium text-gray-700">
              Upload degree(s), certificate(s) or license document(s){" "}
              <span className="text-danger-900">*</span>
            </label>
            <div className="w-full mt-2.5">
              <label
                htmlFor="dropzone-file"
                className="flex flex-1 flex-col items-center justify-center py-4 px-6 w-full h-32 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white/20 hover:bg-gray-100/50"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <img src={upload} className="mb-3" />
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="text-blue-900">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500">
                    Only .pdf, .png and .jpg file formats are allowed.
                  </p>
                </div>

                <input
                  id="dropzone-file"
                  type="file"
                  onChange={handleDocChange}
                  accept="application/pdf, image/*"
                  multiple
                />
              </label>
              {errors.degreeError && (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.degreeError}
                </div>
              )}
            </div>
            {showFiles.length > 0 && (
              <ul className="flex flex-wrap gap-4 mt-4">
                {showFiles.map((file, index) => (
                  <li
                    className="p-4 bg-gray-100 rounded-2xl text-sm text-gray-500 w-full lg:w-[32%] relative"
                    key={index}
                  >
                    <div className="flex space-x-4 items-center">
                      {file?.full_url && file?.media_mime?.includes("image") ? (
                        <img
                          src={file?.full_url}
                          className={
                            "w-10 h-10 rounded-lg object-contain bg-white"
                          }
                        />
                      ) : (
                        <div className="p-2 bg-white rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              d="M11.3333 1.67932C11.3333 1.6574 11.3333 1.64645 11.3324 1.62657C11.3127 1.21509 10.9509 0.853408 10.5394 0.833941C10.5195 0.833002 10.513 0.833004 10.5 0.833008H7.79888C7.1281 0.832999 6.57446 0.832992 6.12348 0.869838C5.65507 0.908109 5.22448 0.990246 4.82003 1.19632C4.19283 1.5159 3.68289 2.02584 3.36331 2.65304C3.15724 3.05749 3.0751 3.48807 3.03683 3.95649C2.99998 4.40748 2.99999 4.96111 3 5.6319V14.3674C2.99999 15.0382 2.99998 15.5919 3.03683 16.0429C3.0751 16.5113 3.15724 16.9419 3.36331 17.3463C3.68289 17.9735 4.19283 18.4835 4.82003 18.803C5.22448 19.0091 5.65507 19.0912 6.12348 19.1295C6.57447 19.1664 7.1281 19.1664 7.7989 19.1663H13.2011C13.8719 19.1664 14.4255 19.1664 14.8765 19.1295C15.3449 19.0912 15.7755 19.0091 16.18 18.803C16.8072 18.4835 17.3171 17.9735 17.6367 17.3463C17.8428 16.9419 17.9249 16.5113 17.9632 16.0429C18 15.5919 18 15.0382 18 14.3674V8.33296C18 8.32001 18 8.31354 17.9991 8.29369C17.9796 7.88216 17.6179 7.52027 17.2064 7.50063C17.1865 7.49968 17.1756 7.49968 17.1538 7.49968L13.4734 7.49969C13.2631 7.49971 13.0583 7.49974 12.8848 7.48556C12.6943 7.47 12.4695 7.43328 12.2434 7.31803C11.9298 7.15824 11.6748 6.90327 11.515 6.58967C11.3997 6.36348 11.363 6.1387 11.3475 5.94821C11.3333 5.77474 11.3333 5.56992 11.3333 5.3596L11.3333 1.67932ZM10.5 9.99968C10.9602 9.99968 11.3333 10.3728 11.3333 10.833V12.4997H13C13.4602 12.4997 13.8333 12.8728 13.8333 13.333C13.8333 13.7932 13.4602 14.1663 13 14.1663H11.3333V15.833C11.3333 16.2932 10.9602 16.6663 10.5 16.6663C10.0398 16.6663 9.66667 16.2932 9.66667 15.833V14.1663H8C7.53976 14.1663 7.16667 13.7932 7.16667 13.333C7.16667 12.8728 7.53976 12.4997 8 12.4997H9.66667V10.833C9.66667 10.3728 10.0398 9.99968 10.5 9.99968Z"
                              fill="#029CF5"
                            />
                            <path
                              d="M16.2566 5.833C16.5013 5.83301 16.6237 5.83301 16.7239 5.77157C16.8654 5.6848 16.95 5.48038 16.9112 5.31895C16.8838 5.20466 16.804 5.12495 16.6444 4.96554L13.8675 2.18861C13.7081 2.02904 13.6284 1.94925 13.5141 1.92178C13.3526 1.88298 13.1482 1.96759 13.0614 2.10912C13 2.20935 13 2.33169 13 2.57637L13 5.16631C13 5.39966 13 5.51633 13.0454 5.60546C13.0854 5.68386 13.1491 5.7476 13.2275 5.78755C13.3166 5.83296 13.4333 5.83296 13.6667 5.83297L16.2566 5.833Z"
                              fill="#029CF5"
                            />
                          </svg>
                        </div>
                      )}

                      <div className="flex-1 w-[calc(100%-2.5rem)]">
                        <span className=" whitespace-nowrap text-ellipsis overflow-hidden block w-4/5">
                          {file.fileName ?? file.media_name}
                        </span>{" "}
                        <div>
                          {file.formattedSize ?? formatBytes(file.media_size)}
                        </div>
                      </div>
                    </div>

                    <button
                      className="shadow-none absolute right-2 top-4"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent form submission
                        handleDelete(file.id, index);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5"
                          stroke="#98A2B3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="flex flex-col lg:flex-row mt-6 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                {`Choose therapy approache(s) that you're certified to practice`}
              </label>
              <Select
                isMulti
                value={selectedApproach}
                onChange={handleApproachesChange}
                options={approachesOptions}
                placeholder="Select an approach"
                className="mt-2"
                styles={colourStyles}
              />
            </div>
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-500">
                Please select all the problems you are certified to work with
              </label>
              <Select
                isMulti
                value={selectedProblem}
                onChange={handleProblemChange}
                options={expertiseOptions}
                placeholder="Select"
                className="mt-2"
                styles={colourStyles}
              />
            </div>
          </div>
          <div className="pt-6 px-4 lg:px-8 flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0">
            <div>
              <label className="text-sm font-medium text-gray-700">
                Would you be available to provide Introductory Sessions?{" "}
                <span className="text-danger-900">*</span>
              </label>
              <div className="flex space-x-6 mt-6">
                <RadioButton
                  value="yes"
                  checked={selectedIntroductorySession === "yes"}
                  onChange={handleIntroSessionChange}
                  label="Yes"
                  app={app}
                />

                <RadioButton
                  value="no"
                  checked={selectedIntroductorySession === "no"}
                  onChange={handleIntroSessionChange}
                  label="No"
                  app={app}
                />
              </div>
            </div>
            <div>
              <label className="text-sm font-medium text-gray-700">
                Would you be available for in-person sessions?{" "}
                <span className="text-danger-900">*</span>
              </label>
              <div className="flex space-x-6 mt-6">
                <RadioButton
                  value="yes"
                  checked={selectedTherapyOption === "yes"}
                  onChange={handleTherapySessionChange}
                  label="Yes"
                  app={app}
                />

                <RadioButton
                  value="no"
                  checked={selectedTherapyOption === "no"}
                  onChange={handleTherapySessionChange}
                  label="No"
                  app={app}
                />
              </div>
            </div>
          </div>
        </div>
      ) : stepCompleted === 3 ? (
        <div className="mt-8 py-8 rounded-lg bg-white shadow-registrationForm z-10 relative">
          <div className="pb-8 px-4 lg:px-8 border-b border-b-gray-200">
            <Heading variant="h2" className="text-lg text-gray-800">
              Availability
            </Heading>
          </div>

          <div className="flex py-4 lg:py-6 px-4 lg:px-8 space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-700">
                What are the maximum number of hours you can give to your
                clients in a week?<span className="text-danger-900"> *</span>
              </label>
              <Select
                value={selectedMaxHours}
                onChange={handleMaxHoursChange}
                options={maxHoursOptions}
                placeholder="Select hours"
                className="h-11 mt-2"
                styles={colourStyles}
              />
              {errors.maxHoursError ? (
                <div className="text-sm text-danger-900 mt-1.5">
                  {errors.maxHoursError}
                </div>
              ) : (
                <div className="text-sm text-gray-400 mt-1.5">
                  These are estimated hours only, subject to change later
                </div>
              )}
            </div>
          </div>
          <div className="py-4 lg:py-6 px-4 lg:px-8">
            <label className="text-sm font-medium text-gray-700">
              Available Days<span className="text-danger-900"> *</span>
            </label>
            <div className="flex mt-2">
              {daysOfWeek.map((day) => (
                <div
                  key={day}
                  className={`py-4 flex flex-col flex-1 items-center border-t border-b border-l last-of-type:border-r last-of-type:rounded-r-[10px] first-of-type:rounded-l-[10px] border-gray-200 ${
                    selectedDays.includes(day) ? "bg-blue-200" : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    id={day}
                    name={day}
                    checked={selectedDays.includes(day)}
                    onChange={() => handleCheckboxChange(day)}
                    className="appearance-none checked:bg-blue-900 border-gray-200 w-5 h-5 flex items-center justify-center checked:before:w-[.35rem] checked:before:h-[.75rem] before:border-b-2 before:border-white before:border-r-2 before:rotate-45 cursor-pointer"
                  />
                  <label
                    htmlFor={day}
                    className={`text-sm font-medium mt-2.5 cursor-pointer ${
                      selectedDays.includes(day)
                        ? "text-gray-900"
                        : "text-gray-400"
                    }`}
                  >
                    {isMobile ? day.charAt(0) : day}
                  </label>
                </div>
              ))}
            </div>
            {errors.availableDaysError && (
              <div className="text-sm text-danger-900 mt-1.5">
                {errors.availableDaysError}
              </div>
            )}
          </div>
          <div className="pt-6 px-4 lg:px-8">
            <label className="text-sm font-medium text-gray-700">
              Same-day Sessions<span className="text-danger-900"> *</span>
            </label>
            <p className="text-sm text-gray-500 font-normal mt-2">
              Would you like to be contacted for same-day sessions?
            </p>
            <div className="flex space-x-6 mt-6">
              <RadioButton
                value="yes"
                checked={selectedBookingOption === "yes"}
                onChange={handleBookingOptionChange}
                label="Yes"
                app={app}
              />

              <RadioButton
                value="no"
                checked={selectedBookingOption === "no"}
                onChange={handleBookingOptionChange}
                label="No"
                app={app}
              />
            </div>
          </div>
        </div>
      ) : null}

      <div className="px-6 pt-4 pb-2 bg-[#FFEFDB] text-sm space-x-2.5 -mt-2 text-gray-800/70 rounded-b-lg flex items-center shadow-registrationForm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <g clipPath="url(#clip0_400_5820)">
            <path
              d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
              stroke="#FF9310"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_400_5820">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span>
          You can always change & edit these settings later on in Profile
          section
        </span>
      </div>
      <div className="flex justify-end py-4 lg:py-6 items-center space-x-2.5 bg-white lg:bg-transparent drop-shadow lg:drop-shadow-none mt-3 lg:mt-0 -mx-4 lg:-mx-0 px-4 lg:px-0">
        {stepCompleted > 0 && (
          <span
            className="text-gray-400 text-lg cursor-pointer"
            onClick={() => setStepCompleted(stepCompleted - 1)}
          >
            Back
          </span>
        )}

        <Button
          type="submit"
          text="Save & Continue"
          className="bg-blue-900 hover:shadow-button hover:bg-blue-500 transition-all text-white"
          app={app}
        />
      </div>
    </form>
  ) : null;
};
export default RegistrationForm;
