import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonComponent = () => {
  return (
    <SkeletonTheme baseColor="#E4E7EC" highlightColor="#F2F4F7">
      <div className="flex gap-2 lg:gap-6 w-full">
        <ul className="space-y-20 hidden lg:block">
          <li className="space-y-20">
            <div>
              <div className="rounded-lg border border-gray-200 text-center w-[3.25rem]">
                <div className="px-3 py-0.5 bg-white text-teal-50 text-xs font-medium rounded-t-lg">
                  <Skeleton height={12} />
                </div>
                <div className="bg-white px-3 py-0.5 text-lg font-semibold rounded-b-lg">
                  <Skeleton height={20} />
                </div>
              </div>
              <div className="mt-1.5 text-sm font-medium">
                <Skeleton height={16} />
              </div>
              <div className="text-gray-400 text-xs mt-1 font-medium">
                <Skeleton height={12} />
              </div>
            </div>
          </li>
        </ul>

        <div className="w-5 rounded-t-full rounded-b-full">
          <Skeleton height={140} />
        </div>

        <div className="relative bg-white rounded-2xl border border-gray-200 w-full">
          <div className="lg:flex justify-between">
            <div className="px-4 pt-4 pb-6 session-details">
              <div className="text-sm text-gray-400 font-semibold uppercase">
                <Skeleton height={16} />
              </div>
              <div className="text-xl font-medium space-x-4 flex items-center mt-1">
                <span>
                  <Skeleton height={20} width={60} />
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    opacity="0.4"
                    d="M4.16634 10H15.833"
                    stroke="#667085"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.99967 15.8346L15.833 10.0013L9.99967 4.16797"
                    stroke="#667085"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="text-gray-400">
                  <Skeleton height={20} width={60} />
                </span>
              </div>
            </div>

            <div className="px-4 flex items-center join-session">
              <div className="rounded-3xl w-full">
                <Skeleton height={42} width={90} />
              </div>
            </div>
          </div>
          <div className="font-medium flex justify-between border-t border-t-gray-200 mt-2.5 lg:mt-0 session-actions">
            <div className="px-3 py-3.5 flex items-center">
              <Skeleton height={16} width={32} />
            </div>
            <div className="flex">
              <div className="px-4 py-2.5 border-l border-l-gray-200">
                <Skeleton height={16} width={16} />
              </div>
              <div className="px-4 py-2.5 border-l border-l-gray-200">
                <Skeleton height={16} width={16} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonComponent;
