import React from "react";
import Heading from "../atoms/Heading";
import Link from "../atoms/Link";

const ProfileCompleted = () => {
  return (
    <div className="py-8 rounded-lg bg-white shadow-registrationForm z-10 relative max-w-lg mx-auto">
      <div className="w-20 h-20 rounded-full bg-gradient-to-b from-[#209DF2] to-blue-900 flex items-center justify-center mx-auto">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M26.6663 8L11.9997 22.6667L5.33301 16"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="max-w-xs text-center mx-auto">
        <Heading variant="h2" className="text-2xl font-medium mt-4">
          Registration form submitted successfully{" "}
        </Heading>
        <p className="mt-2 text-gray-500">That’s a great start 🙌</p>
      </div>
      <div className="text-gray-500 font-medium mt-8 px-6">
        What should you expect next?
      </div>
      <div className="flex border-t border-b border-gray-200 text-xs lg:text-sm mt-4">
        <ul className="px-6 space-y-11 border-r border-gray-200 pt-6 pb-8 font-medium">
          <li>STEP 1</li>
          <li>STEP 2</li>
          <li>STEP 3</li>
        </ul>
        <ul className="pl-8 pr-6 text-gray-500 space-y-5 lg:space-y-8 flex-1 pt-6 pb-8">
          <li>Our community manager will review your given data</li>
          <li>
            If you fall under our hiring criteria our community manger with
            schedule a call with you
          </li>
          <li>
            After successful meeting you will be given access to fitcy dashboard
            & you will start receiving clients
          </li>
        </ul>
      </div>
      <div className="text-gray-400 font-medium text-sm text-center mt-6">
        Need help?{" "}
        <Link href="#" className="text-blue-900">
          Contact our support team
        </Link>
      </div>
    </div>
  );
};
export default ProfileCompleted;
