import React, { useEffect, useState } from "react";
import Header from "../../components/molecules/Header";
import SideMenu from "../../components/molecules/SideMenu";
import backgroundImage from "../../assets/onboarding/bg-shadow.png";
import arrowRight from "../../assets/arrow-right-green.svg";
import historyIcon from "../../assets/history_icon.svg";
import plusGreenIcon from "../../assets/plus_green_icon.svg";
import emptySessionsList from "../../assets/empty_sessions_list.svg";
import emptyNotificationList from "../../assets/empty_notification_list.svg";
import emptyMoodGrayIcon from "../../assets/empty_mood_gray_icon.svg";
import CheckIcon from "../../assets/check.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPatientDashboard } from "../../redux/patient/patientThunks";
import {
  MetaTags,
  calculateTimeDifference,
  getNotificationIcon,
  getOrdinal,
  getTimeDifference,
} from "../../services/globalFunctions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import StressMoodHistory from "../../components/organisms/StressMoodHistory";
import {
  MOOD_HISTORY,
  MOOD_LEVEL,
  STRESS_HISTORY,
  STRESS_LEVEL,
} from "../../services/utils/constants";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Tooltip from "../../components/atoms/Tooltip";
// import { toast } from "react-toastify";
import userAvatar from "../../assets/user-large.svg";

export default function PatientDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const isIpad = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1024px)",
  });
  const dashboardData = useSelector((state) => state.patient.dashboardData);
  const notifications = useSelector((state) => state.onboarding.notifications);
  const user = useSelector((state) => state.app.user);
  const [isCommonModal, setIsCommonModal] = useState(false);
  const [isModalType, setIsModalType] = useState("");
  const [displayCount, setDisplayCount] = useState(
    isMobile ? 5 : notifications?.length
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getPatientDashboard());
    document.body.classList.remove("overflow-hidden");
  }, []);

  const commonModalStyles = {
    content: {
      maxWidth: !isMobile ? "535px" : "100%",
      width: "100%",
      left: 0,
      right: 0,
      margin: "auto",
      border: "none",
      borderRadius: isMobile ? "0" : "16px",
      maxHeight: isMobile ? "100%" : "calc(100% - 6rem)",
      height: "auto",
      background: "#fff",
      overflowY: "auto",
    },
    overlay: {
      display: "flex",
    },
  };

  const handleJoinSession = (item) => {
    if (getTimeDifference(item?.appointment_time) > 15) {
      // toast.error("You can not join early!")
    } else
      window.open(
        `/dashboard/session/?patient_id=${item?.patient}&appointment=${item?.id}&therapist_id=${item?.therapist}`
      );
  };

  const StressComponent = () => {
    return (
      <div className="bg-white rounded-2xl drop-shadow p-4 w-full space-y-6">
        <div className="flex items-center justify-between">
          <div className="text-xs text-gray-500 font-medium uppercase">
            STRESS LEVEL
          </div>
          <div className="flex items-center space-x-2">
            <img
              src={historyIcon}
              className="cursor-pointer"
              onClick={() => {
                setIsCommonModal(true);
                setIsModalType(STRESS_HISTORY);
              }}
            />
            <div className="border border-solid border-gray-200 h-4"></div>
            {dashboardData ? (
              <div
                className={`${
                  dashboardData?.data?.stress_level?.current_date_available
                    ? "relative group cursor-pointer"
                    : ""
                }`}
              >
                <img
                  src={
                    dashboardData?.data?.stress_level?.current_date_available
                      ? CheckIcon
                      : plusGreenIcon
                  }
                  className="cursor-pointer w-4 h-4"
                  onClick={() => {
                    !dashboardData?.data?.stress_level
                      ?.current_date_available &&
                      (setIsCommonModal(true), setIsModalType(STRESS_LEVEL));
                  }}
                />

                <Tooltip
                  className={`z-auto mt-2 `}
                  direction="left"
                  position="bottom"
                  right="-right-5 after:right-[18px] before:right-4"
                  pin="top"
                >
                  Your stress level has been added.
                  <br />
                  Come back tomorrow to add it again.
                </Tooltip>
              </div>
            ) : (
              <SkeletonTheme baseColor="#E4E7EC" highlightColor="#F2F4F7">
                <Skeleton height={16} width={16} />
              </SkeletonTheme>
            )}
          </div>
        </div>
        <div className="space-y-0 lg:space-y-4 flex lg:block items-center justify-between">
          <div
            className={`${
              dashboardData?.data?.stress_level?.label
                ? "text-teal-900"
                : "text-gray-300"
            } text-2xl font-medium`}
          >
            {dashboardData?.data?.stress_level?.label || "N/A"}
          </div>
          <div className="flex items-center space-x-0.5 justify-center">
            {Array(5)
              .fill()
              ?.map((_, index) => {
                return (
                  <div
                    key={index}
                    className={`${
                      dashboardData?.data?.stress_level?.avg
                        ? index >= (dashboardData?.data?.stress_level?.avg || 0)
                          ? "bg-teal-100"
                          : "bg-teal-500"
                        : "bg-gray-300"
                    }  rounded-lg w-10 lg:w-8 h-3.5`}
                  ></div>
                );
              })}
          </div>
        </div>
        <div className="text-xs text-gray-500 text-center">
          This is your average stress for the last 30 days
        </div>
      </div>
    );
  };

  const MoodComponent = () => {
    return (
      <div className="bg-white rounded-2xl drop-shadow p-4 w-full">
        <div className="flex items-center justify-between mb-6">
          <div className="text-xs text-gray-500 font-medium uppercase">
            MOOD
          </div>
          <div className="flex items-center space-x-2">
            <img
              src={historyIcon}
              className="cursor-pointer"
              onClick={() => {
                setIsCommonModal(true);
                setIsModalType(MOOD_HISTORY);
              }}
            />
            <div className="border border-solid border-gray-200 h-4"></div>
            {dashboardData ? (
              <div
                className={`${
                  dashboardData?.data?.mood?.current_date_available
                    ? "relative group cursor-pointer"
                    : ""
                }`}
              >
                <img
                  src={
                    dashboardData?.data?.mood?.current_date_available
                      ? CheckIcon
                      : plusGreenIcon
                  }
                  className="cursor-pointer w-4 h-4"
                  onClick={() => {
                    !dashboardData?.data?.mood?.current_date_available &&
                      (setIsCommonModal(true), setIsModalType(MOOD_LEVEL));
                  }}
                />

                <Tooltip
                  className="z-auto mt-2"
                  direction="left"
                  position="bottom"
                  right="-right-5 after:right-[18px] before:right-4"
                  pin="top"
                >
                  Your mood has been added.
                  <br />
                  Come back tomorrow to add it again.
                </Tooltip>
              </div>
            ) : (
              <SkeletonTheme baseColor="#E4E7EC" highlightColor="#F2F4F7">
                <Skeleton height={16} width={16} />
              </SkeletonTheme>
            )}
          </div>
        </div>
        <div className="space-y-0 lg:space-y-4 flex lg:block items-center justify-between">
          <div
            className={`${
              dashboardData?.data?.mood?.label
                ? "text-teal-900"
                : "text-gray-300"
            } text-2xl font-medium`}
          >
            {dashboardData?.data?.mood?.label || "N/A"}
          </div>
          {dashboardData?.data?.mood?.history?.length > 0 ? (
            <div>
              <div className="border-b border-solid border-gray-200 mt-6"></div>
              <div className="flex space-x-0.5">
                {dashboardData?.data?.mood?.history?.map((item, index) => {
                  return (
                    <div key={index}>
                      {item?.level == 1 && (
                        <div
                          className={"w-1 h-4 bg-orange-500 rounded-b-3xl"}
                        ></div>
                      )}
                      {item?.level == 2 && (
                        <div
                          className={"w-1 h-2 bg-orange-500 rounded-b-3xl"}
                        ></div>
                      )}
                      {item?.level == 4 && (
                        <div
                          className={"w-1 h-2 bg-teal-500 rounded-t-3xl -mt-2"}
                        ></div>
                      )}
                      {item?.level == 5 && (
                        <div
                          className={"w-1 h-4 bg-teal-500 rounded-t-3xl -mt-4"}
                        ></div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <img src={emptyMoodGrayIcon} />
          )}
        </div>
        <div className="text-xs text-gray-500 text-center mt-6">
          This is your average mood for the last 30 days
        </div>
      </div>
    );
  };

  const SessionCredits = () => {
    return (
      <div className="bg-white rounded-2xl drop-shadow xs:p-3 p-4 w-full">
        <div className="text-xs text-gray-500 font-medium uppercase mb-4">
          SESSION CREDITS
        </div>
        <div className="flex items-center justify-between xs:space-x-1 space-x-2 lg:space-x-0 lg:block">
          <div className="rounded-lg py-2 xs:px-2 px-3 lg:flex items-center justify-between bg-teal-50 space-y-2 lg:space-y-0 w-full">
            <div className="text-2xl lg:text-base font-medium">
              {dashboardData?.data?.session_info?.credit_available
                ?.toString()
                .padStart(2, "0") || "00"}
            </div>
            <div className="text-xxs lg:text-xs font-medium text-gray-400">
              AVAILABLE
            </div>
          </div>
          <div className="rounded-lg py-2 xs:px-2 px-3 lg:flex items-center justify-between bg-gray-100 lg:my-2 space-y-2 lg:space-y-0 w-full">
            <div className="text-2xl lg:text-base font-medium">
              {dashboardData?.data?.session_info?.credit_used
                ?.toString()
                .padStart(2, "0") || "00"}
            </div>
            <div className="text-xxs lg:text-xs font-medium text-gray-400">
              USED
            </div>
          </div>
          <div className="rounded-lg py-2 xs:px-2 px-3 lg:flex items-center justify-between bg-sky-50 space-y-2 lg:space-y-0 w-full">
            <div className="text-2xl lg:text-base font-medium">
              {dashboardData?.data?.session_info?.credit_total
                ?.toString()
                .padStart(2, "0") || "00"}
            </div>
            <div className="text-xxs lg:text-xs font-medium text-gray-400">
              TOTAL
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MyProgressComponent = () => {
    return (
      <div className="bg-white rounded-2xl drop-shadow xs:p-3 p-4 md:w-full lg:w-2/5 space-y-4">
        <div className="text-xs text-gray-500 font-medium uppercase">
          MY PROGRESS
        </div>
        <div className="flex items-center justify-between xs:space-x-1 space-x-2">
          <div className="rounded-lg xs:px-2 px-3 pt-2 h-[72px] lg:h-[74px] w-full bg-sky-50">
            <div className="font-medium text-2xl">
              {dashboardData?.data?.session_info?.session_total
                ?.toString()
                .padStart(2, "0") || "00"}
            </div>
            <div className="text-xxs lg:text-xs font-medium text-gray-400 mt-1 lg:mt-2">
              ALL SESSIONS
            </div>
          </div>
          <div className="rounded-lg xs:px-2 px-3 pt-2 h-[72px] lg:h-[74px] w-full bg-teal-50">
            <div className="font-medium text-2xl">
              {dashboardData?.data?.session_info?.session_completed
                ?.toString()
                .padStart(2, "0") || "00"}
            </div>
            <div className="text-xxs lg:text-xs font-medium text-gray-400 mt-1.5 lg:mt-2">
              COMPLETED
            </div>
          </div>
          <div className="rounded-lg xs:px-2 px-3 pt-2 h-[72px] lg:h-[74px] w-full bg-gray-100">
            <div className="font-medium text-2xl">
              {dashboardData?.data?.session_info?.session_scheduled
                ?.toString()
                .padStart(2, "0") || "00"}
            </div>
            <div className="text-xxs lg:text-xs font-medium text-gray-400 mt-1.5 lg:mt-2">
              SCHEDULED
            </div>
          </div>
        </div>
        <div className="text-xs text-gray-500 text-center lg:w-11/12 m-auto">
          Holistic overview of your progress so far. Track your commitment to
          self-care and celebrate your achievements.
        </div>
      </div>
    );
  };

  return (
    <>
      <MetaTags
        title="Home | Fitcy Health"
        description="Explore your Fitcy Health dashboard"
        url="/home"
      />
      <Header
        dashboard={true}
        className="border-b border-gray-200 fixed z-10 bg-white"
      />
      <div
        className="flex lg:pl-24 pt-[4.5rem] bg-cover"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          width: "-webkit-fill-available",
          backgroundSize: isMobile ? "100% 100%" : "100% auto",
        }}
      >
        <SideMenu className="bg-white left-0 lg:top-[4.45rem]" />
        <div className={`w-full px-4 lg:px-16 pt-10 pb-24`}>
          <div className="text-xl lg:text-2xl font-medium">{`Welcome back, ${user.first_name} ${user.last_name} 👋`}</div>
          <div className="text-sm lg:text-base mt-2">
            Let’s navigate through your wellness journey
          </div>
          {isIpad ? (
            <div className="my-7 space-y-7">
              <div className="w-full flex space-x-6">
                {StressComponent()}
                {MoodComponent()}
              </div>
              <div className="w-full flex space-x-6">
                {SessionCredits()}
                {MyProgressComponent()}
              </div>
            </div>
          ) : (
            <div className="lg:flex space-y-6 lg:space-x-6 lg:space-y-0 my-7">
              <div className="lg:flex space-y-6 lg:space-x-6 lg:space-y-0 lg:w-3/5">
                {StressComponent()}
                {MoodComponent()}
                {SessionCredits()}
              </div>
              {MyProgressComponent()}
            </div>
          )}

          <div className="lg:flex space-y-6 lg:space-x-6 lg:space-y-0">
            <div className="bg-white rounded-2xl lg:w-3/5 drop-shadow">
              <div className="flex justify-between items-center p-5 border-b border-solid border-gray-100">
                <div className="text-xs text-gray-500 font-medium uppercase">
                  UPCOMING SESSIONS
                </div>
                <div
                  className="flex items-center cursor-pointer space-x-1"
                  onClick={() => navigate(`/sessions`)}
                >
                  <div className="text-xs text-teal-900 font-medium">
                    View all sessions
                  </div>
                  <img src={arrowRight} />
                </div>
              </div>
              <div className="overflow-auto lg:h-[345px] pt-6">
                {dashboardData?.data?.sessions?.length > 0 ? (
                  <>
                    {dashboardData?.data?.sessions
                      ?.slice(0, 5)
                      ?.map((item, index) => {
                        const todaySession =
                          item.session_date ==
                          moment(new Date()).format("ddd, DD MMM");
                        return (
                          <div
                            key={index}
                            className="flex items-center justify-between mb-3 mx-5"
                          >
                            <div className="text-xs">
                              {todaySession
                                ? "Today"
                                : item?.session_date || ""}
                            </div>
                            <div
                              className={`flex items-center justify-between ${
                                index == 0 ? "bg-teal-50" : "bg-gray-100"
                              } rounded-lg p-3 w-[77%] lg:w-[88%]`}
                            >
                              <div className="flex items-center space-x-2">
                                <div
                                  className={`${
                                    index == 0 ? "bg-teal-500" : "bg-gray-400"
                                  } h-9 w-1 rounded-full`}
                                ></div>
                                <div className="space-y-1">
                                  <div className="flex items-center space-x-1.5">
                                    {item?.therapist_info?.profile_picture
                                      ?.full_url && (
                                      <img
                                        src={
                                          item?.therapist_info?.profile_picture
                                            ?.full_url
                                        }
                                        className="w-4 h-4 rounded-full"
                                      />
                                    )}
                                    <div className="text-gray-500 text-xs">
                                      {(item?.therapist_info?.first_name ||
                                        "") +
                                        " " +
                                        (item?.therapist_info?.last_name || "")}
                                    </div>
                                    <div className="border border-solid border-gray-300 h-3"></div>
                                    <div className="text-gray-500 text-xs">
                                      {getOrdinal(item?.sr_num || 0)} session
                                    </div>
                                  </div>
                                  <div className="text-xs">
                                    {item?.start_time || ""} to{" "}
                                    {item?.end_time || ""}
                                  </div>
                                </div>
                              </div>
                              <div
                                onClick={() => handleJoinSession(item)}
                                className={`text-xs cursor-pointer ${
                                  index == 0 ? "text-teal-500" : "text-gray-400"
                                }`}
                              >
                                {getTimeDifference(item?.appointment_time) > 15
                                  ? ""
                                  : "Join now"}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </>
                ) : (
                  <div className="flex items-center flex-col pt-12 pb-12 lg:pb-0">
                    <img src={emptySessionsList} />
                    <div className="text-sm font-medium mt-4">
                      Your upcoming sessions will appear here
                    </div>
                    <div className="text-xs text-gray-400 text-center w-1/2 mt-1.5">
                      Head over to sessions and schedule your first session!
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="bg-white rounded-2xl lg:w-2/5 drop-shadow">
              <div className="p-5 border-b border-solid border-gray-100">
                <div className="text-xs text-gray-500 font-medium uppercase">
                  Activity Feed
                </div>
              </div>
              <div className="overflow-auto lg:h-[345px] py-6 px-5 ">
                {notifications?.length > 0 ? (
                  <>
                    {notifications
                      ?.slice(0, displayCount)
                      .map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex space-x-3 items-start mb-2"
                          >
                            {item?.notification_iconcode == 13 ? (
                              <img
                                className="h-10 w-10 rounded-xl"
                                src={
                                  item?.notification_dump?.icon_url ||
                                  userAvatar
                                }
                              />
                            ) : (
                              <img
                                src={getNotificationIcon(
                                  item?.notification_iconcode
                                )}
                              />
                            )}
                            <div>
                              <div className="text-sm">
                                {item?.notification_text || ""}
                              </div>
                              <div className="text-[10px] text-gray-500">
                                {calculateTimeDifference(item?.created_at)}
                              </div>
                              <div className="flex items-center space-x-2 mt-1">
                                {item?.notification_action?.map((i) => {
                                  return (
                                    <div
                                      className="text-sm font-medium text-teal-900 rounded-full bg-teal-100 py-1 px-2"
                                      key={i}
                                    >
                                      {i}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {isMobile && displayCount < notifications?.length ? (
                      <div
                        onClick={() =>
                          setDisplayCount(
                            (prevDisplayCount) => prevDisplayCount + 10
                          )
                        }
                      >
                        See More
                      </div>
                    ) : null}
                  </>
                ) : (
                  <div className="flex items-center flex-col pt-12 pb-12 lg:pb-0">
                    <img src={emptyNotificationList} />
                    <div className="text-sm font-medium mt-4">
                      Your activity feed will appear here
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isCommonModal}
        onRequestClose={() => setIsCommonModal(false)}
        overlayClassName="fixed inset-0 bg-black/10 backdrop-blur-sm z-20 transition-all"
        style={commonModalStyles}
        className={`${
          isMobile && isCommonModal
            ? "translate-y-0"
            : "translate-y-full lg:translate-y-0"
        } w-full transform transition-transform fixed bottom-0 outline-none lg:relative`}
      >
        <StressMoodHistory
          setIsCommonModal={setIsCommonModal}
          isModalType={isModalType}
          setIsModalType={setIsModalType}
        />
      </Modal>
    </>
  );
}
