import React from "react";

const Star = ({ isSelected }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g filter="url(#filter0_d_6665_2869)">
        <g clipPath="url(#clip0_6665_2869)">
          <rect
            x="2"
            y="1"
            width="20"
            height="20"
            rx="4"
            fill={isSelected ? "#009688" : "#BAE3E0"}
          />
          <g filter="url(#filter1_d_6665_2869)">
            <path
              d="M11.7147 3.87811C11.8045 3.60172 12.1955 3.60172 12.2853 3.87812L13.7288 8.32057C13.7689 8.44418 13.8841 8.52786 14.0141 8.52786H18.6851C18.9758 8.52786 19.0966 8.89975 18.8615 9.07057L15.0825 11.8162C14.9774 11.8926 14.9334 12.028 14.9735 12.1516L16.417 16.594C16.5068 16.8704 16.1904 17.1003 15.9553 16.9294L12.1763 14.1838C12.0712 14.1074 11.9288 14.1075 11.8237 14.1838L8.04469 16.9294C7.80957 17.1003 7.49323 16.8704 7.58303 16.594L9.02648 12.1516C9.06664 12.028 9.02264 11.8925 8.91749 11.8162L5.13852 9.07057C4.9034 8.89975 5.02424 8.52786 5.31485 8.52786H9.98593C10.1159 8.52786 10.2311 8.44418 10.2712 8.32057L11.7147 3.87811Z"
              fill="white"
            />
          </g>
        </g>
        <rect
          x="2.25"
          y="1.25"
          width="19.5"
          height="19.5"
          rx="3.75"
          stroke={isSelected ? "#009688" : "#BAE3E0"}
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6665_2869"
          x="0"
          y="0"
          width="24"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6665_2869"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6665_2869"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_6665_2869"
          x="-44.9854"
          y="-42.3301"
          width="113.971"
          height="113.318"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6665_2869"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6665_2869"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_6665_2869">
          <rect x="2" y="1" width="20" height="20" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const RatingStars = ({ value }) => {
  const maxStars = 5;
  return (
    <div className="flex items-center gap-1">
      {[...Array(maxStars)].map((_, index) => {
        const starValue = index + 1;
        return (
          <span key={starValue}>
            <Star isSelected={starValue <= value} />
          </span>
        );
      })}
    </div>
  );
};

export default RatingStars;
