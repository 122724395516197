import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import Check from "../../assets/check.svg";
import clock from "../../assets/clock.svg";
import Button from "../atoms/Button";
import { useMediaQuery } from "react-responsive";

// Define the steps
const generateTourSteps = () => [
  {
    target: ".time-line",
    content: (
      <div>
        <div className="font-medium text-lg">Timeline view</div>
        <p className="mt-2 text-sm text-gray-500">
          Each dot represent a session taken by you
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
  },
  {
    target: ".session-details",
    content: (
      <div>
        <div className="font-medium text-lg">Session Details</div>
        <p className="mt-2 text-sm text-gray-500">
          This is where you can see at what time and date your session is going to take place.
        </p>
        <div className="text-xs font-medium space-x-4 flex items-center mt-6">
          <span>01:00 PM</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              opacity="0.4"
              d="M4.16634 10H15.833"
              stroke="#667085"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.99967 15.8346L15.833 10.0013L9.99967 4.16797"
              stroke="#667085"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="text-gray-400">02:00 PM</span>
        </div>
        <p className="text-sm font-medium mt-1">{`This will be the session start time and end time respectively`}</p>
      </div>
    ),
  },
  {
    target: ".join-session",
    content: (
      <div>
        <div className="font-medium text-lg">{`Starting your session...`}</div>
        <p className="mt-2 text-sm text-gray-500">
          {`When it's time of joining the session, you can click here to join the session.`}
        </p>

        <Button
          className={`flex items-center space-x-2 w-full text-xs max-w-max mt-6`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
          >
            <path
              opacity="0.12"
              d="M2.28516 6.87962C2.28516 5.73288 2.28516 5.1595 2.50833 4.72151C2.70463 4.33623 3.01787 4.02299 3.40315 3.82669C3.84114 3.60352 4.41452 3.60352 5.56126 3.60352H9.24688C10.3936 3.60352 10.967 3.60352 11.405 3.82669C11.7903 4.02299 12.1035 4.33623 12.2998 4.72151C12.523 5.1595 12.523 5.73288 12.523 6.87962V9.88272C12.523 11.0295 12.523 11.6028 12.2998 12.0408C12.1035 12.4261 11.7903 12.7393 11.405 12.9356C10.967 13.1588 10.3936 13.1588 9.24688 13.1588H5.56126C4.41452 13.1588 3.84114 13.1588 3.40315 12.9356C3.01787 12.7393 2.70463 12.4261 2.50833 12.0408C2.28516 11.6028 2.28516 11.0295 2.28516 9.88272V6.87962Z"
              fill="#009688"
              stroke="#009688"
              strokeWidth="1.36504"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.9356 6.28676C15.9356 5.87328 15.9356 5.66654 15.8538 5.5708C15.7829 5.48774 15.6765 5.44366 15.5676 5.45223C15.442 5.4621 15.2959 5.60829 15.0035 5.90067L12.523 8.38117L15.0035 10.8617C15.2959 11.154 15.442 11.3002 15.5676 11.3101C15.6765 11.3187 15.7829 11.2746 15.8538 11.1915C15.9356 11.0958 15.9356 10.8891 15.9356 10.4756V6.28676Z"
              stroke="#009688"
              strokeWidth="1.36504"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.28516 6.87962C2.28516 5.73288 2.28516 5.1595 2.50833 4.72151C2.70463 4.33623 3.01787 4.02299 3.40315 3.82669C3.84114 3.60352 4.41452 3.60352 5.56126 3.60352H9.24688C10.3936 3.60352 10.967 3.60352 11.405 3.82669C11.7903 4.02299 12.1035 4.33623 12.2998 4.72151C12.523 5.1595 12.523 5.73288 12.523 6.87962V9.88272C12.523 11.0295 12.523 11.6028 12.2998 12.0408C12.1035 12.4261 11.7903 12.7393 11.405 12.9356C10.967 13.1588 10.3936 13.1588 9.24688 13.1588H5.56126C4.41452 13.1588 3.84114 13.1588 3.40315 12.9356C3.01787 12.7393 2.70463 12.4261 2.50833 12.0408C2.28516 11.6028 2.28516 11.0295 2.28516 9.88272V6.87962Z"
              stroke="#009688"
              strokeWidth="1.36504"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Join Session in 30m</span>
        </Button>
        <p className="mt-2 text-sm">
          {`If you try to join early e.g. 30 mins early, the button will reflect that there's still 30 min time left until session start time`}
        </p>
      </div>
    ),
  },
  {
    target: ".session-actions",
    content: (
      <div>
        <div className="font-medium text-lg">Actions</div>
        <p className="mt-2 text-sm text-gray-500">
          This is where you can see actions you can perform on this specific session
        </p>
        <ul className="divide-y divide-y-bg-gray-200 mt-6">
          <li className="pb-4">
            <div className="flex space-x-2 items-center">
              <img src={clock} />
              <span className="text-xs text-gray-500">55-60 min</span>
            </div>
            <p className="mt-2 text-sm">This indicates the duration of the session</p>
          </li>
          <li className="py-4">
            <div className="flex space-x-2 items-center">
              <img src={Check} />
              <span className="text-xs text-gray-500">
                Confirmed by Therapist
              </span>
            </div>

            <p className="mt-2 text-sm">{`This indicates that the therapist has confirmed they'll be joining the session`}</p>
          </li>
          <li className="py-4">
            <div className="flex space-x-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M16 4.76172C16.93 4.76172 17.395 4.76172 17.7765 4.86394C18.8117 5.14134 19.6204 5.94999 19.8978 6.98526C20 7.36676 20 7.83175 20 8.76172V17.9617C20 19.6419 20 20.482 19.673 21.1237C19.3854 21.6882 18.9265 22.1471 18.362 22.4347C17.7202 22.7617 16.8802 22.7617 15.2 22.7617H8.8C7.11984 22.7617 6.27976 22.7617 5.63803 22.4347C5.07354 22.1471 4.6146 21.6882 4.32698 21.1237C4 20.482 4 19.6419 4 17.9617V8.76172C4 7.83175 4 7.36676 4.10222 6.98526C4.37962 5.94999 5.18827 5.14134 6.22354 4.86394C6.60504 4.76172 7.07003 4.76172 8 4.76172M9.6 6.76172H14.4C14.9601 6.76172 15.2401 6.76172 15.454 6.65273C15.6422 6.55685 15.7951 6.40387 15.891 6.21571C16 6.0018 16 5.72177 16 5.16172V4.36172C16 3.80167 16 3.52164 15.891 3.30773C15.7951 3.11957 15.6422 2.96659 15.454 2.87071C15.2401 2.76172 14.9601 2.76172 14.4 2.76172H9.6C9.03995 2.76172 8.75992 2.76172 8.54601 2.87071C8.35785 2.96659 8.20487 3.11957 8.10899 3.30773C8 3.52164 8 3.80167 8 4.36172V5.16172C8 5.72177 8 6.0018 8.10899 6.21571C8.20487 6.40387 8.35785 6.55685 8.54601 6.65273C8.75992 6.76172 9.03995 6.76172 9.6 6.76172Z"
                  stroke="#98A2B3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="text-xs text-gray-500">Notes by Therapist</span>
            </div>

            <p className="mt-2 text-sm">{`This is where you can see the notes provided by the Therapist`}</p>
          </li>
          {/* <li className="py-4">
            <div className="flex space-x-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M20.9996 12.2617C20.9996 16.9561 17.194 20.7617 12.4996 20.7617C11.4228 20.7617 10.3928 20.5615 9.44478 20.1963C9.27145 20.1295 9.18478 20.0961 9.11586 20.0802C9.04807 20.0646 8.999 20.058 8.92949 20.0554C8.85881 20.0527 8.78127 20.0607 8.62619 20.0767L3.50517 20.6061C3.01692 20.6566 2.7728 20.6818 2.6288 20.594C2.50337 20.5174 2.41794 20.3896 2.3952 20.2445C2.36909 20.0778 2.48575 19.8619 2.71906 19.4301L4.35472 16.4025C4.48942 16.1532 4.55677 16.0285 4.58728 15.9087C4.6174 15.7903 4.62469 15.7049 4.61505 15.5831C4.60529 15.4598 4.55119 15.2993 4.443 14.9783C4.15547 14.1253 3.99962 13.2117 3.99962 12.2617C3.99962 7.5673 7.8052 3.76172 12.4996 3.76172C17.194 3.76172 20.9996 7.5673 20.9996 12.2617Z"
                  stroke="#98A2B3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="text-xs text-gray-500">Messages</span>
            </div>

            <p className="mt-2 text-sm">Qui alias dolores ut laboriosam</p>
          </li> */}
          <li className="pt-4">
            <div className="flex space-x-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M12 13.7617C12.5523 13.7617 13 13.314 13 12.7617C13 12.2094 12.5523 11.7617 12 11.7617C11.4477 11.7617 11 12.2094 11 12.7617C11 13.314 11.4477 13.7617 12 13.7617Z"
                  stroke="#98A2B3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19 13.7617C19.5523 13.7617 20 13.314 20 12.7617C20 12.2094 19.5523 11.7617 19 11.7617C18.4477 11.7617 18 12.2094 18 12.7617C18 13.314 18.4477 13.7617 19 13.7617Z"
                  stroke="#98A2B3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 13.7617C5.55228 13.7617 6 13.314 6 12.7617C6 12.2094 5.55228 11.7617 5 11.7617C4.44772 11.7617 4 12.2094 4 12.7617C4 13.314 4.44772 13.7617 5 13.7617Z"
                  stroke="#98A2B3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="text-xs text-gray-500">More Options</span>
            </div>

            <p className="mt-2 text-sm">You can click here to access more actions on the session.</p>
          </li>
        </ul>
      </div>
    ),

  },
  {
    target: ".my-therapist",
    content: (
      <div>
        <div className="font-medium text-lg">My Therapist</div>
        <p className="mt-2 text-sm text-gray-500">
          This is where you can see your therapist. In case you have matched with more than one therapist, you can switch them by clicking here.
        </p>
      </div>
    ),
  },
];

// Define our state
const INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  disableBeacon: true,
  hideCloseButton: true,
  disableCloseOnEsc: true,
  disableOverlayClose: true,
  locale: { skip: "Close", last: "Got it!" },
  hideBackButton: true,
};

// Set up the reducer function
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

// Define the Tour component
const ProductTour = ({ sessionData, setTourGuide }) => {
  const [tourState, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
    steps: generateTourSteps(sessionData),
  });

  useEffect(() => {
    if (!localStorage.getItem("tour")) {
      dispatch({ type: "START" });
    }
  }, []);

  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });
      setTourGuide(false);
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });




  const defaultStyles = {
    buttonNext: {
      backgroundColor: "#01554D",
      borderRadius: 24,
      color: "#fff",
      fontSize: 12,
      outline: "none",
      padding: "0.5rem 0.75rem",
    },
    buttonSkip: {
      color: "",
      fontSize: 12,
      boxShadow: "none",
      fontWeight: "500",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: "20",
    },
    tooltipContainer: {
      textAlign: "left",
      maxHeight: isMobile ? "10rem" : '15rem',
      overflow: "auto",
      padding: isMobile ? 16 : 18,
    },
    tooltipContent: {
      padding: "0",
    },
    tooltipFooter: {
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
      padding: "0px 16px 14px",
      marginTop: isMobile ? 64 : 20,
    },
    buttonBack: {
      marginRight: 10,
    },
    tooltip: {
      borderRadius: 16,
      maxWidth: isMobile ? 260 : "auto",
    },
    spotlight: {
      // background:
      //   "linear-gradient(180deg, rgba(0, 150, 136, 0.10) 0%, rgba(0, 150, 136, 0.00) 100%)",
      backgroundColor: "gray",
      border: "2px dashed #BAE3E0",
    },
    spotlightLegacy: {
      boxShadow: `0 0 0 9999px #cccccc, #bbbbbb`,
    },
  };

  return (
    <JoyRide
      {...tourState}
      callback={callback}
      showSkipButton={true}
      styles={defaultStyles}
    />
  );
};

export default ProductTour;
