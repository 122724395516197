import React, { useState, useRef, useMemo, useEffect } from "react";
import TinderCard from "react-tinder-card";
import Heading from "../../components/atoms/Heading";
import cardGradient from "../../assets/cardGradient.png";
import slide from "../../assets/slideOne.png";
import sheild from "../../assets/sheild.png";
import dashboard from "../../assets/dashboard.png";
import graph from "../../assets/graph.png";
import invoice from "../../assets/invoice.png";
import arrowLeft from "../../assets/onboarding/arrow-left.svg";
import arrowRight from "../../assets/onboarding/arrow-right.svg";

const data = [
  {
    title: `Pay as you go, No auto-subscription`,
    desc: "A therapist who gets your culture and understands you.",
    url: invoice,
    tooltip01: "Buy now, Pay later",
    tooltip02: "Schedule that fits you",
    tooltip03: "Easy check-out booking",
    tooltip01Classes: "bg-[#0F8BFD] -left-8 top-10",
    tooltip02Classes: "bg-[#003833] -right-8 top-16",
    tooltip03Classes: "bg-[#FD9B0F] -left-8 top-1/3",
    index: 4,
  },
  {
    title: "You're on the path to Positive Change",
    desc: "Experience growth through therapy, tools, and personalized support - step by step.",
    url: graph,
    index: 3,
  },
  {
    title: `One dashboard to manage your sessions & progress`,
    desc: "Engage in messaging, sessions, notes and curated resources for your mental well-being.",
    url: dashboard,
    tooltip01: "Therapist’s notes",
    tooltip02: "Appointment reminders",
    tooltip01Classes: "bg-[#0F8BFD] -right-8 top-6",
    tooltip02Classes: "bg-[#FD9B0F] -left-8 top-1/3",
    index: 2,
  },
  {
    title: "End-to-End Encrypted and Private Chats",
    desc: "Connect securely, share openly, and find support within our platform.",
    url: sheild,
    index: 1,
  },
  {
    label: "Licensed Clinical Psychologist",
    title: "Gaston Molina ",
    desc: "Abuse, Anxiety, Burnout, Self con...",
    tipOne:
      "He is very friendly, very insightful, listens to you very well and willing to help you in depth.",
    tipTwo: "Very empathic and non- judgemental",
    image: slide,
    index: 0,
  },
];

const SwiperToolTip = ({ className, text }) => {
  return (
    <span
      className={`px-2 py-1.5 rounded-2xl border border-teal-100 absolute text-sm text-white ${className}`}
    >
      {text}
    </span>
  );
};

const Swiper = () => {
  const [currentIndex, setCurrentIndex] = useState(data.length - 1);
  const currentIndexRef = useRef(currentIndex);
  const childRefs = useMemo(
    () =>
      Array(data.length)
        .fill(0)
        .map(() => React.createRef()),
    []
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canSwipe = currentIndex > 0;
  const canGoBack = currentIndex < data?.length - 1;

  const swiped = (direction, nameToDelete, index) => {
    if (canSwipe && index > 0) {
      updateCurrentIndex(index - 1);
    }
  };

  const swipe = async (dir) => {
    if (canSwipe && currentIndex >= 0) {
      await childRefs[currentIndex]?.current?.swipe(dir);
    }
  };

  const goBack = async () => {
    if (!canGoBack) return;
    const newIndex = currentIndex + 1;
    updateCurrentIndex(newIndex);
    await childRefs[newIndex]?.current.restoreCard();
  };

  useEffect(() => {
    const timer = setInterval(() => {
      swipe("left"); // Swipe left (you can change it to "right" if you want to swipe right)
    }, 5000); // Swipe every 5 seconds

    return () => {
      clearInterval(timer);
    };
  }, [currentIndex]);

  return (
    <div>
      <div className="swipeContainer relative w-[300px] h-[400px]">
        {data.map((card, index) => (
          <TinderCard
            ref={childRefs[index]}
            className={`swipe overflow-hidden ${
              index === currentIndex - 1
                ? "w-[calc(100%-24px)] -top-3"
                : index === currentIndex - 2
                ? "w-[calc(100%-46px)] -top-6"
                : index === currentIndex - 3
                ? "w-[calc(100%-70px)] -top-8"
                : index === currentIndex - 4
                ? "w-[calc(100%-90px)] -top-10"
                : "w-full overflow-visible"
            }`}
            key={card.title}
            preventSwipe={index == 0 ? ["left", "right", "down", "up"] : []}
            onSwipe={(dir) => swiped(dir, card.title, index)}
          >
            {card.image ? (
              <div
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 21.75%, #000 100%), url(${card.image}`,
                }}
                className="shadow-cardImage h-full rounded-2xl flex items-end bg-cover bg-center"
              >
                <div className="p-6 text-white">
                  <span className="text-xxs uppercase text-white/70">
                    Licensed Clinical Psychologist
                  </span>
                  <Heading
                    variant="h2"
                    className="text-2xl text-white/80 font-medium"
                  >
                    {card.title}
                  </Heading>
                  <span className="text-sm flex items-center mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="mr-1"
                    >
                      <path
                        d="M13.3334 4L6.00008 11.3333L2.66675 8"
                        stroke="#009688"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Abuse, Anxiety, Burnout, Self con...
                  </span>
                </div>
                <div className="py-3 px-4 bg-success-900 rounded-2xl text-white absolute top-4 w-3/6 xl:w-4/5 -left-12 xl:-left-36">
                  {/* <div className="absolute text-gray-400 -top-5 text-xs">
                    {`🔊Client's Review`}
                  </div> */}
                  {card?.tipOne}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="34"
                    viewBox="0 0 25 34"
                    fill="none"
                    className="absolute bottom-0 -right-1.5"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.4983 20.9363C17.9824 19.354 18.2428 17.674 18.2428 15.9332V0.60791H1.16479V33.0113C5.64578 33.0113 9.72369 31.2855 12.7701 28.4623C15.6543 30.3013 19.5251 31.5729 24 30.5064C22.8366 29.8527 19.2146 26.7819 17.4983 20.9363Z"
                      fill="#53C645"
                    />
                    <path
                      d="M17.4983 20.9363L17.1623 21.0349L17.1328 20.9342L17.1635 20.8338L17.4983 20.9363ZM18.2428 0.60791V0.25777H18.5929V0.60791H18.2428ZM1.16479 0.60791H0.814654V0.25777H1.16479V0.60791ZM1.16479 33.0113V33.3614H0.814654V33.0113H1.16479ZM12.7701 28.4623L12.5321 28.2055L12.7304 28.0217L12.9584 28.1671L12.7701 28.4623ZM24 30.5064L24.1715 30.2011L24.9518 30.6395L24.0812 30.847L24 30.5064ZM18.5929 15.9332C18.5929 17.7091 18.3272 19.4236 17.8331 21.0387L17.1635 20.8338C17.6375 19.2844 17.8927 17.6389 17.8927 15.9332H18.5929ZM18.5929 0.60791V15.9332H17.8927V0.60791H18.5929ZM1.16479 0.25777H18.2428V0.95805H1.16479V0.25777ZM0.814654 33.0113V0.60791H1.51493V33.0113H0.814654ZM13.0081 28.7191C9.89951 31.5999 5.73747 33.3614 1.16479 33.3614V32.6611C5.5541 32.6611 9.54787 30.971 12.5321 28.2055L13.0081 28.7191ZM24.0812 30.847C19.4911 31.941 15.5237 30.6333 12.5819 28.7575L12.9584 28.1671C15.7849 29.9693 19.559 31.2049 23.9188 30.1658L24.0812 30.847ZM17.8342 20.8376C19.5244 26.5941 23.0864 29.5915 24.1715 30.2011L23.8285 30.8116C22.5867 30.114 18.9048 26.9697 17.1623 21.0349L17.8342 20.8376Z"
                      fill="#53C645"
                    />
                  </svg>
                  <span className="w-7 h-7 rounded-full bg-success-900 absolute bottom-0 -right-9"></span>
                </div>
                <div className="py-3 px-4 bg-blue-600 rounded-2xl text-white absolute w-3/5 xl:w-4/5 left-36 xl:left-56 top-1/2">
                  {card?.tipTwo}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="16"
                    viewBox="0 0 25 16"
                    fill="none"
                    className="absolute bottom-0 -left-1.5"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.4215 6.15499C7.75983 4.50144 7.39587 2.6966 7.39587 0.806763H24.4739V15.2101H21.7992C18.8728 15.2101 16.1501 14.3373 13.8775 12.8379C10.9089 14.0809 6.39321 15.1709 1 14.5071C2.54218 14.0597 8.40435 11.419 8.4215 6.15499Z"
                      fill="#007BD2"
                    />
                    <path
                      d="M7.39587 0.806763V0.456623H7.04573V0.806763H7.39587ZM8.4215 6.15499L8.77163 6.15613L8.77186 6.08808L8.74658 6.02491L8.4215 6.15499ZM24.4739 0.806763H24.824V0.456623H24.4739V0.806763ZM24.4739 15.2101V15.5603H24.824V15.2101H24.4739ZM13.8775 12.8379L14.0703 12.5456L13.9144 12.4428L13.7422 12.5149L13.8775 12.8379ZM1 14.5071L0.902441 14.1709L0.957228 14.8547L1 14.5071ZM7.04573 0.806763C7.04573 2.7419 7.41848 4.59084 8.09642 6.28507L8.74658 6.02491C8.10119 4.41204 7.74601 2.65131 7.74601 0.806763H7.04573ZM24.4739 0.456623H7.39587V1.1569H24.4739V0.456623ZM24.824 15.2101V0.806763H24.1237V15.2101H24.824ZM21.7992 15.5603H24.4739V14.86H21.7992V15.5603ZM13.6846 13.1302C16.0128 14.6662 18.8021 15.5603 21.7992 15.5603V14.86C18.9434 14.86 16.2875 14.0085 14.0703 12.5456L13.6846 13.1302ZM0.957228 14.8547C6.42026 15.527 10.9973 14.4235 14.0127 13.1609L13.7422 12.5149C10.8205 13.7383 6.36617 14.8148 1.04277 14.1596L0.957228 14.8547ZM8.07136 6.15385C8.06325 8.64276 6.67589 10.5298 5.05941 11.8675C3.44063 13.2072 1.6339 13.9587 0.902441 14.1709L1.09756 14.8434C1.90829 14.6082 3.80373 13.8156 5.50587 12.407C7.21031 10.9966 8.7626 8.93126 8.77163 6.15613L8.07136 6.15385Z"
                      fill="#007BD2"
                    />
                  </svg>
                  <span className="w-5 h-5 rounded-full bg-blue-600 absolute bottom-0 -left-7"></span>
                </div>
              </div>
            ) : (
              <div
                className="bg-white rounded-2xl p-6 shadow-slide bg-no-repeat h-full"
                style={{ backgroundImage: `url(${cardGradient})` }}
              >
                <img src={card.url} alt={card.title} className="rounded-2xl" />
                <Heading variant="h2" className="text-2xl text-gray-900 mt-8">
                  {card.title}
                </Heading>
                <p className="text-sm text-gray-400 mt-4">{card.desc}</p>
                {card.tooltip01 && (
                  <SwiperToolTip
                    text={card.tooltip01}
                    className={card.tooltip01Classes}
                  />
                )}
                {card.tooltip02 && (
                  <SwiperToolTip
                    text={card.tooltip02}
                    className={card.tooltip02Classes}
                  />
                )}
                {card.tooltip03 && (
                  <SwiperToolTip
                    text={card.tooltip03}
                    className={card.tooltip03Classes}
                  />
                )}
              </div>
            )}
          </TinderCard>
        ))}
        <div className="absolute flex items-center -bottom-10 justify-center inset-x-0">
          <img
            src={arrowLeft}
            className="mr-2 cursor-pointer"
            onClick={() => goBack()}
          />
          {data?.map((item, index) => {
            return (
              <div
                key={index}
                className="mr-2 w-1.5 h-1.5 rounded-full"
                style={{
                  backgroundColor:
                    item?.index == currentIndex ? "#009688" : "#F2F4F7",
                }}
              ></div>
            );
          })}
          <img
            className="cursor-pointer"
            src={arrowRight}
            onClick={() => swipe("left")}
          />
        </div>
      </div>
    </div>
  );
};

export default Swiper;
