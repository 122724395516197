import React, { useEffect, useRef, useState, useContext } from "react";
import Header from "../../components/molecules/Header";
import SideMenu from "../../components/molecules/SideMenu";
// import AddChat from "../../assets/add-chat.svg";
import SearchIcon from "../../assets/search-icon.svg";
import FilterIcon from "../../assets/filter-lines.svg";
import ClockIcon from "../../assets/clock-stopwatch.svg";
import SendIcon from "../../assets/send_green_icon.svg";
import CloseIcon from "../../assets/x-close.svg";
import MessageSqaure from "../../assets/message-fill-chat-square.svg";
import CheckIcon from "../../assets/check.svg";
import ArrowRight from "../../assets/arrow-right.svg";
import PatientDefaultChatIcon from "../../assets/patient_default_chat.svg";
import MessageLockIcon from "../../assets/message_lock.svg"
import PatientMessageCorner from "../../assets/green-message-corner.svg";
import TherapistMessageCorner from "../../assets/white-message-corner.svg";
import Avatar from "../../assets/profile-picture-avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getPatientTherapistChatList } from "../../redux/agora/agoraThunks";
import {
  getMessageHistory,
  patchSingleMessage,
  saveSingleMessage,
  sendMessageToFitcyAIBot,
  deleteChatWithBot
} from "../../redux/patient/patientThunks";
import moment from "moment";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import { whatsappClientNumber } from "../../services/utils/constants";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { MetaTags, lastMessageTime } from "../../services/globalFunctions";
import { RTMClientContext } from "../../contexts/RTMClientContext";
import mixpanel from "mixpanel-browser";
import ChatInput from "../../components/organisms/ChatInput";
let page = 1;

const userSkeletonData = [{}, {}];
const PatientMessages = () => {
  const { rtmClient } = useContext(RTMClientContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.app.user);
  const token = useSelector((state) => state.agora.rtmToken);
  const messageHistory = useSelector((state) => state.patient.messageHistory);
  const chatList = useSelector((state) => state.agora.chatList);
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const messageRef = useRef();
  const filterRef = useRef();
  const uid = user?.id;

  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const fitcyBotID = process.env.REACT_APP_FITCY_BOT_ID;
  const [filterModal, setFilterModal] = useState(false);
  const [modalPosition, setModalPosition] = useState({
    top: 0,
    left: 0,
  });
  const [filter, setFilter] = useState("Newest");
  const [messageRefHeight, setMessageRefHeight] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [therapistList, setTherapistList] = useState(chatList?.data);
  const [refresh, setRefresh] = useState(false);
  const [isAwaitingAIResponse, setIsAwaitingAIResponse] = useState(false);

  const sortedTherapistList =
    therapistList && therapistList?.length !== 0
      ? [...therapistList]?.sort((a, b) => {
        if (a.id == fitcyBotID) return -1;
        if (b.id == fitcyBotID) return 1;
        return 0;
      })
      : [];

  const emptyUserList = therapistList?.length == 0 && searchText == "";

  const FinalMessageList = [];
  const reverseArray = chatMessages?.slice(0, page * 100)?.reverse();

  for (let i = 0; i < reverseArray?.length; i++) {
    const currentItem = reverseArray[i];
    const previousItem = reverseArray[i - 1];

    if (i === 0 || currentItem.days !== previousItem.days) {
      FinalMessageList.push({ newDay: currentItem.days });
    }

    FinalMessageList.push(currentItem);
  }

  useEffect(() => {
    document.body.classList.remove("overflow-hidden");
  }, []);
  useEffect(() => {
    if (selectedTherapist) {
      const container = document.getElementById("message-container");
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [messageHistory, selectedTherapist]);

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    if (scrollTop === 0 && messageHistory?.next) {
      page += 1;
      loadMessageHistory();
    }
  };

  useEffect(() => {
    setTherapistList(chatList?.data);
  }, [chatList]);

  useEffect(() => {
    if (page == 1) setChatMessages(messageHistory?.data || []);
    else
      setChatMessages(
        Object.assign([], chatMessages?.concat(messageHistory?.data))
      );
  }, [messageHistory]);

  useEffect(() => {
    if (messageRef.current) {
      if (page == 1) {
        messageRef.current.scrollTop = messageRef?.current?.scrollHeight;
        setMessageRefHeight(messageRef?.current?.scrollHeight);
      } else
        messageRef.current.scrollTop =
          messageRef?.current?.scrollHeight - messageRefHeight;
    }
  }, [chatMessages, isMobile, page]);

  useEffect(() => {
    page = 1;
    fetchChatList();
    if (location?.state?.therapist_info) {
      setSelectedTherapist(location?.state?.therapist_info);
    } else if (chatList?.data && chatList.data.length > 0) {
      // Select first therapist by default if no therapist is specified in location state
      const sortedList = [...chatList.data].sort((a, b) => {
        if (a.id == fitcyBotID) return -1;
        if (b.id == fitcyBotID) return 1;
        return 0;
      });
      setSelectedTherapist(sortedList[0]);
    }
  }, []);

  useEffect(() => {
    if (!selectedTherapist && chatList?.data && chatList.data.length > 0) {
      // Select first therapist when chat list loads if none is selected
      const sortedList = [...chatList.data].sort((a, b) => {
        if (a.id == fitcyBotID) return -1;
        if (b.id == fitcyBotID) return 1;
        return 0;
      });
      setSelectedTherapist(sortedList[0]);
    }
  }, [chatList]);

  useEffect(() => {
    if (token && selectedTherapist && !subscribed) initialCall();
    else if (token && selectedTherapist) {
      page = 1;
      loadMessageHistory();
    }
  }, [token, selectedTherapist]);

  useEffect(() => {
    if (rtmClient) {
      const handleMessage = (eventArgs) => {
        page = 1;
        if (eventArgs.publisher !== uid?.toString()) {
          loadMessageHistory(eventArgs?.publisher);
        }
        fetchChatList();
      };

      const handleStatus = (eventArgs) => {
        console.log(eventArgs, "event status change");
      };

      try {
        rtmClient.addEventListener("message", handleMessage);
        rtmClient.addEventListener("status", handleStatus);
      } catch {
        console.log("Error in fetching listener message");
      }

      // Cleanup function
      return () => {
        rtmClient.removeEventListener("message", handleMessage);
        rtmClient.removeEventListener("status", handleStatus);
      };
    }
  }, [rtmClient]);

  const initialCall = async () => {
    try {
      if (rtmClient) {
        try {
          await subscribeCall();
          setSubscribed(true);
          page = 1;
          loadMessageHistory();
        } catch (err) {
          console.log(err, "error in login");
        }
      }
    } catch (error) {
      console.log(error, "error in setup");
    }
  };

  const subscribeCall = async () => {
    try {
      const subscribeOptions = {
        withMessage: true,
        withPresence: true,
        withMetadata: true,
        withLock: true,
      };
      selectedTherapist.id != fitcyBotID &&
        (await rtmClient.subscribe(uid?.toString(), subscribeOptions));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchChatList = (text = "", sort = filter?.toLowerCase()) => {
    dispatch(
      getPatientTherapistChatList({
        keyword: text,
        sort: sort,
      })
    );
  };

  const handleDelete = () => {
    console.log("Delete Chat");
    dispatch(deleteChatWithBot({}));
  };

  const loadMessageHistory = (therapist_id = selectedTherapist?.id) => {
    dispatch(
      getMessageHistory({
        patient_id: uid,
        therapist_id: therapist_id,
        page: page,
      })
    );
  };

  const sendText = async () => {
    try {
      let body = {
        to: selectedTherapist.id,
        sender: uid,
        message: message,
        message_type: "text",
        read_status: false,
      };
      const updatedMessages = [body, ...chatMessages];
      setChatMessages(updatedMessages);
      setMessage("");

      if (selectedTherapist.id != fitcyBotID) {
        await rtmClient.publish(uid?.toString(), message);
        dispatch(saveSingleMessage(body));
      } else {
        body.read_status = true;

        setIsAwaitingAIResponse(true);
        console.log('Dispatching message to FitcyAIBot');
        await dispatch(sendMessageToFitcyAIBot(body));
        try {
          await fetchChatList();
          page = 1;
          await loadMessageHistory(selectedTherapist.id);
          setIsAwaitingAIResponse(false);
        } catch (error) {
          console.error('Error updating chat after AI response:', error);
          setIsAwaitingAIResponse(false);
        }
      }

      mixpanel?.track("message", {
        message_type: "text",
        to: selectedTherapist.id,
        role: user.role,
        sender: uid,
      });

      if (selectedTherapist.id != fitcyBotID) {
        body.created = new Date();
        body.days = "Today";
      }
      fetchChatList();
      requestAnimationFrame(() => {
        if (messageRef.current) {
          messageRef.current.scrollTop = messageRef.current.scrollHeight;
          console.log('Scrolled to bottom');
        }
      });
    } catch (err) {
      setRefresh(true);
      setIsAwaitingAIResponse(false);
      console.log({ err }, "error occurs at publish message");
      console.error('Error in sendText:', err);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendText();
    }
  };

  const commonModalStyles = {
    content: {
      maxWidth: "120px",
      width: "100%",
      top: `${modalPosition.top}px`,
      left: `${modalPosition.left}px`,
      border: "none",
      borderRadius: "16px",
      height: "max-content",
      background: "#fff",
      overflowY: "auto",
    },
    overlay: {
      background: "transparent",
      zIndex: "10",
    },
  };

  // const emptyChatStyles = {
  //   content: {
  //     maxWidth: isMobile ? "350px" : "390px",
  //     border: "none",
  //     top: "0px",
  //     bottom: "0px",
  //     left: "0px",
  //     right: "0px",
  //     margin: "auto",
  //     borderRadius: "16px",
  //     height: "max-content",
  //     background: "#fff",
  //     overflowY: "auto",
  //     padding: "24px",
  //   },
  //   overlay: {
  //     background: "rgba(0, 0, 0, 0.09)",
  //     zIndex: 99999,
  //   },
  // };

  const openDropdown = () => {
    const boundingBox = filterRef?.current.getBoundingClientRect();
    setModalPosition({
      top: boundingBox.top + window.scrollY + 20,
      left: boundingBox.left + window.scrollX - 100,
    });
    setFilterModal(true);
  };

  const handleSearchChat = (e) => {
    setSearchText(e.target.value);
    if (e?.target?.value) {
      const res = chatList?.data?.filter((listItem) =>
        (listItem?.first_name + " " + listItem?.last_name)
          ?.toString()
          ?.toLowerCase()
          ?.includes(e.target?.value?.toLowerCase())
      );
      setTherapistList(res);
    } else setTherapistList(chatList?.data);
  };

  const TherapistListComponent = () => {
    return (
      <div
        className={`w-full lg:w-80 px-4 lg:px-5 py-5 lg:border-r lg:border-r-solid`}
      >
        <div className="flex items-center justify-between">
          <div className="text-2xl font-medium">Chats</div>
          {/* <img src={AddChat} /> */}
        </div>
        <div className="flex items-center drop-shadow justify-between bg-white rounded-lg py-2.5 px-3.5 my-5">
          <div className="flex items-center space-x-2">
            <img src={SearchIcon} />
            <input
              placeholder="Search..."
              className="outline-none border-none w-full"
              onChange={handleSearchChat}
            />
          </div>
          <div
            ref={filterRef}
            className="cursor-pointer"
            onClick={openDropdown}
          >
            <img src={FilterIcon} />
          </div>
        </div>
        <div className="overflow-y-auto h-[calc(100vh-19rem)] lg:h-[68vh]">
          {emptyUserList || !therapistList ? (
            <SkeletonTheme baseColor="#E4E7EC" highlightColor="#F2F4F7">
              {userSkeletonData?.map((_, index) => {
                return (
                  <div
                    key={index}
                    className={`px-3 flex items-center space-x-3 rounded-lg`}
                  >
                    <img
                      src={Avatar}
                      alt="Profile Image"
                      className="w-12 h-12 rounded-full my-3 bg-gray-100 object-contain"
                    />
                    <div className="flex-1 space-y-1 py-3">
                      <div className="flex items-center justify-between">
                        <Skeleton height={20} width={130} />
                        <Skeleton height={20} width={30} />
                      </div>
                      <div className="flex items-center justify-between">
                        <Skeleton height={15} width={60} />
                        <Skeleton height={15} width={15} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </SkeletonTheme>
          ) : (
            <>
              {sortedTherapistList?.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      fetchChatList();
                      setSelectedTherapist(item);
                      page = 1;
                      loadMessageHistory(item.id);
                    }}
                    key={index}
                    className={`px-3 flex items-center space-x-3 rounded-lg cursor-pointer ${selectedTherapist.id == item.id && "bg-teal-50"
                      }`}
                  >
                    <img
                      src={item?.profile_picture?.full_url || Avatar}
                      alt="Profile Image"
                      className="w-12 h-12 rounded-full my-3 bg-gray-100 object-contain"
                    />
                    <div className="flex-1 space-y-1 border-b border-b-solid py-3">
                      <div className="flex items-center justify-between">
                        <div className="w-48 lg:w-32 overflow-hidden whitespace-nowrap">
                          <div className="text-base font-medium text-gray-500 text-ellipsis overflow-hidden">
                            {(item?.first_name || "") +
                              " " +
                              (item?.last_name || "")}
                          </div>
                        </div>
                        {item?.latest_msg && (
                          <div className="text-xs text-gray-400">
                            {lastMessageTime(item?.latest_msg?.created)}
                            {/* {moment(item?.latest_msg?.created).format(
                              "hh:mm a"
                            )} */}
                          </div>
                        )}
                      </div>
                      <div className="flex items-center justify-between">
                        <div
                          className={`text-sm ${item?.latest_msg
                            ? "text-gray-500"
                            : "text-transparent"
                            }`}
                        >
                          {item?.latest_msg?.message?.slice(0, 17) +
                            (item?.latest_msg?.message?.length > 17
                              ? "..."
                              : "")}
                        </div>
                        {item?.unread_count > 0 && (
                          <div className="text-[10px] text-white font-semibold bg-[#CD1309] px-2 border-[1.5px] border-white rounded-full">
                            {item?.unread_count || 0}
                          </div>
                        )}

                        {item.id == fitcyBotID && <PinIcon />}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    );
  };

  const ChatComponent = () => {
    return (
      <div className="flex-1">
        <div className="border-b border-b-solid py-4 px-4 lg:px-6 lg:flex space-y-2 lg:space-y-0 items-end justify-between">
          {emptyUserList ? (
            <div className="h-12"></div>
          ) : !emptyUserList ? (
            <>
              <div className="flex items-center space-x-2">
                {isMobile && (
                  <div onClick={() => setSelectedTherapist("")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M9.41379 12L14.6568 17.2788C15.0473 17.6725 15.0473 18.3109 14.6568 18.7047C14.2663 19.0984 13.6333 19.0984 13.2428 18.7047L7.29289 12.7131C6.90237 12.3192 6.90237 11.6807 7.29289 11.2869L13.2428 5.29532C13.6333 4.90156 14.2663 4.90156 14.6568 5.29532C15.0473 5.68907 15.0473 6.32748 14.6568 6.72124L9.41379 12Z"
                        fill="#101828"
                      />
                    </svg>
                  </div>
                )}
                <img
                  src={selectedTherapist?.profile_picture?.full_url || Avatar}
                  alt="Profile Image"
                  className="w-9 h-9 rounded-full bg-gray-100 object-contain"
                />
                <div>
                  <div className="text-base font-medium">
                    {(selectedTherapist?.first_name || "") +
                      " " +
                      (selectedTherapist?.last_name || "")}
                  </div>
                  {selectedTherapist?.next_session && (
                    <div className="text-sm text-gray-500">
                      Upcoming session in{" "}
                      {selectedTherapist?.next_session?.days}
                    </div>
                  )}
                </div>
              </div>
              {selectedTherapist?.id == fitcyBotID ? (
                <button
                  onClick={handleDelete}
                  className="text-sm text-teal-50 hover:text-teal-100 bg-red-600 hover:bg-red-700 rounded px-2 py-0.5 mb-1 transition-colors"
                >
                  Delete Chat
                </button>
              ) : (
                <div className="flex items-center space-x-1">
                  <img src={ClockIcon} alt="Clock Icon" />
                  <div className="text-xs text-gray-500">
                    {selectedTherapist?.first_name}`s Time:
                  </div>
                  <div className="text-xs font-medium">
                    {moment(selectedTherapist?.local_time)
                      .tz(selectedTherapist?.timezone)
                      ?.format("hh:mm A")}
                  </div>
                </div>
              )}


            </>
          ) : (
            <SkeletonTheme baseColor="#E4E7EC" highlightColor="#F2F4F7">
              <div className="flex items-center space-x-2">
                <img
                  src={Avatar}
                  alt="Profile Image"
                  className="w-9 h-9 rounded-full bg-gray-100 object-contain"
                />
                <div>
                  <Skeleton height={20} width={130} />
                  <Skeleton height={20} width={30} />
                </div>
              </div>
              <Skeleton height={20} width={130} />
            </SkeletonTheme>
          )}
        </div>

        <div className="flex justify-center w-full bg-teal-50">
          <div className="flex items-center bg-teal-900 rounded px-2 py-1 space-x-1 shadow-sm">
            <img src={MessageLockIcon} className="w-3 h-3" alt="Encryption Lock" />
            <div className="text-teal-50 text-xs">We respect your privacy. Your messages are end to end encrypted.</div>
          </div>
        </div>

        <div
          id={"message-container"}
          ref={messageRef}
          className={`bg-teal-50 h-[calc(100vh-320px)] lg:h-[calc(100vh-230px)] px-6 lg:px-10 py-3 overflow-y-auto`}
        >

          {FinalMessageList?.map((item, index) => {
            if (item?.sender != uid && !item?.read_status && item?.id)
              dispatch(patchSingleMessage({ id: item?.id, read_status: true }));
            return (
              <div key={index}>
                {item?.newDay ? (
                  <div className="flex justify-center">
                    <div className="font-medium text-xs bg-white py-1.5 px-3 rounded-lg drop-shadow mt-3 mb-1">
                      {item?.newDay}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`flex ${item?.sender == uid ? "justify-end" : "justify-start"
                      }`}
                  >
                    {item?.sender == uid ? (
                      <div className="flex space-x-2 md:space-x-4 bg-[#BAE3E0] p-2 drop-shadow mt-2.5 md:max-w-[50%] rounded-b-lg rounded-tl-lg items-end">
                        <div className="text-sm">{item?.message || ""} </div>
                        <div className="text-xs text-right font-medium text-gray-500 whitespace-nowrap">
                          {moment(item?.created)
                            .tz(user.timezone)
                            .format("hh:mm A")}
                        </div>
                        <img
                          src={PatientMessageCorner}
                          className="absolute -right-1.5 top-0"
                        />
                      </div>
                    ) : (
                      item &&
                      item.message && (
                        <div className="flex space-x-2 md:space-x-4 bg-white p-2 drop-shadow mt-2.5 md:max-w-[50%] rounded-b-lg rounded-tr-lg items-end">
                          <div className="text-sm">{item?.message || ""}</div>
                          <div className="text-xs font-medium text-gray-500 whitespace-nowrap">
                            {moment(item?.created)
                              .tz(user.timezone)
                              .format("hh:mm A")}
                          </div>
                          <img
                            src={TherapistMessageCorner}
                            className="absolute top-0 -left-[22px]"
                          />
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            );
          })}

          {selectedTherapist.id == fitcyBotID && isAwaitingAIResponse && (
            <div className="flex mt-5">
              <TypingIndicator />
            </div>
          )}
        </div>

        <ChatInput
          refresh={refresh}
          message={message}
          setMessage={setMessage}
          sendText={sendText}
          handleKeyPress={handleKeyPress}
          selectedUser={selectedTherapist}
          SendIcon={SendIcon}
          inactiveMessage="Your package with this therapist is no longer active. Please renew your package to continue the conversation."
        />
      </div>
    );
  };

  const PinIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="#5f6368"
    >
      <g>
        <rect fill="none" width="24" height="24" />
      </g>
      <g>
        <path
          fill="#00554d"
          d="M16,9V4l1,0c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H7C6.45,2,6,2.45,6,3v0 c0,0.55,0.45,1,1,1l1,0v5c0,1.66-1.34,3-3,3h0v2h5.97v7l1,1l1-1v-7H19v-2h0C17.34,12,16,10.66,16,9z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );

  const TypingIndicator = () => {
    return (
      <div className="relative flex space-x-2">
        <span className="w-2.5 h-2.5 bg-[#00554d] rounded-full animate-pulse opacity-30"></span>
        <span className="w-2.5 h-2.5 bg-[#00554d] rounded-full animate-pulse opacity-30 [animation-delay:200ms]"></span>
        <span className="w-2.5 h-2.5 bg-[#00554d] rounded-full animate-pulse opacity-30 [animation-delay:400ms]"></span>
      </div>
    );
  };

  const DefaultChatComponent = () => {
    return (
      <div className="flex-1 bg-teal-50 flex flex-col items-center justify-center">
        <img src={PatientDefaultChatIcon} />
        <div className="font-medium text-xl mt-6 mb-4">
          Select a chat to appear here
        </div>
      </div>
    );
  };

  return (
    <>
      <MetaTags
        title="Chats | Fitcy Health"
        description="Manage your Fitcy Health chats"
        url="/messages"
      />
      <Header
        dashboard={true}
        className="border-b border-gray-200 fixed z-10 bg-white"
      />
      <div className="flex lg:pl-24 pt-[4.5rem] relative">
        <SideMenu className="bg-white left-0 lg:top-[4.45rem]" />
        {isMobile ? (
          <>{selectedTherapist ? ChatComponent() : TherapistListComponent()}</>
        ) : (
          <>
            {TherapistListComponent()}
            {selectedTherapist || emptyUserList
              ? ChatComponent()
              : DefaultChatComponent()}
          </>
        )}
        <Modal
          isOpen={filterModal}
          onRequestClose={() => {
            setFilterModal(false);
          }}
          style={commonModalStyles}
          className={`${isMobile && filterModal
            ? "translate-y-0"
            : "translate-y-full lg:translate-y-0"
            } w-full transform transition-transform fixed bottom-0 outline-none`}
        >
          <div
            onClick={() => {
              setFilter("Newest");
              fetchChatList("", "Newest");
              setFilterModal(false);
            }}
            className={`text-base font-medium py-2 px-3 cursor-pointer ${filter == "Newest" ? "bg-gray-100" : "text-gray-500"
              }`}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setFilter("Oldest");
              fetchChatList("", "Oldest");
              setFilterModal(false);
            }}
            className={`text-base font-medium py-2 px-3 cursor-pointer ${filter == "Oldest" ? "bg-gray-100" : "text-gray-500"
              }`}
          >
            Oldest
          </div>
        </Modal>
        {emptyUserList ? (
          <div className="flex justify-center items-center absolute inset-0 bg-black/10 backdrop-blur-sm z-10 px-4 lg:px-0">
            <div className="p-4 pt-8 lg:p-5 lg:pt-10 rounded-2xl max-w-sm w-full bg-white relative">
              <div className="flex items-start justify-between">
                <img src={MessageSqaure} />
                <img
                  className="cursor-pointer"
                  src={CloseIcon}
                  onClick={() =>
                    window.location.replace("/resources/?skip_questions")
                  }
                />
              </div>
              <div className="text-lg font-medium my-2">
                Your messages will appear here
              </div>
              <div className="text-sm font-medium text-gray-500">
                Once you get matched with a therapist, you can text them from
                here
              </div>
              <div className="space-y-1 mt-4">
                <div className="flex items-center space-x-2">
                  <img src={CheckIcon} />
                  <div className="text-sm font-medium">
                    End-to-end encrypted
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <img src={CheckIcon} />
                  <div className="text-sm font-medium">
                    Unlimited texts for the duration of the package validity
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <img src={CheckIcon} />
                  <div className="text-sm font-medium">
                    Available instantly after matching with therapist
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between mt-14">
                <div
                  onClick={() =>
                    window.open(`https://wa.me/${whatsappClientNumber}`)
                  }
                  className="text-gray-500 text-sm font-medium py-2.5 cursor-pointer"
                >
                  Connect with support!
                </div>
                <div
                  onClick={() =>
                    window.location.replace("/resources/?skip_questions")
                  }
                  className="bg-teal-900 rounded-full py-2.5 px-3 flex items-center space-x-2 cursor-pointer"
                >
                  <div className="text-sm lg:text-base font-medium text-white">
                    Get matched!
                  </div>
                  <img className="w-5 lg:w-7 h-5 lg:h-7" src={ArrowRight} />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default PatientMessages;
