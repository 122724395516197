import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPassword,
  forgotTherapistPassword,
} from "../../redux/app/appThunks";
import { useNavigate } from "react-router-dom";
import Heading from "../../components/atoms/Heading";
import Link from "../../components/atoms/Link";
import ButtonLoader from "../../components/atoms/ButtonLoader";
import Header from "../../components/molecules/Header";
import pattern from "../../assets/auth-pattern.png";
import Input from "../../components/atoms/Input";
import Form from "../../components/molecules/Form";
import Button from "../../components/atoms/Button";
import Swiper from "../../components/molecules/Swiper";
import EmailIcon from "../../assets/mail.svg";
import therapistPattern from "../../assets/circles.png";
import calendar from "../../assets/calendar.svg";
import barChart from "../../assets/bar-chart.svg";
import shieldDollar from "../../assets/shield-dollar.svg";
import TherapistImage from "../../assets/therapistAppMain.png";
import successIcon from "../../assets/success.png";
import backgroundImage from "../../assets/onboarding/bg-shadow.png";

const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export default function ForgotPassword({ app }) {
  const [success] = useState(
    localStorage.getItem("passwordResetInProgress") ? true : false
  );
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const emailError = useSelector((state) => state.app.forgotEmailError);

  const handleLogin = (e) => {
    e.preventDefault();
    if (app === "THERAPIST") {
      navigate(`/therapist/login`);
    } else {
      navigate(`/login`);
    }
  };
  const handleSignUp = (e) => {
    e.preventDefault();
    if (app === "THERAPIST") {
      navigate(`/therapist/sign-up`);
    } else {
      navigate(`/sign-up`);
    }
  };
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    let validationErrors = {};
    const emailErrors = checkEmailValidation(email);
    validationErrors = { ...emailErrors };
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const res = await dispatch(
        app === "THERAPIST"
          ? forgotTherapistPassword({
              email: e.target.email.value,
            })
          : forgotPassword({
              email: e.target.email.value,
            })
      );
      if (res.error) {
        setLoading(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const checkEmailValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.emailError = "Please input the email";
    else if (!emailPattern.test(value))
      Errors.emailError = "Please input a valid email";
    else delete Errors.emailError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };
  return (
    <div className="h-100">
      {success ? (
        <div
          className="h-100 bg-cover"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="py-8 rounded-lg bg-white shadow-registrationForm z-10 relative max-w-lg mx-auto top-12">
            <img src={successIcon} className="mx-auto" />
            <div className="max-w-xs text-center mx-auto">
              <Heading variant="h2" className="text-2xl font-medium">
                {"Password Reset Request Successful!"}
              </Heading>
              <p className="mt-2 text-gray-500">
                {`Password reset instructions has been sent to your email, use link from your email to set a new password`}
              </p>
            </div>

            <div className="text-gray-400 font-medium text-sm text-center mt-6">
              <Link
                href={app === "THERAPIST" ? "/therapist/login" : "/login"}
                className="text-teal-500 underline block"
              >
                Go back to Login
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Header className="lg:absolute" />
          <div className="flex flex-col lg:flex-row relative">
            <div className="flex justify-center lg:w-1/2 pb-24 lg:pb-0 pt-16 lg:pt-24">
              <div className="lg:w-[26rem] w-full px-4">
                <Form
                  heading="Forgot Password?"
                  peragraph="Please enter your email, we will send you instruction to reset your password."
                  method="POST"
                  onSubmit={handlePasswordReset}
                  className="mt-6"
                >
                  <Input
                    icon={EmailIcon}
                    label="Email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      checkEmailValidation(e.target.value, errors);
                    }}
                    onChangeCapture={() => {
                      setLoading(false);
                    }}
                    placeholder="Email"
                    error={emailError ?? errors.emailError}
                    app={app}
                    onBlur={() => {
                      checkEmailValidation(email, errors);
                    }}
                  />

                  <Button
                    disabled={loading}
                    type="submit"
                    className={`text-white ${
                      app === "THERAPIST"
                        ? "bg-blue-900 hover:bg-blue-500"
                        : "bg-teal-900 hover:bg-teal-500"
                    }  hover:shadow-button transition-all mt-10`}
                    text={loading ? <ButtonLoader /> : "Reset Password"}
                    full
                  />

                  <div
                    onClick={handleSignUp}
                    className="text-gray-400 cursor-pointer mt-4 text-center"
                  >
                    Create new account
                  </div>
                </Form>
              </div>
            </div>
            {app === "THERAPIST" ? (
              <div
                className="h-screen flex-1 bg-cover lg:w-1/2 justify-end items-center flex overflow-hidden py-24 lg:py-0"
                style={{ backgroundImage: `url(${therapistPattern})` }}
              >
                <div className="relative">
                  <img src={TherapistImage} />
                  <div className="p-3 rounded-lg bg-white/70 shadow-therapistInfoCard w-56 absolute top-20 left-1/3">
                    <img src={calendar} />
                    <span className="mt-3 text-sm font-medium text-gray-800/700">
                      Manage & grow your practice effortlessly
                    </span>
                  </div>
                  <div className="p-3 rounded-lg bg-white/70 shadow-therapistInfoCard w-52 absolute top-1/2 left-0">
                    <img src={shieldDollar} />
                    <span className="mt-3 text-sm font-medium text-gray-800/700">
                      seamless payment system
                    </span>
                  </div>
                  <div className="p-3 rounded-lg bg-white/70 shadow-therapistInfoCard w-[18.5rem] absolute bottom-20 left-1/2">
                    <img src={barChart} />
                    <span className="mt-3 text-sm font-medium text-gray-800/700">
                      Meaningful insights about your practice & client’s
                      behavior
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="h-screen flex-1 bg-cover bg-teal-900 lg:w-1/2 justify-center items-center flex overflow-hidden px-4 py-24 lg:py-0"
                style={{ backgroundImage: `url(${pattern})` }}
              >
                <Swiper />
              </div>
            )}
            <button
              onClick={handleLogin}
              className="absolute w-12 h-12 flex justify-center items-center left-4 lg:left-10 lg:top-24 rounded-full drop-shadow"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.41379 12L14.6568 17.2788C15.0473 17.6725 15.0473 18.3109 14.6568 18.7047C14.2663 19.0984 13.6333 19.0984 13.2428 18.7047L7.29289 12.7131C6.90237 12.3192 6.90237 11.6807 7.29289 11.2869L13.2428 5.29532C13.6333 4.90156 14.2663 4.90156 14.6568 5.29532C15.0473 5.68907 15.0473 6.32748 14.6568 6.72124L9.41379 12Z"
                  fill="#101828"
                />
              </svg>
            </button>
          </div>
        </>
      )}
    </div>
  );
}
