import React from "react";
import clsx from "clsx";

const Button = ({
  type,
  text,
  children,
  className,
  onClick,
  full,
  small,
  app,
  disabled,
  onMouseOver,
  onMouseOut
}) => {
  return (
    <button
      type={type}
      className={`${clsx(className)} ${
        small ? "px-2 py-1 text-xs" : "px-5 py-2.5 text-lg"
      } ${app === "THERAPIST" ? "rounded-xl" : "rounded-3xl"} ${
        disabled ? "" : "cursor-pointer"
      } font-medium ${full ? "w-full" : ""}`}
      onClick={onClick}
      disabled={disabled}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {children}
      {text}
    </button>
  );
};
export default Button;
