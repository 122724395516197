import React from "react";

const RadioButton = ({ value, checked, onChange, label, app, bgColor }) => {
  return (
    <label className="flex items-center">
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        className="hidden"
      />
      <div
        className={`w-6 h-6 flex items-center justify-center rounded-full border border-gray-300 bg-${
          checked
            ? bgColor
              ? "white"
              : app === "THERAPIST"
              ? "blue-900"
              : "teal-900"
            : "gray-300"
        }`}
      >
        {checked && (
          <div
            className={`w-3 h-3 rounded-full ${bgColor ?? "bg-white"} `}
          ></div>
        )}
      </div>
      {label && <span className="ml-2">{label}</span>}
    </label>
  );
};

export default RadioButton;
