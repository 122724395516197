import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../atoms/Heading";
import TherapistCard from "../molecules/TherapistCard";
import Check from "../../assets/check.svg";
import FreeLabel from "../../assets/free-label.svg";
import FreeSwitching from "../../assets/free-switching.svg";
import FreeTextTherapy from "../../assets/free-text-therapy.svg";
import CustomerSupport from "../../assets/customer-support.svg";
import languageIcon from "../../assets/translate.svg";
import searchGlass from "../../assets/search-sm.svg";
import gendersIcon from "../../assets/genders-icon.svg";
import videoIcon from "../../assets/video-icon.svg";
import pricing from "../../assets/currency-dollar-circle.svg";
import expertise from "../../assets/expertise.svg";
import ChevronDown from "../../assets/chevron-down.svg";
import CloseIcon from "../../assets/x-close.svg";
import Button from "../atoms/Button";
import SemiCircleProgressBar from "../molecules/SemiCircleProgressBar";
import TherapistListingFilters from "../molecules/TherapistListingFilters";
import {
  getAllRecommendations,
  getAssessmentsSummary,
} from "../../redux/onboarding/onboardingThunks";
import { getPackages } from "../../redux/patient/patientThunks";
import { useMediaQuery } from "react-responsive";
import Modal from "react-modal";
import {
  LANGUAGE_FILTER,
  GENDER_FILTER,
  TYPE_FILTER,
  PRICING_FILTER,
  EXPERTISE_FILTER,
} from "../../services/utils/constants";
import BlueIcon from "../../assets/blue-tier-ico.svg";
import GoldIcon from "../../assets/Gold-tier-ico.svg";
import SilverIcon from "../../assets/silver-tier-ico.svg";
import VideRecorder from "../../assets/video-session-icon.svg";
import clock from "../../assets/clock-dark-gray.svg";

// const numberToWords = (number) => {
//   const words = [
//     "Zero",
//     "One",
//     "Two",
//     "Three",
//     "Four",
//     "Five",
//     "Six",
//     "Seven",
//     "Eight",
//     "Nine",
//     "Ten",
//     "Eleven",
//     "Twelve",
//     "Thirteen",
//     "Fourteen",
//     "Fifteen",
//     "Sixteen",
//     "Seventeen",
//     "Eighteen",
//     "Nineteen",
//   ];

//   if (number < 0 || number > 19) {
//     return "Out of range";
//   }

//   return words[number];
// };

const buildQueryString = (params) => {
  const queryStringParams = [];

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (Array.isArray(value)) {
      // If the value is an array, join its elements with '&'
      const arrayParams = value.map(
        (v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`
      );
      queryStringParams.push(...arrayParams);
    } else {
      // If it's not an array, handle it as usual
      queryStringParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  });

  return queryStringParams.join("&");
};
const services = {
  1: "In Person Therapy",
  2: "Virtual Therapy",
  3: "In Person and Virtual Therapy",
};
const TherapistSelection = ({
  data,
  goToNextStep,
  setRefresh,
  refresh,
  bookingLoader,
}) => {
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [packages, setPackages] = useState(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [modalState, setModalState] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [allRecommendations, setAllRecommendations] = useState([]);
  const [isCommonModal, setIsCommonModal] = useState(false);
  const [isModalType, setIsModalType] = useState("");
  const [appliedFilters, setAppliedFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [isHover, setIsHover] = useState(false);
  const recordsPerPage = 8;
  const totalPages = Math.ceil(allRecommendations?.count / recordsPerPage);
  const recommendations = useSelector(
    (state) => state.onboarding.recommendations
  );
  const summary = useSelector((state) => state.onboarding.summary);
  const targetSummary = summary?.data.filter(
    (item) => item?.type === data?.type && item.id === data.id
  );
  const getResultPercentage =
    (targetSummary?.[0]?.total_answer_score /
      targetSummary?.[0]?.total_question_score) *
    100;
  const sortedScales =
    data && data.assessment_scales
      ? [...data.assessment_scales].sort(
        (a, b) => a.score_start - b.score_start
      )
      : [];

  const languageDivRef = useRef();
  const genderDivRef = useRef();
  const typeDivRef = useRef();
  const pricingDivRef = useRef();
  const expertiseDivRef = useRef();

  const scrollRef = useRef(null);

  const handlePageClick = (pageNumber) => {
    setActivePage(pageNumber);
    scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssessmentsSummary());
  }, []);

  const handleChooseClick = async (therapist, event) => {
    console.log("therapist", therapist);
    setSelectedTherapist(therapist);
    try {
      const response = await dispatch(getPackages({ id: therapist.id }));
      if (response.payload) {
        setPackages(response.payload.data);
        const newModalPosition = calculateModalPosition(event.target);
        setModalPosition(newModalPosition);
        setModalState(true);
      }
      // After getPackages completes, you can access the updated state
    } catch (error) {
      // Handle any errors that might occur during getPackages
      console.error("Error fetching packages list:", error);
    }
  };
  const calculateModalPosition = (target) => {
    const modalWidth = 400; // Adjust with your modal width
    const modalHeight = 560; // Adjust with your modal height

    const rect = target.getBoundingClientRect();

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Calculate distance from the edges
    const distanceFromBottom = windowHeight - rect.bottom;
    const distanceFromRight = windowWidth - rect.right;
    const distanceFromLeft = rect.left;
    const distanceFromTop = rect.top;

    // Adjust modal position based on available space
    let top = rect.bottom;
    let left = rect.left;

    // Check if there is more space on the right
    if (distanceFromRight >= modalWidth) {
      left = rect.right; // Place modal to the right
    } else if (distanceFromLeft >= modalWidth) {
      // Check if there is more space on the left
      left = rect.left - modalWidth; // Place modal to the left
    } else {
      // If there is not enough space on both sides, prioritize the side with more space
      left =
        distanceFromRight >= distanceFromLeft
          ? rect.right
          : rect.left - modalWidth;
    }

    // Center modal vertically if there's not enough space at the top and bottom
    if (distanceFromBottom < modalHeight && distanceFromTop < modalHeight) {
      top = (windowHeight - modalHeight) / 2;
    } else if (distanceFromBottom < modalHeight) {
      // Adjust top position if there's not enough space at the bottom
      top = rect.top - modalHeight;
    }

    // Center modal horizontally if there's not enough space at the left and right
    if (distanceFromLeft < modalWidth && distanceFromRight < modalWidth) {
      left = (windowWidth - modalWidth) / 2;
    }

    return { top: top, left: left };
  };

  const handleViewAllTherapist = async () => {
    setIsModalOpen(true);
    const response = await dispatch(getAllRecommendations(`filter=all`));
    if (response.payload) {
      setAllRecommendations(response.payload);
      setLoading(false);
    }
  };

  const handleSearch = (query) => {
    setSearchText(query);
    setActivePage(1);
  };

  useEffect(() => {
    const fetchList = async () => {
      try {
        setLoading(true);
        const queryStringFilters = buildQueryString(appliedFilters);
        const response = await dispatch(
          getAllRecommendations(
            `filter=all&page=${activePage}${searchText ? `&name=${searchText}` : ""
            }${queryStringFilters && `&${queryStringFilters}`}`
          )
        );
        if (response.payload) {
          setAllRecommendations(response.payload);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching Blog data:", error);
      }
    };
    fetchList();
  }, [appliedFilters, activePage, searchText]);

  const clearAllFilters = () => {
    // setFilters({});
    setAppliedFilters({});
  };

  const totalLength = Object.values(appliedFilters).reduce((total, filter) => {
    if (Array.isArray(filter)) {
      return total + filter.length;
    }
    return total;
  }, 0);

  const commonModalStyles = {
    content: {
      maxWidth: isMobile ? "100%" : "375px",
      width: "100%",
      top: isMobile ? "auto" : `${modalPosition.top}px`,
      left: isMobile ? "0" : `${modalPosition.left}px`,
      right: isMobile ? "0" : `auto`,
      border: "none",
      borderRadius: isMobile ? "16px 16px 0 0" : "16px",
      bottom: isMobile ? "0" : `"auto"`,
      height:
        isModalType === LANGUAGE_FILTER || isModalType === EXPERTISE_FILTER
          ? "460px"
          : "auto",
      maxHeight: isMobile ? "90vh" : "560px",
      background: "#fff",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.09)",
      zIndex: "20",
    },
  };

  const getRefByModal = (type) => {
    if (type == LANGUAGE_FILTER) return languageDivRef;
    else if (type == GENDER_FILTER) return genderDivRef;
    else if (type == TYPE_FILTER) return typeDivRef;
    else if (type == PRICING_FILTER) return pricingDivRef;
    else if (type == EXPERTISE_FILTER) return expertiseDivRef;
  };

  const openDropdown = (modal) => {
    setIsModalType(modal);
    const boundingBox = getRefByModal(modal)?.current.getBoundingClientRect();
    // Calculate the top position by considering both bounding box and scroll position
    const topPosition = boundingBox.top + window.scrollY + 48 - window.scrollY;

    // Calculate the left position based on the modal type
    const leftPosition = boundingBox.left + window.scrollX;
    setModalPosition({
      top: topPosition,
      left: leftPosition,
    });
    setIsCommonModal(true);
  };

  const handleCloseTherapistModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    if (!Object.values(bookingLoader).some((val) => val === true)) {
      setModalState(false);
      setPackages(null);
      setSelectedTherapist(null);
    }
  };

  const customStyles = {
    content: {
      maxWidth: isMobile ? "100%" : "400px",
      width: "100%",
      top: isMobile ? "0" : `${modalPosition.top}px`,
      left: isMobile ? "0" : `${modalPosition.left}px`,
      border: "none",
      borderRadius: isMobile ? "0" : "16px",
      padding: "0",
      maxHeight: isMobile ? "100vh" : "90vh",
      bottom: isMobile ? "0" : "auto",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.09)",
      backdropFilter: "blur(6px)",
      zIndex: "20",
    },
  };

  const styles = {
    content: {
      width: "100%",
      left: 0,
      right: 0,
      bottom: 0,
      border: "none",
      height: "90vh",
    },
    overlay: {
      background: "rgba(16, 24, 40, 0.30)",
      zIndex: "10",
    },
  };

  const LanguageFilter = () => {
    return (
      <div
        ref={languageDivRef}
        onClick={() => openDropdown(LANGUAGE_FILTER)}
        className="flex items-center border-2 border-solid border-teal-300 rounded-3xl py-2 pl-3 pr-4 space-x-1.5 cursor-pointer shadow-filter w-40 lg:w-auto"
      >
        <img src={languageIcon} />
        <div>Language</div>
        <img src={ChevronDown} />
      </div>
    );
  };

  const GenderFilter = () => {
    return (
      <div
        ref={genderDivRef}
        onClick={() => openDropdown(GENDER_FILTER)}
        className="flex items-center border-2 border-solid border-teal-300 rounded-3xl py-2 pl-3 pr-4 space-x-1.5 cursor-pointer shadow-filter w-[8.5rem] lg:w-auto"
      >
        <img src={gendersIcon} />
        <div>Gender</div>
        <img src={ChevronDown} />
      </div>
    );
  };

  const TypeFilter = () => {
    return (
      <div
        ref={typeDivRef}
        onClick={() => openDropdown(TYPE_FILTER)}
        className="flex items-center border-2 border-solid border-teal-300 rounded-3xl py-2 pl-3 pr-4 space-x-1.5 cursor-pointer shadow-filter w-28 lg:w-auto"
      >
        <img src={videoIcon} />
        <div>Type</div>
        <img src={ChevronDown} />
      </div>
    );
  };

  const PricingFilter = () => {
    return (
      <div
        ref={pricingDivRef}
        onClick={() => openDropdown(PRICING_FILTER)}
        className="flex items-center border-2 border-solid border-teal-300 rounded-3xl py-2 pl-3 pr-4 space-x-1.5 cursor-pointer shadow-filter w-32 lg:w-auto"
      >
        <img src={pricing} />
        <div>Pricing</div>
        <img src={ChevronDown} />
      </div>
    );
  };

  const ExpertiseFilter = () => {
    return (
      <div
        ref={expertiseDivRef}
        onClick={() => openDropdown(EXPERTISE_FILTER)}
        className="flex items-center border-2 border-solid border-teal-300 rounded-3xl py-2 pl-3 pr-4 space-x-1.5 cursor-pointer shadow-filter w-[9.5rem] lg:w-auto"
      >
        <img src={expertise} />
        <div>Expertise</div>
        <img src={ChevronDown} />
      </div>
    );
  };

  return (
    <section className="container mx-auto pt-12 px-4">
      {data?.type === "ASSESSMENT" && (
        <div>
          <Heading
            variant="h1"
            heading="Your Results"
            className="text-3xl font-medium max-w-2xl mx-auto w-full text-center"
          />
          <div className="relative">
            <ul className="absolute flex left-0 right-0 w-80 -top-10 mx-auto">
              {sortedScales?.map((item, index) => (
                <li
                  key={index}
                  className={`${index === 0
                    ? "translate-x-[-2rem] lg:translate-x-[-3rem] translate-y-[8rem] text-right"
                    : index === 1
                      ? "translate-x-[-2.5rem] lg:translate-x-[-3.5rem] translate-y-[3rem] text-right"
                      : index === 2
                        ? "translate-x-[3rem] translate-y-[-1.5rem] text-center"
                        : index === 3
                          ? "translate-x-[7.5rem] lg:translate-x-[9.5rem] translate-y-[3rem] text-left"
                          : index === 4
                            ? "translate-x-[6rem] lg:translate-x-[7rem] translate-y-[8rem] text-left"
                            : ""
                    }`}
                >
                  <div className="text-gray-500">
                    {item.score_start}-{item.score_end}
                  </div>
                  <span className="text-gray-400/70 text-sm">
                    {item.score_description}
                  </span>
                </li>
              ))}
            </ul>
            <SemiCircleProgressBar
              width={250}
              height={150}
              percentage={getResultPercentage}
              page="result"
              className="w-[250px] h-[150px]"
              wrapperClassName="mt-24"
              strokeWidth={40}
              graphRadius={100}
              gradient={true}
              scales={sortedScales}
            />
          </div>

          <p className="text-gray-500 text-lg mt-10 max-w-xl mx-auto w-full text-center">
            {`You are not alone, Fitcy has Helped over 1500+ people with moderate
            ${data?.name}`}
          </p>
        </div>
      )}

      <Heading
        variant="h1"
        heading={`Are you looking for the right therapist?`}
        className={`font-medium w-full ${data?.type === "ASSESSMENT"
          ? "text-2xl mt-24"
          : "text-3xl max-w-2xl mx-auto text-center"
          }`}
      />
      {/* {data?.type === "QUESTIONNAIRE" && (
        <p className="text-gray-500 text-lg mt-3 max-w-xl mx-auto w-full text-center">
          Take a [ Condition ] test to assess your mental well-being, seek help,
          and empower yourself.
        </p>
      )} */}
      <div className="grid lg:grid-cols-4 gap-6 mt-8 py-4">
        {recommendations?.data?.map((therapist, index) => (
          <TherapistCard
            key={index}
            data={therapist}
            onChooseClick={(event) => handleChooseClick(therapist, event)}
          />
        ))}

        <Modal
          isOpen={modalState}
          onRequestClose={handleCloseModal}
          modalPosition={modalPosition}
          style={customStyles}
        >
          <div className="flex justify-between p-4 lg:p-6 pb-0">
            <Heading variant="h3" className="text-2xl font-medium">
              Book your package
            </Heading>
            <button onClick={handleCloseModal} className="shadow-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 6L6 18M6 6L18 18"
                  stroke="#667085"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          {packages?.map((tier, tierIndex) => (
            <div key={tierIndex} className="p-4 lg:p-6 grid gap-2">
              {tier.products.map((product, productIndex) => (
                <div key={productIndex} className="grid gap-2">
                  {product.slots.map((slot, slotIndex) => (
                    <div
                      key={slotIndex}
                      className="p-4 bg-teal-50 rounded-2xl relative"
                    >
                      <div className="flex items-center space-x-1">
                        <h3 className="font-medium text-lg">
                          {product.product_count > 1
                            ? `Bundle Package`
                            : product.product_name}
                        </h3>
                        <img
                          src={
                            selectedTherapist?.tier === "BLUE"
                              ? BlueIcon
                              : selectedTherapist?.tier === "GOLD"
                              ? GoldIcon
                              : SilverIcon
                          }
                        />
                      </div>

                      <div className="text-xs text-gray-500 mt-1.5 flex space-x-1.5">
                        <span className="font-semibold">
                          {product.product_count}x
                        </span>{" "}
                        <img src={VideRecorder} />{" "}
                        <span className="font-normal">Session credit</span>
                      </div>
                      <div className="flex items-end space-x-1.5 font-medium mt-6">
                        {slot.crossed_price > 0 && (
                          <span className="text-gray-400 line-through font-normal">
                            $
                            {Math.floor(
                              slot?.crossed_price / product?.product_count
                            )}
                          </span>
                        )}

                        <span className="text-2xl">
                          $
                          {Math.floor(
                            slot?.slot_price / product?.product_count
                          )}
                          <span
                            className={`${product?.product_count > 1
                              ? "text-gray-500 font-normal "
                              : "text-gray-900 font-medium"
                              } mb-[0.1rem] text-sm `}
                          >
                            /session
                          </span>{" "}
                        </span>

                        {product?.product_count > 1 ? (
                          <span className="ml-0 mb-[0.1rem] font-normal text-sm text-gray-500">
                            {" "}
                            Billed ${slot?.slot_price}
                          </span>
                        ) : null}
                      </div>
                      <div className="flex uppercase font-medium text-gray-500 text-xs space-x-2 mt-6">
                        <p className="flex items-center space-x-1">
                          <img src={clock} /> <span>{slot.slot_time} mins</span>
                        </p>
                        {product?.product_count > 1 && (
                          <>
                            {" "}
                            <span className="text-gray-400">|</span>
                            <span>
                              <span className="text-gray-400">Validity:</span>{" "}
                              {slot?.validity_months}{" "}
                              {slot?.validity_months > 1 ? "Months" : "Month"}
                            </span>
                          </>
                        )}
                      </div>
                      <button
                        className="bg-white border-2 border-solid border-teal-900 text-teal-900 hover:shadow-button transition-all mt-4 px-4 py-2 rounded-3xl font-medium text-sm w-full"
                        onClick={() =>
                          goToNextStep({
                            selectedTherapist,
                            slot,
                            productIndex,
                            slotIndex,
                          })
                        }
                        disabled={Object.values(bookingLoader).some(
                          (val) => val === true
                        )}
                      >
                        {bookingLoader[`${productIndex}-${slotIndex}`] ? (
                          <div className="flex items-center justify-center">
                            <div className="border-t-2 border-teal-300 border-solid rounded-full h-5 w-5 animate-spin"></div>
                          </div>
                        ) : (
                          <>Book Now</>
                        )}
                      </button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
          <div className="px-4 lg:px-6 pb-6">
            <ul className="p-4 space-y-4">
              <li className="flex">
                <img src={FreeLabel} className="mr-4" /> Free Cancellations
              </li>
              <li className="flex">
                <img src={FreeSwitching} className="mr-4" /> Free Therapist
                Switching
              </li>
              <li className="flex">
                <img src={FreeTextTherapy} className="mr-4" /> Free Text Therapy
              </li>
              <li className="flex">
                <img src={CustomerSupport} className="mr-4" /> Dedicated
                Customer Support
              </li>
            </ul>
          </div>
        </Modal>

        {isMobile && (
          <>
            <div className="flex justify-center mt-2">
              <Button
                disabled={refresh}
                className="flex items-center text-gray-500 text-sm shadow-none !p-0"
                onClick={() => setRefresh(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  className={`mr-1 ${refresh && "spinner-icon"}`}
                >
                  <path
                    d="M14.1352 9.42813C13.95 11.1681 12.9641 12.7985 11.3328 13.7404C8.62244 15.3052 5.15677 14.3766 3.59196 11.6662L3.42529 11.3776M2.86392 8.23743C3.04912 6.4975 4.03498 4.86702 5.66631 3.92517C8.37663 2.36037 11.8423 3.28899 13.4071 5.99932L13.5738 6.28799M2.82861 12.8768L3.31665 11.0554L5.13801 11.5435M11.8614 6.1221L13.6828 6.61014L14.1708 4.78877"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="font-medium">{`Didn't find,`}&nbsp;</span> what
                you’re looking for?
              </Button>
            </div>
            <div className="flex justify-center mt-2">
              <Button
                className="text-gray-500 font-medium shadow-none flex items-center"
                onClick={handleViewAllTherapist}
              >
                View All therapists
              </Button>
            </div>
          </>
        )}
        <div className="flex items-center mt-12 lg:mt-0">
          <div>
            <Heading
              variant="h2"
              heading="How Fitcy will help you"
              className="text-lg lg:text-2xl font-medium"
            />
            <ul className="mt-8 text-sm text-gray-500 font-normal space-y-6">
              <li className="flex">
                <img src={Check} className="mr-3" />1 on 1 video sessions with
                UKPC or BACP verified therapists
              </li>
              <li className="flex">
                <img src={Check} className="mr-3" />
                Dashboard to manage your appointments
              </li>
              <li className="flex">
                <img src={Check} className="mr-3" />
                Unlimited messaging to your therapists
              </li>
              <li className="flex">
                <img src={Check} className="mr-3" />
                Schedule that fits you
              </li>
              <li className="flex">
                <img src={Check} className="mr-3" />
                Dedicated customer care support
              </li>
            </ul>
          </div>
        </div>
      </div>
      {!isMobile && (
        <>
          <div className="flex justify-center mt-8">
            <Button
              disabled={refresh}
              className="border border-teal-900 text-teal-900 hover:text-white bg-white hover:bg-teal-900 border-solid px-4 py-1.5 rounded-full shadow-none flex items-center"
              onClick={() => setRefresh(true)}
              onMouseOver={() => setIsHover(true)}
              onMouseOut={() => setIsHover(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className={`mr-1 ${refresh && "spinner-icon"}`}
              >
                <path
                  d="M17.0443 10.7439C16.8128 12.9188 15.5805 14.9569 13.5413 16.1342C10.1534 18.0902 5.82132 16.9295 3.86531 13.5415L3.65698 13.1807M2.95527 9.25553C3.18677 7.08062 4.4191 5.04252 6.45825 3.86521C9.84616 1.9092 14.1783 3.06998 16.1343 6.45789L16.3426 6.81873M2.91113 15.0547L3.52118 12.778L5.79788 13.3881M14.2022 6.61137L16.4789 7.22141L17.0889 4.9447"
                  stroke={isHover ? "#ffffff" : "#01554D"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {`Didn't find, what you're looking for?`}
            </Button>
          </div>
          <div className="flex justify-center mt-2">
            <Button
              className="text-gray-500 font-medium shadow-none flex items-center"
              onClick={handleViewAllTherapist}
            >
              View All therapists
            </Button>
          </div>
        </>
      )}

      <div className="py-1">
        <ul className="lg:flex justify-between mt-12 py-4 lg:py-7 px-4 lg:px-24 shadow-featureCard space-y-4 lg:space-y-0">
          <li className="flex">
            <img src={FreeLabel} className="mr-4" /> Free Cancellations
          </li>
          <li className="flex">
            <img src={FreeSwitching} className="mr-4" /> Free Therapist
            Switching
          </li>
          <li className="flex">
            <img src={FreeTextTherapy} className="mr-4" /> Free Text Therapy
          </li>
          <li className="flex">
            <img src={CustomerSupport} className="mr-4" /> Dedicated Customer
            Support
          </li>
        </ul>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseTherapistModal}
        style={styles}
        className={`${isModalOpen ? "translate-y-0" : "translate-y-full"
          } w-full transform transition-transform fixed bottom-0 outline-none bg-white px-0 py-10 rounded-t-2xl`}
      >
        <button
          onClick={handleCloseTherapistModal}
          className="shadow-none rounded-full bg-gray-200 absolute right-6 top-6 p-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="#667085"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <div className="container mx-auto px-4 lg:px-8">
          <Heading
            variant="h3"
            className="text-2xl lg:text-[1.75rem] font-medium"
          >
            Our top Therapists
          </Heading>
          <p className="text-lg text-gray-500 font-normal mt-2">
            Feel free to use to filters below to find the Therapist that suits
            your preference.
          </p>
          <div className="overflow-auto pb-1 scrollbar-hide">
            <div className="grid grid-cols-auto grid-flow-col lg:flex gap-2.5 mt-6">
              <div className="px-3 py-2 border-2 border-teal-300 items-center rounded-3xl shadow-filter relative">
                <div className="w-6 h-6 absolute">
                  <img src={searchGlass} />
                </div>

                <input
                  type="text"
                  placeholder="Search by name"
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="border-0 outline-none indent-7 w-6 lg:w-auto focus:w-auto transition-all relative bg-transparent"
                />
              </div>
              {LanguageFilter()}
              {GenderFilter()}
              {TypeFilter()}
              {PricingFilter()}
              {ExpertiseFilter()}
            </div>
          </div>

          <div className="flex flex-wrap items-center mb-8 mt-6 h-7">
            {Object.entries(appliedFilters).map(
              ([filterKey, filterValue], index) => {
                if (Array.isArray(filterValue) && filterValue.length > 0) {
                  return filterValue.map((value, innerIndex) => {
                    return (
                      <div
                        key={`${index}-${innerIndex}`}
                        className="flex items-center space-x-2 py-1.5 px-3 bg-white drop-shadow rounded-full mr-3"
                      >
                        <div className="text-xs font-medium capitalize">
                          {/^\d+$/.test(value) ? services[value] : value}
                        </div>
                        <img
                          src={CloseIcon}
                          className="h-2.5 w-2.5 cursor-pointer"
                          onClick={() => {
                            // Remove the specific value from the filter array
                            const updatedFilters = { ...appliedFilters };
                            updatedFilters[filterKey] = updatedFilters[
                              filterKey
                            ].filter((item) => item !== value);

                            // If the filter array becomes empty, remove the entire filter
                            if (updatedFilters[filterKey].length === 0) {
                              delete updatedFilters[filterKey];
                            }

                            setAppliedFilters(updatedFilters);
                          }}
                        />
                      </div>
                    );
                  });
                }
                return null;
              }
            )}
            {totalLength > 0 && (
              <div
                className="text-xs font-medium text-gray-500 cursor-pointer"
                onClick={clearAllFilters}
              >
                Clear all filters
              </div>
            )}
          </div>
        </div>
        <div
          className="overflow-auto scroll-track h-[calc(100vh-23rem)]"
          ref={scrollRef}
        >
          <div className="container mx-auto px-4 lg:px-8">
            {loading ? (
              <div className="absolute inset-0 m-auto w-24 h-24">
                <div className="border-t-2 border-teal-300 border-solid rounded-full h-16 w-16 animate-spin"></div>
              </div>
            ) : (
              <div className="grid lg:grid-cols-3 xl:grid-cols-4 gap-6 py-4">
                {allRecommendations?.results?.length === 0 ? (
                  <p>No results found</p>
                ) : (
                  allRecommendations?.results?.map((therapist, index) => (
                    <TherapistCard
                      key={index}
                      data={therapist}
                      onChooseClick={(event) =>
                        handleChooseClick(therapist, event)
                      }
                    />
                  ))
                )}
              </div>
            )}
            {!loading && allRecommendations?.results?.length > 0 && (
              <div className="flex justify-center items-center mt-10 pb-10 space-x-2">
                <div
                  onClick={() =>
                    handlePageClick(activePage === 1 ? 1 : activePage - 1)
                  }
                  className={`${activePage === 1
                    ? "stroke-gray-400 bg-gray-200"
                    : "cursor-pointer stroke-gray-500"
                    } border border-solid border-gray-300 flex items-center justify-center rounded-lg w-8 h-8`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M12.5 15L7.5 10L12.5 5"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className="flex items-center space-x-2">
                  {activePage > 1 && (
                    <>
                      <div
                        onClick={() => handlePageClick(1)}
                        className={`text-sm font-medium ${activePage === 1
                          ? "border-teal-500 text-teal-500"
                          : "border-gray-300 text-gray-500"
                          } rounded-lg w-8 h-8 justify-center items-center flex cursor-pointer border bg-white`}
                      >
                        1
                      </div>
                      {activePage > 2 && (
                        <div className="text-gray-500">..</div>
                      )}
                    </>
                  )}
                  {[activePage, activePage + 1].map((pageNumber) => {
                    if (pageNumber <= totalPages) {
                      return (
                        <div
                          key={pageNumber}
                          onClick={() => handlePageClick(pageNumber)}
                          className={`text-sm font-medium ${activePage === pageNumber
                            ? "border-teal-500 text-teal-500"
                            : "border-gray-300 text-gray-500"
                            } rounded-lg w-8 h-8 justify-center items-center flex cursor-pointer border bg-white`}
                        >
                          {pageNumber}
                        </div>
                      );
                    }
                    return null;
                  })}
                  {activePage < totalPages && totalPages > 4 && (
                    <>
                      {activePage < totalPages - 1 && (
                        <div className="text-gray-500">..</div>
                      )}
                      {activePage < totalPages - 1 && (
                        <div
                          onClick={() => handlePageClick(totalPages)}
                          className={`text-sm font-medium border-gray-300 text-gray-500 rounded-lg w-8 h-8 justify-center items-center flex cursor-pointer border bg-white`}
                        >
                          {totalPages}
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div
                  onClick={() =>
                    handlePageClick(
                      activePage === totalPages ? totalPages : activePage + 1
                    )
                  }
                  className={`${activePage === totalPages || activePage === totalPages - 1
                    ? "stroke-gray-400 bg-gray-200"
                    : "cursor-pointer stroke-gray-500"
                    } border border-solid border-gray-300 flex items-center justify-center rounded-lg w-8 h-8`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M7.5 15L12.5 10L7.5 5"
                      stroke="#98A2B3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isCommonModal}
        onRequestClose={() => {
          setIsCommonModal(false);
        }}
        style={commonModalStyles}
        className={`${isMobile && isCommonModal
          ? "translate-y-0"
          : "translate-y-full lg:translate-y-0"
          } w-full transform transition-transform fixed outline-none`}
      >
        <TherapistListingFilters
          isModalType={isModalType}
          setFilters={setAppliedFilters}
          appliedFilters={appliedFilters}
          setIsCommonModal={setIsCommonModal}
        />
      </Modal>
    </section>
  );
};
export default TherapistSelection;
