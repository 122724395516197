import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NotFound from "./pages/404";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { initializeApp } from "./redux/app/appSlice";
import { hotjar } from "react-hotjar";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/atoms/Loader";
import {
  //  checkBetaUser,
  getMe,
} from "./redux/app/appThunks";
import { submitPatientProfile } from "./redux/patient/patientThunks";
import { submitTherapistProfile } from "./redux/onboarding/onboardingThunks";
import TherapistDashboard from "./pages/therapist/TherapistDashboard";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import SignUp from "./pages/auth/SignUp";
import PatientOnboarding from "./pages/patient/PatientOnboarding";
import PatientSessions from "./pages/patient/PatientSessions";
import Messages from "./pages/patient/Messages/Messages";
import Notes from "./pages/patient/Notes";
import TherapistOnboarding from "./pages/therapist/TherapistOnboarding";
import TherapistSessions from "./pages/therapist/TherapistSessions";
import TherapistNotes from "./pages/therapist/TherapistNotes";
import TherapistProfile from "./pages/therapist/TherapistProfile";
import PatientProfile from "./pages/patient/PatientProfile";
import PatientSettings from "./pages/patient/PatientSettings";
import TherapistSettings from "./pages/therapist/TherapistSettings";
import SetPassword from "./pages/auth/SetPassword";
import { useLocation } from "react-router-dom";
import PatientDashboard from "./pages/patient/PatientDashboard";
import TherapistClients from "./pages/therapist/TherapistClients";
import PatientMessages from "./pages/patient/PatientMessages";
import TherapistMessages from "./pages/therapist/TherapistMessages";
import VerifyOtp from "./pages/auth/VerifyOtp";
import WpRedirect from "./pages/auth/WpRedirect";
import Payment from "./pages/patient/Payment";
import ReactGA from "react-ga4";
import moment from "moment";
import AgoraVideoCall from "./pages/AgoraVideoCall";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);
  const isLoading = useSelector((state) => state.app.isInitialized ?? true);
  const location = useLocation();
  useEffect(() => {
    hotjar.initialize(
      process.env.REACT_APP_HOTJAR_ID,
      process.env.REACT_APP_HOTJAR_VERSION
    );
    // ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const fetchDataAndSubmitProfile = async () => {
      if (localStorage.getItem("fitcyAccessToken")) {
        const result = await dispatch(getMe());
        if (result.payload && !result.payload.is_timezone_updated) {
          const currentTimezone = moment.tz.guess();
          result.payload.role === "PATIENT"
            ? dispatch(
                submitPatientProfile({
                  timezone: currentTimezone,
                })
              )
            : dispatch(
                submitTherapistProfile({
                  timezone: currentTimezone,
                })
              );
        }
      } else dispatch(initializeApp());
    };
    fetchDataAndSubmitProfile();
  }, []);

  // useEffect(() => {
  //   if (user?.email) checkBetaUsers();
  // }, [user]);

  // const checkBetaUsers = async () => {
  //   const betaUserRes = await dispatch(checkBetaUser({ email: user?.email }));
  //   if (!betaUserRes?.payload?.success) {
  //     window.location.href = "https://fitcyhealth.com/";
  //   }
  // };

  const PrivateRoute = ({ children }) => {
    return user ? children : <Navigate to="/login" />;
  };

  const PatientRoute = ({ children }) => {
    return user && user.role === "PATIENT" ? (
      children
    ) : (
      <Navigate to="/login" />
    );
  };
  // const SuperAdminRoute = ({ children }) => {
  //   return user && user.role === "SUPER_ADMIN" ? children : <Navigate to="/login" />;
  // };

  const TherapistRoute = ({ children }) => {
    return user && user.role === "THERAPIST" ? (
      children
    ) : (
      <Navigate to="/therapist/login" />
    );
  };

  const PublicRoute = ({ children }) => {
    localStorage.setItem('comingFromLogin', 'true');
    if (user) {
      const destination =
        user.role === "THERAPIST"
          ? user.onboarding
            ? "/therapist/sessions"
            : `/${String(user.role).toLowerCase()}/onboarding`
          : user.onboarding
          ? `/sessions`
          : `/resources`;

      window.location.href = destination;
      return null;
    }

    return children;
  };
  // useHubspotChat(20127715);
  if (!isLoading) return <Loader />;
  return (
    <>
      <Routes>
        <Route path="/wp-redirect" element={<WpRedirect />} />
        <Route path="/payment" element={<Payment />} />
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/therapist/login"
          element={
            <PublicRoute>
              <Login app={location.pathname.split("/")[1].toUpperCase()} />
            </PublicRoute>
          }
        />
        <Route
          path="/sign-up"
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
        />
        <Route
          path="/therapist/sign-up"
          element={
            <PublicRoute>
              <SignUp app={location.pathname.split("/")[1].toUpperCase()} />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/therapist/forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword
                app={location.pathname.split("/")[1].toUpperCase()}
              />
            </PublicRoute>
          }
        />
        <Route
          path="/verify-otp"
          element={
            <PublicRoute>
              <VerifyOtp />
            </PublicRoute>
          }
        />
        <Route
          path="/therapist/verify-otp"
          element={
            <PublicRoute>
              <VerifyOtp app={location.pathname.split("/")[1].toUpperCase()} />
            </PublicRoute>
          }
        />
        <Route
          path="/therapist/resetPassword/confirm/"
          element={
            <PublicRoute>
              <ResetPassword
                app={location.pathname.split("/")[1].toUpperCase()}
              />
            </PublicRoute>
          }
        />
        <Route
          path="/therapist/setPassword/confirm/"
          element={
            <PublicRoute>
              <SetPassword
                app={location.pathname.split("/")[1].toUpperCase()}
              />
            </PublicRoute>
          }
        />
        <Route
          path="/resetPassword/confirm/"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />

        <Route
          path="/setPassword/confirm/"
          element={
            <PublicRoute>
              <SetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/therapist/settings"
          element={
            <TherapistRoute>
              <Dashboard Page={TherapistSettings} />
            </TherapistRoute>
          }
        />
        <Route
          path="/therapist/profile"
          element={
            <TherapistRoute>
              <Dashboard Page={TherapistProfile} />
            </TherapistRoute>
          }
        />
        <Route
          path="/therapist/home"
          element={
            <TherapistRoute>
              <Dashboard Page={TherapistDashboard} />
            </TherapistRoute>
          }
        />
        <Route
          path="/therapist/sessions"
          element={
            <TherapistRoute>
              <Dashboard Page={TherapistSessions} />
            </TherapistRoute>
          }
        />
        <Route
          path="/therapist/onboarding"
          element={
            <TherapistRoute>
              <Dashboard Page={TherapistOnboarding} />
            </TherapistRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PatientRoute>
              <Dashboard Page={PatientProfile} />
            </PatientRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PatientRoute>
              <Dashboard Page={PatientSettings} />
            </PatientRoute>
          }
        />
        <Route
          path="/sessions"
          element={
            <PatientRoute>
              <Dashboard Page={PatientSessions} />
            </PatientRoute>
          }
        />

        <Route
          path="/resources"
          element={
            <PatientRoute>
              <Dashboard Page={PatientOnboarding} />
            </PatientRoute>
          }
        />

        <Route
          path="/messages"
          element={
            <PatientRoute>
              <Dashboard Page={PatientMessages} />
            </PatientRoute>
          }
        />

        <Route
          path="/dashboard/messages"
          element={
            <PatientRoute>
              <Dashboard Page={Messages} />
            </PatientRoute>
          }
        />
        <Route
          path="/dashboard/notes"
          element={
            <PatientRoute>
              <Dashboard Page={Notes} />
            </PatientRoute>
          }
        />

        <Route
          path="/therapist/messages"
          element={
            <TherapistRoute>
              <Dashboard Page={TherapistMessages} />
            </TherapistRoute>
          }
        />

        <Route
          path="/clients"
          element={
            <TherapistRoute>
              <Dashboard Page={TherapistClients} />
            </TherapistRoute>
          }
        />

        <Route
          path="/dashboard/therapist-notes"
          element={
            <TherapistRoute>
              <Dashboard Page={TherapistNotes} />
            </TherapistRoute>
          }
        />
        <Route
          path="/dashboard/session"
          element={
            <PrivateRoute>
              <Dashboard Page={AgoraVideoCall} />
            </PrivateRoute>
          }
        />

        <Route
          path="/home"
          element={
            <PatientRoute>
              <Dashboard Page={PatientDashboard} />
            </PatientRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
