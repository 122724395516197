import { createSlice } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
// import { getErrorDetailsforNotif } from "../../services/globalFunctions";
import {
  applyCouponOneTimeRecharge,
  applyCouponUpgradeRecharge,
  deleteAppointment,
  getAllAppointmentsPatient,
  getAppointmentsList,
  getSessionsList,
  getBillingRedirect,
  getCardDetails,
  getMessageHistory,
  getPatientNotes,
  getRandomSetupIntent,
  getTherapistList,
  getTherapistTierPackage,
  getTimeZoneOptions,
  getUpcomingAppointments,
  listAvailableSlots,
  listAvailableSlotsUnAuth,
  oneTimeRecharge,
  patchAppointment,
  payOneTime,
  payUpgrade,
  scheduleNewAppointment,
  switchPatientTherapist,
  updatePatientPassword,
  updatePatientProfile,
  submitPatientProfile,
  upgradeRecharge,
  rescheduleSession,
  getPatientDashboard,
  getPatientDashboardStress,
  getPatientDashboardMood,
  addPatientDashboardStress,
  addPatientDashboardMood,
  getPatientSettings,
  submitFeedback,
} from "./patientThunks";

const initialState = {
  notes: [],
  messageHistory: null,
  upcomingAppointments: null,
  timezoneOptions: null,
  patientAppointments: null,
  availableSlots: [],
  setup_intent: null,
  dashboardData: null,
  dashboardStressData: null,
  dashboardMoodData: null,
  patientSettings: null,
  sessionsData: null,
};

export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    clearNotes: (state) => {
      state.notes = [];
    },
    clearSetupIntent: (state) => {
      state.setup_intent = null;
    },
  },
  extraReducers: {
    [getPatientNotes.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getPatientNotes.fulfilled]: (state, action) => {
      state.notes = action.payload;
    },
    [updatePatientPassword.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [updatePatientPassword.fulfilled]: () => {
      // toast("Password updated");
    },
    [getMessageHistory.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getMessageHistory.fulfilled]: (state, action) => {
      state.messageHistory = action.payload;
    },
    [getUpcomingAppointments.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getUpcomingAppointments.fulfilled]: (state, action) => {
      state.upcomingAppointments = action.payload;
    },
    [getAppointmentsList.rejected]: () => {
      // toast.error("Patient does not have a schedule setup yet");
    },
    [getAppointmentsList.fulfilled]: (state, action) => {
      state.patientAppointments = action.payload;
    },
    [getSessionsList.rejected]: () => {
      // toast.error("Patient does not have a schedule setup yet");
    },
    [getSessionsList.fulfilled]: (state, action) => {
      state.patientAppointments = action.payload;
    },
    [scheduleNewAppointment.rejected]: () => {
      // toast.error(action.payload);
    },
    [scheduleNewAppointment.fulfilled]: () => {
      window.location.reload();
    },
    [patchAppointment.rejected]: () => {
      // toast.error(action.payload);
    },
    [patchAppointment.fulfilled]: () => {
      window.location.reload();
    },
    [deleteAppointment.rejected]: () => {
      // toast.error(
      //   "Cannot cancel appointment when there is less than 24 hours left"
      // );
    },
    [deleteAppointment.fulfilled]: () => {
      window.location.reload();
    },
    [getTimeZoneOptions.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getTimeZoneOptions.fulfilled]: (state, action) => {
      state.timezoneOptions = action.payload;
    },
    [listAvailableSlots.rejected]: (state) => {
      state.availableSlots = [];

      // toast.error("No schedule for this therapist");
    },
    [listAvailableSlots.fulfilled]: (state, action) => {
      state.availableSlots = action.payload.bookable;
    },
    [listAvailableSlotsUnAuth.rejected]: (state) => {
      state.availableSlots = [];

      // toast.error("No schedule for this therapist");
    },
    [listAvailableSlotsUnAuth.fulfilled]: (state, action) => {
      state.availableSlots = action.payload.bookable;
    },
    [getCardDetails.rejected]: (state) => {
      state.patient_payment = "none";
    },
    [getCardDetails.fulfilled]: (state, action) => {
      state.patient_payment = action.payload;
    },
    [getTherapistTierPackage.rejected]: (state, action) => {
      state.therapist_tier = action.payload;
    },
    [getTherapistTierPackage.fulfilled]: (state, action) => {
      state.therapist_tier = action.payload;
    },
    [updatePatientProfile.fulfilled]: () => {
      // toast.success("Profile updated");
    },
    [submitPatientProfile.fulfilled]: () => {
      // toast.success("Profile updated.");
    },
    [oneTimeRecharge.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [oneTimeRecharge.fulfilled]: (state, action) => {
      state.onetime_recharge = action.payload;
    },
    [payOneTime.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [payOneTime.fulfilled]: () => {
      // toast.success("Credit added");
    },
    [getBillingRedirect.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getBillingRedirect.fulfilled]: (state, action) => {
      state.billing_redirect = action.payload;
    },
    [getAllAppointmentsPatient.fulfilled]: (state, action) => {
      state.all_appointments = action.payload;
    },
    [getAllAppointmentsPatient.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [applyCouponOneTimeRecharge.fulfilled]: (state, { payload }) => {
      state.onetime_recharge = payload;
    },
    [applyCouponOneTimeRecharge.rejected]: () => {
      // toast.error("Invalid Coupon");
    },

    [upgradeRecharge.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [upgradeRecharge.fulfilled]: (state, action) => {
      state.upgrade_recharge = action.payload;
    },
    [applyCouponUpgradeRecharge.fulfilled]: (state, { payload }) => {
      state.upgrade_recharge = payload;
    },
    [applyCouponUpgradeRecharge.rejected]: () => {
      // toast.error("Invalid Coupon");
    },
    [payUpgrade.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [payUpgrade.fulfilled]: () => {
      // toast.success("Package upgraded");
    },
    [getTherapistList.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getTherapistList.fulfilled]: (state, action) => {
      state.therapist_list = action.payload;
    },
    [getRandomSetupIntent.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getRandomSetupIntent.fulfilled]: (state, action) => {
      state.setup_intent = action.payload;
    },
    [switchPatientTherapist.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [switchPatientTherapist.fulfilled]: () => {
      // toast.success("Therapist changed");
    },
    [rescheduleSession.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [rescheduleSession.fulfilled]: () => {
      // toast.success("Session rescheduled successfully");
    },
    [getPatientDashboard.fulfilled]: (state, action) => {
      state.dashboardData = action.payload;
    },
    [getPatientDashboardStress.fulfilled]: (state, action) => {
      state.dashboardStressData = action.payload;
    },
    [getPatientDashboardMood.fulfilled]: (state, action) => {
      state.dashboardMoodData = action.payload;
    },
    [addPatientDashboardStress.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [addPatientDashboardStress.fulfilled]: () => {
      // toast.success("Stress level added successfully");
    },
    [addPatientDashboardMood.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [addPatientDashboardMood.fulfilled]: () => {
      // toast.success("Mood added successfully");
    },
    [getPatientSettings.fulfilled]: (state, action) => {
      state.patientSettings = action.payload.data;
    },
    [submitFeedback.fulfilled]: (state, action) => {
      state.sessionsData = action.payload.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearNotes, clearSetupIntent } = patientSlice.actions;

export default patientSlice.reducer;
