import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/molecules/Header";
import TherapistAvailability from "../../components/molecules/TherapistAvailability";
import backgroundImage from "../../assets/onboarding/bg-shadow.png";
import backgroundCover from "../../assets/cover-bg.png";
import BlueIcon from "../../assets/blue-tier-icon.svg";
import SilverIcon from "../../assets/silver-tier-icon.svg";
import GoldIcon from "../../assets/gold-tier-icon.svg";
import Heading from "../../components/atoms/Heading";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Button from "../../components/atoms/Button";
import ProfileForm from "../../components/organisms/ProfileForm";
import {
  formatBytes,
  getParseDataKeyOrVal,
  getParseData,
  MetaTags,
} from "../../services/globalFunctions";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import editIcon from "../../assets/edit-02.svg";

import Modal from "react-modal";
import { submitTherapistProfile } from "../../redux/onboarding/onboardingThunks";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import Avatar from "../../assets/profile-picture-avatar.png";
import Tooltip from "../../components/atoms/Tooltip";

const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const options = { year: "numeric", month: "short" };
  return dateObject.toLocaleString("en-US", options);
};

const optionsData = {
  "ANXIETY DISORDERS": [
    "Generalized Anxiety Disorder (GAD)",
    "Obsessive-compulsive Disorder (OCD)",
    "Panic disorder & Panic attacks",
    "Separation Anxiety Disorder",
    "Social Anxiety Disorder",
    "Illness Anxiety Disorder (Hypochondriasis)",
    "Somatic Symptom Disorder",
    "Agoraphobia",
    "Claustrophobia",
    "I'm not an expert in any of the above",
  ],
  "MOOD DISORDERS": [
    "Dysthymia",
    "Clinical depression (MDD)",
    "Bipolar Affective Disorder (BPAD)",
    "Postpartum Depression (PPD)",
    "Seasonal Affective Disorder",
    "Mood disorder due to comorbid health conditions",
    "Substance-induced Mood disorder",
    "I'm not an expert in any of the above",
  ],
  "EATING DISORDERS": [
    "Anorexia Nervosa",
    "Bulimia Nervosa",
    "Binge-Eating Disorder",
    "I'm not an expert in any of the above",
  ],
  "PERSONALITY DISORDERS": [
    "Anti Social Personality Disorder",
    "Avoidant Personality Disorder",
    "Borderline Personality Disorder",
    "Dependent Personality Disorder",
    "Histrionic Personality Disorder",
    "Narcissist Personality Disorder",
    "Obsessive Compulsive Personality Disorder",
    "Paranoid Personality Disorder",
    "Schizoid Personality Disorder",
    "Schizotypal Personality Disorder",
    "I'm not an expert in any of the above",
  ],
  ADDICTIONS: [
    "Alcohol Addiction",
    "Drug & prescription med Addiction",
    "Sex & Porn Addiction",
    "Gambling Addiction",
    "Social Media Addiction",
    "I'm not an expert in any of the above",
  ],
  "TRAUMA AND STRESS DISORDER": [
    "PTSD (Abuse & Rape)",
    "PTSD (Natural calamity, accidents)",
    "PTSD (Veterans)",
    "Complex PTSD",
    "Acute Stress Disorder",
    "Adjustment Disorder",
    "I'm not an expert in any of the above",
  ],
  "SEXUAL DISORDERS": [
    "Erectile Dysfunction & Disorders",
    "Sexual Arousal Disorder",
    "Paraphilias & it’s sub-types",
    "Premature Ejaculation",
    "LQBTQ & Gender Identity",
    "I'm not an expert in any of the above",
  ],
  OTHER: [
    "ADHD/ADD Assessment",
    "ADHD/ADD Treatment",
    "Psychosomatic Disorders",
    "Self-harm/suicidality",
    "Sleep Disorder Treatment",
    "Grief & bereavement",
    "I'm not an expert in any of the above",
  ],
  "SUPPORT THERAPY": [
    "Autism Spectrum Disorder",
    "Learning Disability & Disorder",
    "Neurocognitive disorders (E.g. Dementia, Alzheimer's, etc.)",
    "Conduct Disorder, Oppositional Defiant Disorder, Disruptive Mood Dysregulation Disorder",
    "Schizophrenia & Psychosis",
    "Adjustment Disorder",
    "Loss & Grief Counselling",
    "I'm not an expert in any of the above",
  ],
  "RELATIONSHIP COUNSELLING": [
    "Pre-marital Therapy",
    "Communication & conflict resolution",
    "Trust, intimacy & infidelity",
    "Infertility issues",
    "Parenting/co-parenting",
    "Separation/Divorce",
    "I'm not an expert in any of the above",
  ],
  Others: [
    "Academic, Career & Workplace Counselling",
    "Career coaching",
    "Communication & conflict resolution",
    "Work-Stress management",
    "Productivity & procrastination",
    "Performance Enhancement & Mental Skills Training",
    "Business coaching",
    "Executive & leadership coaching",
    "I'm not an expert in any of the above",
  ],
  "Lifestyle Counselling": [
    "Existential crisis",
    "Anger management",
    "Confidence & self-esteem",
    "Positivity, spirituality & wellbeing",
    "Work-life balance",
    "Money management",
    "Emotional/creativity block",
    "I'm not an expert in any of the above",
  ],
};

const TherapistProfile = ({ app = "THERAPIST" }) => {
  const navigate = useNavigate();
  const params = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);
  const secondaryLanglabels =
    user?.secondary_language &&
    getParseDataKeyOrVal(user?.secondary_language, "keys")
      .map((key) => getParseData(user?.secondary_language)[key].label)
      .join(", ");
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    params?.search === "?professionalInfo"
      ? 1
      : params?.search === "?expertise"
      ? 2
      : params?.search === "?availability"
      ? 3
      : 0
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [finalExpertise, setFinalExprtise] = useState(
    user?.certified_on_extended
  );
  const [selectedOptions, setSelectedOptions] = useState(
    user?.certified_on_extended
  );
  const [selectedCount, setSelectedCount] = useState({});
  const [modalContent, setModalContent] = useState("");
  const [imageUrl, setImageUrl] = useState(
    user?.profile_picture?.full_url || null
  );
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const [seeAllBio, setSeeAllBio] = useState(false);
  const [expertiseError, setExpertiseError] = useState(false);
  const [expertiseErrorText, setExpertiseErrorText] = useState("");
  const [licenses] = useState(
    user.license_certifications.length > 0
      ? user.license_certifications.map((cert) => ({
          licenseNumber: cert.license_number,
          certifiedFrom: cert.certified_from,
        }))
      : [{ licenseNumber: "", certifiedFrom: "" }]
  );

  useEffect(() => {
    // Update the count of selected checkboxes after state has been updated
    const counts = {};
    Object.keys(selectedOptions).forEach((category) => {
      const count = Object.values(selectedOptions[category]).filter(
        Boolean
      ).length;
      counts[category] = count;
    });
    setSelectedCount(counts);

    // Save options to local storage whenever the selectedOptions state changes
    localStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));
    const allFalse = Object.values(selectedOptions).every((categoryOptions) =>
      Object.values(categoryOptions).every((value) => value === false)
    );

    // Check if selectedOptions is empty
    const isEmpty = Object.keys(selectedOptions).length === 0;

    const hasTrue = Object.values(selectedOptions).some((categoryOptions) =>
      Object.values(categoryOptions).some((value) => value === true)
    );
    if (allFalse || isEmpty) {
      setExpertiseError(true);
      setExpertiseErrorText("Please select at least one expertise");
    } else if (hasTrue) {
      setExpertiseError(true);
      setExpertiseErrorText("Please select a value for the chosen expertise");
    } else {
      setExpertiseError(false);
      setExpertiseErrorText("");
    }
  }, [selectedOptions]);

  useEffect(() => {
    // Load saved options from local storage on component mount
    const savedOptions =
      JSON.parse(localStorage.getItem("selectedOptions")) || {};
    setSelectedOptions(savedOptions);
  }, []);

  const handleTabSelect = (index) => {
    let tabName = "";

    switch (index) {
      case 0:
        tabName = "basicInfo";
        break;
      case 1:
        tabName = "professionalInfo";
        break;
      case 2:
        tabName = "expertise";
        break;
      case 3:
        tabName = "availability";
        break;
      default:
        break;
    }

    setSelectedTabIndex(index);
    navigate(`/therapist/profile/?${tabName}`);
  };

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // Use FileReader to display the selected image
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImageUrl(event.target.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        submitTherapistProfile({
          profile_picture: file,
        })
      );
    }
  };

  const handleCheckboxChange = (category, option) => {
    setSelectedOptions((prevSelectedOptions) => {
      const newSelectedOptions = {
        ...prevSelectedOptions,
        [category]: {
          ...prevSelectedOptions[category],
          [option]: !prevSelectedOptions[category]?.[option],
        },
      };

      return newSelectedOptions;
    });
  };

  const handleRadioChange = (category, option, type) => {
    if (selectedOptions[category]?.[option]) {
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [category]: { ...prevSelectedOptions[category], [option]: type },
      }));
    } else {
      // Handle the case where the checkbox is not selected
      // You can show an error message or take other appropriate actions
      console.error(`Checkbox for ${category} - ${option} is not selected.`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    closeModal();
    setFinalExprtise(selectedOptions);
    const filteredData = {};
    for (const category in selectedOptions) {
      const subcategories = selectedOptions[category];
      const filteredSubcategories = {};

      for (const subcategory in subcategories) {
        const value = subcategories[subcategory];
        if (
          typeof value === "string" &&
          (value.toLowerCase().includes("certified") ||
            value.toLowerCase().includes("trained"))
        ) {
          filteredSubcategories[subcategory] = value;
        }
      }

      if (Object.keys(filteredSubcategories).length > 0) {
        filteredData[category] = filteredSubcategories;
      }
    }
    dispatch(
      submitTherapistProfile({
        certified_on: JSON.stringify({ ...filteredData }),
      })
    );
  };
  const renderSelectedOptions = () => {
    const flattenedOptions = Object.entries(finalExpertise).flatMap(
      ([category, options]) => {
        const categoryOptions = Object.entries(options).map(
          ([option, value], index) => {
            const certified =
              value === "Certified" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="m-auto w-4 h-4 lg:w-6 lg:h-6"
                >
                  <path
                    d="M20 6L9 17L4 12"
                    stroke="#029CF5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                ""
              );
            const trained =
              value === "Trained" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="m-auto w-4 h-4 lg:w-6 lg:h-6"
                >
                  <path
                    d="M20 6L9 17L4 12"
                    stroke="#029CF5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                ""
              );

            return value
              ? {
                  category,
                  option,
                  certified,
                  trained,
                  isFirstOption: index === 0, // Add a flag for the first option
                }
              : null;
          }
        );

        // Remove null values (unchecked options) from the array
        return categoryOptions.filter(Boolean);
      }
    );

    return (
      <div className="mt-4 font-medium">
        <div className="flex flex-col text-xs lg:text-sm">
          {flattenedOptions.map((item) => (
            <div
              key={`${item.category}_${item.option}`}
              className={`grid grid-cols-12 ${
                item.isFirstOption ? "mt-4 lg:mt-0" : ""
              }`}
            >
              <div
                className={`col-span-12 lg:col-span-3 font-medium capitalize ${
                  item.isFirstOption ? "py-2" : ""
                }`}
              >
                {item.isFirstOption ? item.category.toLowerCase() : ""}
              </div>
              <div className="col-span-6 lg:col-span-5 lg:border-b border-gray-200 py-2">
                {item.option}
              </div>
              <div className="col-span-3 lg:col-span-2 lg:border-b border-gray-200 py-2">
                {item.certified}
              </div>
              <div className="col-span-3 lg:col-span-2 lg:border-b border-gray-200 py-2">
                {item.trained}
              </div>
            </div>
          ))}
        </div>
        {/* {changesMade && (
          <div className="p-8 flex justify-end">
            <Button
              className="bg-blue-900 hover:shadow-button hover:bg-blue-500 transition-all text-white"
              onClick={handleSubmit}
              app={app}
            >
              Save changes
            </Button>
          </div>
        )} */}
      </div>
    );
  };

  const customStyles = {
    content: {
      maxWidth: "1024px",
      width: isMobile ? "calc(100% - 2rem)" : "100%",
      left: 0,
      right: 0,
      margin: "auto",
      border: "none",
      borderRadius: "16px",
      padding: "0",
      maxHeight: "calc(100% - 6rem)",
      height: "auto",
      bottom: "auto",
    },
    overlay: {
      zIndex: "30",
      background: "rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    <>
      <MetaTags
        title="Profile Settings | Fitcy Health"
        description="Manage your Fitcy Health profile"
        url="/therapist/profile"
      />
      <Header
        className="border-b border-gray-200 fixed z-30 bg-white"
        backButton={true}
        section="profile"
        app="THERAPIST"
      />
      <div
        className={`pt-28 pb-24 bg-cover`}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="container mx-auto max-w-screen-xl px-4">
          <Heading
            variant="h1"
            heading="Profile"
            className="font-medium text-2xl lg:text-3xl pb-5 border-b border-gray-200"
          />
          <div
            className={`mt-10 bg-no-repeat lg:aspect-[5.79/1] h-32 lg:h-auto w-full`}
            style={{
              backgroundImage: `url(${backgroundCover})`,
              backgroundSize: isMobile ? "100% 100%" : "100% auto",
            }}
          ></div>
          <div className="px-4 lg:px-10 relative">
            <div className="-mt-16 lg:-mt-24 relative w-24 h-24 lg:w-32 lg:h-32">
              <img
                src={imageUrl ?? Avatar}
                alt="Selected Image"
                className="w-24 h-24 lg:w-32 lg:h-32 rounded-3xl border-2 border-white/80 object-contain bg-gray-100"
              />

              <label htmlFor="dropzone-file">
                <div className="bg-gray-100 rounded-lg border border-gray-200 p-1.5 absolute bottom-0 -right-1 cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      opacity="0.12"
                      d="M15.75 5.74998C16.5784 4.92156 16.5784 3.57841 15.75 2.74998C14.9216 1.92156 13.5784 1.92156 12.75 2.74998L10.5 4.99998L13.5 7.99998L15.75 5.74998Z"
                      fill="#029CF5"
                    />
                    <path
                      d="M13.5 7.99998L10.5 4.99998M1.875 16.625L4.41328 16.343C4.72339 16.3085 4.87845 16.2913 5.02338 16.2443C5.15197 16.2027 5.27434 16.1439 5.38717 16.0695C5.51434 15.9856 5.62466 15.8753 5.84529 15.6547L15.75 5.74998C16.5784 4.92156 16.5784 3.57841 15.75 2.74998C14.9216 1.92156 13.5784 1.92156 12.75 2.74998L2.8453 12.6547C2.62466 12.8753 2.51434 12.9856 2.43048 13.1128C2.35607 13.2256 2.29726 13.348 2.25564 13.4766C2.20872 13.6215 2.19149 13.7766 2.15703 14.0867L1.875 16.625Z"
                      stroke="#029CF5"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <input
                  id="dropzone-file"
                  type="file"
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </label>
            </div>
            <div className="flex flex-col-reverse lg:flex-row lg:items-start justify-between mt-4 lg:mt-0">
              <div className="lg:w-[calc(100%-14rem)]">
                <div className="font-medium text-xl flex space-x-2 lg:mt-6">
                  <span>
                    {user?.first_name} {user?.last_name}{" "}
                  </span>
                  {user?.is_active && (
                    <span className="flex items-center px-2 py-1.5 text-xs text-blue-500 font-semibold bg-blue-50 rounded-2xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="9"
                        viewBox="0 0 8 9"
                        fill="none"
                        className="mr-1.5"
                      >
                        <circle cx="4" cy="4.5" r="3" fill="#029CF5" />
                      </svg>
                      Active
                    </span>
                  )}
                </div>
                {user?.designation && (
                  <div className="mt-1.5">{user.designation}</div>
                )}
                <div
                  className={`text-gray-500 text-sm mt-2 ${
                    !seeAllBio
                      ? "lg:max-h-10 overflow-hidden lg:w-[25rem]"
                      : "lg:w-full"
                  }`}
                >
                  {user?.bio && user?.bio !== "null" ? (
                    <>
                      {user.bio.length > 100 && !seeAllBio ? (
                        <>
                          {user.bio.slice(0, 100)}...
                          <span
                            className="text-black cursor-pointer"
                            onClick={() => setSeeAllBio(!seeAllBio)}
                          >
                            {" "}
                            See more
                          </span>
                        </>
                      ) : (
                        <>
                          {user.bio}
                          {user.bio.length > 100 && (
                            <span
                              className="text-black cursor-pointer"
                              onClick={() => setSeeAllBio(!seeAllBio)}
                            >
                              {" "}
                              See less
                            </span>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {user?.tier && (
                <div className="absolute right-2 top-5 lg:static lg:mt-6 flex space-x-1 lg:space-x-2 p-1 bg-gray-100 rounded-xl lg:w-48 items-center border border-gray-200">
                  <img
                    src={
                      user?.tier === "GOLD"
                        ? GoldIcon
                        : user?.tier === "SILVER"
                        ? SilverIcon
                        : BlueIcon
                    }
                  />
                  <div className="flex items-center lg:block space-x-0.5 lg:space-x-0 ">
                    <div className="text-xs text-gray-500">Tier</div>
                    <div className="text-xs lg:text-sm lg:mt-0.5 capitalize font-medium">
                      {user?.tier?.toLowerCase() || ""}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-6">
              <div className="flex flex-wrap gap-2">
                {user?.certified_from &&
                  Object.keys(user?.certified_from).map((key) => (
                    <div
                      key={key}
                      className="px-3 py-1.5 bg-gray-100 rounded-lg text-xs font-medium"
                    >
                      {user?.certified_from[key].label}
                    </div>
                  ))}
                <div className="px-3 py-1.5 text-xs font-medium text-gray-500">
                  Member since {formatDate(user?.date_joined)}
                </div>
              </div>
            </div>
          </div>

          <div className="lg:px-10 mt-10">
            <div className="bg-gray-100 xs:p-3 p-4 lg:p-6 rounded-3xl">
              <Tabs
                className="shadow-tabs"
                selectedIndex={selectedTabIndex}
                onSelect={handleTabSelect}
              >
                <TabList className="flex">
                  <Tab className="xs:px-1 px-2 lg:px-4 py-1.5 lg:py-2.5 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-xs lg:text-base">
                    Basic Info
                  </Tab>
                  <Tab className="xs:px-1 px-2 lg:px-4 py-1.5 lg:py-2.5 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-xs lg:text-base">
                    Professional Info
                  </Tab>
                  <Tab className="xs:px-1 px-2 lg:px-4 py-1.5 lg:py-2.5 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-xs lg:text-base">
                    Expertise
                  </Tab>
                  <Tab className="xs:px-1 px-2 lg:px-4 py-1.5 lg:py-2.5 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-xs lg:text-base">
                    Availability
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="bg-white rounded-2xl xs:p-3 p-4 lg:p-6 mt-6 relative">
                    <Heading
                      variant="h2"
                      heading="Personal Details"
                      className="font-medium"
                    />
                    <div className="text-base text-gray-500">
                      {`Here you can provide your personal details.`}
                    </div>
                    <div className="flex flex-col lg:flex-row mt-4 lg:mt-6">
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Email</span>
                        <div className="mt-1 font-medium">{user?.username}</div>
                      </div>
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">First Name</span>
                        <div className="mt-1 font-medium">
                          {user?.first_name}
                        </div>
                      </div>
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Last Name</span>
                        <div className="mt-1 font-medium">
                          {user?.last_name}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col lg:flex-row lg:mt-6">
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Phone Number</span>
                        <div className="mt-1 font-medium">
                          {user?.cell_number}
                        </div>
                      </div>
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Date of Birth</span>
                        <div className="mt-1 font-medium">
                          {user?.date_of_birth || "N/A"}
                        </div>
                      </div>
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Gender</span>
                        <div className="mt-1 font-medium">{user?.gender}</div>
                      </div>
                    </div>
                    <div className="lg:mt-6 py-4 lg:p-2 text-sm">
                      <span className="text-gray-500">Brief Introduction</span>
                      <div className="mt-1">
                        {user?.bio && user?.bio != "null" ? user?.bio : "N/A"}
                      </div>
                    </div>
                    <div className="lg:mt-6 py-4 lg:p-2 text-sm">
                      <span className="text-gray-500">
                        Brief Introduction (Arabic)
                      </span>
                      <div className="mt-1">{user?.bio_arabic || "N/A"}</div>
                    </div>
                    <button
                      onClick={() => openModal("Personal details")}
                      className="shadow-none absolute right-4 lg:right-6 top-4 lg:top-6"
                    >
                      <img src={editIcon} />
                    </button>
                  </div>

                  <div className="bg-white rounded-2xl p-4 lg:p-6 mt-6 relative">
                    <Heading
                      variant="h2"
                      heading="Additional Info"
                      className="font-medium"
                    />
                    <div className="flex flex-col lg:flex-row mt-4 lg:mt-6">
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Residing Country</span>
                        <div className="mt-1 font-medium">
                          {user?.country || "N/A"}
                        </div>
                      </div>
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Ethnicity</span>
                        <div className="mt-1 capitalize font-medium">
                          {user?.ethnicity && user?.ethnicity != "undefined"
                            ? user?.ethnicity.replace(/_/g, ", ")
                            : "N/A"}
                        </div>
                      </div>
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Nationality</span>
                        <div className="mt-1 capitalize font-medium">
                          {user?.nationality && user?.nationality != "undefined"
                            ? user?.nationality.replace(/_/g, ", ")
                            : "N/A"}
                        </div>
                      </div>
                    </div>

                    <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">Languages</span>
                        <div className="mt-1 text-gray-500">
                          Only select the languages you can conduct therapy in.
                        </div>
                      </div>
                      <div>
                        <div>
                          <span className="text-gray-500">
                            Primary language:
                          </span>{" "}
                          <span className="capitalize">
                            {user?.primary_language}
                          </span>
                        </div>
                        <div className="mt-2">
                          <span className="text-gray-500">
                            Secondary language:
                          </span>{" "}
                          <span>{secondaryLanglabels}</span>
                        </div>
                      </div>
                    </div>
                    <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">Timezone</span>
                        <div className="mt-1 text-gray-500">
                          Select the timezone that corresponds to your location.
                        </div>
                      </div>
                      <div className="font-medium">
                        {user?.timezone && user?.timezone != "null"
                          ? user?.timezone
                          : "N/A"}
                      </div>
                    </div>
                    <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">Religion</span>
                        <div className="mt-1 text-gray-500">
                          Select the religion you identify yourself with.
                        </div>
                      </div>
                      <div className="font-medium">
                        {user?.religious_belief &&
                        user?.religious_belief != "null"
                          ? user?.religious_belief
                          : "N/A"}
                      </div>
                    </div>
                    <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10 font-medium">
                      <div>
                        Do you incorporate faith-based therapy
                        support/religion-integrated therapy?
                      </div>
                      <div>{user?.faith_base_therapy ? "Yes" : "No"}</div>
                    </div>
                    <button
                      onClick={() => openModal("Demographical Preferences")}
                      className="shadow-none absolute right-4 lg:right-6 top-4 lg:top-6"
                    >
                      <img src={editIcon} />
                    </button>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="bg-white rounded-2xl xs:p-3 p-4 lg:p-6 mt-6 relative">
                    <Heading
                      variant="h2"
                      heading="Practice Details"
                      className="font-medium"
                    />
                    <div className="mt-4 lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">
                          What year did you start clinical work?
                        </span>
                        <div className="mt-1 text-gray-500">
                          Ensure the year provided is post supervision.
                        </div>
                      </div>
                      <div className="font-medium">
                        {user?.years_of_experience}
                      </div>
                    </div>
                    <div className="mt-4 lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">
                          Clinical Hours of Therapy
                        </span>
                        <div className="mt-1 text-gray-500">
                          How many clinical hours of therapy have you done?
                          (please exclude any supervision hours).
                        </div>
                      </div>
                      <div className="font-medium">{user?.clinical_hours}</div>
                    </div>
                    <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">Clinical Experience</span>
                        <div className="mt-1 text-gray-500">
                          How many clients have you handled in your experience?
                          (please exclude cases handled during
                          supervision/training).
                        </div>
                      </div>
                      <div className="font-medium">
                        {user?.client_experience}
                      </div>
                    </div>
                    <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">
                          Clinical Designation
                        </span>
                        <div className="mt-1 text-gray-500">
                          What is your Clinical Designation or specialization?
                        </div>
                      </div>
                      <div className="font-medium">
                        {user?.designation || "N/A"}
                      </div>
                    </div>
                    <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">Therapy Approach</span>
                        <div className="mt-1 text-gray-500">
                          How many Therapy Approaches are you licensed with?
                        </div>
                      </div>
                      <div>
                        {user?.clinical_creds &&
                        getParseDataKeyOrVal(user?.clinical_creds, "keys")
                          ?.length > 0 ? (
                          getParseDataKeyOrVal(
                            user?.clinical_creds,
                            "keys"
                          ).map((key) => (
                            <div key={key} className="font-medium">
                              {getParseData(user?.clinical_creds)[key].label}
                            </div>
                          ))
                        ) : (
                          <div>{"N/A"}</div>
                        )}
                      </div>
                    </div>
                    <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">Client Types</span>
                        <div className="mt-1 text-gray-500">
                          What type of clients do you work with?
                        </div>
                      </div>
                      {user?.client_work &&
                      getParseDataKeyOrVal(user?.client_work, "keys")?.length >
                        0 ? (
                        <div className="flex items-center gap-1.5 p-1 border flex-wrap border-gray-200 rounded-lg">
                          {getParseDataKeyOrVal(user?.client_work, "keys").map(
                            (key) => (
                              <div
                                key={key}
                                className="px-2 py-0.5 bg-gray-100 rounded-lg text-sm font-medium border border-gray-200"
                              >
                                {getParseData(user?.client_work)[key].label}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div>{"N/A"}</div>
                      )}
                    </div>
                    {/* <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>Therapy approaches you’re certified to practice</div>
                      <div>
                        {Object.keys(user?.therapy_approach).map((key) => (
                          <div key={key} className="font-medium">
                            {user?.therapy_approach[key].label}
                          </div>
                        ))}
                      </div>
                    </div> */}
                    {/* <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>{`Problems you’re certified to practice`}</div>
                      <div className="flex gap-1.5 p-1 border flex-wrap border-gray-200 rounded-lg">
                        {Object.keys(user?.certified_on).map((key) => (
                          <div
                            key={key}
                            className="px-2 py-0.5 bg-gray-100 rounded-lg text-sm font-medium border border-gray-200"
                          >
                            {user?.certified_on[key].label}
                          </div>
                        ))}
                      </div>
                    </div> */}
                    <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10 font-medium">
                      <div>
                        Would you be available for in-person therapy session?
                      </div>
                      <div>{user?.inperson_therapy ? "Yes" : "No"}</div>
                    </div>
                    <button
                      disabled={true}
                      onClick={() => openModal("Practice Details")}
                      className="shadow-none absolute group right-4 lg:right-6 top-4 lg:top-6"
                    >
                      <img src={editIcon} className="opacity-40" />
                      <Tooltip
                        className="mt-2"
                        direction="left"
                        right="-right-5 after:right-[18px] before:right-4"
                        position="bottom"
                        pin="top"
                      >
                        You may reach out to our support team and we’ll update
                        these changes for you
                      </Tooltip>
                    </button>
                  </div>

                  <div className="bg-white rounded-2xl p-4 lg:p-6 mt-6 relative">
                    <Heading
                      variant="h2"
                      heading="Educational Background"
                      className="font-medium"
                    />
                    <div className="text-base text-gray-500">
                      {`Enter your educational background, along with any certifications, qualifications, licenses & independent mental health courses you've done.`}
                    </div>
                    <div className="mt-4 lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10 font-medium">
                      <div>
                        {`Please share your educational background, along with any
                      certifications, qualifications, licenses & independent
                      mental health courses, you've done? (Please specify) *`}
                      </div>
                      <div>
                        {user?.educational_background_certification || "N/A"}
                      </div>
                    </div>
                    <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">
                          Educational Documents *
                        </span>
                        <div className="mt-1 text-gray-500">
                          How many clients have you handled in your experience?
                          (please exclude cases handled during
                          supervision/training).
                        </div>
                      </div>
                      <div>
                        <ul className="flex flex-col gap-4 lg:mt-4">
                          {user?.certifications?.length > 0 ? (
                            <>
                              {user?.certifications?.map((item, index) => (
                                <li
                                  key={index}
                                  className="p-4 bg-gray-100 rounded-2xl text-sm text-gray-500 flex items-start justify-between w-full"
                                >
                                  <div className="flex space-x-4 items-center">
                                    {item?.full_url &&
                                    item?.media_mime?.includes("image") ? (
                                      <img
                                        src={item?.full_url}
                                        className={
                                          "w-10 h-10 rounded-lg object-contain bg-white"
                                        }
                                      />
                                    ) : (
                                      <div className="p-2 bg-white rounded-full">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="21"
                                          height="20"
                                          viewBox="0 0 21 20"
                                          fill="none"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M11.3333 1.67932C11.3333 1.6574 11.3333 1.64645 11.3324 1.62657C11.3127 1.21509 10.9509 0.853408 10.5394 0.833941C10.5195 0.833002 10.513 0.833004 10.5 0.833008H7.79888C7.1281 0.832999 6.57446 0.832992 6.12348 0.869838C5.65507 0.908109 5.22448 0.990246 4.82003 1.19632C4.19283 1.5159 3.68289 2.02584 3.36331 2.65304C3.15724 3.05749 3.0751 3.48807 3.03683 3.95649C2.99998 4.40748 2.99999 4.96111 3 5.6319V14.3674C2.99999 15.0382 2.99998 15.5919 3.03683 16.0429C3.0751 16.5113 3.15724 16.9419 3.36331 17.3463C3.68289 17.9735 4.19283 18.4835 4.82003 18.803C5.22448 19.0091 5.65507 19.0912 6.12348 19.1295C6.57447 19.1664 7.1281 19.1664 7.7989 19.1663H13.2011C13.8719 19.1664 14.4255 19.1664 14.8765 19.1295C15.3449 19.0912 15.7755 19.0091 16.18 18.803C16.8072 18.4835 17.3171 17.9735 17.6367 17.3463C17.8428 16.9419 17.9249 16.5113 17.9632 16.0429C18 15.5919 18 15.0382 18 14.3674V8.33296C18 8.32001 18 8.31354 17.9991 8.29369C17.9796 7.88216 17.6179 7.52027 17.2064 7.50063C17.1865 7.49968 17.1756 7.49968 17.1538 7.49968L13.4734 7.49969C13.2631 7.49971 13.0583 7.49974 12.8848 7.48556C12.6943 7.47 12.4695 7.43328 12.2434 7.31803C11.9298 7.15824 11.6748 6.90327 11.515 6.58967C11.3997 6.36348 11.363 6.1387 11.3475 5.94821C11.3333 5.77474 11.3333 5.56992 11.3333 5.3596L11.3333 1.67932ZM10.5 9.99968C10.9602 9.99968 11.3333 10.3728 11.3333 10.833V12.4997H13C13.4602 12.4997 13.8333 12.8728 13.8333 13.333C13.8333 13.7932 13.4602 14.1663 13 14.1663H11.3333V15.833C11.3333 16.2932 10.9602 16.6663 10.5 16.6663C10.0398 16.6663 9.66667 16.2932 9.66667 15.833V14.1663H8C7.53976 14.1663 7.16667 13.7932 7.16667 13.333C7.16667 12.8728 7.53976 12.4997 8 12.4997H9.66667V10.833C9.66667 10.3728 10.0398 9.99968 10.5 9.99968Z"
                                            fill="#009688"
                                          />
                                          <path
                                            d="M16.2566 5.833C16.5013 5.83301 16.6237 5.83301 16.7239 5.77157C16.8654 5.6848 16.95 5.48038 16.9112 5.31895C16.8838 5.20466 16.804 5.12495 16.6444 4.96554L13.8675 2.18861C13.7081 2.02904 13.6284 1.94925 13.5141 1.92178C13.3526 1.88298 13.1482 1.96759 13.0614 2.10912C13 2.20935 13 2.33169 13 2.57637L13 5.16631C13 5.39966 13 5.51633 13.0454 5.60546C13.0854 5.68386 13.1491 5.7476 13.2275 5.78755C13.3166 5.83296 13.4333 5.83296 13.6667 5.83297L16.2566 5.833Z"
                                            fill="#009688"
                                          />
                                        </svg>
                                      </div>
                                    )}
                                    <div>
                                      {item.media_name}{" "}
                                      <div>{formatBytes(item.media_size)}</div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </ul>
                      </div>
                    </div>
                    <button
                      disabled={true}
                      onClick={() => openModal("Educational Background")}
                      className="shadow-none absolute group right-4 top-4 lg:right-6 lg:top-6"
                    >
                      <img src={editIcon} className="opacity-40" />
                      <Tooltip
                        className="mt-2"
                        direction="left"
                        right="-right-5 after:right-[18px] before:right-4"
                        position="bottom"
                        pin="top"
                      >
                        You may reach out to our support team and we’ll update
                        these changes for you
                      </Tooltip>
                    </button>
                  </div>

                  <div className="bg-white rounded-2xl p-4 lg:p-6 mt-6 relative">
                    <Heading
                      variant="h2"
                      heading="License Details"
                      className="font-medium"
                    />
                    <div className="text-base text-gray-500">
                      Please provide your licensing details.
                    </div>
                    <div className="mt-4 lg:mt-6 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">License Number</span>
                      </div>
                      {licenses?.length > 0 && licenses[0].certifiedFrom ? (
                        <div className="space-y-8">
                          {licenses.map((item, key) => (
                            <div key={key}>
                              <div className="px-2 py-0.5 bg-gray-100 rounded-lg text-sm font-medium border border-gray-200 max-w-fit">
                                {item.certifiedFrom}
                              </div>
                              <div className="font-medium mt-2">
                                {item.licenseNumber}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        "N/A"
                      )}
                    </div>

                    <div className="lg:mt-6 py-4 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">
                          Supporting Documents
                        </span>
                      </div>
                      <div>
                        <ul className="flex flex-wrap gap-4 lg:mt-4">
                          {user?.licenses?.length > 0 ? (
                            <>
                              {user?.licenses?.map((item, index) => (
                                <li
                                  key={index}
                                  className="p-4 bg-gray-100 rounded-2xl text-sm text-gray-500 flex items-start justify-between w-full"
                                >
                                  <div className="flex space-x-4 items-center">
                                    {item?.full_url &&
                                    item?.media_mime?.includes("image") ? (
                                      <img
                                        src={item?.full_url}
                                        className={
                                          "w-10 h-10 rounded-lg object-contain bg-white"
                                        }
                                      />
                                    ) : (
                                      <div className="p-2 bg-white rounded-full">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="21"
                                          height="20"
                                          viewBox="0 0 21 20"
                                          fill="none"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M11.3333 1.67932C11.3333 1.6574 11.3333 1.64645 11.3324 1.62657C11.3127 1.21509 10.9509 0.853408 10.5394 0.833941C10.5195 0.833002 10.513 0.833004 10.5 0.833008H7.79888C7.1281 0.832999 6.57446 0.832992 6.12348 0.869838C5.65507 0.908109 5.22448 0.990246 4.82003 1.19632C4.19283 1.5159 3.68289 2.02584 3.36331 2.65304C3.15724 3.05749 3.0751 3.48807 3.03683 3.95649C2.99998 4.40748 2.99999 4.96111 3 5.6319V14.3674C2.99999 15.0382 2.99998 15.5919 3.03683 16.0429C3.0751 16.5113 3.15724 16.9419 3.36331 17.3463C3.68289 17.9735 4.19283 18.4835 4.82003 18.803C5.22448 19.0091 5.65507 19.0912 6.12348 19.1295C6.57447 19.1664 7.1281 19.1664 7.7989 19.1663H13.2011C13.8719 19.1664 14.4255 19.1664 14.8765 19.1295C15.3449 19.0912 15.7755 19.0091 16.18 18.803C16.8072 18.4835 17.3171 17.9735 17.6367 17.3463C17.8428 16.9419 17.9249 16.5113 17.9632 16.0429C18 15.5919 18 15.0382 18 14.3674V8.33296C18 8.32001 18 8.31354 17.9991 8.29369C17.9796 7.88216 17.6179 7.52027 17.2064 7.50063C17.1865 7.49968 17.1756 7.49968 17.1538 7.49968L13.4734 7.49969C13.2631 7.49971 13.0583 7.49974 12.8848 7.48556C12.6943 7.47 12.4695 7.43328 12.2434 7.31803C11.9298 7.15824 11.6748 6.90327 11.515 6.58967C11.3997 6.36348 11.363 6.1387 11.3475 5.94821C11.3333 5.77474 11.3333 5.56992 11.3333 5.3596L11.3333 1.67932ZM10.5 9.99968C10.9602 9.99968 11.3333 10.3728 11.3333 10.833V12.4997H13C13.4602 12.4997 13.8333 12.8728 13.8333 13.333C13.8333 13.7932 13.4602 14.1663 13 14.1663H11.3333V15.833C11.3333 16.2932 10.9602 16.6663 10.5 16.6663C10.0398 16.6663 9.66667 16.2932 9.66667 15.833V14.1663H8C7.53976 14.1663 7.16667 13.7932 7.16667 13.333C7.16667 12.8728 7.53976 12.4997 8 12.4997H9.66667V10.833C9.66667 10.3728 10.0398 9.99968 10.5 9.99968Z"
                                            fill="#009688"
                                          />
                                          <path
                                            d="M16.2566 5.833C16.5013 5.83301 16.6237 5.83301 16.7239 5.77157C16.8654 5.6848 16.95 5.48038 16.9112 5.31895C16.8838 5.20466 16.804 5.12495 16.6444 4.96554L13.8675 2.18861C13.7081 2.02904 13.6284 1.94925 13.5141 1.92178C13.3526 1.88298 13.1482 1.96759 13.0614 2.10912C13 2.20935 13 2.33169 13 2.57637L13 5.16631C13 5.39966 13 5.51633 13.0454 5.60546C13.0854 5.68386 13.1491 5.7476 13.2275 5.78755C13.3166 5.83296 13.4333 5.83296 13.6667 5.83297L16.2566 5.833Z"
                                            fill="#009688"
                                          />
                                        </svg>
                                      </div>
                                    )}
                                    <div>
                                      {item.media_name}{" "}
                                      <div>{formatBytes(item.media_size)}</div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </ul>
                      </div>
                    </div>
                    <button
                      // disabled={true}
                      onClick={() => openModal("License Details")}
                      className="shadow-none absolute group right-4 top-4 lg:right-6 lg:top-6"
                    >
                      <img src={editIcon} className="opacity-40" />
                      <Tooltip
                        className="mt-2"
                        direction="left"
                        right="-right-5 after:right-[18px] before:right-4"
                        position="bottom"
                        pin="top"
                      >
                        You may reach out to our support team and we’ll update
                        these changes for you
                      </Tooltip>
                    </button>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="bg-white rounded-2xl xs:p-3 p-4 lg:p-6 mt-6 relative">
                    <Heading
                      variant="h2"
                      heading="Expertise"
                      className="font-medium"
                    />
                    <p className="text-sm text-gray-500">
                      Please select all the problems you are certified to work
                      with (you can choose multiple options).
                    </p>
                    <div className="mt-4">
                      <div className="grid grid-cols-12 text-gray-500 text-xs lg:text-sm">
                        <div className="hidden lg:block col-span-3 uppercase">
                          Conditions
                        </div>
                        <div className="col-span-6 lg:col-span-5 uppercase">
                          PROBLEMS
                        </div>
                        <div className="col-span-3 lg:col-span-2 text-right lg:text-center uppercase">
                          CERTIFIED
                        </div>
                        <div className="col-span-3 lg:col-span-2 text-right lg:text-center uppercase">
                          TRAINED
                        </div>
                      </div>
                      {renderSelectedOptions()}
                      <button
                        disabled={true}
                        onClick={() => openModal("Expertise")}
                        className="shadow-none absolute group right-4 lg:right-6 top-4 lg:top-6"
                      >
                        <img src={editIcon} className="opacity-40" />
                        <Tooltip
                          className="mt-2"
                          direction="left"
                          right="-right-5 after:right-[18px] before:right-4"
                          position="bottom"
                          pin="top"
                        >
                          You may reach out to our support team and we’ll update
                          these changes for you
                        </Tooltip>
                      </button>
                    </div>
                  </div>
                  <div className="bg-white rounded-2xl xs:p-3 p-4 lg:p-6 mt-6 relative">
                    <Heading
                      variant="h2"
                      heading="Approaches/Techniques"
                      className="font-medium"
                    />
                    <p className="text-sm text-gray-500">
                      {`Choose the therapy approaches below that you're certified to
                    practice. *`}
                    </p>
                    <div className="mt-6 p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div className="font-medium">Approaches/Techniques</div>
                      {user?.therapy_approach &&
                      getParseDataKeyOrVal(user?.therapy_approach, "keys")
                        ?.length > 0 ? (
                        <div className="space-y-3">
                          {getParseDataKeyOrVal(
                            user?.therapy_approach,
                            "keys"
                          )?.map((key) => (
                            <div key={key} className="font-medium">
                              {getParseData(user?.therapy_approach)[key].label}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>{"N/A"}</div>
                      )}
                    </div>
                    <div className="mt-6 p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10 font-medium">
                      <div>Are you able to provide assessments?*</div>
                      <div>{user?.assessment_availability ? "Yes" : "No"}</div>
                    </div>
                    <button
                      disabled={true}
                      onClick={() => openModal("Approaches/Techniques")}
                      className="shadow-none absolute group right-4 lg:right-6 top-4 lg:top-6"
                    >
                      <img src={editIcon} className="opacity-40" />
                      <Tooltip
                        className="mt-2"
                        direction="left"
                        right="-right-5 after:right-[18px] before:right-4"
                        position="bottom"
                        pin="top"
                      >
                        You may reach out to our support team and we’ll update
                        these changes for you
                      </Tooltip>
                    </button>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="bg-white rounded-2xl xs:p-3 p-4 lg:p-6 mt-6 relative">
                    <Heading
                      variant="h2"
                      heading="Manage Availability "
                      className="font-medium"
                    />
                    {!isMobile && (
                      <p className="text-sm text-gray-500">
                        Please configure your availability based on which the
                        clients will be able to book a session.
                      </p>
                    )}
                    <TherapistAvailability userData={user} />
                  </div>
                  <div className="bg-white rounded-2xl xs:p-3 p-4 lg:p-6 mt-6 relative">
                    <Heading
                      variant="h2"
                      heading="Schedule Preferences"
                      className="font-medium"
                    />
                    <p className="text-sm text-gray-500">
                      Additional settings for your availability.
                    </p>
                    <div className="mt-4 lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">Same Day Bookings</span>
                        <div className="mt-1 text-gray-500">
                          Would you like to be contacted for same-day
                          counselling sessions?
                        </div>
                      </div>
                      <div className="font-medium">
                        {user?.same_day_book ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span className="font-medium">
                          Introductory Sessions
                        </span>
                        <div className="mt-1 text-gray-500">
                          {`Would you like to be available for introductory sessions? This is where you'd provide a free introduction to client in order to help them understand what to expect in an actual therapy session.`}
                        </div>
                      </div>
                      <div className="font-medium">
                        {user?.intro_session ? "Yes" : "No"}
                      </div>
                    </div>
                    {/* <div className="lg:mt-6 py-4 lg:p-2 text-sm grid lg:grid-cols-2 gap-8 lg:gap-10">
                      <div>
                        <span>Reminders</span>
                        <div className="mt-1 text-gray-500">
                          Would you like to be updated?
                        </div>
                      </div>
                      <div>{user?.reminders ? "Yes" : "No"}</div>
                    </div> */}

                    <button
                      onClick={() => openModal("Schedule Preferences")}
                      className="shadow-none absolute right-4 top-4 lg:right-6 lg:top-6"
                    >
                      <img src={editIcon} />
                    </button>
                  </div>
                </TabPanel>
              </Tabs>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel={modalContent}
                style={customStyles}
              >
                {modalContent === "Expertise" ? (
                  <div>
                    <div className="p-4 lg:p-8">
                      <Heading
                        variant="h3"
                        heading="Expertise"
                        className="font-medium text-lg"
                      />
                      <div className="grid lg:grid-cols-2 gap-4 mt-2">
                        <div className="px-3 py-2 bg-gray-100 rounded-lg">
                          <Heading
                            variant="h3"
                            heading="CERTFIED"
                            className="font-medium text-lg text-gray-500"
                          />
                          <p className="text-xs mt-0.5">
                            Only check if you have certification to treat the
                            specified condition. You will be asked to provide
                            documents proof.
                          </p>
                        </div>
                        <div className="px-3 py-2 bg-gray-100 rounded-lg">
                          <Heading
                            variant="h3"
                            heading="TRAINED"
                            className="font-medium text-lg text-gray-500"
                          />
                          <p className="text-xs mt-0.5">
                            Only check if you are trained and not necessarily
                            certified to treat the specified condition. You will
                            be asked to provide documents proof.
                          </p>
                        </div>
                      </div>
                    </div>
                    <Accordion
                      allowZeroExpanded
                      className="px-4 lg:px-8 pt-6 space-y-3 border-t border-gray-200"
                    >
                      {Object.entries(optionsData).map(
                        ([category, options]) => (
                          <AccordionItem
                            key={category}
                            className={`${
                              selectedCount[category] > 0 && "bg-blue-50 "
                            } border border-gray-200 rounded-xl`}
                          >
                            <AccordionItemButton
                              className={`${
                                selectedCount[category] > 0
                                  ? "text-blue-600"
                                  : "text-gray-500"
                              } h-11 flex items-center px-2 lg:px-3`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                className="mr-1 lg:mr-2"
                              >
                                <path
                                  d="M7.5 15L12.5 10L7.5 5"
                                  stroke="#98A2B3"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <span className="text-sm lg:text-base">
                                {category}
                              </span>{" "}
                              <div className="ml-auto text-xs lg:text-sm text-gray-500">
                                {selectedCount[category] > 0
                                  ? `${selectedCount[category]} problems selected`
                                  : ""}
                              </div>
                            </AccordionItemButton>
                            <AccordionItemPanel className="space-y-4 px-4 lg:px-6 pb-4 pt-3">
                              <div className="grid grid-cols-12">
                                <div className="col-span-7 lg:col-span-8 text-xs lg:text-sm">
                                  PROBLEMS
                                </div>
                                <div className="col-span-3 lg:col-span-2 text-center text-xs lg:text-sm">
                                  CERTIFIED
                                </div>
                                <div className="col-span-2 text-center text-xs lg:text-sm">
                                  TRAINED
                                </div>
                              </div>
                              {options.map((option) => (
                                <div key={option} className="grid grid-cols-12">
                                  <div className="flex items-center col-span-7 lg:col-span-8">
                                    <input
                                      type="checkbox"
                                      id={option}
                                      onChange={() =>
                                        handleCheckboxChange(category, option)
                                      }
                                      checked={
                                        !!selectedOptions[category]?.[option]
                                      }
                                      className="appearance-none checked:bg-blue-600 w-4 h-4 flex items-center justify-center checked:before:mb-[1px] checked:before:w-[.30rem] checked:before:h-[.60rem] before:border-b-2 before:border-white before:border-r-2 before:rotate-45 cursor-pointer"
                                    />
                                    <label
                                      htmlFor={option}
                                      className="ml-2 text-sm font-medium flex-1"
                                    >
                                      {option}
                                    </label>
                                  </div>

                                  <div className="flex items-center justify-center col-span-3 lg:col-span-2">
                                    {/* <input
                                      type="radio"
                                      name={`${category}_${option}`}
                                      value="Certified"
                                      onChange={() =>
                                        handleRadioChange(
                                          category,
                                          option,
                                          "Certified"
                                        )
                                      }
                                      className="hidden" // Hide the default radio button
                                      id={`${category}_${option}_certified`}
                                    /> */}
                                    <label
                                      onClick={() =>
                                        handleRadioChange(
                                          category,
                                          option,
                                          "Certified"
                                        )
                                      }
                                      htmlFor={`${category}_${option}_certified`}
                                      className={`flex items-center col-span-2 border border-gray-200 rounded-full p-1 cursor-pointer ${
                                        selectedOptions[category] &&
                                        selectedOptions[category][option] ===
                                          "Certified"
                                          ? "bg-blue-600 text-white"
                                          : "bg-white"
                                      }`}
                                    >
                                      <div
                                        className={`w-2.5 h-2.5 border border-white rounded-full ${
                                          selectedOptions[category] &&
                                          selectedOptions[category][option] ===
                                            "Certified"
                                            ? "bg-white"
                                            : ""
                                        }`}
                                      ></div>
                                    </label>
                                  </div>
                                  <div className="flex items-center justify-center col-span-2">
                                    {/* <input
                                      type="radio"
                                      name={`${category}_${option}`}
                                      value="Trained"
                                      onChange={() =>
                                        handleRadioChange(
                                          category,
                                          option,
                                          "Trained"
                                        )
                                      }
                                      className="hidden" // Hide the default radio button
                                      id={`${category}_${option}_trained`}
                                    /> */}
                                    <label
                                      onClick={() =>
                                        handleRadioChange(
                                          category,
                                          option,
                                          "Trained"
                                        )
                                      }
                                      htmlFor={`${category}_${option}_trained`}
                                      className={`flex items-center col-span-2 border border-gray-200 rounded-full p-1 cursor-pointer ${
                                        selectedOptions[category] &&
                                        selectedOptions[category][option] ===
                                          "Trained"
                                          ? "bg-blue-600 text-white"
                                          : "bg-white"
                                      }`}
                                    >
                                      <div
                                        className={`w-2.5 h-2.5 border border-white rounded-full ${
                                          selectedOptions[category] &&
                                          selectedOptions[category][option] ===
                                            "Trained"
                                            ? "bg-white"
                                            : ""
                                        }`}
                                      ></div>
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </AccordionItemPanel>
                          </AccordionItem>
                        )
                      )}
                    </Accordion>
                    {expertiseError && (
                      <div className="text-sm text-danger-900 mt-1.5 px-4 lg:px-8">
                        {expertiseErrorText}
                      </div>
                    )}
                    <div className="p-8 flex justify-end">
                      <Button
                        className={`${
                          !expertiseError
                            ? "bg-blue-900 hover:shadow-button hover:bg-blue-500 text-white"
                            : "bg-gray-200 text-gray-500"
                        }`}
                        onClick={handleSubmit}
                        app={app}
                        disabled={expertiseError ? true : false}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </div>
                ) : (
                  <ProfileForm
                    target={modalContent}
                    setModalIsOpen={(value) => setModalIsOpen(value)}
                  />
                )}
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TherapistProfile;
