import React from "react";
import Heading from "../atoms/Heading";
import Button from "../atoms/Button";
import Info from "../../assets/info-circle.svg";
import BlueIcon from "../../assets/Blue.png";
import GoldIcon from "../../assets/Gold.png";
import SilverIcon from "../../assets/Silver.png";
import Tooltip from "../atoms/Tooltip";

const TherapistCard = ({ data, onChooseClick }) => {
  const maxCategoriesToShow = 3;
  const certifiedKeys = [];
  const expertise =
    typeof data.certified_on === "string"
      ? JSON.parse(data.certified_on)
      : data.certified_on;

  expertise &&
    Object.entries(expertise).forEach(([, disorders]) => {
      Object.entries(disorders).forEach(([disorder, status]) => {
        if (status === "Certified") {
          certifiedKeys.push(disorder);
        }
      });
    });
  const visibleCategories = certifiedKeys
    .slice(0, maxCategoriesToShow)
    .sort((a, b) => {
      return a.localeCompare(b);
    });
  const hiddenCategories = certifiedKeys.slice(maxCategoriesToShow);
  return (
    <div className="px-6 pt-10 pb-10 lg:pb-52 rounded-3xl bg-white shadow-therapistCard font-medium relative">
      <div className="w-[100px] h-[100px] rounded-full">
        <img
          src={data?.profile_picture?.full_url}
          className="w-full h-full object-cover rounded-full"
        />
      </div>
      {/* <div className="text-gray-400 text-xxs mt-8 uppercase">
        UKPC Registered
      </div> */}
      <Heading
        variant="h2"
        heading={`${data.first_name} ${data.last_name}`}
        className="text-xl mt-2"
      />
      <div className="text-sm">{data.designation}</div>
      {data?.all_languages?.length > 0 && (
        <div className="text-sm font-normal text-gray-400 mt-1">
          Speaks:{" "}
          {data.all_languages?.map((item, index) => (
            <span key={index} className="capitalize">
              {item}
              {index < data.all_languages.length - 1 ? ", " : ""}
            </span>
          ))}
        </div>
      )}
      {visibleCategories.length > 0 && (
        <div className="text-sm mt-8">Specialized in treating</div>
      )}

      <ul className="text-sm text-gray-500 mt-1 space-y-1">
        {visibleCategories?.map((item, index) => (
          <li key={index} className="pr-2 flex items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="3"
              height="4"
              viewBox="0 0 3 4"
              fill="none"
              className="mr-1 mt-2"
            >
              <circle cx="1.5" cy="2.08301" r="1.5" fill="#98A2B3" />
            </svg>
            <span className="flex-1">{item}</span>
          </li>
        ))}

        {hiddenCategories.length > 0 && (
          <li className="text-gray-400 text-sm font-normal">
            {`+${hiddenCategories.length} more`}
          </li>
        )}
      </ul>

      <div
        className={`${data.tier === "GOLD"
          ? "bg-[#F5C44F]/20"
          : data.tier === "BLUE"
            ? "bg-blue-50"
            : "bg-gray-100"
          } p-3 rounded-2xl mt-8 lg:w-[calc(100%-3rem)] lg:absolute bottom-28`}
      >
        <div
          className={`${data.tier === "BLUE" ? "text-blue-600" : "text-gray-500"
            } bg-white px-2 py-1 text-xs rounded-full inline-flex space-x-1`}
        >
          <img
            src={
              data.tier === "BLUE"
                ? BlueIcon
                : data.tier === "GOLD"
                  ? GoldIcon
                  : SilverIcon
            }
          />
          <span className="uppercase">{data.tier} Therapist</span>
          <div className="relative z-10 group text-gray-500">
            <img src={Info} className="cursor-pointer" />
            <Tooltip>
              {data.tier === "GOLD"
                ? "Gold Therapists have 4+ years of experience"
                : data.tier === "BLUE"
                  ? "Blue Therapists have 2+ years of experience"
                  : "Silver Therapists have 3+ years of experience"}
            </Tooltip>
          </div>
        </div>
        <div className="text-sm text-gray-500 mt-2">
          Session starts @$
          {data.tier === "BLUE" ? "49" : data.tier === "GOLD" ? "79" : "60"}
        </div>
      </div>
      <Button
        text="Choose"
        className="bg-teal-900 text-white hover:shadow-button hover:bg-teal-500 transition-all mt-10 lg:mt-0 lg:absolute bottom-10 lg:inset-x-6 w-full lg:w-[calc(100%-3rem)] !text-base"
        onClick={onChooseClick}
      />
    </div>
  );
};
export default TherapistCard;
