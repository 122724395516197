import overjoyedIcon from "../../assets/overjoyed.svg";
import happyIcon from "../../assets/happy.svg";
import neutralIcon from "../../assets/neutral.svg";
import sadIcon from "../../assets/sad.svg";
import depressedIcon from "../../assets/solid-mood-depressed.svg";

export const whatsappClientNumber = "+971585869829";

export const sessionCountOptions = [
  "Less than 5",
  "5 - 10",
  "10 - 15",
  "15 - 20",
  "More than 20",
];

export const statusOptions = [
  // "All",
  "Ongoing",
  // "Abandoned",
  "Completed",
  "Paused",
];

export const conditionOptions = [
  "All",
  "Generalized Anxiety Disorder (GAD)",
  "Obsessive-compulsive Disorder (OCD)",
  "Panic disorder & Panic attacks",
  "Separation Anxiety Disorder",
  "Illness Anxiety Disorder (Hypochondriasis)",
  "Somatic Symptom Disorder",
  "Agoraphobia",
  "Claustrophobia",
  "Dysthymia",
  "Clinical depression (MDD)",
  "Bipolar Affective Disorder (BPAD)",
  "Paranoid Personality Disorder (PPD)",
  "Seasonal Affective Disorder",
  "Mood disorder due to comorbid health conditions",
  "Substance-induced Mood disorder",
  "Postpartum Depression",
  "Anorexia Nervosa",
  "Bulimia Nervosa",
  "Binge-Eating Disorder",
  "Anti Social Personality Disorder",
  "Avoidant Personality Disorder",
  "Borderline Personality Disorder",
  "Dependent Personality Disorder",
  "Histrionic Personality Disorder",
  "Narcissist Personality Disorder",
  "Obsessive Compulsive Personality Disorder",
  "Paranoid Personality Disorder",
  "Schizoid Personality Disorder",
  "Schizotypal Personality Disorder",
  "Alcohol Addiction",
  "Drug & prescription med Addiction",
  "Sex & Porn Addiction",
  "Gambling Addiction",
  "Gaming Addiction",
  "Social Media Addiction",
  "PTSD (Abuse & Rape)",
  "PTSD (Natural calamity, accidents)",
  "PTSD (Veterans)",
  "Complex PTSD",
  "Acute Stress Disorder",
  "Adjustment Disorder",
  "Erectile Dysfunction & Disorders",
  "Sexual Arousal Disorder",
  "Paraphilias & it’s sub-types",
  "Premature Ejaculation",
  "LQBTQ & Gender Identity",
  "ADHD/ADD assessment",
  "ADHD/ADD Treatment",
  "Psychosomatic Disorders",
  "Self-harm/suicidality",
  "Sleep Disorder Treatment",
  "Autism Spectrum Disorder",
  "Learning Disability & Disorder",
  "Neurocognitive disorders",
  "Conduct Disorder, Oppositional Defiant Disorder, Disruptive Mood Dysregulation Disorder",
  "Schizophrenia & Psychosis",
  "Adjustment Disorder",
  "Grief & bereavement",
  "Pre-marital Therapy",
  "Communication & conflict resolution",
  "Trust, intimacy & infidelity",
  "Infertility issues",
  "Parenting/co-parenting",
  "Separation/Divorce",
  "Career Coaching",
  "Work-Stress management",
  "Productivity & procrastination",
  "Performance Enhancement & Mental Skills Training",
  "Business coaching",
  "Executive & leadership coaching",
  "Existential crisis",
  "Anger management",
  "Confidence & self-esteem",
  "Positivity, spirituality & wellbeing",
  "Work-life balance",
  "Money management",
  "Emotional/creativity block",
];

export const sortingOptions = [
  { label: "Most Recent", value: "latest" },
  { label: "Least Recent", value: "oldest" },
  { label: "Ascending", value: "ascending" },
  { label: "Descending", value: "descending" },
];

export const STRESS_HISTORY = "Stress History";
export const MOOD_HISTORY = "Mood History";
export const STRESS_LEVEL = "Stress Level";
export const MOOD_LEVEL = "Mood Level";

export const SESSION_FILTER = "Sessions";
export const STATUS_FILTER = "Status";
export const CONDITIONS_FILTER = "Condition";
export const COUNTRY_FILTER = "Country";
export const SORTING_FILTER = "Sorting";
export const ACTION_POPUP = "Actions";
export const LANGUAGE_FILTER = "Language";
export const GENDER_FILTER = "Gender";
export const TYPE_FILTER = "Type";
export const PRICING_FILTER = "Pricing";
export const EXPERTISE_FILTER = "Expertise";

export const StressLevelData = [
  {
    title: "Level 1 : Not at all",
    desc: "Feeling calm and collected, managing stress with ease",
  },
  {
    title: "Level 2 : Low",
    desc: "Navigating through manageable stress, staying composed",
  },
  {
    title: "Level 3 : Moderate",
    desc: "Feeling the weight of stress, seeking balance in daily life",
  },
  {
    title: "Level 4 : High",
    desc: "Overwhelmed by stress, seeking relief and support",
  },
  {
    title: "Level 5 : Severe",
    desc: "Struggling under immense stress, in need of urgent intervention and care",
  },
];

export const StressLevelSliderData = [
  {
    title: "Not at all",
    level: "Level 1",
  },
  {
    title: "Low",
    level: "Level 2",
  },
  {
    title: "Moderate",
    level: "Level 3",
  },
  {
    title: "High",
    level: "Level 4",
  },
  {
    title: "Severe",
    level: "Level 5",
  },
];

export const moodLevelData = [
  {
    title: "Overjoyed",
    desc: "Overflowing with happiness, every moment is a celebration",
    icon: overjoyedIcon,
    level: 5,
  },
  {
    title: "Happy",
    desc: "Feeling cheerful and uplifted, seeing the beauty in every moment",
    icon: happyIcon,
    level: 4,
  },
  {
    title: "Neutral",
    desc: "Feeling calm and balanced, neither highs nor lows",
    icon: neutralIcon,
    level: 3,
  },
  {
    title: "Sad",
    desc: "Feeling lost in the shadows, longing for a glimmer of light",
    icon: sadIcon,
    level: 2,
  },
  {
    title: "Depressed",
    desc: "Weighed by despair, a heavy fog clouding the mind",
    icon: depressedIcon,
    level: 1,
  },
];

export const genderOptions = [
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" },
  { value: "OTHER", label: "Other" },
];

export const filterGenderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];

export const serviceOptions = [
  { value: "1", label: "In Person " },
  { value: "2", label: "Online" },
  // { value: "3", label: "Both" },
];

export const pricingOptions = [
  {
    value: "Gold",
    label: "Gold",
    experience: "Therapists with 4+ years of Experience",
    price: "109 USD/session",
  },
  {
    value: "Silver",
    label: "Silver",
    experience: "Therapists with 3+ years of Experience",
    price: "81 USD/session",
  },
  {
    value: "Blue",
    label: "Blue",
    experience: "Therapists with 2+ years of Experience",
    price: "54 USD/session",
  },
  {
    value: "In-person",
    label: "In-person",
    price: "163 USD/session",
  },
];

export const therapistGenderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
  { value: "All Genders", label: "All Genders" },
];

export const bloodGroupOptions = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
];

export const countriesOptions = {
  userCountryvalue: "AE",
  countries: [
    { value: "Afghanistan", label: "🇦🇫 Afghanistan" },
    { value: "Albania", label: "🇦🇱 Albania" },
    { value: "Algeria", label: "🇩🇿 Algeria" },
    { value: "Andorra", label: "🇦🇩 Andorra" },
    { value: "Angola", label: "🇦🇴 Angola" },
    { value: "Antigua and Barbuda", label: "🇦🇬 Antigua and Barbuda" },
    { value: "Argentina", label: "🇦🇷 Argentina" },
    { value: "Armenia", label: "🇦🇲 Armenia" },
    { value: "Australia", label: "🇦🇺 Australia" },
    { value: "Austria", label: "🇦🇹 Austria" },
    { value: "Azerbaijan", label: "🇦🇿 Azerbaijan" },
    { value: "Bahamas", label: "🇧🇸 Bahamas" },
    { value: "Bahrain", label: "🇧🇭 Bahrain" },
    { value: "Bangladesh", label: "🇧🇩 Bangladesh" },
    { value: "Barbados", label: "🇧🇧 Barbados" },
    { value: "Belarus", label: "🇧🇾 Belarus" },
    { value: "Belgium", label: "🇧🇪 Belgium" },
    { value: "Belize", label: "🇧🇿 Belize" },
    { value: "Benin", label: "🇧🇯 Benin" },
    { value: "Bhutan", label: "🇧🇹 Bhutan" },
    { value: "Bolivia", label: "🇧🇴 Bolivia" },
    { value: "Bosnia and Herzegovina", label: "🇧🇦 Bosnia and Herzegovina" },
    { value: "Botswana", label: "🇧🇼 Botswana" },
    { value: "Brazil", label: "🇧🇷 Brazil" },
    { value: "Brunei", label: "🇧🇳 Brunei" },
    { value: "Bulgaria", label: "🇧🇬 Bulgaria" },
    { value: "Burkina Faso", label: "🇧🇫 Burkina Faso" },
    { value: "Burundi", label: "🇧🇮 Burundi" },
    { value: "Cabo Verde", label: "🇨🇻 Cabo Verde" },
    { value: "Cambodia", label: "🇰🇭 Cambodia" },
    { value: "Cameroon", label: "🇨🇲 Cameroon" },
    { value: "Canada", label: "🇨🇦 Canada" },
    { value: "Central African Republic", label: "🇨🇫 Central African Republic" },
    { value: "Chad", label: "🇹🇩 Chad" },
    { value: "Chile", label: "🇨🇱 Chile" },
    { value: "China", label: "🇨🇳 China" },
    { value: "Colombia", label: "🇨🇴 Colombia" },
    { value: "Comoros", label: "🇰🇲 Comoros" },
    { value: "Costa Rica", label: "🇨🇷 Costa Rica" },
    { value: "Croatia", label: "🇭🇷 Croatia" },
    { value: "Cuba", label: "🇨🇺 Cuba" },
    { value: "Cyprus", label: "🇨🇾 Cyprus" },
    { value: "Czech Republic", label: "🇨🇿 Czech Republic" },
    {
      value: "Democratic Republic of the Congo",
      label: "🇨🇩 Democratic Republic of the Congo",
    },
    { value: "Denmark", label: "🇩🇰 Denmark" },
    { value: "Djibouti", label: "🇩🇯 Djibouti" },
    { value: "Dominica", label: "🇩🇲 Dominica" },
    { value: "Dominican Republic", label: "🇩🇴 Dominican Republic" },
    { value: "East Timor (Timor-Leste)", label: "🇹🇱 East Timor (Timor-Leste)" },
    { value: "Ecuador", label: "🇪🇨 Ecuador" },
    { value: "Egypt", label: "🇪🇬 Egypt" },
    { value: "El Salvador", label: "🇸🇻 El Salvador" },
    { value: "Equatorial Guinea", label: "🇬🇶 Equatorial Guinea" },
    { value: "Eritrea", label: "🇪🇷 Eritrea" },
    { value: "Estonia", label: "🇪🇪 Estonia" },
    { value: "Eswatini", label: "🇸🇿 Eswatini" },
    { value: "Ethiopia", label: "🇪🇹 Ethiopia" },
    { value: "Fiji", label: "🇫🇯 Fiji" },
    { value: "Finland", label: "🇫🇮 Finland" },
    { value: "France", label: "🇫🇷 France" },
    { value: "Gabon", label: "🇬🇦 Gabon" },
    { value: "The Gambia", label: "🇬🇲 The Gambia" },
    { value: "Georgia", label: "🇬🇪 Georgia" },
    { value: "Germany", label: "🇩🇪 Germany" },
    { value: "Ghana", label: "🇬🇭 Ghana" },
    { value: "Greece", label: "🇬🇷 Greece" },
    { value: "Grenada", label: "🇬🇩 Grenada" },
    { value: "Guatemala", label: "🇬🇹 Guatemala" },
    { value: "Guinea", label: "🇬🇳 Guinea" },
    { value: "Guinea-Bissau", label: "🇬🇼 Guinea-Bissau" },
    { value: "Guyana", label: "🇬🇾 Guyana" },
    { value: "Haiti", label: "🇭🇹 Haiti" },
    { value: "Honduras", label: "🇭🇳 Honduras" },
    { value: "Hungary", label: "🇭🇺 Hungary" },
    { value: "Iceland", label: "🇮🇸 Iceland" },
    { value: "India", label: "🇮🇳 India" },
    { value: "Indonesia", label: "🇮🇩 Indonesia" },
    { value: "Iran", label: "🇮🇷 Iran" },
    { value: "Iraq", label: "🇮🇶 Iraq" },
    { value: "Ireland", label: "🇮🇪 Ireland" },
    { value: "Israel", label: "🇮🇱 Israel" },
    { value: "Italy", label: "🇮🇹 Italy" },
    { value: "Jamaica", label: "🇯🇲 Jamaica" },
    { value: "Japan", label: "🇯🇵 Japan" },
    { value: "Jordan", label: "🇯🇴 Jordan" },
    { value: "Kazakhstan", label: "🇰🇿 Kazakhstan" },
    { value: "Kenya", label: "🇰🇪 Kenya" },
    { value: "Kiribati", label: "🇰🇮 Kiribati" },
    { value: "Kosovo", label: "🇽🇰 Kosovo" },
    { value: "Kuwait", label: "🇰🇼 Kuwait" },
    { value: "Kyrgyzstan", label: "🇰🇬 Kyrgyzstan" },
    { value: "Laos", label: "🇱🇦 Laos" },
    { value: "Latvia", label: "🇱🇻 Latvia" },
    { value: "Lebanon", label: "🇱🇧 Lebanon" },
    { value: "Lesotho", label: "🇱🇸 Lesotho" },
    { value: "Liberia", label: "🇱🇷 Liberia" },
    { value: "Libya", label: "🇱🇾 Libya" },
    { value: "Liechtenstein", label: "🇱🇮 Liechtenstein" },
    { value: "Lithuania", label: "🇱🇹 Lithuania" },
    { value: "Luxembourg", label: "🇱🇺 Luxembourg" },
    {
      value: "North Macedonia (formerly Macedonia)",
      label: "🇲🇰 North Macedonia (formerly Macedonia)",
    },
    { value: "Madagascar", label: "🇲🇬 Madagascar" },
    { value: "Malawi", label: "🇲🇼 Malawi" },
    { value: "Malaysia", label: "🇲🇾 Malaysia" },
    { value: "Maldives", label: "🇲🇻 Maldives" },
    { value: "Mali", label: "🇲🇱 Mali" },
    { value: "Malta", label: "🇲🇹 Malta" },
    { value: "Marshall Islands", label: "🇲🇭 Marshall Islands" },
    { value: "Mauritania", label: "🇲🇷 Mauritania" },
    { value: "Mauritius", label: "🇲🇺 Mauritius" },
    { value: "Mexico", label: "🇲🇽 Mexico" },
    { value: "Micronesia", label: "🇫🇲 Micronesia" },
    { value: "Moldova", label: "🇲🇩 Moldova" },
    { value: "Monaco", label: "🇲🇨 Monaco" },
    { value: "Mongolia", label: "🇲🇳 Mongolia" },
    { value: "Montenegro", label: "🇲🇪 Montenegro" },
    { value: "Morocco", label: "🇲🇦 Morocco" },
    { value: "Mozambique", label: "🇲🇿 Mozambique" },
    { value: "Myanmar (Burma)", label: "🇲🇲 Myanmar (Burma)" },
    { value: "Namibia", label: "🇳🇦 Namibia" },
    { value: "Nauru", label: "🇳🇷 Nauru" },
    { value: "Nepal", label: "🇳🇵 Nepal" },
    { value: "Netherlands", label: "🇳🇱 Netherlands" },
    { value: "New Zealand", label: "🇳🇿 New Zealand" },
    { value: "Nicaragua", label: "🇳🇮 Nicaragua" },
    { value: "Niger", label: "🇳🇪 Niger" },
    { value: "Nigeria", label: "🇳🇬 Nigeria" },
    { value: "North Korea", label: "🇰🇵 North Korea" },
    { value: "Norway", label: "🇳🇴 Norway" },
    { value: "Oman", label: "🇴🇲 Oman" },
    { value: "Pakistan", label: "🇵🇰 Pakistan" },
    { value: "Palau", label: "🇵🇼 Palau" },
    { value: "Panama", label: "🇵🇦 Panama" },
    { value: "Papua New Guinea", label: "🇵🇬 Papua New Guinea" },
    { value: "Paraguay", label: "🇵🇾 Paraguay" },
    { value: "Peru", label: "🇵🇪 Peru" },
    { value: "Philippines", label: "🇵🇭 Philippines" },
    { value: "Poland", label: "🇵🇱 Poland" },
    { value: "Portugal", label: "🇵🇹 Portugal" },
    { value: "Qatar", label: "🇶🇦 Qatar" },
    { value: "Republic of the Congo", label: "🇨🇬 Republic of the Congo" },
    { value: "Romania", label: "🇷🇴 Romania" },
    { value: "Russia", label: "🇷🇺 Russia" },
    { value: "Rwanda", label: "🇷🇼 Rwanda" },
    { value: "Saint Kitts and Nevis", label: "🇰🇳 Saint Kitts and Nevis" },
    { value: "Saint Lucia", label: "🇱🇨 Saint Lucia" },
    {
      value: "Saint Vincent and the Grenadines",
      label: "🇻🇨 Saint Vincent and the Grenadines",
    },
    { value: "Samoa", label: "🇼🇸 Samoa" },
    { value: "San Marino", label: "🇸🇲 San Marino" },
    { value: "Sao Tome and Principe", label: "🇸🇹 Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "🇸🇦 Saudi Arabia" },
    { value: "Senegal", label: "🇸🇳 Senegal" },
    { value: "Serbia", label: "🇷🇸 Serbia" },
    { value: "Seychelles", label: "🇸🇨 Seychelles" },
    { value: "Sierra Leone", label: "🇸🇱 Sierra Leone" },
    { value: "Singapore", label: "🇸🇬 Singapore" },
    { value: "Slovakia", label: "🇸🇰 Slovakia" },
    { value: "Slovenia", label: "🇸🇮 Slovenia" },
    { value: "Solomon Islands", label: "🇸🇧 Solomon Islands" },
    { value: "Somalia", label: "🇸🇴 Somalia" },
    { value: "South Africa", label: "🇿🇦 South Africa" },
    { value: "South Korea", label: "🇰🇷 South Korea" },
    { value: "South Sudan", label: "🇸🇸 South Sudan" },
    { value: "Spain", label: "🇪🇸 Spain" },
    { value: "Sri Lanka", label: "🇱🇰 Sri Lanka" },
    { value: "Sudan", label: "🇸🇩 Sudan" },
    { value: "Suriname", label: "🇸🇷 Suriname" },
    { value: "Sweden", label: "🇸🇪 Sweden" },
    { value: "Switzerland", label: "🇨🇭 Switzerland" },
    { value: "Syria", label: "🇸🇾 Syria" },
    { value: "Taiwan", label: "🇹🇼 Taiwan" },
    { value: "Tajikistan", label: "🇹🇯 Tajikistan" },
    { value: "Tanzania", label: "🇹🇿 Tanzania" },
    { value: "Thailand", label: "🇹🇭 Thailand" },
    { value: "Togo", label: "🇹🇬 Togo" },
    { value: "Tonga", label: "🇹🇴 Tonga" },
    { value: "Trinidad and Tobago", label: "🇹🇹 Trinidad and Tobago" },
    { value: "Tunisia", label: "🇹🇳 Tunisia" },
    { value: "Turkey", label: "🇹🇷 Turkey" },
    { value: "Turkmenistan", label: "🇹🇲 Turkmenistan" },
    { value: "Tuvalu", label: "🇹🇻 Tuvalu" },
    { value: "Uganda", label: "🇺🇬 Uganda" },
    { value: "Ukraine", label: "🇺🇦 Ukraine" },
    { value: "United Arab Emirates", label: "🇦🇪 United Arab Emirates" },
    { value: "United Kingdom", label: "🇬🇧 United Kingdom" },
    { value: "United States", label: "🇺🇸 United States" },
    { value: "Uruguay", label: "🇺🇾 Uruguay" },
    { value: "Uzbekistan", label: "🇺🇿 Uzbekistan" },
    { value: "Vanuatu", label: "🇻🇺 Vanuatu" },
    { value: "Vatican City", label: "🇻🇦 Vatican City" },
    { value: "Venezuela", label: "🇻🇪 Venezuela" },
    { value: "Vietnam", label: "🇻🇳 Vietnam" },
    { value: "Yemen", label: "🇾🇪 Yemen" },
    { value: "Zambia", label: "🇿🇲 Zambia" },
    { value: "Zimbabwe", label: "🇿🇼 Zimbabwe" },
  ],
  userSelectValue: { value: "AE", label: "🇦🇪 United Arab Emirates" },
};

export const nationalityOptions = [
  { value: "afghan", label: "Afghan" },
  { value: "albanian", label: "Albanian" },
  { value: "algerian", label: "Algerian" },
  { value: "andorran", label: "Andorran" },
  { value: "angolan", label: "Angolan" },
  { value: "antiguan_barbudan", label: "Antiguan, Barbudan" },
  { value: "argentine", label: "Argentine" },
  { value: "armenian", label: "Armenian" },
  { value: "australian", label: "Australian" },
  { value: "austrian", label: "Austrian" },
  { value: "azerbaijani", label: "Azerbaijani" },
  { value: "bahamian", label: "Bahamian" },
  { value: "bahraini", label: "Bahraini" },
  { value: "bangladeshi", label: "Bangladeshi" },
  { value: "barbadian", label: "Barbadian" },
  { value: "belarusian", label: "Belarusian" },
  { value: "belgian", label: "Belgian" },
  { value: "belizean", label: "Belizean" },
  { value: "beninese", label: "Beninese" },
  { value: "bhutanese", label: "Bhutanese" },
  { value: "bolivian", label: "Bolivian" },
  { value: "bosnian_herzegovinian", label: "Bosnian, Herzegovinian" },
  { value: "motswana", label: "Motswana (singular)" },
  { value: "batswana", label: "Batswana (plural)" },
  { value: "brazilian", label: "Brazilian" },
  { value: "bruneian", label: "Bruneian" },
  { value: "bulgarian", label: "Bulgarian" },
  { value: "burkinabe", label: "Burkinabe" },
  { value: "burundian", label: "Burundian" },
  { value: "cabo_verdean", label: "Cabo Verdean" },
  { value: "cambodian", label: "Cambodian" },
  { value: "cameroonian", label: "Cameroonian" },
  { value: "canadian", label: "Canadian" },
  { value: "central_african", label: "Central African" },
  { value: "chadian", label: "Chadian" },
  { value: "chilean", label: "Chilean" },
  { value: "chinese", label: "Chinese" },
  { value: "colombian", label: "Colombian" },
  { value: "comorian", label: "Comorian" },
  { value: "congolese", label: "Congolese" },
  { value: "costa_rican", label: "Costa Rican" },
  { value: "croatian", label: "Croatian" },
  { value: "cuban", label: "Cuban" },
  { value: "cypriot", label: "Cypriot" },
  { value: "czech", label: "Czech" },
  { value: "congolese", label: "Congolese" },
  { value: "danish", label: "Danish" },
  { value: "djiboutian", label: "Djiboutian" },
  { value: "dominican", label: "Dominican" },
  { value: "dominican", label: "Dominican" },
  { value: "timorese", label: "Timorese" },
  { value: "ecuadorian", label: "Ecuadorian" },
  { value: "egyptian", label: "Egyptian" },
  { value: "salvadoran", label: "Salvadoran" },
  { value: "equatorial_guinean", label: "Equatorial Guinean" },
  { value: "eritrean", label: "Eritrean" },
  { value: "estonian", label: "Estonian" },
  { value: "eswatini", label: "Eswatini (fmr. 'Swaziland')" },
  { value: "ethiopian", label: "Ethiopian" },
  { value: "fijian", label: "Fijian" },
  { value: "finnish", label: "Finnish" },
  { value: "french", label: "French" },
  { value: "gabonese", label: "Gabonese" },
  { value: "gambian", label: "Gambian" },
  { value: "georgian", label: "Georgian" },
  { value: "german", label: "German" },
  { value: "ghanaian", label: "Ghanaian" },
  { value: "greek", label: "Greek" },
  { value: "grenadian", label: "Grenadian" },
  { value: "guatemalan", label: "Guatemalan" },
  { value: "guinea", label: "Guinea" },
  { value: "guinea-bissau", label: "Guinea-Bissau" },
  { value: "guyanese", label: "Guyanese" },
  { value: "haitian", label: "Haitian" },
  { value: "honduran", label: "Honduran" },
  { value: "hungarian", label: "Hungarian" },
  { value: "icelandic", label: "Icelandic" },
  { value: "indian", label: "Indian" },
  { value: "indonesian", label: "Indonesian" },
  { value: "iranian", label: "Iranian" },
  { value: "iraqi", label: "Iraqi" },
  { value: "irish", label: "Irish" },
  { value: "israeli", label: "Israeli" },
  { value: "italian", label: "Italian" },
  { value: "ivorian", label: "Ivorian" },
  { value: "jamaican", label: "Jamaican" },
  { value: "japanese", label: "Japanese" },
  { value: "jordanian", label: "Jordanian" },
  { value: "kazakh", label: "Kazakh" },
  { value: "kenyan", label: "Kenyan" },
  { value: "i_kiribati", label: "I-Kiribati" },
  { value: "kosovar", label: "Kosovar" },
  { value: "kuwaiti", label: "Kuwaiti" },
  { value: "kyrgyz", label: "Kyrgyz" },
  { value: "laotian", label: "Laotian" },
  { value: "latvian", label: "Latvian" },
  { value: "lebanese", label: "Lebanese" },
  { value: "mosotho", label: "Mosotho (singular)" },
  { value: "basotho", label: "Basotho (plural)" },
  { value: "liberian", label: "Liberian" },
  { value: "libyan", label: "Libyan" },
  { value: "liechtensteiner", label: "Liechtensteiner" },
  { value: "lithuanian", label: "Lithuanian" },
  { value: "luxembourgish", label: "Luxembourgish" },
  { value: "macedonian", label: "Macedonian" },
  { value: "malagasy", label: "Malagasy" },
  { value: "malawian", label: "Malawian" },
  { value: "malaysian", label: "Malaysian" },
  { value: "maldivian", label: "Maldivian" },
  { value: "malian", label: "Malian" },
  { value: "maltese", label: "Maltese" },
  { value: "marshallese", label: "Marshallese" },
  { value: "mauritanian", label: "Mauritanian" },
  { value: "mauritian", label: "Mauritian" },
  { value: "mexican", label: "Mexican" },
  { value: "micronesian", label: "Micronesian" },
  { value: "moldovan", label: "Moldovan" },
  { value: "monegasque", label: "Monegasque" },
  { value: "mongolian", label: "Mongolian" },
  { value: "montenegrin", label: "Montenegrin" },
  { value: "moroccan", label: "Moroccan" },
  { value: "mozambican", label: "Mozambican" },
  { value: "burmese", label: "Burmese" },
  { value: "namibian", label: "Namibian" },
  { value: "nauruan", label: "Nauruan" },
  { value: "nepalese", label: "Nepalese" },
  { value: "dutch", label: "Dutch" },
  { value: "new_zealander", label: "New Zealander" },
  { value: "nicaraguan", label: "Nicaraguan" },
  { value: "nigerien", label: "Nigerien" },
  { value: "nigerian", label: "Nigerian" },
  { value: "north_korean", label: "North Korean" },
  { value: "norwegian", label: "Norwegian" },
  { value: "omani", label: "Omani" },
  { value: "pakistani", label: "Pakistani" },
  { value: "palauan", label: "Palauan" },
  { value: "panamanian", label: "Panamanian" },
  { value: "papua_new_guinean", label: "Papua New Guinean" },
  { value: "paraguayan", label: "Paraguayan" },
  { value: "peruvian", label: "Peruvian" },
  { value: "filipino", label: "Filipino" },
  { value: "polish", label: "Polish" },
  { value: "portuguese", label: "Portuguese" },
  { value: "qatari", label: "Qatari" },
  { value: "romanian", label: "Romanian" },
  { value: "russian", label: "Russian" },
  { value: "rwandan", label: "Rwandan" },
  { value: "kittitian_nevisian", label: "Kittitian, Nevisian" },
  { value: "saint_lucian", label: "Saint Lucian" },
  { value: "vincentian", label: "Vincentian" },
  { value: "samoan", label: "Samoan" },
  { value: "sammarinese", label: "Sammarinese" },
  { value: "sao_tomean", label: "Sao Tomean" },
  { value: "saudi", label: "Saudi" },
  { value: "senegalese", label: "Senegalese" },
  { value: "serbian", label: "Serbian" },
  { value: "seychellois", label: "Seychellois" },
  { value: "sierra_leonean", label: "Sierra Leonean" },
  { value: "singaporean", label: "Singaporean" },
  { value: "slovak", label: "Slovak" },
  { value: "slovenian", label: "Slovenian" },
  { value: "solomon_islander", label: "Solomon Islander" },
  { value: "somali", label: "Somali" },
  { value: "south_african", label: "South African" },
  { value: "south_korean", label: "South Korean" },
  { value: "south_sudanese", label: "South Sudanese" },
  { value: "spanish", label: "Spanish" },
  { value: "sri_lankan", label: "Sri Lankan" },
  { value: "sudanese", label: "Sudanese" },
  { value: "surinamese", label: "Surinamese" },
  { value: "swedish", label: "Swedish" },
  { value: "swiss", label: "Swiss" },
  { value: "syrian", label: "Syrian" },
  { value: "taiwanese", label: "Taiwanese" },
  { value: "tajik", label: "Tajik" },
  { value: "tanzanian", label: "Tanzanian" },
  { value: "thai", label: "Thai" },
  { value: "togolese", label: "Togolese" },
  { value: "tongan", label: "Tongan" },
  { value: "trinidadian_tobagonian", label: "Trinidadian, Tobagonian" },
  { value: "tunisian", label: "Tunisian" },
  { value: "turkish", label: "Turkish" },
  { value: "turkmen", label: "Turkmen" },
  { value: "tuvaluan", label: "Tuvaluan" },
];

export const languageOptions = [
  { value: "abkhaz", label: "Abkhaz" },
  { value: "afar", label: "Afar" },
  { value: "afrikaans", label: "Afrikaans" },
  { value: "akan", label: "Akan" },
  { value: "albanian", label: "Albanian" },
  { value: "amharic", label: "Amharic" },
  { value: "arabic", label: "Arabic" },
  { value: "aramaic", label: "Aramaic" },
  { value: "armenian", label: "Armenian" },
  { value: "assamese", label: "Assamese" },
  { value: "aymara", label: "Aymara" },
  { value: "azerbaijani", label: "Azerbaijani" },
  { value: "bambara", label: "Bambara" },
  { value: "basque", label: "Basque" },
  { value: "belarusian", label: "Belarusian" },
  { value: "bengali", label: "Bengali" },
  { value: "bihari", label: "Bihari" },
  { value: "bislama", label: "Bislama" },
  { value: "bosnian", label: "Bosnian" },
  { value: "breton", label: "Breton" },
  { value: "bulgarian", label: "Bulgarian" },
  { value: "burmese", label: "Burmese" },
  { value: "catalan", label: "Catalan" },
  { value: "cebuano", label: "Cebuano" },
  { value: "chamorro", label: "Chamorro" },
  { value: "chechen", label: "Chechen" },
  { value: "chichewa", label: "Chichewa" },
  { value: "chinese", label: "Chinese (Mandarin, Cantonese, etc.)" },
  { value: "chuvash", label: "Chuvash" },
  { value: "cornish", label: "Cornish" },
  { value: "corsican", label: "Corsican" },
  { value: "cree", label: "Cree" },
  { value: "croatian", label: "Croatian" },
  { value: "czech", label: "Czech" },
  { value: "danish", label: "Danish" },
  { value: "dhivehi", label: "Dhivehi" },
  { value: "dutch", label: "Dutch" },
  { value: "dzongkha", label: "Dzongkha" },
  { value: "english", label: "English" },
  { value: "esperanto", label: "Esperanto" },
  { value: "estonian", label: "Estonian" },
  { value: "ewe", label: "Ewe" },
  { value: "faroese", label: "Faroese" },
  { value: "fijian", label: "Fijian" },
  { value: "filipino", label: "Filipino" },
  { value: "finnish", label: "Finnish" },
  { value: "french", label: "French" },
  { value: "frisian", label: "Frisian" },
  { value: "galician", label: "Galician" },
  { value: "georgian", label: "Georgian" },
  { value: "german", label: "German" },
  { value: "greek", label: "Greek" },
  { value: "greenlandic", label: "Greenlandic" },
  { value: "guarani", label: "Guarani" },
  { value: "gujarati", label: "Gujarati" },
  { value: "haitian_creole", label: "Haitian Creole" },
  { value: "hausa", label: "Hausa" },
  { value: "hebrew", label: "Hebrew" },
  { value: "herero", label: "Herero" },
  { value: "hindi", label: "Hindi" },
  { value: "hiri_motu", label: "Hiri Motu" },
  { value: "hungarian", label: "Hungarian" },
  { value: "icelandic", label: "Icelandic" },
  { value: "igbo", label: "Igbo" },
  { value: "indonesian", label: "Indonesian" },
  { value: "interlingua", label: "Interlingua" },
  { value: "irish", label: "Irish" },
  { value: "italian", label: "Italian" },
  { value: "japanese", label: "Japanese" },
  { value: "javanese", label: "Javanese" },
  { value: "kannada", label: "Kannada" },
  { value: "kashmiri", label: "Kashmiri" },
  { value: "kazakh", label: "Kazakh" },
  { value: "khmer", label: "Khmer" },
  { value: "kinyarwanda", label: "Kinyarwanda" },
  { value: "kirundi", label: "Kirundi" },
  { value: "komi", label: "Komi" },
  { value: "kongo", label: "Kongo" },
  { value: "korean", label: "Korean" },
  { value: "kurdish", label: "Kurdish" },
  { value: "kyrgyz", label: "Kyrgyz" },
  { value: "lao", label: "Lao" },
  { value: "latin", label: "Latin" },
  { value: "latvian", label: "Latvian" },
  { value: "limburgish", label: "Limburgish" },
  { value: "lingala", label: "Lingala" },
  { value: "lithuanian", label: "Lithuanian" },
  { value: "luba_katanga", label: "Luba-Katanga" },
  { value: "luxembourgish", label: "Luxembourgish" },
  { value: "macedonian", label: "Macedonian" },
  { value: "malagasy", label: "Malagasy" },
  { value: "malay", label: "Malay" },
  { value: "malayalam", label: "Malayalam" },
  { value: "maltese", label: "Maltese" },
  { value: "manx", label: "Manx" },
  { value: "maori", label: "Maori" },
  { value: "marathi", label: "Marathi" },
  { value: "marshallese", label: "Marshallese" },
  { value: "moldovan", label: "Moldovan" },
  { value: "mongolian", label: "Mongolian" },
  { value: "nauruan", label: "Nauruan" },
  { value: "navajo", label: "Navajo" },
  { value: "ndonga", label: "Ndonga" },
  { value: "nepali", label: "Nepali" },
  { value: "north_ndebele", label: "North Ndebele" },
  { value: "northern_sami", label: "Northern Sami" },
  { value: "norwegian", label: "Norwegian" },
  { value: "nyanja", label: "Nyanja" },
  { value: "occitan", label: "Occitan" },
  { value: "ojibwe", label: "Ojibwe" },
  { value: "old_church_slavonic", label: "Old Church Slavonic" },
  { value: "oriya", label: "Oriya" },
  { value: "oromo", label: "Oromo" },
  { value: "ossetian", label: "Ossetian" },
  { value: "pali", label: "Pāli" },
  { value: "pashto", label: "Pashto" },
  { value: "persian", label: "Persian (Farsi)" },
  { value: "polish", label: "Polish" },
  { value: "portuguese", label: "Portuguese" },
  { value: "punjabi", label: "Punjabi" },
  { value: "quechua", label: "Quechua" },
  { value: "romanian", label: "Romanian" },
  { value: "romansh", label: "Romansh" },
  { value: "russian", label: "Russian" },
  { value: "samoan", label: "Samoan" },
  { value: "sango", label: "Sango" },
  { value: "sanskrit", label: "Sanskrit" },
  { value: "scottish_gaelic", label: "Scottish Gaelic" },
  { value: "serbian", label: "Serbian" },
  { value: "sesotho", label: "Sesotho" },
  { value: "shona", label: "Shona" },
  { value: "sindhi", label: "Sindhi" },
  { value: "sinhalese", label: "Sinhalese" },
  { value: "slovak", label: "Slovak" },
  { value: "slovenian", label: "Slovenian" },
  { value: "somali", label: "Somali" },
  { value: "southern_sotho", label: "Southern Sotho" },
  { value: "spanish", label: "Spanish" },
  { value: "sundanese", label: "Sundanese" },
  { value: "swahili", label: "Swahili" },
  { value: "swedish", label: "Swedish" },
  { value: "tajik", label: "Tajik" },
  { value: "tamil", label: "Tamil" },
  { value: "tatar", label: "Tatar" },
  { value: "telugu", label: "Telugu" },
  { value: "tetum", label: "Tetum" },
  { value: "thai", label: "Thai" },
  { value: "tibetan", label: "Tibetan" },
  { value: "tigrinya", label: "Tigrinya" },
  { value: "tonga", label: "Tonga" },
  { value: "tsonga", label: "Tsonga" },
  { value: "tswana", label: "Tswana" },
  { value: "turkish", label: "Turkish" },
  { value: "turkmen", label: "Turkmen" },
  { value: "twi", label: "Twi" },
  { value: "uighur", label: "Uighur" },
  { value: "ukrainian", label: "Ukrainian" },
  { value: "urdu", label: "Urdu" },
  { value: "uzbek", label: "Uzbek" },
  { value: "venda", label: "Venda" },
  { value: "vietnamese", label: "Vietnamese" },
  { value: "volapuk", label: "Volapük" },
  { value: "walloon", label: "Walloon" },
  { value: "welsh", label: "Welsh" },
  { value: "wolof", label: "Wolof" },
  { value: "xhosa", label: "Xhosa" },
  { value: "yiddish", label: "Yiddish" },
  { value: "yoruba", label: "Yoruba" },
  { value: "zhuang", label: "Zhuang" },
  { value: "zulu", label: "Zulu" },
];

export const religionOptions = [
  {
    value: "African Traditional & Diasporic",
    label: "African Traditional & Diasporic",
  },
  { value: "Agnostic", label: "Agnostic" },
  { value: "Atheist", label: "Atheist" },
  { value: "Baha'i", label: "Baha'i" },
  { value: "Buddhism", label: "Buddhism" },
  { value: "Cao Dai", label: "Cao Dai" },
  {
    value: "Chinese traditional religion",
    label: "Chinese traditional religion",
  },
  { value: "Christianity", label: "Christianity" },
  { value: "Hinduism", label: "Hinduism" },
  { value: "Islam", label: "Islam" },
  { value: "Jainism", label: "Jainism" },
  { value: "Juche", label: "Juche" },
  { value: "Judaism", label: "Judaism" },
  { value: "Neo-Paganism", label: "Neo-Paganism" },
  { value: "Nonreligious", label: "Nonreligious" },
  { value: "Rastafarianism", label: "Rastafarianism" },
  { value: "Secular", label: "Secular" },
  { value: "Shinto", label: "Shinto" },
  { value: "Sikhism", label: "Sikhism" },
  { value: "Spiritism", label: "Spiritism" },
  { value: "Tenrikyo", label: "Tenrikyo" },
  { value: "Unitarian-Universalism", label: "Unitarian-Universalism" },
  { value: "Zoroastrianism", label: "Zoroastrianism" },
  { value: "primal-indigenous", label: "primal-indigenous" },
  { value: "Other", label: "Other" },
];

export const hoursOfTherapyOptions = [
  {
    value: "Less than 500",
    label: "Less than 500 hours",
  },
  { value: "500 - 1000", label: "500 - 1000 hours" },
  { value: "1000 - 2000", label: "1000 - 2000 hours" },
  { value: "2000 - 3000", label: "2000 - 3000 hours" },
  { value: "3000 - 5000", label: "3000 - 5000 hours" },
  { value: "5000 - 7000", label: "5000 - 7000 hours" },
  { value: "7000 - 10000", label: "7000 - 10000 hours" },
  { value: "More than 10000", label: "More than 10000 hours" },
];

export const clientsExpOptions = [
  {
    value: "Less than 100",
    label: "Less than 100 clients",
  },
  { value: "100 - 500", label: "100 - 500 clients" },
  { value: "500 - 1000", label: "500 - 1000 clients" },
  { value: "1000 - 3000", label: "1000 - 3000 clients" },
  { value: "3000 - 5000", label: "3000 - 5000 clients" },
  { value: "5000 - 10000", label: "5000 - 10000 clients" },
  { value: "More than 10000", label: "More than 10000 clients" },
];

export const designationOptions = [
  {
    value: "Therapist",
    label: "Therapist",
  },
  { value: "Psychologist", label: "Psychologist" },
  { value: "Counsellor", label: "Counsellor" },
  { value: "Coach", label: "Coach" },
  { value: "Other", label: "Other" },
];

export const clientsTypeOptions = [
  {
    value: "Adolescents",
    label: "Adolescents",
  },
  {
    value: "Children (between 3 to 12 years old)",
    label: "Children (between 3 to 12 years old)",
  },
  {
    value: "Teenagers (between 13 to 18)",
    label: "Teenagers (between 13 to 18)",
  },
  { value: "Elderly", label: "Elderly" },
  { value: "Adults", label: "Adults" },
  { value: "Groups", label: "Groups" },
  { value: "Others", label: "Others" },
];

export const certifiedBodiesOptions = [
  {
    value: "American Psychological Association (APA)",
    label: "American Psychological Association (APA)",
  },
  {
    value: "British Psychological Society (BPS)",
    label: "British Psychological Society (BPS)",
  },
  {
    value: "Australian Psychological Society (APS)",
    label: "Australian Psychological Society (APS)",
  },
  {
    value: "Canadian Psychological Association (CPA)",
    label: "Canadian Psychological Association (CPA)",
  },
  {
    value: "European Federation of Psychologists' Associations (EFPA)",
    label: "European Federation of Psychologists' Associations (EFPA)",
  },
  {
    value: "Board of Counseling and Psychotherapy in India (BCI)",
    label: "Board of Counseling and Psychotherapy in India (BCI)",
  },
  {
    value: "Hong Kong Psychological Society (HKPS)",
    label: "Hong Kong Psychological Society (HKPS)",
  },
  {
    value: "Singapore Psychological Society (SPS)",
    label: "Singapore Psychological Society (SPS)",
  },
  {
    value: "Psychological Society of South Africa (PsySSA)",
    label: "Psychological Society of South Africa (PsySSA)",
  },
  {
    value: "New Zealand Psychological Society (NZPsS)",
    label: "New Zealand Psychological Society (NZPsS)",
  },
  {
    value: "American Association for Marriage and Family Therapy (AAMFT)",
    label: "American Association for Marriage and Family Therapy (AAMFT)",
  },
  {
    value: "British Association for Counselling and Psychotherapy (BACP)",
    label: "British Association for Counselling and Psychotherapy (BACP)",
  },
  {
    value: "Australian Counselling Association (ACA)",
    label: "Australian Counselling Association (ACA)",
  },
  {
    value: "Canadian Counselling and Psychotherapy Association (CCPA)",
    label: "Canadian Counselling and Psychotherapy Association (CCPA)",
  },
  {
    value: "European Association for Psychotherapy (EAP)",
    label: "European Association for Psychotherapy (EAP)",
  },
  {
    value: "Indian Association for Clinical Psychologists (IACP)",
    label: "Indian Association for Clinical Psychologists (IACP)",
  },
  {
    value: "Hong Kong Professional Counselling Association (HKPCA)",
    label: "Hong Kong Professional Counselling Association (HKPCA)",
  },
  {
    value: "Singapore Association for Counselling (SAC)",
    label: "Singapore Association for Counselling (SAC)",
  },
  {
    value: "New Zealand Association of Counsellors (NZAC)",
    label: "New Zealand Association of Counsellors (NZAC)",
  },
  {
    value: "South African College of Applied Psychology (SACAP)",
    label: "South African College of Applied Psychology (SACAP)",
  },
  {
    value: "NHS (National Health Service)",
    label: "NHS (National Health Service)",
  },
  {
    value: "National Board for Certified Counselors (NBCC)",
    label: "National Board for Certified Counselors (NBCC)",
  },
  {
    value: "American Association for Marriage and Family Therapy (AAMFT)",
    label: "American Association for Marriage and Family Therapy (AAMFT)",
  },
  {
    value: "Association of State and Provincial Psychology Boards (ASPPB)",
    label: "Association of State and Provincial Psychology Boards (ASPPB)",
  },
  {
    value: "National Association of School Psychologists (NASP)",
    label: "National Association of School Psychologists (NASP)",
  },
  {
    value: "Health Authority - Abu Dhabi (HAAD)",
    label: "Health Authority - Abu Dhabi (HAAD)",
  },
  {
    value: "Dubai Health Authority (DHA)",
    label: "Dubai Health Authority (DHA)",
  },
  {
    value: "Ministry of Health and Prevention (MOHAP) - UAE",
    label: "Ministry of Health and Prevention (MOHAP) - UAE",
  },
  {
    value: "British Psychological Society (BPS) - United Kingdom",
    label: "British Psychological Society (BPS) - United Kingdom",
  },
  {
    value:
      "Fédération Française de Psychologie (French Federation of Psychology) - France",
    label:
      "Fédération Française de Psychologie (French Federation of Psychology) - France",
  },
  {
    value:
      "Deutsche Gesellschaft für Psychologie (German Psychological Society) - Germany",
    label:
      "Deutsche Gesellschaft für Psychologie (German Psychological Society) - Germany",
  },
  {
    value:
      "Colegio Oficial de Psicólogos (Official College of Psychologists) - Spain",
    label:
      "Colegio Oficial de Psicólogos (Official College of Psychologists) - Spain",
  },
  {
    value: "Ordine degli Psicologi (Order of Psychologists) - Italy",
    label: "Ordine degli Psicologi (Order of Psychologists) - Italy",
  },
  {
    value: "Psychological Society of the Philippines (PSP)",
    label: "Psychological Society of the Philippines (PSP)",
  },
  {
    value: "Japanese Psychological Association (JPA)",
    label: "Japanese Psychological Association (JPA)",
  },
];

export const expertiseOptions = [
  { value: "ANXIETY DISORDERS", label: "ANXIETY DISORDERS", isDisabled: true },
  {
    value: "ANXIETY DISORDERS - Generalized Anxiety Disorder (GAD)",
    label: "Generalized Anxiety Disorder (GAD)",
  },
  {
    value: "ANXIETY DISORDERS - Obsessive-compulsive Disorder (OCD)",
    label: "Obsessive-compulsive Disorder (OCD)",
  },
  {
    value: "ANXIETY DISORDERS - Panic disorder & Panic attacks",
    label: "Panic disorder & Panic attacks",
  },
  {
    value: "ANXIETY DISORDERS - Separation Anxiety Disorder",
    label: "Separation Anxiety Disorder",
  },
  {
    value: "ANXIETY DISORDERS - Social Anxiety Disorder",
    label: "Social Anxiety Disorder",
  },
  {
    value: "ANXIETY DISORDERS - Illness Anxiety Disorder (Hypochondriasis)",
    label: "Illness Anxiety Disorder (Hypochondriasis)",
  },
  {
    value: "ANXIETY DISORDERS - Somatic Symptom Disorder",
    label: "Somatic Symptom Disorder",
  },
  { value: "ANXIETY DISORDERS - Agoraphobia", label: "Agoraphobia" },
  { value: "ANXIETY DISORDERS - Claustrophobia", label: "Claustrophobia" },
  { value: "MOOD DISORDERS", label: "MOOD DISORDERS", isDisabled: true },
  { value: "MOOD DISORDERS - Dysthymia", label: "Dysthymia" },
  {
    value: "MOOD DISORDERS - Clinical depression (MDD)",
    label: "Clinical depression (MDD)",
  },
  {
    value: "MOOD DISORDERS - Bipolar Affective Disorder (BPAD)",
    label: "Bipolar Affective Disorder (BPAD)",
  },
  {
    value: "MOOD DISORDERS - Postpartum Depression (PPD)",
    label: "Postpartum Depression (PPD)",
  },
  {
    value: "MOOD DISORDERS - Seasonal Affective Disorder",
    label: "Seasonal Affective Disorder",
  },
  {
    value: "MOOD DISORDERS - Mood disorder due to comorbid health conditions",
    label: "Mood disorder due to comorbid health conditions",
  },
  {
    value: "MOOD DISORDERS - Substance-induced Mood disorder",
    label: "Substance-induced Mood disorder",
  },
  { value: "EATING DISORDERS", label: "EATING DISORDERS", isDisabled: true },
  { value: "EATING DISORDERS - Anorexia Nervosa", label: "Anorexia Nervosa" },
  { value: "EATING DISORDERS - Bulimia Nervosa", label: "Bulimia Nervosa" },
  {
    value: "EATING DISORDERS - Binge-Eating Disorder",
    label: "Binge-Eating Disorder",
  },
  {
    value: "PERSONALITY DISORDERS",
    label: "PERSONALITY DISORDERS",
    isDisabled: true,
  },
  {
    value: "PERSONALITY DISORDERS - Anti Social Personality Disorder",
    label: "Anti Social Personality Disorder",
  },
  {
    value: "PERSONALITY DISORDERS - Avoidant Personality Disorder",
    label: "Avoidant Personality Disorder",
  },
  {
    value: "PERSONALITY DISORDERS - Borderline Personality Disorder",
    label: "Borderline Personality Disorder",
  },
  {
    value: "PERSONALITY DISORDERS - Dependent Personality Disorder",
    label: "Dependent Personality Disorder",
  },
  {
    value: "PERSONALITY DISORDERS - Histrionic Personality Disorder",
    label: "Histrionic Personality Disorder",
  },
  {
    value: "PERSONALITY DISORDERS - Narcissist Personality Disorder",
    label: "Narcissist Personality Disorder",
  },
  {
    value: "PERSONALITY DISORDERS - Obsessive Compulsive Personality Disorder",
    label: "Obsessive Compulsive Personality Disorder",
  },
  {
    value: "PERSONALITY DISORDERS - Paranoid Personality Disorder",
    label: "Paranoid Personality Disorder",
  },
  {
    value: "PERSONALITY DISORDERS - Schizoid Personality Disorder",
    label: "Schizoid Personality Disorder",
  },
  {
    value: "PERSONALITY DISORDERS - Schizotypal Personality Disorder",
    label: "Schizotypal Personality Disorder",
  },
  { value: "ADDICTIONS", label: "ADDICTIONS", isDisabled: true },
  { value: "ADDICTIONS - Alcohol Addiction", label: "Alcohol Addiction" },
  {
    value: "ADDICTIONS - Drug & prescription med Addiction",
    label: "Drug & prescription med Addiction",
  },
  { value: "ADDICTIONS - Sex & Porn Addiction", label: "Sex & Porn Addiction" },
  { value: "ADDICTIONS - Gambling Addiction", label: "Gambling Addiction" },
  {
    value: "ADDICTIONS - Social Media Addiction",
    label: "Social Media Addiction",
  },
  {
    value: "TRAUMA AND STRESS DISORDER",
    label: "TRAUMA AND STRESS DISORDER",
    isDisabled: true,
  },
  {
    value: "TRAUMA AND STRESS DISORDER - PTSD (Abuse & Rape)",
    label: "PTSD (Abuse & Rape)",
  },
  {
    value: "TRAUMA AND STRESS DISORDER - PTSD (Natural calamity, accidents)",
    label: "PTSD (Natural calamity, accidents)",
  },
  {
    value: "TRAUMA AND STRESS DISORDER - PTSD (Veterans)",
    label: "PTSD (Veterans)",
  },
  { value: "TRAUMA AND STRESS DISORDER - Complex PTSD", label: "Complex PTSD" },
  {
    value: "TRAUMA AND STRESS DISORDER - Acute Stress Disorder",
    label: "Acute Stress Disorder",
  },
  {
    value: "TRAUMA AND STRESS DISORDER - Adjustment Disorder",
    label: "Adjustment Disorder",
  },
  { value: "SEXUAL DISORDERS", label: "SEXUAL DISORDERS", isDisabled: true },
  {
    value: "SEXUAL DISORDERS - Erectile Dysfunction & Disorders",
    label: "Erectile Dysfunction & Disorders",
  },
  {
    value: "SEXUAL DISORDERS - Sexual Arousal Disorder",
    label: "Sexual Arousal Disorder",
  },
  {
    value: "SEXUAL DISORDERS - Paraphilias & it’s sub-types",
    label: "Paraphilias & it’s sub-types",
  },
  {
    value: "SEXUAL DISORDERS - Premature Ejaculation",
    label: "Premature Ejaculation",
  },
  { value: "LQBTQ & Gender Identity", label: "LQBTQ & Gender Identity" },
  { value: "OTHER", label: "OTHER", isDisabled: true },
  { value: "OTHER - ADHD/ADD Assessment", label: "ADHD/ADD Assessment" },
  { value: "OTHER - ADHD/ADD Treatment", label: "ADHD/ADD Treatment" },
  {
    value: "OTHER - Psychosomatic Disorders",
    label: "Psychosomatic Disorders",
  },
  { value: "OTHER - Self-harm/suicidality", label: "Self-harm/suicidality" },
  {
    value: "OTHER - Sleep Disorder Treatment",
    label: "Sleep Disorder Treatment",
  },
  { value: "OTHER - Grief & bereavement", label: "Grief & bereavement" },
  { value: "SUPPORT THERAPY", label: "SUPPORT THERAPY", isDisabled: true },
  {
    value: "SUPPORT THERAPY - Autism Spectrum Disorder",
    label: "Autism Spectrum Disorder",
  },
  {
    value: "SUPPORT THERAPY - Learning Disability & Disorder",
    label: "Learning Disability & Disorder",
  },
  {
    value:
      "SUPPORT THERAPY - Neurocognitive disorders (E.g. Dementia, Alzheimer's, etc.)",
    label: "Neurocognitive disorders (E.g. Dementia, Alzheimer's, etc.)",
  },
  {
    value:
      "SUPPORT THERAPY - Conduct Disorder, Oppositional Defiant Disorder, Disruptive Mood Dysregulation Disorder",
    label:
      "Conduct Disorder, Oppositional Defiant Disorder, Disruptive Mood Dysregulation Disorder",
  },
  {
    value: "SUPPORT THERAPY - Schizophrenia & Psychosis",
    label: "Schizophrenia & Psychosis",
  },
  {
    value: "SUPPORT THERAPY - Adjustment Disorder",
    label: "Adjustment Disorder",
  },
  {
    value: "SUPPORT THERAPY - Loss & Grief Counselling",
    label: "Loss & Grief Counselling",
  },
  {
    value: "RELATIONSHIP COUNSELLING",
    label: "RELATIONSHIP COUNSELLING",
    isDisabled: true,
  },
  {
    value: "RELATIONSHIP COUNSELLING - Pre-marital Therapy",
    label: "Pre-marital Therapy",
  },
  {
    value: "RELATIONSHIP COUNSELLING - Communication & conflict resolution",
    label: "Communication & conflict resolution",
  },
  {
    value: "RELATIONSHIP COUNSELLING - Trust, intimacy & infidelity",
    label: "Trust, intimacy & infidelity",
  },
  {
    value: "RELATIONSHIP COUNSELLING - Infertility issues",
    label: "Infertility issues",
  },
  {
    value: "RELATIONSHIP COUNSELLING - Parenting/co-parenting",
    label: "Parenting/co-parenting",
  },
  { value: "RELATIONSHIP COUNSELLING - OTHERS", label: "Others" },
  {
    value: "Academic, Career & Workplace Counselling",
    label: "Academic, Career & Workplace Counselling",
    isDisabled: true,
  },
  {
    value: "Academic, Career & Workplace Counselling - Career coaching",
    label: "Career coaching",
  },
  {
    value:
      "Academic, Career & Workplace Counselling - Communication & conflict resolution",
    label: "Communication & conflict resolution",
  },
  {
    value: "Academic, Career & Workplace Counselling - Work-Stress management",
    label: "Work-Stress management",
  },
  {
    value:
      "Academic, Career & Workplace Counselling - Productivity & procrastination",
    label: "Productivity & procrastination",
  },
  {
    value:
      "Academic, Career & Workplace Counselling - Performance Enhancement & Mental Skills Training",
    label: "Performance Enhancement & Mental Skills Training",
  },
  {
    value: "Academic, Career & Workplace Counselling - Business coaching",
    label: "Business coaching",
  },
  {
    value:
      "Academic, Career & Workplace Counselling - Executive & leadership coaching",
    label: "Executive & leadership coaching",
  },
  {
    value: "Lifestyle Counselling",
    label: "Lifestyle Counselling",
    isDisabled: true,
  },
  {
    value: "Lifestyle Counselling - Existential crisis",
    label: "Existential crisis",
  },
  {
    value: "Lifestyle Counselling - Anger management",
    label: "Anger management",
  },
  {
    value: "Lifestyle Counselling - Confidence & self-esteem",
    label: "Confidence & self-esteem",
  },
  {
    value: "Lifestyle Counselling - Positivity, spirituality & wellbeing",
    label: "Lifestyle Counselling - Positivity, spirituality & wellbeing",
  },
  {
    value: "Lifestyle Counselling - Work-life balance",
    label: "Work-life balance",
  },
  {
    value: "Lifestyle Counselling - Money management",
    label: "Money management",
  },
  {
    value: "Lifestyle Counselling - Emotional/creativity block",
    label: "Emotional/creativity block",
  },
];

export const approachesOptions = [
  {
    label: "Acceptance and Commitment Therapy (ACT)",
    value: "Acceptance and Commitment Therapy (ACT)",
  },
  {
    label: "Applied Behavior Analysis (ABA) Therapy",
    value: "Applied Behavior Analysis (ABA) Therapy",
  },
  {
    label: "Cognitive Behavioral Therapy (CBT)",
    value: "Cognitive Behavioral Therapy (CBT)",
  },
  {
    label: "Dialectical Behavior Therapy (DBT)",
    value: "Dialectical Behavior Therapy (DBT)",
  },
  { label: "Emotion-Focused Therapy", value: "Emotion-Focused Therapy" },
  { label: "Existential Therapy", value: "Existential Therapy" },
  { label: "Expressive Art Therapy", value: "Expressive Art Therapy" },
  {
    label: "Eye Movement Desensitization and Reprocessing (EMDR) Therapy",
    value: "Eye Movement Desensitization and Reprocessing (EMDR) Therapy",
  },
  {
    label: "Family Constellation Therapy",
    value: "Family Constellation Therapy",
  },
  { label: "Gestalt Psychotherapy", value: "Gestalt Psychotherapy" },
  { label: "Hypnotherapy", value: "Hypnotherapy" },
  {
    label: "Mindfulness-Based Approaches",
    value: "Mindfulness-Based Approaches",
  },
  { label: "Motivational Interviewing", value: "Motivational Interviewing" },
  { label: "Music Therapy", value: "Music Therapy" },
  { label: "Narrative Therapy", value: "Narrative Therapy" },
  {
    label: "Neuro-Linguistic Programming (NLP)",
    value: "Neuro-Linguistic Programming (NLP)",
  },
  { label: "Person-Centered Therapy", value: "Person-Centered Therapy" },
  { label: "Psychoanalysis", value: "Psychoanalysis" },
  { label: "Psychodrama", value: "Psychodrama" },
  { label: "Psychodynamic Therapy", value: "Psychodynamic Therapy" },
  {
    label: "Rational Emotive Behavior Therapy (REBT)",
    value: "Rational Emotive Behavior Therapy (REBT)",
  },
  { label: "Solution-focused Therapy", value: "Solution-focused Therapy" },
  {
    label: "Solution-oriented Brief Therapy",
    value: "Solution-oriented Brief Therapy",
  },
  { label: "Somatic Experiencing", value: "Somatic Experiencing" },
  { label: "Systemic Therapy", value: "Systemic Therapy" },
  { label: "Time Line Therapy", value: "Time Line Therapy" },
  { label: "Transpersonal Therapy", value: "Transpersonal Therapy" },
  { label: "Trauma-focused Therapy", value: "Trauma-focused Therapy" },
  { label: "Other", value: "Other" },
];

export const maxHoursOptions = [
  { label: "0 - 15 hours", value: "0 - 15 hours" },
  { label: "15 - 30 hours", value: "15 - 30 hours" },
  { label: "30 - 45 hours", value: "30 - 45 hours" },
  { label: "45 - 60 hours", value: "45 - 60 hours" },
  { label: "60 - 75 hours", value: "60 - 75 hours" },
  { label: "75+ hours", value: "75+ hours" },
];

export const clinicalCredsOptions = [
  { value: "ABA Therapy", label: "ABA Therapy" },
  { value: "ACT Practitioner", label: "ACT Practitioner" },
  { value: "Business Coach", label: "Business Coach" },
  { value: "CBT Practitioner", label: "CBT Practitioner" },
  { value: "Child Psychology", label: "Child Psychology" },
  { value: "Clinical Psychologist", label: "Clinical Psychologist" },
  { value: "Compassionate Therapy", label: "Compassionate Therapy" },
  { value: "Counselling Psychologist", label: "Counselling Psychologist" },
  { value: "DBT Practitioner", label: "DBT Practitioner" },
  { value: "EMDR Therapy", label: "EMDR Therapy" },
  { value: "Expressive Art Therapy", label: "Expressive Art Therapy" },
  { value: "Gestalt Psychotherapy", label: "Gestalt Psychotherapy" },
  { value: "Life Coach", label: "Life Coach" },
  { value: "Mindfulness", label: "Mindfulness" },
  {
    value: "Neuropsychological Assessment",
    label: "Neuropsychological Assessment",
  },
  { value: "NLP Practitioner", label: "NLP Practitioner" },
  { value: "Occupational Therapist", label: "Occupational Therapist" },
  { value: "Psychiatry", label: "Psychiatry" },
  { value: "Psychoanalysis", label: "Psychoanalysis" },
  { value: "Psychodynamic Therapy", label: "Psychodynamic Therapy" },
  { value: "Psychological Assessments", label: "Psychological Assessments" },
  { value: "Solution Focused Therapy", label: "Solution Focused Therapy" },
  { value: "Sports Psychology", label: "Sports Psychology" },
  { value: "Trauma-Focused Therapy", label: "Trauma-Focused Therapy" },
  { value: "Clinical Dietitian", label: "Clinical Dietitian" },
  { value: "Wellness Coach", label: "Wellness Coach" },
  {
    value: "Nutrition Support Specialist",
    label: "Nutrition Support Specialist",
  },
  {
    value: "Specialist Complex Obesity Dietitian",
    label: "Specialist Complex Obesity Dietitian",
  },
  {
    value: "Specialist Bariatric Dietitian",
    label: "Specialist Bariatric Dietitian",
  },
];

export const professionsOptions = [
  { value: "Doctor", label: "Doctor" },
  { value: "Nurse", label: "Nurse" },
  { value: "Pharmacist", label: "Pharmacist" },
  { value: "Psychologist/Psychiatrist", label: "Psychologist/Psychiatrist" },
  { value: "Dentist", label: "Dentist" },
  { value: "Physical Therapist", label: "Physical Therapist" },
  { value: "Laboratory Technician", label: "Laboratory Technician" },
  { value: "Teacher", label: "Teacher" },
  { value: "Professor", label: "Professor" },
  { value: "Engineer", label: "Engineer" },
  { value: "Technician", label: "Technician" },
  { value: "Chef", label: "Chef" },
  { value: "Baker", label: "Baker" },
  { value: "Butcher", label: "Butcher" },
  { value: "Police Officer", label: "Police Officer" },
  { value: "Lawyer", label: "Lawyer" },
  { value: "Artist", label: "Artist" },
  { value: "Screenwriter", label: "Screenwriter" },
  { value: "Musician", label: "Musician" },
  { value: "DJ", label: "DJ" },
  { value: "Writer", label: "Writer" },
  { value: "Journalist", label: "Journalist" },
  { value: "Photographer", label: "Photographer" },
  { value: "Director", label: "Director" },
  { value: "Programmer", label: "Programmer" },
  { value: "Network Engineer", label: "Network Engineer" },
  { value: "Accountant", label: "Accountant" },
  { value: "CEO", label: "CEO" },
  { value: "Firefighter", label: "Firefighter" },
  { value: "Paramedic", label: "Paramedic" },
  { value: "Other", label: "Other" },
];

export const timeZonesOptions = [
  { value: "Pacific/Midway", label: "(GMT-11:00) Pacific/Midway" },
  { value: "Pacific/Niue", label: "(GMT-11:00) Pacific/Niue" },
  { value: "Pacific/Pago_Pago", label: "(GMT-11:00) Pacific/Pago_Pago" },
  { value: "America/Adak", label: "(GMT-10:00) America/Adak" },
  { value: "Pacific/Honolulu", label: "(GMT-10:00) Pacific/Honolulu" },
  { value: "Pacific/Rarotonga", label: "(GMT-10:00) Pacific/Rarotonga" },
  { value: "Pacific/Tahiti", label: "(GMT-10:00) Pacific/Tahiti" },
  { value: "US/Hawaii", label: "(GMT-10:00) US/Hawaii" },
  { value: "Pacific/Marquesas", label: "(GMT-09:30) Pacific/Marquesas" },
  { value: "America/Anchorage", label: "(GMT-09:00) America/Anchorage" },
  { value: "America/Juneau", label: "(GMT-09:00) America/Juneau" },
  { value: "America/Metlakatla", label: "(GMT-09:00) America/Metlakatla" },
  { value: "America/Nome", label: "(GMT-09:00) America/Nome" },
  { value: "America/Sitka", label: "(GMT-09:00) America/Sitka" },
  { value: "America/Yakutat", label: "(GMT-09:00) America/Yakutat" },
  { value: "Pacific/Gambier", label: "(GMT-09:00) Pacific/Gambier" },
  { value: "US/Alaska", label: "(GMT-09:00) US/Alaska" },
  { value: "America/Los_Angeles", label: "(GMT-08:00) America/Los_Angeles" },
  { value: "America/Tijuana", label: "(GMT-08:00) America/Tijuana" },
  { value: "America/Vancouver", label: "(GMT-08:00) America/Vancouver" },
  { value: "Canada/Pacific", label: "(GMT-08:00) Canada/Pacific" },
  { value: "Pacific/Pitcairn", label: "(GMT-08:00) Pacific/Pitcairn" },
  { value: "US/Pacific", label: "(GMT-08:00) US/Pacific" },
  { value: "America/Boise", label: "(GMT-07:00) America/Boise" },
  {
    value: "America/Cambridge_Bay",
    label: "(GMT-07:00) America/Cambridge_Bay",
  },
  {
    value: "America/Ciudad_Juarez",
    label: "(GMT-07:00) America/Ciudad_Juarez",
  },
  { value: "America/Creston", label: "(GMT-07:00) America/Creston" },
  { value: "America/Dawson", label: "(GMT-07:00) America/Dawson" },
  { value: "America/Dawson_Creek", label: "(GMT-07:00) America/Dawson_Creek" },
  { value: "America/Denver", label: "(GMT-07:00) America/Denver" },
  { value: "America/Edmonton", label: "(GMT-07:00) America/Edmonton" },
  { value: "America/Fort_Nelson", label: "(GMT-07:00) America/Fort_Nelson" },
  { value: "America/Hermosillo", label: "(GMT-07:00) America/Hermosillo" },
  { value: "America/Inuvik", label: "(GMT-07:00) America/Inuvik" },
  { value: "America/Mazatlan", label: "(GMT-07:00) America/Mazatlan" },
  { value: "America/Phoenix", label: "(GMT-07:00) America/Phoenix" },
  { value: "America/Whitehorse", label: "(GMT-07:00) America/Whitehorse" },
  { value: "Canada/Mountain", label: "(GMT-07:00) Canada/Mountain" },
  { value: "US/Arizona", label: "(GMT-07:00) US/Arizona" },
  { value: "US/Mountain", label: "(GMT-07:00) US/Mountain" },
  {
    value: "America/Bahia_Banderas",
    label: "(GMT-06:00) America/Bahia_Banderas",
  },
  { value: "America/Belize", label: "(GMT-06:00) America/Belize" },
  { value: "America/Chicago", label: "(GMT-06:00) America/Chicago" },
  { value: "America/Chihuahua", label: "(GMT-06:00) America/Chihuahua" },
  { value: "America/Costa_Rica", label: "(GMT-06:00) America/Costa_Rica" },
  { value: "America/El_Salvador", label: "(GMT-06:00) America/El_Salvador" },
  { value: "America/Guatemala", label: "(GMT-06:00) America/Guatemala" },
  { value: "America/Indiana/Knox", label: "(GMT-06:00) America/Indiana/Knox" },
  {
    value: "America/Indiana/Tell_City",
    label: "(GMT-06:00) America/Indiana/Tell_City",
  },
  { value: "America/Managua", label: "(GMT-06:00) America/Managua" },
  { value: "America/Matamoros", label: "(GMT-06:00) America/Matamoros" },
  { value: "America/Menominee", label: "(GMT-06:00) America/Menominee" },
  { value: "America/Merida", label: "(GMT-06:00) America/Merida" },
  { value: "America/Mexico_City", label: "(GMT-06:00) America/Mexico_City" },
  { value: "America/Monterrey", label: "(GMT-06:00) America/Monterrey" },
  {
    value: "America/North_Dakota/Beulah",
    label: "(GMT-06:00) America/North_Dakota/Beulah",
  },
  {
    value: "America/North_Dakota/Center",
    label: "(GMT-06:00) America/North_Dakota/Center",
  },
  {
    value: "America/North_Dakota/New_Salem",
    label: "(GMT-06:00) America/North_Dakota/New_Salem",
  },
  { value: "America/Ojinaga", label: "(GMT-06:00) America/Ojinaga" },
  { value: "America/Rankin_Inlet", label: "(GMT-06:00) America/Rankin_Inlet" },
  { value: "America/Regina", label: "(GMT-06:00) America/Regina" },
  { value: "America/Resolute", label: "(GMT-06:00) America/Resolute" },
  {
    value: "America/Swift_Current",
    label: "(GMT-06:00) America/Swift_Current",
  },
  { value: "America/Tegucigalpa", label: "(GMT-06:00) America/Tegucigalpa" },
  { value: "America/Winnipeg", label: "(GMT-06:00) America/Winnipeg" },
  { value: "Canada/Central", label: "(GMT-06:00) Canada/Central" },
  { value: "Pacific/Galapagos", label: "(GMT-06:00) Pacific/Galapagos" },
  { value: "US/Central", label: "(GMT-06:00) US/Central" },
  { value: "America/Atikokan", label: "(GMT-05:00) America/Atikokan" },
  { value: "America/Bogota", label: "(GMT-05:00) America/Bogota" },
  { value: "America/Cancun", label: "(GMT-05:00) America/Cancun" },
  { value: "America/Cayman", label: "(GMT-05:00) America/Cayman" },
  { value: "America/Detroit", label: "(GMT-05:00) America/Detroit" },
  { value: "America/Eirunepe", label: "(GMT-05:00) America/Eirunepe" },
  { value: "America/Grand_Turk", label: "(GMT-05:00) America/Grand_Turk" },
  { value: "America/Guayaquil", label: "(GMT-05:00) America/Guayaquil" },
  { value: "America/Havana", label: "(GMT-05:00) America/Havana" },
  {
    value: "America/Indiana/Indianapolis",
    label: "(GMT-05:00) America/Indiana/Indianapolis",
  },
  {
    value: "America/Indiana/Marengo",
    label: "(GMT-05:00) America/Indiana/Marengo",
  },
  {
    label: "(GMT-05:00) America/Indiana/Petersburg",
    value: "America/Indiana/Petersburg",
  },
  {
    label: "(GMT-05:00) America/Indiana/Vevay",
    value: "America/Indiana/Vevay",
  },
  {
    label: "(GMT-05:00) America/Indiana/Vincennes",
    value: "America/Indiana/Vincennes",
  },
  {
    label: "(GMT-05:00) America/Indiana/Winamac",
    value: "America/Indiana/Winamac",
  },
  { label: "(GMT-05:00) America/Iqaluit", value: "America/Iqaluit" },
  { label: "(GMT-05:00) America/Jamaica", value: "America/Jamaica" },
  {
    label: "(GMT-05:00) America/Kentucky/Louisville",
    value: "America/Kentucky/Louisville",
  },
  {
    label: "(GMT-05:00) America/Kentucky/Monticello",
    value: "America/Kentucky/Monticello",
  },
  { label: "(GMT-05:00) America/Lima", value: "America/Lima" },
  { label: "(GMT-05:00) America/Nassau", value: "America/Nassau" },
  { label: "(GMT-05:00) America/New_York", value: "America/New_York" },
  { label: "(GMT-05:00) America/Panama", value: "America/Panama" },
  {
    label: "(GMT-05:00) America/Port-au-Prince",
    value: "America/Port-au-Prince",
  },
  { label: "(GMT-05:00) America/Rio_Branco", value: "America/Rio_Branco" },
  { label: "(GMT-05:00) America/Toronto", value: "America/Toronto" },
  { label: "(GMT-05:00) Canada/Eastern", value: "Canada/Eastern" },
  { label: "(GMT-05:00) Pacific/Easter", value: "Pacific/Easter" },
  { label: "(GMT-05:00) US/Eastern", value: "US/Eastern" },
  { label: "(GMT-04:00) America/Anguilla", value: "America/Anguilla" },
  { label: "(GMT-04:00) America/Antigua", value: "America/Antigua" },
  { label: "(GMT-04:00) America/Aruba", value: "America/Aruba" },
  { label: "(GMT-04:00) America/Barbados", value: "America/Barbados" },
  { label: "(GMT-04:00) America/Blanc-Sablon", value: "America/Blanc-Sablon" },
  { label: "(GMT-04:00) America/Boa_Vista", value: "America/Boa_Vista" },
  { label: "(GMT-04:00) America/Campo_Grande", value: "America/Campo_Grande" },
  { label: "(GMT-04:00) America/Caracas", value: "America/Caracas" },
  { label: "(GMT-04:00) America/Cuiaba", value: "America/Cuiaba" },
  { label: "(GMT-04:00) America/Curacao", value: "America/Curacao" },
  { label: "(GMT-04:00) America/Dominica", value: "America/Dominica" },
  { label: "(GMT-04:00) America/Glace_Bay", value: "America/Glace_Bay" },
  { label: "(GMT-04:00) America/Goose_Bay", value: "America/Goose_Bay" },
  { label: "(GMT-04:00) America/Grenada", value: "America/Grenada" },
  { label: "(GMT-04:00) America/Guadeloupe", value: "America/Guadeloupe" },
  { label: "(GMT-04:00) America/Guyana", value: "America/Guyana" },
  { label: "(GMT-04:00) America/Halifax", value: "America/Halifax" },
  { label: "(GMT-04:00) America/Kralendijk", value: "America/Kralendijk" },
  { label: "(GMT-04:00) America/La_Paz", value: "America/La_Paz" },
  {
    label: "(GMT-04:00) America/Lower_Princes",
    value: "America/Lower_Princes",
  },
  { label: "(GMT-04:00) America/Manaus", value: "America/Manaus" },
  { label: "(GMT-04:00) America/Marigot", value: "America/Marigot" },
  { label: "(GMT-04:00) America/Martinique", value: "America/Martinique" },
  { label: "(GMT-04:00) America/Moncton", value: "America/Moncton" },
  { label: "(GMT-04:00) America/Montserrat", value: "America/Montserrat" },
  {
    label: "(GMT-04:00) America/Port_of_Spain",
    value: "America/Port_of_Spain",
  },
  { label: "(GMT-04:00) America/Porto_Velho", value: "America/Porto_Velho" },
  { label: "(GMT-04:00) America/Puerto_Rico", value: "America/Puerto_Rico" },
  {
    label: "(GMT-04:00) America/Santo_Domingo",
    value: "America/Santo_Domingo",
  },
  {
    label: "(GMT-04:00) America/St_Barthelemy",
    value: "America/St_Barthelemy",
  },
  { label: "(GMT-04:00) America/St_Kitts", value: "America/St_Kitts" },
  { label: "(GMT-04:00) America/St_Lucia", value: "America/St_Lucia" },
  { label: "(GMT-04:00) America/St_Thomas", value: "America/St_Thomas" },
  { label: "(GMT-04:00) America/St_Vincent", value: "America/St_Vincent" },
  { label: "(GMT-04:00) America/Thule", value: "America/Thule" },
  { label: "(GMT-04:00) America/Tortola", value: "America/Tortola" },
  { label: "(GMT-04:00) Atlantic/Bermuda", value: "Atlantic/Bermuda" },
  { label: "(GMT-04:00) Canada/Atlantic", value: "Canada/Atlantic" },
  { label: "(GMT-03:30) America/St_Johns", value: "America/St_Johns" },
  { label: "(GMT-03:30) Canada/Newfoundland", value: "Canada/Newfoundland" },
  { label: "(GMT-03:00) America/Araguaina", value: "America/Araguaina" },
  {
    label: "(GMT-03:00) America/Argentina/Buenos_Aires",
    value: "America/Argentina/Buenos_Aires",
  },
  {
    label: "(GMT-03:00) America/Argentina/Catamarca",
    value: "America/Argentina/Catamarca",
  },
  {
    label: "(GMT-03:00) America/Argentina/Cordoba",
    value: "America/Argentina/Cordoba",
  },
  {
    label: "(GMT-03:00) America/Argentina/Jujuy",
    value: "America/Argentina/Jujuy",
  },
  {
    label: "(GMT-03:00) America/Argentina/La_Rioja",
    value: "America/Argentina/La_Rioja",
  },
  {
    label: "(GMT-03:00) America/Argentina/Mendoza",
    value: "America/Argentina/Mendoza",
  },
  {
    label: "(GMT-03:00) America/Argentina/Rio_Gallegos",
    value: "America/Argentina/Rio_Gallegos",
  },
  {
    label: "(GMT-03:00) America/Argentina/Salta",
    value: "America/Argentina/Salta",
  },
  {
    label: "(GMT-03:00) America/Argentina/San_Juan",
    value: "America/Argentina/San_Juan",
  },
  {
    label: "(GMT-03:00) America/Argentina/San_Luis",
    value: "America/Argentina/San_Luis",
  },
  {
    label: "(GMT-03:00) America/Argentina/Tucuman",
    value: "America/Argentina/Tucuman",
  },
  {
    label: "(GMT-03:00) America/Argentina/Ushuaia",
    value: "America/Argentina/Ushuaia",
  },
  { label: "(GMT-03:00) America/Asuncion", value: "America/Asuncion" },
  { label: "(GMT-03:00) America/Bahia", value: "America/Bahia" },
  { label: "(GMT-03:00) America/Belem", value: "America/Belem" },
  { label: "(GMT-03:00) America/Cayenne", value: "America/Cayenne" },
  { label: "(GMT-03:00) America/Fortaleza", value: "America/Fortaleza" },
  { label: "(GMT-03:00) America/Maceio", value: "America/Maceio" },
  { label: "(GMT-03:00) America/Miquelon", value: "America/Miquelon" },
  { label: "(GMT-03:00) America/Montevideo", value: "America/Montevideo" },
  { label: "(GMT-03:00) America/Paramaribo", value: "America/Paramaribo" },
  { label: "(GMT-03:00) America/Punta_Arenas", value: "America/Punta_Arenas" },
  { label: "(GMT-03:00) America/Recife", value: "America/Recife" },
  { label: "(GMT-03:00) America/Santarem", value: "America/Santarem" },
  { label: "(GMT-03:00) America/Santiago", value: "America/Santiago" },
  { label: "(GMT-03:00) America/Sao_Paulo", value: "America/Sao_Paulo" },
  { label: "(GMT-03:00) Antarctica/Palmer", value: "Antarctica/Palmer" },
  { label: "(GMT-03:00) Antarctica/Rothera", value: "Antarctica/Rothera" },
  { label: "(GMT-03:00) Atlantic/Stanley", value: "Atlantic/Stanley" },
  { label: "(GMT-02:00) America/Noronha", value: "America/Noronha" },
  { label: "(GMT-02:00) America/Nuuk", value: "America/Nuuk" },
  {
    label: "(GMT-02:00) Atlantic/South_Georgia",
    value: "Atlantic/South_Georgia",
  },
  { label: "(GMT-02:00) America/Scoresbysund", value: "America/Scoresbysund" },
  { label: "(GMT-01:00) Atlantic/Azores", value: "Atlantic/Azores" },
  { label: "(GMT-01:00) Atlantic/Cape_Verde", value: "Atlantic/Cape_Verde" },
  { label: "(GMT+00:00) Africa/Abidjan", value: "Africa/Abidjan" },
  { label: "(GMT+00:00) Africa/Accra", value: "Africa/Accra" },
  { label: "(GMT+00:00) Africa/Bamako", value: "Africa/Bamako" },
  { label: "(GMT+00:00) Africa/Banjul", value: "Africa/Banjul" },
  { label: "(GMT+00:00) Africa/Bissau", value: "Africa/Bissau" },
  { label: "(GMT+00:00) Africa/Conakry", value: "Africa/Conakry" },
  { label: "(GMT+00:00) Africa/Dakar", value: "Africa/Dakar" },
  { label: "(GMT+00:00) Africa/Freetown", value: "Africa/Freetown" },
  { label: "(GMT+00:00) Africa/Lome", value: "Africa/Lome" },
  { label: "(GMT+00:00) Africa/Monrovia", value: "Africa/Monrovia" },
  { label: "(GMT+00:00) Africa/Nouakchott", value: "Africa/Nouakchott" },
  { label: "(GMT+00:00) Africa/Ouagadougou", value: "Africa/Ouagadougou" },
  { label: "(GMT+00:00) Africa/Sao_Tome", value: "Africa/Sao_Tome" },
  { label: "(GMT+00:00) America/Danmarkshavn", value: "America/Danmarkshavn" },
  { label: "(GMT+00:00) Antarctica/Troll", value: "Antarctica/Troll" },
  { label: "(GMT+00:00) Atlantic/Canary", value: "Atlantic/Canary" },
  { label: "(GMT+00:00) Atlantic/Faroe", value: "Atlantic/Faroe" },
  { label: "(GMT+00:00) Atlantic/Madeira", value: "Atlantic/Madeira" },
  { label: "(GMT+00:00) Atlantic/Reykjavik", value: "Atlantic/Reykjavik" },
  { label: "(GMT+00:00) Atlantic/St_Helena", value: "Atlantic/St_Helena" },
  { label: "(GMT+00:00) Europe/Dublin", value: "Europe/Dublin" },
  { label: "(GMT+00:00) Europe/Guernsey", value: "Europe/Guernsey" },
  { label: "(GMT+00:00) Europe/Isle_of_Man", value: "Europe/Isle_of_Man" },
  { label: "(GMT+00:00) Europe/Jersey", value: "Europe/Jersey" },
  { label: "(GMT+00:00) Europe/Lisbon", value: "Europe/Lisbon" },
  { label: "(GMT+00:00) Europe/London", value: "Europe/London" },
  { label: "(GMT+00:00) GMT", value: "GMT" },
  { label: "(GMT+00:00) UTC", value: "UTC" },
  { label: "(GMT+01:00) Africa/Algiers", value: "Africa/Algiers" },
  { label: "(GMT+01:00) Africa/Bangui", value: "Africa/Bangui" },
  { label: "(GMT+01:00) Africa/Brazzaville", value: "Africa/Brazzaville" },
  { label: "(GMT+01:00) Africa/Casablanca", value: "Africa/Casablanca" },
  { label: "(GMT+01:00) Africa/Ceuta", value: "Africa/Ceuta" },
  { label: "(GMT+01:00) Africa/Douala", value: "Africa/Douala" },
  { label: "(GMT+01:00) Africa/El_Aaiun", value: "Africa/El_Aaiun" },
  { label: "(GMT+01:00) Africa/Kinshasa", value: "Africa/Kinshasa" },
  { label: "(GMT+01:00) Africa/Lagos", value: "Africa/Lagos" },
  { label: "(GMT+01:00) Africa/Libreville", value: "Africa/Libreville" },
  { label: "(GMT+01:00) Africa/Luanda", value: "Africa/Luanda" },
  { label: "(GMT+01:00) Africa/Malabo", value: "Africa/Malabo" },
  { label: "(GMT+01:00) Africa/Ndjamena", value: "Africa/Ndjamena" },
  { label: "(GMT+01:00) Africa/Niamey", value: "Africa/Niamey" },
  { label: "(GMT+01:00) Africa/Porto-Novo", value: "Africa/Porto-Novo" },
  { label: "(GMT+01:00) Africa/Tunis", value: "Africa/Tunis" },
  { label: "(GMT+01:00) Arctic/Longyearbyen", value: "Arctic/Longyearbyen" },
  { label: "(GMT+01:00) Europe/Amsterdam", value: "Europe/Amsterdam" },
  { label: "(GMT+01:00) Europe/Andorra", value: "Europe/Andorra" },
  { label: "(GMT+01:00) Europe/Belgrade", value: "Europe/Belgrade" },
  { label: "(GMT+01:00) Europe/Berlin", value: "Europe/Berlin" },
  { label: "(GMT+01:00) Europe/Bratislava", value: "Europe/Bratislava" },
  { label: "(GMT+01:00) Europe/Brussels", value: "Europe/Brussels" },
  { label: "(GMT+01:00) Europe/Budapest", value: "Europe/Budapest" },
  { label: "(GMT+01:00) Europe/Busingen", value: "Europe/Busingen" },
  { label: "(GMT+01:00) Europe/Copenhagen", value: "Europe/Copenhagen" },
  { label: "(GMT+01:00) Europe/Gibraltar", value: "Europe/Gibraltar" },
  { label: "(GMT+01:00) Europe/Ljubljana", value: "Europe/Ljubljana" },
  { label: "(GMT+01:00) Europe/Luxembourg", value: "Europe/Luxembourg" },
  { label: "(GMT+01:00) Europe/Madrid", value: "Europe/Madrid" },
  { label: "(GMT+01:00) Europe/Malta", value: "Europe/Malta" },
  { label: "(GMT+01:00) Europe/Monaco", value: "Europe/Monaco" },
  { label: "(GMT+01:00) Europe/Oslo", value: "Europe/Oslo" },
  { label: "(GMT+01:00) Europe/Paris", value: "Europe/Paris" },
  { label: "(GMT+01:00) Europe/Podgorica", value: "Europe/Podgorica" },
  { label: "(GMT+01:00) Europe/Prague", value: "Europe/Prague" },
  { label: "(GMT+01:00) Europe/Rome", value: "Europe/Rome" },
  { label: "(GMT+01:00) Europe/San_Marino", value: "Europe/San_Marino" },
  { label: "(GMT+01:00) Europe/Sarajevo", value: "Europe/Sarajevo" },
  { label: "(GMT+01:00) Europe/Skopje", value: "Europe/Skopje" },
  { label: "(GMT+01:00) Europe/Stockholm", value: "Europe/Stockholm" },
  { label: "(GMT+01:00) Europe/Tirane", value: "Europe/Tirane" },
  { label: "(GMT+01:00) Europe/Vaduz", value: "Europe/Vaduz" },
  { label: "(GMT+01:00) Europe/Vatican", value: "Europe/Vatican" },
  { label: "(GMT+01:00) Europe/Vienna", value: "Europe/Vienna" },
  { label: "(GMT+01:00) Europe/Warsaw", value: "Europe/Warsaw" },
  { label: "(GMT+01:00) Europe/Zagreb", value: "Europe/Zagreb" },
  { label: "(GMT+01:00) Europe/Zurich", value: "Europe/Zurich" },
  { label: "(GMT+02:00) Africa/Blantyre", value: "Africa/Blantyre" },
  { label: "(GMT+02:00) Africa/Bujumbura", value: "Africa/Bujumbura" },
  { label: "(GMT+02:00) Africa/Cairo", value: "Africa/Cairo" },
  { label: "(GMT+02:00) Africa/Gaborone", value: "Africa/Gaborone" },
  { label: "(GMT+02:00) Africa/Harare", value: "Africa/Harare" },
  { label: "(GMT+02:00) Africa/Johannesburg", value: "Africa/Johannesburg" },
  { label: "(GMT+02:00) Africa/Juba", value: "Africa/Juba" },
  { label: "(GMT+02:00) Africa/Khartoum", value: "Africa/Khartoum" },
  { label: "(GMT+02:00) Africa/Kigali", value: "Africa/Kigali" },
  { label: "(GMT+02:00) Africa/Lubumbashi", value: "Africa/Lubumbashi" },
  { label: "(GMT+02:00) Africa/Lusaka", value: "Africa/Lusaka" },
  { label: "(GMT+02:00) Africa/Maputo", value: "Africa/Maputo" },
  { label: "(GMT+02:00) Africa/Maseru", value: "Africa/Maseru" },
  { label: "(GMT+02:00) Africa/Mbabane", value: "Africa/Mbabane" },
  { label: "(GMT+02:00) Africa/Tripoli", value: "Africa/Tripoli" },
  { label: "(GMT+02:00) Africa/Windhoek", value: "Africa/Windhoek" },
  { label: "(GMT+02:00) Asia/Beirut", value: "Asia/Beirut" },
  { label: "(GMT+02:00) Asia/Famagusta", value: "Asia/Famagusta" },
  { label: "(GMT+02:00) Asia/Gaza", value: "Asia/Gaza" },
  { label: "(GMT+02:00) Asia/Hebron", value: "Asia/Hebron" },
  { label: "(GMT+02:00) Asia/Jerusalem", value: "Asia/Jerusalem" },
  { label: "(GMT+02:00) Asia/Nicosia", value: "Asia/Nicosia" },
  { label: "(GMT+02:00) Europe/Athens", value: "Europe/Athens" },
  { label: "(GMT+02:00) Europe/Bucharest", value: "Europe/Bucharest" },
  { label: "(GMT+02:00) Europe/Chisinau", value: "Europe/Chisinau" },
  { label: "(GMT+02:00) Europe/Helsinki", value: "Europe/Helsinki" },
  { label: "(GMT+02:00) Europe/Kaliningrad", value: "Europe/Kaliningrad" },
  { label: "(GMT+02:00) Europe/Kyiv", value: "Europe/Kyiv" },
  { label: "(GMT+02:00) Europe/Mariehamn", value: "Europe/Mariehamn" },
  { label: "(GMT+02:00) Europe/Riga", value: "Europe/Riga" },
  { label: "(GMT+02:00) Europe/Sofia", value: "Europe/Sofia" },
  { label: "(GMT+02:00) Europe/Tallinn", value: "Europe/Tallinn" },
  { label: "(GMT+02:00) Europe/Vilnius", value: "Europe/Vilnius" },
  { label: "(GMT+03:00) Africa/Addis_Ababa", value: "Africa/Addis_Ababa" },
  { label: "(GMT+03:00) Africa/Asmara", value: "Africa/Asmara" },
  { label: "(GMT+03:00) Africa/Dar_es_Salaam", value: "Africa/Dar_es_Salaam" },
  { label: "(GMT+03:00) Africa/Djibouti", value: "Africa/Djibouti" },
  { label: "(GMT+03:00) Africa/Kampala", value: "Africa/Kampala" },
  { label: "(GMT+03:00) Africa/Mogadishu", value: "Africa/Mogadishu" },
  { label: "(GMT+03:00) Africa/Nairobi", value: "Africa/Nairobi" },
  { label: "(GMT+03:00) Antarctica/Syowa", value: "Antarctica/Syowa" },
  { label: "(GMT+03:00) Asia/Aden", value: "Asia/Aden" },
  { label: "(GMT+03:00) Asia/Amman", value: "Asia/Amman" },
  { label: "(GMT+03:00) Asia/Baghdad", value: "Asia/Baghdad" },
  { label: "(GMT+03:00) Asia/Bahrain", value: "Asia/Bahrain" },
  { label: "(GMT+03:00) Asia/Damascus", value: "Asia/Damascus" },
  { label: "(GMT+03:00) Asia/Kuwait", value: "Asia/Kuwait" },
  { label: "(GMT+03:00) Asia/Qatar", value: "Asia/Qatar" },
  { label: "(GMT+03:00) Asia/Riyadh", value: "Asia/Riyadh" },
  { label: "(GMT+03:00) Europe/Istanbul", value: "Europe/Istanbul" },
  { label: "(GMT+03:00) Europe/Kirov", value: "Europe/Kirov" },
  { label: "(GMT+03:00) Europe/Minsk", value: "Europe/Minsk" },
  { label: "(GMT+03:00) Europe/Moscow", value: "Europe/Moscow" },
  { label: "(GMT+03:00) Europe/Simferopol", value: "Europe/Simferopol" },
  { label: "(GMT+03:00) Europe/Volgograd", value: "Europe/Volgograd" },
  { label: "(GMT+03:00) Indian/Antananarivo", value: "Indian/Antananarivo" },
  { label: "(GMT+03:00) Indian/Comoro", value: "Indian/Comoro" },
  { label: "(GMT+03:00) Indian/Mayotte", value: "Indian/Mayotte" },
  { label: "(GMT+03:30) Asia/Tehran", value: "Asia/Tehran" },
  { label: "(GMT+04:00) Asia/Baku", value: "Asia/Baku" },
  { label: "(GMT+04:00) Asia/Dubai", value: "Asia/Dubai" },
  { label: "(GMT+04:00) Asia/Muscat", value: "Asia/Muscat" },
  { label: "(GMT+04:00) Asia/Tbilisi", value: "Asia/Tbilisi" },
  { label: "(GMT+04:00) Asia/Yerevan", value: "Asia/Yerevan" },
  { label: "(GMT+04:00) Europe/Astrakhan", value: "Europe/Astrakhan" },
  { label: "(GMT+04:00) Europe/Samara", value: "Europe/Samara" },
  { label: "(GMT+04:00) Europe/Saratov", value: "Europe/Saratov" },
  { label: "(GMT+04:00) Europe/Ulyanovsk", value: "Europe/Ulyanovsk" },
  { label: "(GMT+04:00) Indian/Mahe", value: "Indian/Mahe" },
  { label: "(GMT+04:00) Indian/Mauritius", value: "Indian/Mauritius" },
  { label: "(GMT+04:00) Indian/Reunion", value: "Indian/Reunion" },
  { label: "(GMT+04:30) Asia/Kabul", value: "Asia/Kabul" },
  { label: "(GMT+05:00) Antarctica/Mawson", value: "Antarctica/Mawson" },
  { label: "(GMT+05:00) Asia/Aqtau", value: "Asia/Aqtau" },
  { label: "(GMT+05:00) Asia/Aqtobe", value: "Asia/Aqtobe" },
  { label: "(GMT+05:00) Asia/Ashgabat", value: "Asia/Ashgabat" },
  { label: "(GMT+05:00) Asia/Atyrau", value: "Asia/Atyrau" },
  { label: "(GMT+05:00) Asia/Dushanbe", value: "Asia/Dushanbe" },
  { label: "(GMT+05:00) Asia/Karachi", value: "Asia/Karachi" },
  { label: "(GMT+05:00) Asia/Oral", value: "Asia/Oral" },
  { label: "(GMT+05:00) Asia/Qyzylorda", value: "Asia/Qyzylorda" },
  { label: "(GMT+05:00) Asia/Samarkand", value: "Asia/Samarkand" },
  { label: "(GMT+05:00) Asia/Tashkent", value: "Asia/Tashkent" },
  { label: "(GMT+05:00) Asia/Yekaterinburg", value: "Asia/Yekaterinburg" },
  { label: "(GMT+05:00) Indian/Kerguelen", value: "Indian/Kerguelen" },
  { label: "(GMT+05:00) Indian/Maldives", value: "Indian/Maldives" },
  { label: "(GMT+05:30) Asia/Colombo", value: "Asia/Colombo" },
  { label: "(GMT+05:30) Asia/Kolkata", value: "Asia/Kolkata" },
  { label: "(GMT+05:45) Asia/Kathmandu", value: "Asia/Kathmandu" },
  { label: "(GMT+06:00) Antarctica/Vostok", value: "Antarctica/Vostok" },
  { label: "(GMT+06:00) Asia/Almaty", value: "Asia/Almaty" },
  { label: "(GMT+06:00) Asia/Bishkek", value: "Asia/Bishkek" },
  { label: "(GMT+06:00) Asia/Dhaka", value: "Asia/Dhaka" },
  { label: "(GMT+06:00) Asia/Omsk", value: "Asia/Omsk" },
  { label: "(GMT+06:00) Asia/Qostanay", value: "Asia/Qostanay" },
  { label: "Asia/Thimphu", value: "Asia/Thimphu" },
  { label: "(GMT+06:00) Asia/Thimphu", value: "Asia/Thimphu" },
  { label: "(GMT+06:00) Asia/Urumqi", value: "Asia/Urumqi" },
  { label: "(GMT+06:00) Indian/Chagos", value: "Indian/Chagos" },
  { label: "(GMT+06:30) Asia/Yangon", value: "Asia/Yangon" },
  { label: "(GMT+06:30) Indian/Cocos", value: "Indian/Cocos" },
  { label: "(GMT+07:00) Antarctica/Davis", value: "Antarctica/Davis" },
  { label: "(GMT+07:00) Asia/Bangkok", value: "Asia/Bangkok" },
  { label: "(GMT+07:00) Asia/Barnaul", value: "Asia/Barnaul" },
  { label: "(GMT+07:00) Asia/Ho_Chi_Minh", value: "Asia/Ho_Chi_Minh" },
  { label: "(GMT+07:00) Asia/Hovd", value: "Asia/Hovd" },
  { label: "(GMT+07:00) Asia/Jakarta", value: "Asia/Jakarta" },
  { label: "(GMT+07:00) Asia/Krasnoyarsk", value: "Asia/Krasnoyarsk" },
  { label: "(GMT+07:00) Asia/Novokuznetsk", value: "Asia/Novokuznetsk" },
  { label: "(GMT+07:00) Asia/Novosibirsk", value: "Asia/Novosibirsk" },
  { label: "(GMT+07:00) Asia/Phnom_Penh", value: "Asia/Phnom_Penh" },
  { label: "(GMT+07:00) Asia/Pontianak", value: "Asia/Pontianak" },
  { label: "(GMT+07:00) Asia/Tomsk", value: "Asia/Tomsk" },
  { label: "(GMT+07:00) Asia/Vientiane", value: "Asia/Vientiane" },
  { label: "(GMT+07:00) Indian/Christmas", value: "Indian/Christmas" },
  { label: "(GMT+08:00) Asia/Brunei", value: "Asia/Brunei" },
  { label: "(GMT+08:00) Asia/Choibalsan", value: "Asia/Choibalsan" },
  { label: "(GMT+08:00) Asia/Hong_Kong", value: "Asia/Hong_Kong" },
  { value: "Asia/Irkutsk", label: "(GMT+08:00) Asia/Irkutsk" },
  { value: "Asia/Kuala_Lumpur", label: "(GMT+08:00) Asia/Kuala_Lumpur" },
  { value: "Asia/Kuching", label: "(GMT+08:00) Asia/Kuching" },
  { value: "Asia/Macau", label: "(GMT+08:00) Asia/Macau" },
  { value: "Asia/Makassar", label: "(GMT+08:00) Asia/Makassar" },
  { value: "Asia/Manila", label: "(GMT+08:00) Asia/Manila" },
  { value: "Asia/Shanghai", label: "(GMT+08:00) Asia/Shanghai" },
  { value: "Asia/Singapore", label: "(GMT+08:00) Asia/Singapore" },
  { value: "Asia/Taipei", label: "(GMT+08:00) Asia/Taipei" },
  { value: "Asia/Ulaanbaatar", label: "(GMT+08:00) Asia/Ulaanbaatar" },
  { value: "Australia/Perth", label: "(GMT+08:00) Australia/Perth" },
  { value: "Australia/Eucla", label: "(GMT+08:45) Australia/Eucla" },
  { value: "Asia/Chita", label: "(GMT+09:00) Asia/Chita" },
  { value: "Asia/Dili", label: "(GMT+09:00) Asia/Dili" },
  { value: "Asia/Jayapura", label: "(GMT+09:00) Asia/Jayapura" },
  { value: "Asia/Khandyga", label: "(GMT+09:00) Asia/Khandyga" },
  { value: "Asia/Pyongyang", label: "(GMT+09:00) Asia/Pyongyang" },
  { value: "Asia/Seoul", label: "(GMT+09:00) Asia/Seoul" },
  { value: "Asia/Tokyo", label: "(GMT+09:00) Asia/Tokyo" },
  { value: "Asia/Yakutsk", label: "(GMT+09:00) Asia/Yakutsk" },
  { value: "Pacific/Palau", label: "(GMT+09:00) Pacific/Palau" },
  { value: "Australia/Darwin", label: "(GMT+09:30) Australia/Darwin" },
  {
    value: "Antarctica/DumontDUrville",
    label: "(GMT+10:00) Antarctica/DumontDUrville",
  },
  { value: "Asia/Ust-Nera", label: "(GMT+10:00) Asia/Ust-Nera" },
  { value: "Asia/Vladivostok", label: "(GMT+10:00) Asia/Vladivostok" },
  { value: "Australia/Brisbane", label: "(GMT+10:00) Australia/Brisbane" },
  { value: "Australia/Lindeman", label: "(GMT+10:00) Australia/Lindeman" },
  { value: "Pacific/Chuuk", label: "(GMT+10:00) Pacific/Chuuk" },
  { value: "Pacific/Guam", label: "(GMT+10:00) Pacific/Guam" },
  { value: "Pacific/Port_Moresby", label: "(GMT+10:00) Pacific/Port_Moresby" },
  { value: "Pacific/Saipan", label: "(GMT+10:00) Pacific/Saipan" },
  { value: "Australia/Adelaide", label: "(GMT+10:30) Australia/Adelaide" },
  {
    value: "Australia/Broken_Hill",
    label: "(GMT+10:30) Australia/Broken_Hill",
  },
  { value: "Antarctica/Casey", label: "(GMT+11:00) Antarctica/Casey" },
  { value: "Antarctica/Macquarie", label: "(GMT+11:00) Antarctica/Macquarie" },
  { value: "Asia/Magadan", label: "(GMT+11:00) Asia/Magadan" },
  { value: "Asia/Sakhalin", label: "(GMT+11:00) Asia/Sakhalin" },
  { value: "Asia/Srednekolymsk", label: "(GMT+11:00) Asia/Srednekolymsk" },
  { value: "Australia/Hobart", label: "(GMT+11:00) Australia/Hobart" },
  { value: "Australia/Lord_Howe", label: "(GMT+11:00) Australia/Lord_Howe" },
  { value: "Australia/Melbourne", label: "(GMT+11:00) Australia/Melbourne" },
  { value: "Australia/Sydney", label: "(GMT+11:00) Australia/Sydney" },
  { value: "Pacific/Bougainville", label: "(GMT+11:00) Pacific/Bougainville" },
  { value: "Pacific/Efate", label: "(GMT+11:00) Pacific/Efate" },
  { value: "Pacific/Guadalcanal", label: "(GMT+11:00) Pacific/Guadalcanal" },
  { value: "Pacific/Kosrae", label: "(GMT+11:00) Pacific/Kosrae" },
  { value: "Pacific/Noumea", label: "(GMT+11:00) Pacific/Noumea" },
  { value: "Pacific/Pohnpei", label: "(GMT+11:00) Pacific/Pohnpei" },
  { value: "Asia/Anadyr", label: "(GMT+12:00) Asia/Anadyr" },
  { value: "Asia/Kamchatka", label: "(GMT+12:00) Asia/Kamchatka" },
  { value: "Pacific/Fiji", label: "(GMT+12:00) Pacific/Fiji" },
  { value: "Pacific/Funafuti", label: "(GMT+12:00) Pacific/Funafuti" },
  { value: "Pacific/Kwajalein", label: "(GMT+12:00) Pacific/Kwajalein" },
  { value: "Pacific/Majuro", label: "(GMT+12:00) Pacific/Majuro" },
  { value: "Pacific/Nauru", label: "(GMT+12:00) Pacific/Nauru" },
  { value: "Pacific/Norfolk", label: "(GMT+12:00) Pacific/Norfolk" },
  { value: "Pacific/Tarawa", label: "(GMT+12:00) Pacific/Tarawa" },
  { value: "Pacific/Wake", label: "(GMT+12:00) Pacific/Wake" },
  { value: "Pacific/Wallis", label: "(GMT+12:00) Pacific/Wallis" },
  { value: "Antarctica/McMurdo", label: "(GMT+13:00) Antarctica/McMurdo" },
  { value: "Pacific/Apia", label: "(GMT+13:00) Pacific/Apia" },
  { value: "Pacific/Auckland", label: "(GMT+13:00) Pacific/Auckland" },
  { value: "Pacific/Fakaofo", label: "(GMT+13:00) Pacific/Fakaofo" },
  { value: "Pacific/Kanton", label: "(GMT+13:00) Pacific/Kanton" },
  { value: "Pacific/Tongatapu", label: "(GMT+13:00) Pacific/Tongatapu" },
  { value: "Pacific/Chatham", label: "(GMT+13:45) Pacific/Chatham" },
  { value: "Pacific/Kiritimati", label: "(GMT+14:00) Pacific/Kiritimati" },
];
